import { ICustomIdFieldPopulateFieldItem } from './custom-id-input-field-populates-item.model';

export interface IFormFieldsValidationModel {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  uniqueField?: string[];
  uniqueFieldMessage?: string;
  patternValidation?: {
    patternType: EFieldValidationPatternConfigOptions;
    customPattern: string;
  };
  populates?: ICustomIdFieldPopulateFieldItem[];
  validationMessages?: Record<string, string>;
  fileSize?: IFileSizeValidationConfig;
  fileFormats?: Record<string, boolean> | undefined;
}

export enum EFieldValidationPatternConfigOptions {
  EMAIL = 'Email',
  LETTERS_A_TO_Z = 'LettersAToZ',
  NUMBERS_0_TO_9 = 'Numbers0To9',
  SYMBOLS = 'Symbols',
  LETTERS_AND_NUMBERS = 'LettersAndNumbers',
  LETTERS_AND_NUMBERS_AND_SYMBOLS = 'LettersAndNumbersAndSymbols',
  CUSTOM = 'Custom',
}

interface IFileSizeValidationConfig {
  minimumUploadSize?: number;
  maximumUploadSize?: number;
}
