import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  IBaseConfigurationFormModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  baseFieldParseIntoSettingsFormModel,
  baseFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import {
  getMaxLengthValidatorFormConfig,
  getMinLengthValidatorFormConfig,
  getRequiredValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import {
  parseMaxLengthValidatorParseFieldIntoModel,
  parseMinLengthValidatorParseFieldIntoModel,
  parseRequiredValidatorParseFieldIntoModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import {
  setRequiredValidatorUpdateFieldFromModel,
  setMinLengthValidatorUpdateFieldFromModel,
  setMaxLengthValidatorUpdateFieldFromModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';

const defaultTextareaConfigFormModel: IBaseConfigurationFormModel = {
  ...fieldsBaseConfigurationFormModel(),
};

export const textareaFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultTextareaConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getMinLengthValidatorFormConfig(),
        getMaxLengthValidatorFormConfig(),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const textareaFieldParseIntoSettingsFormModel = (
  textareaField: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  return baseFieldParseIntoSettingsFormModel(textareaField);
};

export const textareaFieldUpdateFromSettingsFormModel = (
  model: IBaseConfigurationFormModel,
  textareaField: FormlyFieldConfig
): FormlyFieldConfig => {
  return baseFieldUpdateFromSettingsFormModel(model, textareaField);
};

export const textareaFieldParseIntoValidationFormModel = (
  textareaField: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, textareaField);
  model = parseMinLengthValidatorParseFieldIntoModel(model, textareaField);
  model = parseMaxLengthValidatorParseFieldIntoModel(model, textareaField);

  return model;
};

export const textareaFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  textareaField: FormlyFieldConfig
): FormlyFieldConfig => {
  textareaField = setRequiredValidatorUpdateFieldFromModel(
    model,
    textareaField
  );
  textareaField = setMinLengthValidatorUpdateFieldFromModel(
    model,
    textareaField
  );
  textareaField = setMaxLengthValidatorUpdateFieldFromModel(
    model,
    textareaField
  );

  return textareaField;
};

export const textareaFieldParseFieldIntoDisplayFormModel = (
  textareaField: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(textareaField);
};

export const textareaFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  textareaField: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, textareaField);
};
