import {
  IBaseConfigurationFormModel,
  IFormbuilderFieldTypeConfiguration,
} from '../../../models/form-builder-types-configurations.model';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import {
  getDefaultBaseSettingsConfigFormly,
  getPopulatesPropsSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import {
  getRequiredValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { FormBuilderService } from '../../../services/form-builder.service';

export const fieldsBaseConfigurationFormModel =
  (): IBaseConfigurationFormModel => {
    return {
      fieldLabel: '',
      placeholder: '',
      hint: '',
      tooltip: '',
      internalDescription: '',
      readonly: false,
    };
  };

export const getGenericCustomIdFieldTypeconfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService,
  dataFieldProps: string[]
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: { ...fieldsBaseConfigurationFormModel() },
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
        ...getPopulatesPropsSettingsConfigFormly(
          formBuilderServiceInstance,
          dataFieldProps
        ),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};
