import { EServiceChangeRequestComparator } from './../../core/models/service-change-request-comparator.enum';
export const ServiceComparatorOptions = [
  {
    value: EServiceChangeRequestComparator.EQ,
    label: 'Equal To',
  },
  {
    value: EServiceChangeRequestComparator.GT,
    label: 'Greater Than',
  },
  {
    value: EServiceChangeRequestComparator.GTE,
    label: 'Greater Or Equal To',
  },
  {
    value: EServiceChangeRequestComparator.LT,
    label: 'Less Than',
  },
  {
    value: EServiceChangeRequestComparator.LTE,
    label: 'Less Or Equal To',
  },
];
