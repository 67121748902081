import { IBasicLabelValuePair } from '../models/basic-label-value-pair.model';

export const AttachmentFieldFileFormat: Record<
  string,
  IBasicLabelValuePair<string[]>
> = {
  IMAGE: {
    label: 'Images(.jpg, .jpeg, .png ...)',
    value: ['jpg', 'jpeg', 'jfif', 'pjpeg', '.pjp', 'png'],
  },
  PDF: {
    label: 'Pdf',
    value: ['pdf'],
  },
};
