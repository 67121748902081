<div class="d-flex">
  <div class="w-25 mt-5 ms-3">
    <irembogov-service-request-header-togger-widget
      [options]="headerOptions"
      [selectedOption]="selectedOption"
      (selectOption)="onSelectOptionChange($event)"
      class="pl-2"></irembogov-service-request-header-togger-widget>
    <div class="workflow-menu w-100">
      <div
        class="configure-stepper-nav-items"
        *ngIf="selectedOption === EServiceChangeRequestToggleHeaders.WIZARD">
        <div
          class="config-navs d-flex flex-column align-items-start bg-white"
          style="height: 100%">
          <button
            *ngFor="let step of workflowSteps; let index = index"
            [class]="{
              selected: index === currentConfigStep,
              linkitem: true,
              completed: step.isComplete,
              'w-100': true
            }"
            (click)="OnStepperChange(index)"
            [style.background-color]="
              index === currentConfigStep ? '#EAF4FF' : ''
            ">
            <div class="d-flex justify-content-start">
              <div class="">
                <i
                  aria-hidden="true"
                  [class]="
                    workflowSteps[index].icon
                      ? workflowSteps[index].icon + ' me-2'
                      : 'me-2'
                  "
                  *ngIf="!step.isComplete"></i>
              </div>
              <div class="d-flex flex-column justify-content-start text-left">
                <span
                  style="
                    font-size: 16px;
                    font-weight: 500;
                    font-style: normal;
                    text-align: left;
                    color: #757c8a;
                  "
                  ><span *ngIf="false">{{ index + 1 }}.&nbsp;</span
                  >{{ step.label }}</span
                >
                <span
                  style="
                    font-size: 12px;
                    font-weight: 400;
                    font-style: normal;
                    text-align: left;
                  "
                  class="text-muted"
                  >{{ step.subLabel }}</span
                >
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="ms-5 me-3 {{
      changeRequestStatus === EServiceChangeRequestStatus.IN_REDEVELOPMENT
        ? 'w-50'
        : 'w-75'
    }}">
    <div
      class="irembogov-configuration-workflow-json-editor-form mt-5 container">
      <div class="col-10 mx-auto">
        <div #errorMessageElement>
          <irembogov-irembo-section-message
            *ngIf="showConfigWorkflowErrorMessage"
            [type]="formAlertContent.type"
            [title]="formAlertContent.title"
            [message]="formAlertContent.message"
            [extraMessage]="formAlertContent.extraMessage"
            [extraMessageAsHtml]="true"></irembogov-irembo-section-message>
        </div>
        <div class="main">
          <div [hidden]="currentConfigStep !== 0">
            <ng-container [ngTemplateOutlet]="workflowConfig"></ng-container>
          </div>
          <div [hidden]="currentConfigStep !== 1">
            <ng-container [ngTemplateOutlet]="workflowSla"></ng-container>
          </div>
          <div [hidden]="currentConfigStep !== 2">
            <ng-container
              [ngTemplateOutlet]="workflowNotification"></ng-container>
          </div>
          <div [hidden]="currentConfigStep !== 3">
            <ng-container
              [ngTemplateOutlet]="workflowOfficerAssignment"></ng-container>
          </div>
          <div [hidden]="currentConfigStep !== 4">
            <ng-container
              [ngTemplateOutlet]="workflowIntegrations"></ng-container>
          </div>
          <div [hidden]="currentConfigStep !== 5">
            <ng-container [ngTemplateOutlet]="workflowDocuments"></ng-container>
          </div>
        </div>
        <div class="text-end">
          <button
            class="btn btn-primary mt-1 mx-auto px-5"
            type="submit"
            [disabled]="
              isLoading ||
              jsonEditorValidationErrors.length > 0 ||
              !jsonEditor ||
              nextSteps.length === 0 ||
              !nextStepsForm.valid ||
              !slaForm.valid
            "
            (click)="onWorkflowSubmit()">
            <div
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-2"
              role="status"></div>
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="pt-5 ms-3 me-3 w-25"
    *ngIf="
      changeRequestStatus === EServiceChangeRequestStatus.IN_REDEVELOPMENT
    ">
    <irembogov-service-change-request-feedback-handler-widget
      *ngIf="changeRequest"
      [changeRequestId]="changeRequest.id ?? ''"
      [serviceChangeRequestSection]="
        EServiceChangeRequestSection.WORKFLOW
      "></irembogov-service-change-request-feedback-handler-widget>
  </div>
</div>
<ng-template #workflowConfig>
  <ng-container>
    <div class="workflow_step mb-5 step-1">
      <h5 class="text-primary">Select Workflow</h5>
      <div class="workflow_step_body">
        <irembogov-workflow-template-selector-list-widget
          (selectWorkflowTemplateChange)="
            onSelectWorkflowTemplate($event)
          "></irembogov-workflow-template-selector-list-widget>
      </div>
    </div>
  </ng-container>
  <ng-container jsoneditor>
    <div
      class="workflow_step mb-5 step-2 {{
        selectedOption === EServiceChangeRequestToggleHeaders.CODE_EDITOR
          ? 'd-block'
          : 'd-none'
      }}">
      <h5 class="text-primary">Workflow Builder</h5>
      <div class="workflow_step_body">
        <div class="mb-3">
          <div class="form-group">
            <label for="jsonWorkflowData" class="form-label">
              Json Workflow Builder Editor
              <i
                class="fa-solid fa-circle-info text-muted"
                placement="top"
                ngbTooltip="Build form with Json"></i>
            </label>
            <div class="input-group mb-3">
              <json-editor
                [options]="editorOptions"
                id="jsonWorkflowData"
                class="form-control p-0 json-form-data-field"
                (change)="validateJsonEditor()"
                (keyup)="validateJsonEditor()"
                #jsonWorkflowData>
              </json-editor>
            </div>
            <div
              class="form-error w-100 small text-danger"
              *ngIf="jsonEditorValidationErrors.length > 0">
              JSON input is invalid
              <ol>
                <li
                  class="w-100 small"
                  *ngFor="
                    let error of jsonEditorValidationErrors;
                    let index = index
                  ">
                  <em class="text-muted"> Line {{ error['line'] }}</em>
                  <br />
                  <small [innerHTML]="error['message']"></small>
                </li>
              </ol>
            </div>
            <div
              class="form-error w-100 small text-danger"
              *ngIf="!jsonWorkflowData">
              Please enter a JSON Workflow entry
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      selectedOption === EServiceChangeRequestToggleHeaders.WIZARD &&
      changeRequestJsonWorkflowData
    "
    #nextSteps>
    <div class="workflow_step mb-5 step-2">
      <h5 class="text-primary">Next steps page</h5>
      <div class="workflow_step_body">
        <div class="mb-3">
          <div class="form-group">
            <label for="jsonWorkflowData" class="form-label">
              When necessary Edit the text with useful information for the
              applied citizen
              <i
                class="fa-solid fa-circle-info text-muted"
                placement="top"
                ngbTooltip="Configure next steps"></i>
            </label>
            <form
              [formGroup]="nextStepsForm"
              *ngIf="changeRequestJsonWorkflowData">
              <div class="form-group">
                <div formArrayName="nextSteps">
                  <div
                    *ngFor="
                      let stepForm of getSteps(nextStepsForm);
                      let i = index;
                      let step = index
                    ">
                    <div [formGroupName]="i">
                      <p class="p-2">
                        Step {{ step + 1 }}
                        <i
                          class="fa-solid fa-circle-info text-muted"
                          placement="top"
                          ngbTooltip="Step {{ step + 1 }}"></i>
                      </p>
                      <div class="d-flex">
                        <div class="form-group col-md-10">
                          <input
                            type="text"
                            class="form-control mb-2"
                            placeholder="Title"
                            formControlName="title" />
                        </div>
                        <span class="text-muted ms-auto">
                          <a
                            style="cursor: pointer"
                            (click)="removeNextStep(i)">
                            <i class="fa fa-trash-alt"></i>
                          </a>
                        </span>
                      </div>
                      <div class="form-group">
                        <quill-editor
                          #editor
                          format="html"
                          [modules]="quillConfig"
                          [style.display]="hide ? 'none' : 'block'"
                          class="bg-white rounded-lg border border-light"
                          [styles]="{ backgroundColor: backgroundColor }"
                          formControlName="description"
                          [required]="true"
                          placeholder="Step description">
                        </quill-editor>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary btn-sm mt-3"
                (click)="addNextStep()">
                <i class="fas fa-plus"></i> Add Step
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
<ng-template #workflowSla>
  <div class="mb-3 step-1">
    <h5 class="text-primary">SLA of status changes</h5>
    <div class="body">
      <form [formGroup]="slaForm">
        <div class="form-group">
          <label for="jsonWorkflowData" class="form-label text-muted">
            Overall SLA
            <i
              class="fa-solid fa-circle-info text-muted"
              placement="top"
              ngbTooltip="Overall SLA"></i>
          </label>
          <input
            type="number"
            class="form-control"
            id="expectedProcessingDays"
            placeholder="Expected Processing Days"
            formControlName="expectedProcessingDays" />
          <small class="text-primary"
            ><i
              >This text will be displayed to the citizen to communicate the
              duration of the process</i
            ></small
          >
        </div>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #workflowNotification> </ng-template>
<ng-template #workflowOfficerAssignment> </ng-template>
<ng-template #workflowIntegrations> </ng-template>
<ng-template #workflowDocuments> </ng-template>
