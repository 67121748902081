import { Injector } from '@angular/core';
import { FormBuilderService } from '../../services/form-builder.service';
import {
  IFormbuilderFieldTypeConfiguration,
  IGenericDataFetchFieldsConfigFormModel,
  IGenericLabelValueFieldsConfigFormModel,
  TConfigurationFormModelTypes,
} from '../../models/form-builder-types-configurations.model';
import {
  sectionFieldGetConfigurationConfig,
  sectionFieldParseIntoSettingsFormModel,
  sectionFieldUpdateFromSettingsFormModel,
} from './form-field-formly-types-main-configurations/section-field-type.configuration';
import {
  radioFieldGetConfigurationConfig,
  radioFieldParseFieldIntoDisplayFormModel,
  radioFieldParseIntoSettingsFormModel,
  radioFieldParseIntoValidationFormModel,
  radioFieldUpdateFromDisplayFormModel,
  radioFieldUpdateFromSettingsFormModel,
  radioFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/radio-field-type.configuration';
import {
  multiCheckboxFieldGetConfigurationConfig,
  multiCheckboxFieldParseIntoSettingsFormModel,
  multiCheckboxFieldUpdateFromSettingsFormModel,
  multiCheckboxFieldUpdateFromDisplayFormModel,
  multiCheckboxFieldParseFieldIntoDisplayFormModel,
  multiCheckboxFieldParseIntoValidationFormModel,
  multiCheckboxFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/multicheckbox-field-type.configuration';
import {
  customDropDownFieldGetConfigurationConfig,
  customDropDownFieldParseFieldIntoDisplayFormModel,
  customDropDownFieldParseIntoSettingsFormModel,
  customDropDownFieldParseIntoValidationFormModel,
  customDropDownFieldUpdateFromDisplayFormModel,
  customDropDownFieldUpdateFromSettingsFormModel,
  customDropDownFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/custom-dropdown-field-type.configuration';
import {
  inputFieldGetConfigurationConfig,
  inputFieldParseFieldIntoDisplayFormModel,
  inputFieldParseIntoSettingsFormModel,
  inputFieldParseIntoValidationFormModel,
  inputFieldUpdateFromDisplayFormModel,
  inputFieldUpdateFromSettingsFormModel,
  inputFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/input-field-type.configuration';
import {
  textareaFieldGetConfigurationConfig,
  textareaFieldParseFieldIntoDisplayFormModel,
  textareaFieldParseIntoSettingsFormModel,
  textareaFieldParseIntoValidationFormModel,
  textareaFieldUpdateFromDisplayFormModel,
  textareaFieldUpdateFromSettingsFormModel,
  textareaFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/textarea-field-type.configuration';
import {
  blockFieldGetConfigurationConfig,
  blockFieldParseIntoSettingsFormModel,
  blockFieldUpdateFromSettingsFormModel,
} from './form-field-formly-types-main-configurations/block-field-type.configuration';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IFormFieldsValidationModel } from '../../models/form-fields-formly-validation.model';
import {
  EIremboFormlyFieldSubTypes,
  EIremboFormlyFieldTypes,
} from '@irembo-andela/irembogov3-common';
import { IFormFieldsDisplayModel } from '../../models/form-fields-formly-display.model';
import {
  nidFieldGetConfigurationConfig,
  nidFieldParseFieldIntoDisplayFormModel,
  nidFieldParseIntoSettingsFormModel,
  nidFieldParseIntoValidationFormModel,
  nidFieldUpdateFromDisplayFormModel,
  nidFieldUpdateFromSettingsFormModel,
  nidFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/nid-field-type.configuration';
import { childNidFieldGetConfigurationConfig } from './form-field-formly-types-main-configurations/child-nid-field-type.configuration';
import { ninFieldGetConfigurationConfig } from './form-field-formly-types-main-configurations/nin-field-type.configuration';
import {
  customDropDownWithDataFetchFieldGetConfigurationConfig,
  customDropDownWithDataFetchFieldParseIntoSettingsFormModel,
  customDropDownWithDataFetchFieldUpdateFromSettingsFormModel,
} from './form-field-formly-types-main-configurations/custom-dropdown-with-data-fetch-field-type.configuration';
import {
  customDropDownWithDatasetFieldGetConfigurationConfig,
  customDropDownWithDataSetFieldNonPaginatedGetConfigurationConfig,
  customDropDownWithDatasetFieldParseIntoSettingsFormModel,
  customDropDownWithDatasetFieldUpdateFromSettingsFormModel,
} from './form-field-formly-types-main-configurations/custom-dropdown-with-dataset-field-type.configuration';
import {
  customFileUploadFieldGetConfigurationConfig,
  customFileUploadFieldParseFieldIntoDisplayFormModel,
  customFileUploadFieldParseIntoSettingsFormModel,
  customFileUploadFieldParseIntoValidationFormModel,
  customFileUploadFieldUpdateFromDisplayFormModel,
  customFileUploadFieldUpdateFromSettingsFormModel,
  customFileUploadFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/custom-file-upload-field-type.configuration';
import {
  locationWidgetFieldGetConfigurationConfig,
  locationWidgetFieldParseFieldIntoDisplayFormModel,
  locationWidgetFieldParseIntoSettingsFormModel,
  locationWidgetFieldParseIntoValidationFormModel,
  locationWidgetFieldUpdateFromDisplayFormModel,
  locationWidgetFieldUpdateFromSettingsFormModel,
  locationWidgetFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/location-widget-field-type.configuration';
import {
  internationalPhoneNumberFieldGetConfigurationConfig,
  internationalPhoneNumberFieldParseFieldIntoDisplayFormModel,
  internationalPhoneNumberFieldParseIntoSettingsFormModel,
  internationalPhoneNumberFieldParseIntoValidationFormModel,
  internationalPhoneNumberFieldUpdateFromDisplayFormModel,
  internationalPhoneNumberFieldUpdateFromSettingsFormModel,
  internationalPhoneNumberFieldUpdateFromValidationFormModel,
} from './form-field-formly-types-main-configurations/international-phone-number-field-type.configuration';
import {
  datePickerFieldGetSettingsConfigurationConfig,
  datePickerFieldParseIntoSettingsFormModel,
  datePickerFieldUpdateFromSettingsFormModel,
} from './form-field-formly-types-main-configurations/custom-datepicker-field-type.configuration';
export class FormBuilderFieldConfigurationParserAndSetterClass {
  formBuilderService!: FormBuilderService;

  constructor(private injector: Injector) {
    this.formBuilderService = this.injector.get(FormBuilderService);
  }

  getFormlyFieldConfigurationConfigByType = (
    fieldType: string | undefined,
    subType?: EIremboFormlyFieldSubTypes | undefined
  ): IFormbuilderFieldTypeConfiguration => {
    switch (fieldType) {
      case EIremboFormlyFieldTypes.block:
        return blockFieldGetConfigurationConfig(this.formBuilderService);
      case EIremboFormlyFieldTypes.input:
        return inputFieldGetConfigurationConfig(this.formBuilderService);
      case EIremboFormlyFieldTypes.textarea:
        return textareaFieldGetConfigurationConfig(this.formBuilderService);
      case EIremboFormlyFieldTypes.customdropdown:
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH
        ) {
          return customDropDownWithDataFetchFieldGetConfigurationConfig(
            this.formBuilderService
          );
        }
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET
        ) {
          return customDropDownWithDataSetFieldNonPaginatedGetConfigurationConfig(
            this.formBuilderService
          );
        }
        return customDropDownFieldGetConfigurationConfig(
          this.formBuilderService
        );
      case EIremboFormlyFieldTypes.customdropdownpaginated:
        if (
          subType ===
          EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH_PAGINATED
        ) {
          return customDropDownWithDataFetchFieldGetConfigurationConfig(
            this.formBuilderService
          );
        }
        if (
          subType ===
          EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED
        ) {
          return customDropDownWithDatasetFieldGetConfigurationConfig(
            this.formBuilderService
          );
        }
        return customDropDownFieldGetConfigurationConfig(
          this.formBuilderService
        );

      case EIremboFormlyFieldTypes.multicheckbox:
        return multiCheckboxFieldGetConfigurationConfig(
          this.formBuilderService
        );
      case EIremboFormlyFieldTypes.radio:
        return radioFieldGetConfigurationConfig(this.formBuilderService);
      case EIremboFormlyFieldTypes.customfileupload:
        return customFileUploadFieldGetConfigurationConfig(
          this.formBuilderService
        );
      case EIremboFormlyFieldTypes.customidinput:
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_NID
        ) {
          return nidFieldGetConfigurationConfig(this.formBuilderService);
        }
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_CHILD_ID
        ) {
          return childNidFieldGetConfigurationConfig(this.formBuilderService);
        }
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_NIN
        ) {
          return ninFieldGetConfigurationConfig(this.formBuilderService);
        }
        return sectionFieldGetConfigurationConfig(this.formBuilderService);

      case EIremboFormlyFieldTypes.customphonenumber:
        return internationalPhoneNumberFieldGetConfigurationConfig(
          this.formBuilderService
        );
      case EIremboFormlyFieldTypes.customdatepicker:
        return datePickerFieldGetSettingsConfigurationConfig(
          this.formBuilderService
        );
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
        return locationWidgetFieldGetConfigurationConfig(
          this.formBuilderService
        );
      default:
        return sectionFieldGetConfigurationConfig(this.formBuilderService);
    }
  };

  parseFieldIntoSettingsFormModelByType = (
    field: FormlyFieldConfig,
    fieldType: string | undefined
  ): TConfigurationFormModelTypes => {
    const subType: EIremboFormlyFieldSubTypes = field.props?.['subType'];
    switch (fieldType) {
      case EIremboFormlyFieldTypes.block:
        return blockFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.input:
        return inputFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.textarea:
        return textareaFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.customdropdown:
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH
        ) {
          return customDropDownWithDataFetchFieldParseIntoSettingsFormModel(
            field
          );
        }
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET
        ) {
          return customDropDownWithDatasetFieldParseIntoSettingsFormModel(
            field
          );
        }

        return customDropDownFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.customdropdownpaginated:
        if (
          subType &&
          subType ===
            EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH_PAGINATED
        ) {
          return customDropDownWithDataFetchFieldParseIntoSettingsFormModel(
            field
          );
        }
        if (
          subType &&
          subType ===
            EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED
        ) {
          return customDropDownWithDatasetFieldParseIntoSettingsFormModel(
            field
          );
        }

        return customDropDownFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.multicheckbox:
        return multiCheckboxFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.radio:
        return radioFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.customidinput:
        return nidFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.customfileupload:
        return customFileUploadFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.customphonenumber:
        return internationalPhoneNumberFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.customdatepicker:
        return datePickerFieldParseIntoSettingsFormModel(field);
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
        return locationWidgetFieldParseIntoSettingsFormModel(field);
      default:
        return sectionFieldParseIntoSettingsFormModel(field);
    }
  };

  updateFieldFromSettingsFormModel = (
    model: TConfigurationFormModelTypes,
    field: FormlyFieldConfig,
    fieldType: string | undefined
  ): FormlyFieldConfig => {
    const subType: EIremboFormlyFieldSubTypes = field.props?.['subType'];
    switch (fieldType) {
      case EIremboFormlyFieldTypes.block:
        return blockFieldUpdateFromSettingsFormModel(model, field);
      case EIremboFormlyFieldTypes.input:
        return inputFieldUpdateFromSettingsFormModel(model, field);
      case EIremboFormlyFieldTypes.textarea:
        return textareaFieldUpdateFromSettingsFormModel(model, field);
      case EIremboFormlyFieldTypes.customdropdown:
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH
        ) {
          return customDropDownWithDataFetchFieldUpdateFromSettingsFormModel(
            model as IGenericDataFetchFieldsConfigFormModel,
            field
          );
        }
        if (
          subType &&
          subType === EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET
        ) {
          return customDropDownWithDatasetFieldUpdateFromSettingsFormModel(
            model as IGenericDataFetchFieldsConfigFormModel,
            field
          );
        }
        return customDropDownFieldUpdateFromSettingsFormModel(
          model as IGenericLabelValueFieldsConfigFormModel,
          field
        );
      case EIremboFormlyFieldTypes.customdropdownpaginated:
        if (
          subType &&
          subType ===
            EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH_PAGINATED
        ) {
          return customDropDownWithDataFetchFieldUpdateFromSettingsFormModel(
            model as IGenericDataFetchFieldsConfigFormModel,
            field
          );
        }
        if (
          subType &&
          subType ===
            EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED
        ) {
          return customDropDownWithDatasetFieldUpdateFromSettingsFormModel(
            model as IGenericDataFetchFieldsConfigFormModel,
            field
          );
        }
        return customDropDownWithDatasetFieldUpdateFromSettingsFormModel(
          model as IGenericDataFetchFieldsConfigFormModel,
          field
        );

      case EIremboFormlyFieldTypes.multicheckbox:
        return multiCheckboxFieldUpdateFromSettingsFormModel(
          model as IGenericLabelValueFieldsConfigFormModel,
          field
        );
      case EIremboFormlyFieldTypes.radio:
        return radioFieldUpdateFromSettingsFormModel(
          model as IGenericLabelValueFieldsConfigFormModel,
          field
        );
      case EIremboFormlyFieldTypes.customidinput:
        return nidFieldUpdateFromSettingsFormModel(
          model as IGenericLabelValueFieldsConfigFormModel,
          field
        );
      case EIremboFormlyFieldTypes.customfileupload:
        return customFileUploadFieldUpdateFromSettingsFormModel(model, field);
      case EIremboFormlyFieldTypes.customphonenumber:
        return internationalPhoneNumberFieldUpdateFromSettingsFormModel(
          model,
          field
        );
      case EIremboFormlyFieldTypes.customdatepicker:
        return datePickerFieldUpdateFromSettingsFormModel(model, field);
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
      default:
        return sectionFieldUpdateFromSettingsFormModel(model, field);
    }
  };

  generateWidgetFieldsFromSettingsFormModel = (
    model: TConfigurationFormModelTypes,
    field: FormlyFieldConfig,
    fieldType: string | undefined
  ): FormlyFieldConfig[] => {
    switch (fieldType) {
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
        return locationWidgetFieldUpdateFromSettingsFormModel(model, field);
      default:
        return [field];
    }
  };

  parseFieldIntoValidationFormModelByType = (
    field: FormlyFieldConfig,
    fieldType: string | undefined
  ): IFormFieldsValidationModel => {
    switch (fieldType) {
      case EIremboFormlyFieldTypes.input:
        return inputFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.textarea:
        return textareaFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.customdropdown:
        return customDropDownFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.customdropdownpaginated:
        return customDropDownFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.multicheckbox:
        return multiCheckboxFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.radio:
        return radioFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.customidinput:
        return nidFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.customfileupload:
        return customFileUploadFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.customphonenumber:
        return internationalPhoneNumberFieldParseIntoValidationFormModel(field);
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
        return locationWidgetFieldParseIntoValidationFormModel(field);
      default:
        return {};
    }
  };

  updateFieldFromValidationsFormModel = (
    model: IFormFieldsValidationModel,
    field: FormlyFieldConfig,
    fieldType: string | undefined
  ): FormlyFieldConfig => {
    switch (fieldType) {
      case EIremboFormlyFieldTypes.input:
        return inputFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.textarea:
        return textareaFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.customdropdown:
        return customDropDownFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.multicheckbox:
        return multiCheckboxFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.radio:
        return radioFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.customidinput:
        return nidFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.customfileupload:
        return customFileUploadFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.customphonenumber:
        return internationalPhoneNumberFieldUpdateFromValidationFormModel(
          model,
          field
        );
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
        return locationWidgetFieldUpdateFromValidationFormModel(model, field);
      case EIremboFormlyFieldTypes.customdropdownpaginated:
        return customDropDownFieldUpdateFromValidationFormModel(model, field);
      default:
        return field;
    }
  };

  parseFieldIntoDisplayFormModel(
    field: FormlyFieldConfig,
    fieldType: string | undefined
  ): IFormFieldsDisplayModel {
    switch (fieldType) {
      case EIremboFormlyFieldTypes.input:
        return inputFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.textarea:
        return textareaFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.customdropdown:
        return customDropDownFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.multicheckbox:
        return multiCheckboxFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.radio:
        return radioFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.customidinput:
        return nidFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.customfileupload:
        return customFileUploadFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.customphonenumber:
        return internationalPhoneNumberFieldParseFieldIntoDisplayFormModel(
          field
        );
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
        return locationWidgetFieldParseFieldIntoDisplayFormModel(field);
      case EIremboFormlyFieldTypes.customdropdownpaginated:
        return customDropDownFieldParseFieldIntoDisplayFormModel(field);
      default:
        return {};
    }
  }

  updateFieldFromDisplayFormModel = (
    model: IFormFieldsDisplayModel,
    field: FormlyFieldConfig,
    fieldType: string | undefined
  ): FormlyFieldConfig => {
    switch (fieldType) {
      case EIremboFormlyFieldTypes.input:
        return inputFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.textarea:
        return textareaFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.customdropdown:
        return customDropDownFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.multicheckbox:
        return multiCheckboxFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.radio:
        return radioFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.customidinput:
        return nidFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.customfileupload:
        return customFileUploadFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.customphonenumber:
        return internationalPhoneNumberFieldUpdateFromDisplayFormModel(
          model,
          field
        );
      case EIremboFormlyFieldTypes.customcascadingdropdowns:
        return locationWidgetFieldUpdateFromDisplayFormModel(model, field);
      case EIremboFormlyFieldTypes.customdropdownpaginated:
        return customDropDownFieldUpdateFromDisplayFormModel(model, field);
      default:
        return field;
    }
  };
}
