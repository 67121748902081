import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';
import { FormBuilderService } from '../../../core/services/form-builder.service';
import { Subject, takeUntil } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';

enum EWizardTabTemplateKeys {
  Fields = 'Fields',
  Widgets = 'Widgets',
  Templates = 'Templates',
}

@Component({
  selector: 'irembogov-form-field-configurator',
  templateUrl: './form-field-configurator.component.html',
  styleUrls: ['./form-field-configurator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldConfiguratorComponent implements OnInit, OnDestroy {
  @ViewChild('settings') settingsTemplate!: TemplateRef<unknown>;
  @ViewChild('validation') validationTemplate!: TemplateRef<unknown>;
  @ViewChild('display') displayTemplate!: TemplateRef<unknown>;

  EWizardTabTemplateKeys = EWizardTabTemplateKeys;

  configuredField!: FormlyFieldConfig | null;

  configurationTabs: IIrembogovBasicLabelKeyPair<EWizardTabTemplateKeys>[] = [
    {
      label: 'Fields',
      key: EWizardTabTemplateKeys.Fields,
      icon: 'fa-solid fa-vector-square',
    },
    {
      label: 'Widgets',
      key: EWizardTabTemplateKeys.Widgets,
      icon: 'fa-solid fa-object-ungroup',
    },
    {
      label: 'Templates',
      key: EWizardTabTemplateKeys.Templates,
      icon: 'fa-solid fa-object-group',
    },
  ];

  selectedConfigurationTab: EWizardTabTemplateKeys =
    EWizardTabTemplateKeys.Fields;

  configureFieldSubControl$: Subject<void> = new Subject<void>();

  constructor(private formBuilderService: FormBuilderService) {}

  ngOnInit(): void {
    this.formBuilderService.configureFieldAction$
      .pipe(takeUntil(this.configureFieldSubControl$))
      .subscribe((configuredField: FormlyFieldConfig | null) => {
        this.configuredField = configuredField;
      });
  }

  cancelFieldConfiguration() {
    this.formBuilderService.configureFieldAction$.next(null);
  }

  ngOnDestroy(): void {
    this.configureFieldSubControl$.next();
    this.configureFieldSubControl$.complete();
  }
}
