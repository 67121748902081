<ng-container *ngIf="previewDataFields">
  <div class="groups" id="parentdiv">
    <form [formGroup]="form" class="officer-form-preview-json-formdata-widget">
      <formly-form
        [options]="options"
        [model]="model"
        [fields]="previewDataFields"></formly-form>
    </form>
  </div>
</ng-container>
