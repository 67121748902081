import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';
import { EServiceChangeRequestType } from '../models/service-change-request-type.enum';

export const GetServiceChangeRequestTypeEnumLabelKeyPair = (
  status: EServiceChangeRequestType | null
): IIrembogovBasicLabelKeyPair<EServiceChangeRequestType> => {
  switch (status) {
    case EServiceChangeRequestType.NEW_SERVICE:
      return {
        label: 'New Service',
        key: status,
        bgClass: 'bg-new-service',
      };
    case EServiceChangeRequestType.SERVICE_UPDATE:
      return {
        label: 'Service Update',
        key: status,
        bgClass: 'bg-service-update',
      };
    default:
      return {
        label: 'Unknown',
        key: EServiceChangeRequestType._UNKNOWN_,
        bgClass: 'bg-info',
      };
  }
};
