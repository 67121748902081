<ng-container *ngIf="previewDataFields">
  <div class="groups" id="parentdiv">
    <form
      [formGroup]="form"
      class="preview-service-change-request-json-formdata-widget">
      <formly-form
        [form]="form"
        [options]="options"
        [model]="model"
        [fields]="previewDataFields"
        cdkDropListGroup></formly-form>

      <div
        class="border-dashed border-dashed-primary p-5 text-center pointer-cursor"
        *ngIf="!reviewModeEnabled"
        (click)="addNewSectionToFields()">
        <h6><i class="fa-solid fa-plus me-1"></i>New Section</h6>
      </div>
    </form>
  </div>
</ng-container>
