<div *ngIf="selectedLanguage">
  <div #errorMessageElement>
    <irembogov-irembo-section-message
      *ngIf="showConfigWorkflowErrorMessage"
      [type]="formAlertContent.type"
      [title]="formAlertContent.title"
      [message]="formAlertContent.message"
      [extraMessage]="formAlertContent.extraMessage"
      [extraMessageAsHtml]="true"></irembogov-irembo-section-message>
  </div>
  <div class="wizard-editor-section mt-5 bg-white rounded p-3">
    <div class="row pb-3 border-bottom border-secondary">
      <div class="col">
        <h5 class="font-weight-700 ms-2">English</h5>
      </div>
      <div class="col">
        <h5 class="font-weight-700">{{ selectedLanguage.name }}</h5>
      </div>
    </div>
    <form
      [formGroup]="translationsForm"
      (ngSubmit)="onTranslationsSubmit()"
      class="pt-3"
      *ngIf="selectedLanguageData">
      <input
        type="text"
        class="form-control"
        [value]="selectedLanguage.locale"
        hidden="true" />
      <ng-container formArrayName="translations">
        <ng-container
          *ngFor="let translation of translations.controls; let i = index">
          <div class="row p-2" [formGroupName]="i">
            <div class="col">
              <textarea
                class="text-bold form-control font-weight-bold"
                formControlName="word"
                [attr.disabled]="true"
                rows="2"></textarea>
            </div>
            <div class="col">
              <textarea
                class="form-control"
                formControlName="translation"></textarea>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="text-end">
        <button
          class="btn btn-primary mt-2 mx-auto px-3 me-2"
          type="submit"
          [disabled]="
            isLoading ||
            !translationsForm.valid ||
            !changeRequest ||
            !selectedLanguageData
          ">
          <div
            *ngIf="isLoading"
            class="spinner-border spinner-border-sm mr-1"
            role="status"></div>
          Save
        </button>
      </div>
    </form>
  </div>
</div>
