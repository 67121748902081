import { IBasicFieldLabelAndDetails } from './basic-label-value-pair.model';
import {
  EFormBuilderDisplayComparatorActions,
  EFormBuilderDisplayConditionComparator,
  EFormBuilderDisplayFieldComparator,
} from './form-builder-display-comparator.enum';
import { TFormlyFieldKey } from './formly-key.type';

export interface IFormFieldsDisplayModel {
  action?: EFormBuilderDisplayComparatorActions;
  displayRules?: IFormFieldsDisplayRule[];
}

export interface IFormFieldsDisplayRule {
  comparator: EFormBuilderDisplayConditionComparator;
  compareField: IBasicFieldLabelAndDetails<string>;
  compareFieldType: TFormlyFieldKey;
  compareFieldPropsType: string;
  condition: EFormBuilderDisplayFieldComparator;
  comparedValues: {
    isValue: any;
    isChecked: EFieldDisplayComparedValueCheckedStatus;
  };
}

export enum EFieldDisplayComparedValueCheckedStatus {
  isNotChecked = 'isNotChecked',
  isChecked = 'isChecked',
}
