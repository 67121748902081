<div class="d-flex">
  <div
    class="change-request-translations d-flex flex-row h-100 mt-5 {{
      changeRequest.status === EServiceChangeRequestStatus.IN_REDEVELOPMENT
        ? 'w-70'
        : 'w-100'
    }}"
    *ngIf="changeRequest && defaultData">
    <div class="languages-tab-buttons mt-5 mx-5">
      <button
        type="button"
        *ngFor="let language of serviceLanguages; let index = index"
        class="btn px-2 d-block mb-3 font-weight-700 text-start"
        [ngClass]="{
          'btn-primary': activeLanguage.locale === language.locale,
          'border border-light bg-white':
            activeLanguage.locale !== language.locale,
          'border-end': !index
        }"
        (click)="updateLanguageTranslationsByLanguage(language)">
        {{ language.name }}
      </button>
      <div
        class="small pointer-cursor text-center text-muted mt-2"
        *ngIf="
          !isLoadingLanguages &&
          serviceLanguages.length < serviceLanguagesCollectionSize
        "
        (click)="fetchMoreLanguages()">
        Load more
      </div>
    </div>
    <div class="translation-form rounded flex-grow-1 mx-5" *ngIf="formFields">
      <irembogov-translation-service-overview
        *ngIf="activeLanguage && selectedLanguageData"
        [changeRequest]="changeRequest"
        [defaultTranslations]="defaultData"
        [selectedLanguage]="activeLanguage"
        [selectedLanguageData]="selectedLanguageData"
        (formSubmitted)="
          onTranslationsSubmitted($event)
        "></irembogov-translation-service-overview>
    </div>
  </div>
  <div
    class="col-lg-4 pt-5 ms-3 me-3 mt-5 w-30"
    *ngIf="
      changeRequest?.status === EServiceChangeRequestStatus.IN_REDEVELOPMENT
    ">
    <irembogov-service-change-request-feedback-handler-widget
      [changeRequestId]="changeRequest.id ?? ''"
      [serviceChangeRequestSection]="
        EServiceChangeRequestSection.TRANSLATION
      "></irembogov-service-change-request-feedback-handler-widget>
  </div>
</div>
