import {
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, finalize, of, Subscription, switchMap } from 'rxjs';
import {
  ExtractHttpErrorResponseMessage,
  IHttpPagedResponse,
  updateApiFilter,
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { HttpErrorResponse } from '@angular/common/http';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { IWorkflowTemplate } from '../../../../core/models/workflow-templates.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewWorkflowTemplateDetailsWidgetComponent } from '../view-workflow-template-details-widget/view-workflow-template-details-widget.component';

interface IGetListParamsFields extends IFilterAndPaginationFieldsBase {
  query: string | null;
  sort?: string;
}

@Component({
  selector: 'irembogov-workflow-template-selector-list-widget',
  templateUrl: './workflow-template-selector-list-widget.component.html',
  styleUrls: ['./workflow-template-selector-list-widget.component.scss'],
})
export class WorkflowTemplateSelectorListWidgetComponent
  implements OnInit, OnChanges
{
  @Output() selectWorkflowTemplateChange: EventEmitter<IWorkflowTemplate> =
    new EventEmitter<IWorkflowTemplate>();
  workflowTemplates: IWorkflowTemplate[] = [];
  selectedTemplate!: IWorkflowTemplate;

  collectionSize = 0;
  pageSize = 3;
  page = 0;

  alertMessage = '';

  isLoadingWorkflowTemplatesList = false;

  defaultParamsFields: IGetListParamsFields = {
    page: 0,
    size: 5,
    query: '',
  };

  sortFieldOptions: IIrembogovBasicLabelKeyPair<string>[] = [
    { label: 'Date Created - DESC', key: 'createdDate,DESC' },
    { label: 'Date Created - ASC', key: 'createdDate,ASC' },
  ];

  updateApiFilter = updateApiFilter;

  getQueryParamsSubscription$!: Subscription;
  _filterObject: BehaviorSubject<IGetListParamsFields> =
    new BehaviorSubject<IGetListParamsFields>(this.defaultParamsFields);

  selectedWorkflowTemplate!: IWorkflowTemplate;

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private modalService: NgbModal,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getQueryParamsSubscription$ = this._filterObject
      .asObservable()
      .pipe(
        switchMap((paramsFields: IGetListParamsFields) => {
          this.getWorkflowTemplates(paramsFields);
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequestStatus']?.currentValue) {
      this.reInitWorkflowTemplateList();
    }
  }

  updateWorkflowTemplateList(keyPair: Partial<IGetListParamsFields>) {
    this._filterObject.next({
      ...this._filterObject.getValue(),
      ...keyPair,
    });
  }

  reInitWorkflowTemplateList() {
    this.page = this.defaultParamsFields.page ?? 0;
    this.pageSize = this.defaultParamsFields.size ?? 5;

    this.updateWorkflowTemplateList({
      page: this.page,
      size: this.pageSize,
    });
  }

  getWorkflowTemplates(requestParams: IGetListParamsFields) {
    this.isLoadingWorkflowTemplatesList = true;
    let queryParams = `page=${requestParams.page}&size=${requestParams.size}`;

    if (requestParams.query) {
      queryParams = `${queryParams}&query=${requestParams.query}`;
    }
    this.serviceChangeRequestService
      .getWorkflowTemplates(queryParams)
      .pipe(finalize(() => (this.isLoadingWorkflowTemplatesList = false)))
      .subscribe({
        next: (response: IHttpPagedResponse<IWorkflowTemplate>) => {
          this.workflowTemplates = response.data.content;
          this.collectionSize = response.data.totalPages * response.data.size;
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Failed to fetch workflow templates.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  onSelectTemplate(template: IWorkflowTemplate) {
    this.selectedTemplate = template;
    this.selectWorkflowTemplateChange.emit(this.selectedTemplate);
  }

  openViewWorkflowImageModal(workflowTemplateToView: IWorkflowTemplate): void {
    const modalRef: NgbModalRef = this.modalService.open(
      ViewWorkflowTemplateDetailsWidgetComponent,
      {
        fullscreen: true,
      }
    );
    modalRef.componentInstance.workflowTemplate = workflowTemplateToView;
  }
}
