import { IServiceChangeRequestReviewIssue } from './../../../../core/models/service-change-request-review-issue.model';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'irembogov-review-issues-edit-form-widget',
  templateUrl: './review-issues-edit-form-widget.component.html',
  styleUrls: ['./review-issues-edit-form-widget.component.scss'],
})
export class ReviewIssuesEditFormWidgetComponent implements OnInit {
  @Input() issue!: IServiceChangeRequestReviewIssue;
  @Input() index!: number;
  @Output() editIssueChange: EventEmitter<IServiceChangeRequestReviewIssue> =
    new EventEmitter<IServiceChangeRequestReviewIssue>();
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  isEditIssueSaving = false;

  issueEditForm: FormGroup = new FormGroup({
    id: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    this.issueEditForm.patchValue({
      id: this.issue?.id,
      title: this.issue?.title,
      description: this.issue?.description,
    });
  }

  onIssueEditSave() {
    this.isEditIssueSaving = true;
    return this.editIssueChange.emit(this.issueEditForm.value);
  }

  onCloseForm() {
    this.closeForm.emit(true);
  }
}
