import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormBuilderService } from '../../../../core/services/form-builder.service';

@Component({
  selector: 'irembogov-condition-wrapper',
  templateUrl: './condition-wrapper.component.html',
})
export class ConditionWrapperComponent extends FieldWrapper {
  constructor(private formBuilderService: FormBuilderService) {
    super();
  }
  remove(): void {
    const index = this.field.key as number;
    this.formBuilderService.removeItemAction$.next(index);
  }
}
