import { Pipe, PipeTransform } from '@angular/core';
import { EServiceChangeRequestStatus } from '../models/service-change-request-status.enum';

@Pipe({ name: 'changeRequestStatusToLabel' })
export class ChangeRequestStatusToLabelPipe implements PipeTransform {
  transform(status: EServiceChangeRequestStatus | undefined): string {
    switch (status) {
      case 'IN_QA':
        return 'In QA';
      case 'LIVE_READY':
        return 'Ready To Go Live';
      case 'REJECTED_REVIEW':
        return 'Review Rejected';
      case 'REJECTED_REQUEST':
        return 'Request Rejected';
      case 'WAITING_APPROVER':
        return 'Waiting Approval';
      case 'IN_DEVELOPMENT':
      case 'IN_REDEVELOPMENT':
        return 'In Development';
      case 'LIVE':
        return 'Live';
      default:
        return '';
    }
  }
}
