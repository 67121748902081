import { IFieldFormGroup } from './../../../../core/models/service-change-requests-form-fields.model';
import { IFormBuilderFieldsData } from './../../../../core/models/form-builder-fields-data.model';
import { IServiceChangeRequest } from './../../../../core/models/service-change-request.model';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { EServiceChangeRequestSection } from './../../../../core/models/service-change-request-sections.enum';
import {
  IFilterAndPaginationFieldsBase,
  IUiAlertContent,
  ToastService,
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  IHttpSingleDataResponse,
  updateApiFilter,
} from '@irembo-andela/irembogov3-common';
import { IServiceLanguage } from './../../../../core/models/service-language.model';
import { ServiceChangeRequestService } from './../../../../core/services/service-change-request.service';
import { BehaviorSubject, finalize, of, switchMap } from 'rxjs';
import { IServiceCategory } from './../../../../core/models/service-category.model';
import { EServiceChangeRequestStatus } from './../../../../core/models/service-change-request-status.enum';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  name: string | null;
  keywords: string | null;
}

@Component({
  selector: 'irembogov-configuration-translation',
  templateUrl: './configuration-translation.component.html',
  styleUrls: ['./configuration-translation.component.scss'],
})
export class ConfigurationTranslationComponent implements OnInit, OnChanges {
  @Input() changeRequest!: IServiceChangeRequest;
  @Input() defaultData: any = {};
  @Input() inReviewMode = false;
  @Input() formFields!: IFormBuilderFieldsData;
  @Input() changeRequestJsonWorkflowData: Record<string, unknown> = {};
  @Output() translationFormSubmitted: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  EServiceChangeRequestSection = EServiceChangeRequestSection;
  formFieldsJsonData: Record<string, unknown> = {};
  serviceLanguagesCollectionSize = 0;
  EServiceChangeRequestStatus = EServiceChangeRequestStatus;
  page = 0;
  pageSize = 10;
  isLoading = false;
  showConfigFormErrorMessage = false;
  _filterObject: BehaviorSubject<IFilterFields> =
    new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 8,
      name: '',
      keywords: '',
    });
  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };

  serviceLanguages: IServiceLanguage[] = [];
  isLoadingLanguages = false;
  activeLanguage!: IServiceLanguage;
  selectedLanguageData!: any;

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this._filterObject
      .asObservable()
      .pipe(
        switchMap((filter: IFilterFields) => {
          const queryParams: string =
            FilterFieldsToQueryParamsBuilder<IFilterFields>(filter);
          this.getServiceLanguages(queryParams, filter.page ?? 0);
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.onChangeRequestChanges(changes);
    this.onDefaultDataChanges(changes);
    this.onFormFieldsChanges(changes);
  }

  private onChangeRequestChanges(changes: SimpleChanges) {
    if (changes['changeRequest']?.currentValue) {
      this.changeRequest = changes['changeRequest'].currentValue;
      this.updateFormField(this.changeRequest.serviceName);
      this.updateFormField(this.changeRequest.serviceSummary);
      this.updateFormField(this.changeRequest.comment);
      this.updateFormFieldWithNextSteps(
        (this.changeRequestJsonWorkflowData['nextSteps'] as Record<
          string,
          string
        >[]) ?? []
      );

      this.addServiceCategoryNameToFieldsList(this.changeRequest.categoryId);
    }
  }

  updateFormFieldWithNextSteps(nextStes: Record<string, string>[]) {
    nextStes.forEach(element => {
      this.updateFormField(element['title']);
      this.updateFormField(element['description']);
    });
  }

  private onDefaultDataChanges(changes: SimpleChanges) {
    if (changes['defaultData']?.currentValue) {
      this.defaultData = changes['defaultData'].currentValue;
      const selectedDataInitializer =
        this.defaultData?.translations !== undefined
          ? this.defaultData?.translations[this.activeLanguage?.locale]
          : this.formFieldsJsonData;
      this.selectedLanguageData =
        Object.keys(selectedDataInitializer ?? {}).length > 0
          ? selectedDataInitializer
          : this.formFieldsJsonData;
      this.updateSelectedTranslationsFormFields();
    }
  }

  private onFormFieldsChanges(changes: SimpleChanges) {
    if (changes['formFields']?.currentValue) {
      this.formFields = changes['formFields'].currentValue;
      this.formFields?.fields?.forEach((field: any) => {
        field?.fieldGroup.forEach((formSection: any) => {
          this.updateFormField(formSection?.props?.label);
          formSection.fieldGroup.forEach((formBlock: any) => {
            this.updateFormField(formBlock?.props?.label);
            formBlock.fieldGroup.forEach((formInputs: IFieldFormGroup) => {
              this.updateFormField(formInputs.props.label);
              this.updateFormField(formInputs.props.placeholder);
              this.updateFormField(formInputs.validation.messages.invalid);
              this.updateFormField(formInputs.validation.messages.required);
              this.updateFormField(formInputs.props?.hint);
              this.updateFormField(formInputs.props?.internalDescription);
            });
          });
        });
      });
    }
  }

  private updateFormField(key: string) {
    if (key === '' || key === undefined || key === null) {
      return;
    } else {
      this.formFieldsJsonData[key] = '';
    }
  }

  getServiceLanguages(queryString: string, page: number) {
    this.isLoadingLanguages = true;
    this.serviceChangeRequestService
      .getServiceLanguages(queryString)
      .pipe(finalize(() => (this.isLoadingLanguages = false)))
      .subscribe({
        next: response => {
          this.serviceLanguages =
            page > 0
              ? this.serviceLanguages?.concat(response?.data?.content)
              : response?.data?.content;
          this.serviceLanguagesCollectionSize =
            response.data.totalPages * response.data.size;
          this.activeLanguage = this.serviceLanguages[0];
        },
        error: err => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Error getting services'
          );
          this.toastService.show({ body: errorMessage, type: 'error' });
        },
      });
  }

  updateLanguageTranslationsByLanguage(language: IServiceLanguage) {
    let languageObject: any = {};
    languageObject = {
      ...(this.defaultData?.translations
        ? this.defaultData?.translations[language?.locale]
        : this.formFieldsJsonData),
    };
    this.selectedLanguageData =
      Object.keys(languageObject).length > 0
        ? languageObject
        : this.formFieldsJsonData;
    this.updateSelectedTranslationsFormFields();
    this.activeLanguage = language;
  }

  onTranslationsSubmitted(event: boolean): void {
    this.translationFormSubmitted.emit(event);
  }

  private updateSelectedTranslationsFormFields(): void {
    const formFieldsKeys = Object.keys(this.formFieldsJsonData);

    formFieldsKeys.forEach(key => {
      if (!(key in this.selectedLanguageData)) {
        this.selectedLanguageData[key] = '';
      }
    });
  }

  addServiceCategoryNameToFieldsList(categoryId: string): void {
    this.serviceChangeRequestService
      .getServiceCategoryById(categoryId ?? '')
      .subscribe({
        next: (response: IHttpSingleDataResponse<IServiceCategory>) => {
          this.updateFormField(response.data.name);
        },
      });
  }

  fetchMoreLanguages() {
    if (this.serviceLanguages.length >= this.serviceLanguagesCollectionSize)
      return;
    this.page++;
    updateApiFilter(this._filterObject, {
      page: this.page,
      size: this.pageSize,
    });
  }
}
