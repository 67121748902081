import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { Subject, takeUntil } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-settings-toolbar',
  templateUrl: './settings-toolbar.component.html',
  styleUrls: ['./settings-toolbar.component.scss'],
})
export class SettingsToolbarComponent {
  @Input() label? = '';
  @Input() type? = '';
  @Input() field!: FormlyFieldConfig;
  @Output() duplicate = new EventEmitter();
  configureFieldActionSubControl$: Subject<void> = new Subject<void>();

  @Output() fieldConfigActionEvent = new EventEmitter();
  @Output() fieldDuplicateActionEvent = new EventEmitter();

  constructor(private formBuilderService: FormBuilderService) {
    this.formBuilderService.configureFieldAction$
      .pipe(takeUntil(this.configureFieldActionSubControl$))
      .subscribe((configuredField: FormlyFieldConfig | null) => {
        if (!(configuredField && this.field)) {
          this.fieldConfigActionEvent.emit(false);
          return;
        }

        this.fieldConfigActionEvent.emit(
          configuredField?.key === this.field.key
        );
      });
  }

  setIssueTitleForField(
    label: string | undefined,
    type: string | undefined
  ): void {
    const typeDesc: string = type ? ` [type: ${type}]` : '';
    this.formBuilderService.reviewFlagItemSubject$.next(`${label}${typeDesc}`);
  }

  configureField() {
    this.formBuilderService.configureFieldAction$.next(this.field);
    this.fieldConfigActionEvent.emit(true);
  }

  deleteAction() {
    if (this.field.props) {
      this.formBuilderService.deleteFormField(this.field);
    }
  }

  duplicateAction() {
    if (this.field.props) {
      const sectionId = this.field.props['sectionId'];
      const blockId = this.field.props['blockId'];
      const fieldId = this.field.props['fieldId'];

      const payload = { sectionId, blockId, fieldId };

      this.formBuilderService.duplicateFieldAction$.next(payload);
    }
  }
}
