import { ServiceChangeRequestService } from './../../../../core/services/service-change-request.service';
import {
  EServiceChangeRequestReviewStatus,
  IServiceChangeRequestReviewIssue,
} from './../../../../core/models/service-change-request-review-issue.model';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  IHttpPagedResponse,
  updateApiFilter,
  RolesEnum,
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { BehaviorSubject, Subscription, finalize, of, switchMap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { EServiceChangeRequestSection } from './../../../../core/models/service-change-request-sections.enum';

interface IGetFeedbackQueryParams extends IFilterAndPaginationFieldsBase {
  section: EServiceChangeRequestSection | null;
  status: EServiceChangeRequestReviewStatus[] | null;
  changeRequestId: string | null;
}

@Component({
  selector: 'irembogov-reviewer-issues-list-widget',
  templateUrl: './reviewer-issues-list-widget.component.html',
  styleUrls: ['./reviewer-issues-list-widget.component.scss'],
})
export class ReviewerIssuesListWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() changeRequestId!: string;
  @Input() changeRequestSection!: EServiceChangeRequestSection;

  isLoadingFeedbackList = false;
  feedbackList: IServiceChangeRequestReviewIssue[] = [];
  userActiveRole!: RolesEnum;
  defaultFetchByStatus: EServiceChangeRequestReviewStatus =
    EServiceChangeRequestReviewStatus.PENDING;
  EServiceChangeRequestReviewStatus = EServiceChangeRequestReviewStatus;

  collectionSize = 0;
  pageSize = 10;
  page = 0;

  defaultQueryParams: IGetFeedbackQueryParams = {
    page: 0,
    size: 10,
    section: this.changeRequestSection,
    status: [this.defaultFetchByStatus],
    changeRequestId: this.changeRequestId ?? null,
  };

  updateApiFilter = updateApiFilter;

  queryParamsSubscription$!: Subscription;
  _filterObject: BehaviorSubject<IGetFeedbackQueryParams> =
    new BehaviorSubject<IGetFeedbackQueryParams>(this.defaultQueryParams);

  statusFilterOptions: IIrembogovBasicLabelKeyPair<EServiceChangeRequestReviewStatus>[] =
    [
      { label: 'Pending', key: EServiceChangeRequestReviewStatus.PENDING },
      { label: 'Solved', key: EServiceChangeRequestReviewStatus.RESOLVED },
    ];

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.queryParamsSubscription$ = this._filterObject
      .asObservable()
      .pipe(
        switchMap((paramsFields: IGetFeedbackQueryParams) => {
          this.getFeedbackForSection(paramsFields);
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequestId']?.currentValue) {
      this.changeRequestId = changes['changeRequestId'].currentValue;
      this.updateApiFilter(this._filterObject, {
        changeRequestId: this.changeRequestId,
      });
    }

    if (changes['changeRequestSection']?.currentValue) {
      this.changeRequestSection = changes['changeRequestSection'].currentValue;
      this.updateApiFilter(this._filterObject, {
        section: this.changeRequestSection,
      });
    }
  }

  getFeedbackForSection(queryParams: IGetFeedbackQueryParams): void {
    if (!(queryParams.section && queryParams.changeRequestId)) return;
    this.isLoadingFeedbackList = true;

    const queryParamsFields: string =
      FilterFieldsToQueryParamsBuilder<IGetFeedbackQueryParams>(queryParams);
    this.serviceChangeRequestService
      .getChangeRequestReviewIssues(queryParamsFields)
      .pipe(finalize(() => (this.isLoadingFeedbackList = false)))
      .subscribe({
        next: (
          response: IHttpPagedResponse<IServiceChangeRequestReviewIssue>
        ) => {
          this.feedbackList = this.feedbackList.concat(response.data.content);
          this.collectionSize = response.data.totalElements;
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Failed to fetch list of review issues.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  loadMoreIsues(): void {
    if (this.feedbackList.length >= this.collectionSize) return;
    this.page++;
    updateApiFilter(this._filterObject, {
      page: this.page,
      size: this.pageSize,
    });
  }

  updateToGetIssuesByStatus(
    status: EServiceChangeRequestReviewStatus,
    forceReload = false
  ) {
    if (this.defaultFetchByStatus === status && !forceReload) return;

    this.feedbackList = [];
    this.defaultFetchByStatus = status;
    this.updateApiFilter(this._filterObject, {
      status:
        this.defaultFetchByStatus === EServiceChangeRequestReviewStatus.PENDING
          ? [
              this.defaultFetchByStatus,
              EServiceChangeRequestReviewStatus.PENDING,
            ]
          : [this.defaultFetchByStatus],
      page: this.defaultQueryParams.page,
      size: this.defaultQueryParams.size,
    });
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription$) {
      this.queryParamsSubscription$.unsubscribe();
    }
  }
}
