import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  EServiceChangeRequestReviewStatus,
  IServiceChangeRequestReviewIssue,
} from '../../../../core/models/service-change-request-review-issue.model';
import {
  IFilterAndPaginationFieldsBase,
  ToastService,
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  IHttpPagedResponse,
  updateApiFilter,
} from '@irembo-andela/irembogov3-common';
import { BehaviorSubject, Subscription, finalize, of, switchMap } from 'rxjs';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { HttpErrorResponse } from '@angular/common/http';

interface IGetAllReviewIssuesParamsFields
  extends IFilterAndPaginationFieldsBase {
  status: EServiceChangeRequestReviewStatus[] | null;
  changeRequestId: string | null;
}

@Component({
  selector: 'irembogov-review-submission',
  templateUrl: './review-submission.component.html',
  styleUrls: ['./review-submission.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewSubmissionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() changeRequestId?: string;
  @Output() reviewApproved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() reviewRejected: EventEmitter<boolean> = new EventEmitter<boolean>();

  allReviewIssues: IServiceChangeRequestReviewIssue[] = [];

  isLoading = false;

  collectionSize = 0;
  page = 0;
  pageSize = 10;
  currentPage = 0;

  defaultParamsFields: IGetAllReviewIssuesParamsFields = {
    page: 0,
    size: 10,
    status: [
      EServiceChangeRequestReviewStatus.PENDING,
      EServiceChangeRequestReviewStatus.NEW,
    ],
    changeRequestId: this.changeRequestId ?? null,
  };

  updateApiFilter = updateApiFilter;

  queryParamsSubscription$!: Subscription;
  _filterObject: BehaviorSubject<IGetAllReviewIssuesParamsFields> =
    new BehaviorSubject<IGetAllReviewIssuesParamsFields>(
      this.defaultParamsFields
    );

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.queryParamsSubscription$ = this._filterObject
      .asObservable()
      .pipe(
        switchMap((paramsFields: IGetAllReviewIssuesParamsFields) => {
          paramsFields.status = [
            EServiceChangeRequestReviewStatus.PENDING,
            EServiceChangeRequestReviewStatus.NEW,
          ];
          this.getAllReviewIssues(paramsFields);
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequestId']?.currentValue) {
      this.changeRequestId = changes['changeRequestId'].currentValue;
      this.updateApiFilter(this._filterObject, {
        changeRequestId: this.changeRequestId,
      });
    }
  }

  getAllReviewIssues(paramsFields: IGetAllReviewIssuesParamsFields) {
    this.isLoading = true;
    if (!paramsFields.changeRequestId) return;
    const queryParams: string =
      FilterFieldsToQueryParamsBuilder<IGetAllReviewIssuesParamsFields>(
        paramsFields
      );
    this.serviceChangeRequestService
      .getChangeRequestReviewIssues(queryParams)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (
          response: IHttpPagedResponse<IServiceChangeRequestReviewIssue>
        ) => {
          this.allReviewIssues = response.data.content;
          this.collectionSize = response.data.totalElements;
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Failed to fetch all review issues.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  onPaginationChange($event: { pageNumber: 1; pageSize: number }): void {
    updateApiFilter(this._filterObject, {
      page: $event.pageNumber ? $event.pageNumber - 1 : $event.pageNumber,
      size: $event?.pageSize,
    });
    this.currentPage = $event.pageNumber;
  }

  ngOnDestroy(): void {
    if (this.queryParamsSubscription$)
      this.queryParamsSubscription$.unsubscribe();
  }
}
