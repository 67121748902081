import { Pipe, PipeTransform } from '@angular/core';
import { EServiceChangeRequestComparator } from '../models/service-change-request-comparator.enum';

@Pipe({ name: 'pricingDynamicOperatorToLabel' })
export class RequestPricingDynamicOperatorToLabel implements PipeTransform {
  transform(status: EServiceChangeRequestComparator | undefined): string {
    switch (status) {
      case 'EQ':
        return 'Equals To';
      case 'GT':
        return 'Greater than';
      case 'GTE':
        return 'Greater than Or Equals To';
      case 'LT':
        return 'Less than';
      case 'LTE':
        return 'Less than Or Equals To';
      default:
        return '';
    }
  }
}
