import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, finalize, of, Subscription, switchMap } from 'rxjs';
import {
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  IHttpPagedResponse,
  updateApiFilter,
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
  RolesEnum,
} from '@irembo-andela/irembogov3-common';
import { HttpErrorResponse } from '@angular/common/http';
import { EServiceChangeRequestType } from '../../../../core/models/service-change-request-type.enum';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { EServiceChangeRequestStatus } from '../../../../core/models/service-change-request-status.enum';
import { Router } from '@angular/router';
import { OrganizationService } from '../../../../core/services/organization.service';
import { IIremboOrganization } from '../../../../core/models/irembo-organization.model';

interface IGetListParamsFields extends IFilterAndPaginationFieldsBase {
  status: EServiceChangeRequestStatus[] | null;
  sort: string;
  sortDirection: 'ASC' | 'DESC';
}

@Component({
  selector: 'irembogov-service-change-requests-list-table-widget',
  templateUrl: './service-change-requests-list-table.component.html',
  styleUrls: ['./service-change-requests-list-table.component.scss'],
})
export class ServiceChangeRequestsListTableWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() changeRequestStatus?: EServiceChangeRequestStatus;
  @Input() viewAsRole!:
    | RolesEnum.ROLE_QA_REVIEWER
    | RolesEnum.ROLE_SERVICE_MANAGER;

  RolesEnum = RolesEnum;

  changeRequestList: IServiceChangeRequest[] = [];
  organizationMap: Record<string, IIremboOrganization> = {};

  collectionSize = 0;

  alertMessage = '';

  selectAllChangeRequests = false;
  isLoadingChangeRequestList = true;
  isLoadingOrganizationList = true;

  EServiceChangeRequestStatus = EServiceChangeRequestStatus;

  sortFieldOptions: IIrembogovBasicLabelKeyPair<{
    column: string;
    direction: 'ASC' | 'DESC';
  }>[] = [
    {
      label: 'Date Created - ASC',
      key: { column: 'dateCreated', direction: 'ASC' },
    },
    {
      label: 'Date Created - DESC',
      key: { column: 'dateCreated', direction: 'DESC' },
    },
  ];

  defaultParamsFields: IGetListParamsFields = {
    page: 0,
    size: 10,
    status: null,
    sort: this.sortFieldOptions[0].key.column,
    sortDirection: this.sortFieldOptions[1].key.direction,
  };

  updateApiFilter = updateApiFilter;

  getQueryParamsSubscription$!: Subscription;
  _filterObject: BehaviorSubject<IGetListParamsFields> =
    new BehaviorSubject<IGetListParamsFields>(this.defaultParamsFields);

  changeRequestForConfig!: IServiceChangeRequest;

  configChangeRequestOpen = false;

  EServiceChangeRequestType = EServiceChangeRequestType;

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private organizationService: OrganizationService,
    private toastService: ToastService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getQueryParamsSubscription$ = this._filterObject
      .asObservable()
      .pipe(
        switchMap((paramsFields: IGetListParamsFields) => {
          this.getAssignedServiceChangeRequestsForType(paramsFields);
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['changeRequestStatus']) {
      this.reInitTableList();
    }
  }

  updateTableList(keyPair: Partial<IGetListParamsFields>) {
    if (keyPair.page) {
      keyPair.page--;
    }
    this._filterObject.next({
      ...this._filterObject.getValue(),
      ...keyPair,
    });
  }

  reInitTableList() {
    this.updateTableList({
      status: this.changeRequestStatus ? [this.changeRequestStatus] : null,
      page: this.defaultParamsFields.page ?? 0,
      size: this.defaultParamsFields.size ?? 10,
    });
  }

  getAssignedServiceChangeRequestsForType(requestParams: IGetListParamsFields) {
    this.isLoadingChangeRequestList = true;

    if (
      requestParams.status &&
      requestParams.status.indexOf(EServiceChangeRequestStatus.IN_DEVELOPMENT) >
        -1
    ) {
      requestParams.status.push(EServiceChangeRequestStatus.IN_REDEVELOPMENT);
    }

    const queryParams: string =
      FilterFieldsToQueryParamsBuilder<IGetListParamsFields>(requestParams);

    this.serviceChangeRequestService
      .getAssignedServiceChangeRequests(queryParams, this.viewAsRole)
      .pipe(finalize(() => (this.isLoadingChangeRequestList = false)))
      .subscribe({
        next: (response: IHttpPagedResponse<IServiceChangeRequest>) => {
          this.changeRequestList = response.data.content;
          this.mapOrganizationToChangeRequest(this.changeRequestList);
          this.collectionSize = response.data.totalPages * response.data.size;
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Failed to fetch change requests.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  mapOrganizationToChangeRequest(changeRequestList: IServiceChangeRequest[]) {
    if (!changeRequestList || changeRequestList?.length < 1) {
      this.isLoadingOrganizationList = false;
      return;
    }
    this.isLoadingOrganizationList = true;
    const organizationIds = Array.from(
      new Set(
        changeRequestList.map(changeRequest => changeRequest.organizationId)
      )
    );
    const organizationListQueryStr: string =
      'id=' + organizationIds.join('&id=');
    const queryParams = `page=${0}&size=${
      organizationIds.length
    }&${organizationListQueryStr}`;

    this.organizationService
      .getOrganizations(queryParams)
      .pipe(finalize(() => (this.isLoadingOrganizationList = false)))
      .subscribe({
        next: (response: IHttpPagedResponse<IIremboOrganization>) => {
          response.data.content.forEach(organization => {
            this.organizationMap[organization.id] = organization;
          });

          this.changeRequestList = this.changeRequestList.map(changeRequest => {
            return {
              ...changeRequest,
              organization: this.organizationMap[changeRequest.organizationId],
            };
          });
        },
      });
  }

  toggleSelectAllChangeRequests(): void {
    this.selectAllChangeRequests = !this.selectAllChangeRequests;
    this.changeRequestList.forEach(
      (changeRequest: IServiceChangeRequest) =>
        (changeRequest.selectedInView = this.selectAllChangeRequests)
    );
  }

  setChangeRequestForConfig(changeRequest: IServiceChangeRequest) {
    this.changeRequestForConfig = changeRequest;
    this.updatedConfigChangeRequestViewState(true);
  }

  updatedConfigChangeRequestViewState($event: boolean) {
    this.configChangeRequestOpen = $event;
  }

  async onConfigureRequestClick(requestId: string) {
    try {
      await this.router.navigate(['/services/configure', requestId]);
    } catch (e: unknown) {
      return;
    }
  }

  async onReviewRequestClick(requestId: string) {
    try {
      await this.router.navigate(['/services/review', requestId]);
    } catch (e: unknown) {
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.getQueryParamsSubscription$) {
      this.getQueryParamsSubscription$.unsubscribe();
    }
  }
}
