export interface IServiceChangeRequestReviewIssue {
  id?: string;
  title: string;
  description: string;
  section: EServiceChangeRequestReviewSection;
  tags?: string[];
  status?: EServiceChangeRequestReviewStatus;
  changeRequestId?: string;
  createdBy?: string;
  lastModifiedBy?: string;
  dateCreated?: string;
  lastModifiedDate?: string;
  isProcessing: boolean;
}

export enum EServiceChangeRequestReviewSection {
  OVERVIEW = 'OVERVIEW',
  FORM = 'FORM',
  PRICING = 'PRICING',
  WORKFLOW = 'WORKFLOW',
  TRANSLATION = 'TRANSLATION',
  _UNKOWN_ = '_UNKOWN_',
}

export enum EServiceChangeRequestReviewStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REMOVED = 'REMOVED',
  _UNKOWN_ = '_UNKOWN_',
}
