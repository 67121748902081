import { FormlyFieldConfig } from '@ngx-formly/core';
import { IIremboFormlyFieldOnlyAllowedProps } from '../models/irembo-formly-field-allowed-props.type';

export const FormlyFieldArrayToIremboFormlyFieldsConverter = (
  fields: FormlyFieldConfig[] | undefined
): IIremboFormlyFieldOnlyAllowedProps[] | undefined => {
  if (!fields || fields.length < 1) return undefined;
  const iremboFormlyFields: Array<IIremboFormlyFieldOnlyAllowedProps> = [];
  fields.forEach((field: FormlyFieldConfig) => {
    const iremboField: IIremboFormlyFieldOnlyAllowedProps | undefined =
      FormlyFieldItemToIremboFormlyFieldsConverter(field);
    if (iremboField) iremboFormlyFields.push(iremboField);
  });
  return iremboFormlyFields;
};

export const FormlyFieldItemToIremboFormlyFieldsConverter = (
  field: FormlyFieldConfig
): IIremboFormlyFieldOnlyAllowedProps | undefined => {
  if (!field) return undefined;
  return {
    key: <string>field?.key,
    id: field?.id,
    type: <string>field?.type,
    props: field?.props,
    defaultValue: field?.defaultValue,
    expressions: field?.expressions,
    validation: field?.validation,
    validators: field?.validators,
    fieldGroup: FormlyFieldArrayToIremboFormlyFieldsConverter(
      <FormlyFieldConfig[]>field?.fieldGroup
    ),
    fieldArray: FormlyFieldItemToIremboFormlyFieldsConverter(
      <FormlyFieldConfig>field?.fieldArray
    ),
    className: field?.className,
  };
};
