import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EServicePriceType } from '../../../utils/enums';

interface IPricingTypesOption {
  label: string;
  value: EServicePriceType;
}

@Component({
  selector: 'irembogov-toggle-button',
  templateUrl: './toggle-button.component.html',
})
export class ToggleButtonComponent {
  @Input() selectedOption?: string;
  @Input() options: IPricingTypesOption[] = [];
  @Output() selectOption = new EventEmitter<EServicePriceType>();

  onSelectOption(value: EServicePriceType) {
    this.selectOption.emit(value);
  }
}
