import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { ServicesRoutingModule } from './services-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  UiModule,
  formlyConfigs,
  HideFieldWrapperComponent,
  RfaReadonlyCheckerWrapperComponent,
  EIremboFormlyFieldTypes,
  UtilsModule,
  HintFieldWrapperComponent,
  ReadonlyWrapperComponent,
} from '@irembo-andela/irembogov3-common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FieldWrapperComponent } from './service-configuration/field-wrapper/field-wrapper.component';
import { ConditionWrapperComponent } from './service-configuration/condition-wrapper/condition-wrapper.component';
import { FormlySwitchComponent } from './service-configuration/formly-switch/formly-switch.component';
import { FormlyRepeatComponent } from './service-configuration/formly-repeat/formly-repeat.component';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AlertWrapperComponent } from './service-configuration/alert-wrapper/alert-wrapper.component';
import { ManageServiceChangeRequestsComponent } from './manage-service-change-requests/manage-service-change-requests.component';
import { SettingsToolbarComponent } from './service-configuration/settings-toolbar/settings-toolbar.component';
import { FormTemplatesComponent } from './service-configuration/form-templates/form-templates.component';
import { RouterModule } from '@angular/router';
import { ServiceChangeRequestsListTableWidgetComponent } from './widgets/service-change-requests-list-table/service-change-requests-list-table.component';
import { ServiceChangeRequestStatusBadgeComponent } from './widgets/service-change-request-status-badge/service-change-request-status-badge.component';
import { ServiceChangeRequestTypeBadgeComponent } from './widgets/service-change-request-status-type/service-change-request-type-badge.component';
import { ConfigureServiceChangeRequestComponent } from './configure-service-change-request/configure-service-change-request.component';
import { ChangeRequestConfigurationHeaderWidgetComponent } from './widgets/change-request-configuration-header-widget/change-request-configuration-header-widget.component';
import { ServiceChangeRequestReviewerActionWidgetComponent } from './widgets/service-change-request-reviewer-action-widget/service-change-request-reviewer-action-widget.component';
import { ServiceChangeRequestConfigurerActionWidgetComponent } from './widgets/service-change-request-configurer-action-widget/service-change-request-configurer-action-widget.component';
import { ConfigurationMetadataOverviewFormComponent } from './configuration-steps/configuration-metadata-overview-form/configuration-metadata-overview-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ConfigureServiceChangeRequestStepperComponent } from './widgets/configure-service-change-request-stepper/configure-service-change-request.stepper.component';
import { AngJsoneditorModule } from '@maaxgr/ang-jsoneditor';
import { ConfigurationWorkflowJsonEditorFormComponent } from './configuration-steps/configuration-workflow-json-editor-form/configuration-workflow-json-editor-form.component';
import { WorkflowTemplateSelectorListWidgetComponent } from './widgets/workflow-template-selector-list-widget/workflow-template-selector-list-widget.component';
import { ViewWorkflowTemplateDetailsWidgetComponent } from './widgets/view-workflow-template-details-widget/view-workflow-template-details-widget.component';
import { ConfigurationPricingFormComponent } from './configuration-steps/configuration-pricing-form/configuration-pricing-form.component';
import { PreviewServiceChangeRequestJsonFormdataWidgetComponent } from './widgets/preview-service-change-request-json-formdata-widget/preview-service-change-request-json-formdata-widget.component';
import { FormlyFieldStepperComponent } from '../../formly-components/formly-types/formly-field-stepper/formly-field-stepper.component';
import { FormlyFieldGroupComponent } from '../../formly-components/formly-types/formly-field-group/formly-field-group.component';
import { ConfigureServiceChangeRequestPriceHandlerComponent } from './widgets/configure-service-change-request-price-handler/configure-service-change-request-price-handler.component';
import { ConfigureServiceFixedPriceHandlerComponent } from './widgets/configure-service-fixed-price-handler/configure-service-fixed-price-handler.component';
import { ReviewServiceChangeRequestComponent } from './review-service-change-request/review-service-change-request.component';
import { ReviewMetadataOverviewComponent } from './review-steps/review-metadata-overview/review-metadata-overview.component';
import { ReviewerIssuesEntryFormWidgetWidgetComponent } from './widgets/reviewer-issues-entry-form-widget/reviewer-issues-entry-form-widget.component';
import { ReviewFormbuilderJsonEditorComponent } from './review-steps/review-formbuilder-json-editor/review-formbuilder-json-editor.component';
import { ReviewSubmissionComponent } from './review-steps/review-submission/review-submission.component';
import { ConfigureServiceDynamicPriceHandlerComponent } from './widgets/configure-service-dynamic-price-handler/configure-service-dynamic-price-handler.component';
import { ConfigureServiceDynamicPricingFormComponent } from './widgets/configure-service-dynamic-pricing-form/configure-service-dynamic-pricing-form.component';
import { ChangeRequestStatusToLabelPipe } from '../../core/pipes/change-request-status-to-label.pipe';
import { ReviewIssueStatusToLabelPipe } from '../../core/pipes/review-issue-status-to-label.pipe';
import { ReviewSectionToLabelPipe } from '../../core/pipes/review-section-to-label.pipe';
import { ReviewWorkflowJsonEntryComponent } from './review-steps/review-workflow-json-entry/review-workflow-json-entry.component';
import { ReviewPricingComponent } from './review-steps/review-pricing/review-pricing.component';
import { ServiceRequestItemPricingWidgetComponent } from './widgets/service-request-item-pricing-widget/service-request-item-pricing-widget.component';
import { RequestPricingDynamicOperatorToLabel } from '../../core/pipes/request-pricing-dynamic-operator-to-label.pipe';
import { ReviewIssueCreatedDatePipe } from '../../core/pipes/review-issue-created-date.pipe';
import { ReviewerIssueStatusBadgeWidgetComponent } from './widgets/reviewer-issue-status-badge-widget/reviewer-issue-status-badge-widget.component';
import { ConfigurationFormBuilderComponent } from './configuration-steps/configuration-form-builder/configuration-form-builder.component';
import { ReviewerIssuesListWidgetComponent } from './widgets/reviewer-issues-list-widget/reviewer-issues-list-widget.component';
import { ServiceRequestHeaderToggerWidgetComponent } from './widgets/service-request-header-togger-widget/service-request-header-togger-widget.component';
import { ServiceChangeRequestFeedbackHandlerWidgetComponent } from './widgets/service-change-request-feedback-handler-widget/service-change-request-feedback-handler-widget.component';
import { ReviewIssuesEditFormWidgetComponent } from './widgets/review-issues-edit-form-widget/review-issues-edit-form-widget.component';
import { FormFieldConfiguratorSettingsComponent } from './form-field-configurator/form-field-configurator-settings/form-field-configurator-settings.component';
import { FormFieldConfiguratorComponent } from './form-field-configurator/form-field-configurator.component';
import { FieldConfigTypeAsStringPipe } from '../../core/pipes/field-config-type-as-string.pipe';
import { FormFieldTypesListComponent } from './form-field-configurator/form-field-types-list/form-field-types-list.component';
import { TranslationServiceOverviewComponent } from './configuration-steps/configuration-translation/widgets/translation-service-overview/translation-service-overview.component';
import { ConfigurationTranslationComponent } from './configuration-steps/configuration-translation/configuration-translation.component';
import { ReviewTranslationsComponent } from './review-steps/review-translations/review-translations.component';
import { PanelWrapperComponent } from './service-configuration/panel-wrapper/panel-wrapper.component';
import { FormFieldWidgetsListComponent } from './form-field-configurator/form-field-widgets-list/form-field-widgets-list.component';
import { QuillModule } from 'ngx-quill';
import { ConfigurationOfficerFormComponent } from './configuration-steps/configuration-officer-form/configuration-officer-form.component';
import { OfficerFormPreviewJsonFormdataWidgetComponent } from './configuration-steps/configuration-officer-form/widgets/officer-form-preview-json-formdata-widget/officer-form-preview-json-formdata-widget.component';
import { TagInputModule } from 'ngx-chips';
@NgModule({
  declarations: [
    ManageServiceChangeRequestsComponent,
    ServiceChangeRequestsListTableWidgetComponent,
    FieldWrapperComponent,
    PanelWrapperComponent,
    SettingsToolbarComponent,
    FormTemplatesComponent,
    FormlySwitchComponent,
    FormlyRepeatComponent,
    AlertWrapperComponent,
    ConditionWrapperComponent,
    ServiceChangeRequestStatusBadgeComponent,
    ServiceChangeRequestTypeBadgeComponent,
    ConfigureServiceChangeRequestComponent,
    ChangeRequestConfigurationHeaderWidgetComponent,
    ServiceChangeRequestReviewerActionWidgetComponent,
    ServiceChangeRequestConfigurerActionWidgetComponent,
    ConfigurationMetadataOverviewFormComponent,
    ConfigureServiceChangeRequestStepperComponent,
    ConfigurationWorkflowJsonEditorFormComponent,
    ConfigurationFormBuilderComponent,
    WorkflowTemplateSelectorListWidgetComponent,
    ViewWorkflowTemplateDetailsWidgetComponent,
    ConfigurationPricingFormComponent,
    PreviewServiceChangeRequestJsonFormdataWidgetComponent,
    FormlyFieldStepperComponent,
    FormlyFieldGroupComponent,
    ConfigurationPricingFormComponent,
    ConfigureServiceChangeRequestPriceHandlerComponent,
    ConfigureServiceFixedPriceHandlerComponent,
    ReviewServiceChangeRequestComponent,
    ReviewMetadataOverviewComponent,
    ReviewerIssuesEntryFormWidgetWidgetComponent,
    ReviewFormbuilderJsonEditorComponent,
    ReviewSubmissionComponent,
    ConfigureServiceDynamicPriceHandlerComponent,
    ConfigureServiceDynamicPricingFormComponent,
    ChangeRequestStatusToLabelPipe,
    ReviewIssueStatusToLabelPipe,
    ReviewSectionToLabelPipe,
    ReviewWorkflowJsonEntryComponent,
    ReviewPricingComponent,
    ServiceRequestItemPricingWidgetComponent,
    RequestPricingDynamicOperatorToLabel,
    ReviewIssueCreatedDatePipe,
    FieldConfigTypeAsStringPipe,
    ReviewerIssueStatusBadgeWidgetComponent,
    ReviewerIssuesListWidgetComponent,
    ServiceRequestHeaderToggerWidgetComponent,
    ServiceChangeRequestFeedbackHandlerWidgetComponent,
    ReviewIssuesEditFormWidgetComponent,
    FormFieldConfiguratorComponent,
    FormFieldConfiguratorSettingsComponent,
    FormFieldTypesListComponent,
    ConfigurationTranslationComponent,
    TranslationServiceOverviewComponent,
    ReviewTranslationsComponent,
    FormFieldWidgetsListComponent,
    ConfigurationOfficerFormComponent,
    OfficerFormPreviewJsonFormdataWidgetComponent,
  ],
  imports: [
    CommonModule,
    ServicesRoutingModule,
    UiModule,
    UtilsModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    CdkStepperModule,
    AngJsoneditorModule,
    QuillModule.forRoot(),
    FormlyModule.forRoot({
      wrappers: [
        {
          name: 'field-wrapper',
          component: FieldWrapperComponent,
          types: [
            EIremboFormlyFieldTypes.input,
            EIremboFormlyFieldTypes.textarea,
            EIremboFormlyFieldTypes.checkbox,
            EIremboFormlyFieldTypes.multicheckbox,
            EIremboFormlyFieldTypes.radio,
            ...formlyConfigs().types.map(type => type.name),
          ],
        },
        {
          name: 'hidden-field-wrapper',
          component: HideFieldWrapperComponent,
          types: [
            EIremboFormlyFieldTypes.input,
            EIremboFormlyFieldTypes.textarea,
            EIremboFormlyFieldTypes.checkbox,
            EIremboFormlyFieldTypes.multicheckbox,
            EIremboFormlyFieldTypes.radio,
            ...formlyConfigs().types.map(type => type.name),
          ],
        },
        { name: 'condition-wrapper', component: ConditionWrapperComponent },
        { name: 'panel-wrapper', component: PanelWrapperComponent },
        {
          name: 'hint-field-wrapper',
          component: HintFieldWrapperComponent,
          types: [
            'input',
            'textarea',
            'multicheckbox',
            'block',
            'formly-group',
            'sections',
            'radio',
            ...formlyConfigs().types.map(type => type.name),
          ],
        },
        {
          name: 'rfa-readonly-field-wrapper',
          component: RfaReadonlyCheckerWrapperComponent,
          types: [
            'input',
            'textarea',
            'multicheckbox',
            'radio',
            ...formlyConfigs()
              .types.map(type => type.name)
              .filter(
                name =>
                  ![
                    EIremboFormlyFieldTypes.customrepeater,
                    EIremboFormlyFieldTypes.customsectionmessagewithlink,
                  ].includes(name)
              ),
          ],
        },
        {
          name: 'irembogov-readonly-wrapper',
          component: ReadonlyWrapperComponent,
          types: [
            'input',
            'textarea',
            'multicheckbox',
            'radio',
            ...formlyConfigs().types.map(type => type.name),
          ],
        },
      ],
      ...formlyConfigs(),
      types: [
        ...formlyConfigs().types.map(type => ({
          component: type.component,
          name: type.name,
          wrappers: ['form-field'],
        })),
        { name: 'block', component: FormlyFieldGroupComponent, wrappers: [] },
        {
          name: 'sections',
          component: FormlyFieldStepperComponent,
          wrappers: [],
        },
        { name: 'formlyswitch', component: FormlySwitchComponent },
        { name: 'repeat', component: FormlyRepeatComponent },
      ],
      validators: [...formlyConfigs().validators],
    }),
    FormlyBootstrapModule,
    DragDropModule,
    TagInputModule,
  ],
  providers: [DatePipe],
})
export class ServicesModule {}
