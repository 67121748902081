import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  ExtractHttpErrorResponseCodeAndMessage,
  IHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { finalize, tap } from 'rxjs';
import { IServiceChangeRequest } from '../../../core/models/service-change-request.model';
import { ServiceChangeRequestService } from '../../../core/services/service-change-request.service';
import { IServiceConfigStep } from '../../../core/models/service-change-request-config-steps-forms.model';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormBuilderService } from '../../../core/services/form-builder.service';
import { DefaultEmptyFormData } from '../../../core/models/form-builder-fields-data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-review-service-change-request',
  templateUrl: './review-service-change-request.component.html',
  styleUrls: ['./review-service-change-request.component.scss'],
  providers: [{ provide: CdkStepper }],
})
export class ReviewServiceChangeRequestComponent implements OnInit, OnDestroy {
  isLoadingChangeRequest = false;
  loadRequestDetailsFailed = false;
  isLoadingChangeRequestDetails = false;
  loadFailedErrorCodeMessage!: IHttpErrorResponseCodeAndMessage;
  requestId!: string;

  showFormPreview = false;

  @ViewChild('reviewOverview', { static: true })
  reviewOverviewTemplate!: TemplateRef<any>;
  @ViewChild('reviewForm', { static: false })
  reviewFormTemplate!: TemplateRef<any>;
  @ViewChild('reviewPricing', { static: false })
  reviewPricingTemplate!: TemplateRef<any>;
  @ViewChild('reviewWorkflow', { static: false })
  reviewWorkflowTemplate!: TemplateRef<any>;
  @ViewChild('reviewSla', { static: false })
  reviewSlaTemplate!: TemplateRef<any>;
  @ViewChild('reviewNotifications', { static: false })
  reviewNotificationsTemplate!: TemplateRef<any>;
  @ViewChild('reviewOfficeAssignment', { static: false })
  reviewOfficeAssignmentTemplate!: TemplateRef<any>;
  @ViewChild('reviewIntegrations', { static: false })
  reviewIntegrationsTemplate!: TemplateRef<any>;
  @ViewChild('reviewDocuments', { static: false })
  reviewDocumentsTemplate!: TemplateRef<any>;
  @ViewChild('reviewTranslation', { static: false })
  reviewTranslationTemplate!: TemplateRef<any>;
  @ViewChild('reviewPreviewSubmit', { static: false })
  reviewPreviewSubmitTemplate!: TemplateRef<any>;

  reviewSteps: IServiceConfigStep[] = [
    {
      icon: 'fa-clock fas',
      label: 'Overview',
      key: 'reviewOverview',
    },
    {
      icon: 'fa-border-none fas',
      label: 'Form',
      key: 'reviewForm',
    },
    {
      icon: 'fa-money-bill-1-wave fas',
      label: 'Pricing',
      key: 'reviewPricing',
    },
    {
      icon: 'fa-diagram-project fas',
      label: 'Workflow Config',
      key: 'reviewWorkflow',
    },
    {
      icon: 'fa-language fas',
      label: 'Translation',
      key: 'reviewTranslation',
    },
    {
      icon: 'fa-list-check fa-solid',
      label: 'Preview & Submit',
      key: 'reviewPreviewSubmit',
    },
  ];

  currentConfigStep = 0;

  changeRequest!: IServiceChangeRequest;

  changeRequestJsonFormData!: Record<string, FormlyFieldConfig[]>;

  changeRequestJsonWorkflowData!: Record<string, unknown>;

  serviceChangeRequestFormFields!: any;

  serviceChangesRequestTranslations!: Record<string, Record<string, unknown>>;

  serviceChangeRequestPricingData!: Record<string, unknown>;

  readyForFinalReview = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public toastService: ToastService,
    public formBuilderService: FormBuilderService,
    private serviceChangeRequestService: ServiceChangeRequestService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const paramRequestId: string | null = params.get('requestId');
      if (paramRequestId) {
        this.requestId = paramRequestId;
        this.isLoadingChangeRequest = true;
        this.getChangeRequest();
      }
    });

    this.formBuilderService.startOnlyFormPreviewSubjects();
    this.formBuilderService.reviewModeEnabled$.next(true);
  }

  getChangeRequestPricingData() {
    this.isLoadingChangeRequestDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestPricingDetails(this.requestId)
      .pipe(finalize(() => (this.isLoadingChangeRequestDetails = false)))
      .subscribe({
        next: (response: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.serviceChangeRequestPricingData = response.data;
        },
        error: () => {
          this.serviceChangeRequestPricingData = {};
        },
      });
  }

  getChangeRequestTranslationData() {
    this.isLoadingChangeRequestDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestTranslation(this.requestId)
      .pipe(finalize(() => (this.isLoadingChangeRequestDetails = false)))
      .subscribe({
        next: (
          response: IHttpSingleDataResponse<
            Record<string, Record<string, unknown>>
          >
        ) => {
          this.serviceChangesRequestTranslations = response.data;
        },
        error: () => {
          this.serviceChangesRequestTranslations = {};
        },
      });
  }

  getChangeRequest() {
    this.isLoadingChangeRequestDetails = true;
    this.serviceChangeRequestService
      .getAssignedServiceChangeRequestById(this.requestId)
      .pipe(
        finalize(() => {
          this.isLoadingChangeRequest = false;
          this.isLoadingChangeRequestDetails = false;
        }),
        tap(() => {
          this.getChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime();
          this.getChangeRequestPricingData();
          this.getChangeRequestFormDefinition();
          this.getChangeRequestTranslationData();
        })
      )
      .subscribe({
        next: (response: IHttpSingleDataResponse<IServiceChangeRequest>) => {
          this.changeRequest = response.data;
        },
        error: (error: HttpErrorResponse) => {
          this.loadRequestDetailsFailed = true;
          this.loadFailedErrorCodeMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              `Failed to load Service Change Request: #${this.requestId}`
            );
        },
      });
  }

  getChangeRequestWorkflowDefinition() {
    this.isLoadingChangeRequestDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestWorkflowDefinition(this.requestId)
      .pipe(
        finalize(() => {
          this.isLoadingChangeRequestDetails = false;
        })
      )
      .subscribe({
        next: (response: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.changeRequestJsonWorkflowData = response.data;
        },
        error: () => {
          this.changeRequestJsonWorkflowData = {};
        },
      });
  }

  getChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime() {
    this.isLoadingChangeRequestDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime(
        this.requestId
      )
      .pipe(
        finalize(() => {
          this.isLoadingChangeRequestDetails = false;
        })
      )
      .subscribe({
        next: (response: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.changeRequestJsonWorkflowData = response.data;
        },
        error: () => {
          this.changeRequestJsonWorkflowData = {};
        },
      });
  }

  getChangeRequestFormDefinition() {
    this.isLoadingChangeRequestDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestFormDefinition(this.requestId)
      .pipe(
        finalize(() => {
          this.isLoadingChangeRequestDetails = false;
        })
      )
      .subscribe({
        next: (
          response: IHttpSingleDataResponse<Record<string, FormlyFieldConfig[]>>
        ) => {
          this.changeRequestJsonFormData = response.data;
          this.formBuilderService.loadJsonFormDataForPreview(
            this.changeRequestJsonFormData
          );
        },
        error: () => {
          this.changeRequestJsonFormData = DefaultEmptyFormData;
        },
      });
  }

  getCurrentTemplate(index: number): TemplateRef<ElementRef> {
    if (index > 0 && index <= this.reviewSteps.length) {
      return this[
        `${this.reviewSteps[index].key}Template` as keyof ReviewServiceChangeRequestComponent
      ] as TemplateRef<ElementRef>;
    }
    return this.reviewOverviewTemplate;
  }

  onStepFormSubmitted(isValid: boolean) {
    this.reviewSteps[this.currentConfigStep].isComplete = isValid;
    this.handleNextStepValidity(isValid);
  }

  handleNextStepValidity(stepIsValid: boolean): void {
    if (stepIsValid) {
      this.getChangeRequest();
      this.goToNextStep();
    }
  }

  goToNextStep(): void {
    if (this.currentConfigStep < this.reviewSteps.length - 1) {
      this.currentConfigStep++;
      return;
    }
    if (this.currentConfigStep >= this.reviewSteps.length - 1) {
      this.readyForFinalReview = true;
    }
  }

  OnReviewApproved($event: boolean) {
    if (!$event) return;
    this.isLoadingChangeRequestDetails = true;
    this.serviceChangeRequestService
      .approveChangeRequestByReviewer(this.requestId)
      .pipe(finalize(() => (this.isLoadingChangeRequest = false)))
      .subscribe({
        next: () => {
          this.handleActionSuccess(
            'Change request configurations approved succesfully'
          );
        },
        error: (error: HttpErrorResponse) => {
          this.handleActionError(error, 'Failed to approve change request');
        },
      });
  }

  OnReviewRejected($event: boolean) {
    if (!$event) return;
    this.isLoadingChangeRequest = true;
    this.serviceChangeRequestService
      .rejectChangeRequestByReviewer(this.requestId)
      .pipe(finalize(() => (this.isLoadingChangeRequest = false)))
      .subscribe({
        next: () => {
          this.handleActionSuccess('Change request configurations rejected');
        },
        error: (error: HttpErrorResponse) => {
          this.handleActionError(error, 'Failed to reject change request');
        },
      });
  }

  handleActionSuccess(message: string) {
    this.toastService.show({ body: message, type: 'success' });
    this.router
      .navigate(['/services'])
      .then()
      .catch(() => {
        return;
      });
  }

  handleActionError(error: HttpErrorResponse, message: string) {
    const errorMessageAndCode: IHttpErrorResponseCodeAndMessage =
      ExtractHttpErrorResponseCodeAndMessage(error, message);
    this.toastService.show({
      body: `${errorMessageAndCode.message} - ${
        errorMessageAndCode.code ? ': ' + errorMessageAndCode.code : ''
      }`,
      type: 'error',
    });
  }

  toggleFormPreview(event: boolean) {
    this.showFormPreview = event;
  }

  OnStepperChange(event: StepperSelectionEvent) {
    this.currentConfigStep = event.selectedIndex;
  }

  onViewRequirementDocs() {
    if (!this.changeRequest.id) {
      return;
    }
    const file = this.changeRequest.serviceRequirementDocument.file;
    const byteArray = new Uint8Array(
      window
        .atob(file)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], {
      type: this.changeRequest.serviceRequirementDocument.contentType,
    });
    const fileUrl = URL.createObjectURL(blob);
    window.open(fileUrl, '_blank');
  }

  ngOnDestroy(): void {
    this.formBuilderService.completeOnlyFormPreviewSubjects();
  }
}
