import { EServiceChangeRequestToggleHeaders } from './../../../../core/models/service-change-request-toggle-header-section.enum';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-service-request-header-togger-widget',
  templateUrl: './service-request-header-togger-widget.component.html',
})
export class ServiceRequestHeaderToggerWidgetComponent implements OnInit {
  @Input() selectedOption?: string;
  @Input()
  options: IIrembogovBasicLabelKeyPair<EServiceChangeRequestToggleHeaders>[] =
    [];
  @Output() selectOption =
    new EventEmitter<EServiceChangeRequestToggleHeaders>();

  buttonColumn!: number;

  ngOnInit(): void {
    this.buttonColumn = this.options.length + 1;
  }
  onSelectOption(value: EServiceChangeRequestToggleHeaders) {
    this.selectOption.emit(value);
  }
}
