/* eslint-disable @typescript-eslint/no-inferrable-types */
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import {
  IUiAlertContent,
  ToastService,
  TUiAlertTypes,
  EServicePriceType,
  ExtractHttpErrorResponseCodeAndMessage,
  IHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
} from '@irembo-andela/irembogov3-common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { EServiceChangeRequestSection } from '../../../../core/models/service-change-request-sections.enum';
import { EServiceChangeRequestStatus } from '../../../../core/models/service-change-request-status.enum';
import { EServicePaymentType } from '../../../../core/models/service-payment-type.enum';

@Component({
  selector: 'irembogov-configuration-pricing-form',
  templateUrl: './configuration-pricing-form.component.html',
  styleUrls: ['./configuration-pricing-form.component.scss'],
})
export class ConfigurationPricingFormComponent implements OnChanges {
  @Input() changeRequest!: IServiceChangeRequest;
  @Input() defaultData!: Record<string, unknown>;
  @Input() formFields!: any;
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() OnPricingFormSubmitted: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() isPrice = false;

  EServiceChangeRequestSection = EServiceChangeRequestSection;
  EServiceChangeRequestStatus = EServiceChangeRequestStatus;
  EServicePaymentType = EServicePaymentType;
  inReviewMode = false;

  pricingUpdateValues: Record<string, unknown> = {
    paymentType: null,
    pricing: {},
    paymentExpirationHours: 0,
    paymentAccount: {},
    paymentCode: {},
    paymentMerchant: {},
  };
  isLoading = false;
  showConfigFormErrorMessage = false;
  @ViewChild('errorMessageElement') errorMessageElement!: ElementRef;

  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };
  paymentTypeForm: FormGroup = new FormGroup({
    paymentType: new FormControl('', [Validators.required]),
  });
  pricingExpirationForm: FormGroup = new FormGroup({
    paymentExpirationHours: new FormControl('', [Validators.required]),
  });

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequest']?.currentValue) {
      this.changeRequest = changes['changeRequest'].currentValue;
      this.inReviewMode =
        this.changeRequest.status ===
        EServiceChangeRequestStatus.IN_REDEVELOPMENT;
    }

    if (changes['defaultData']?.currentValue) {
      this.defaultData = changes['defaultData'].currentValue;
      this.pricingExpirationForm
        .get('paymentExpirationHours')
        ?.setValue(this.defaultData['paymentExpirationHours']);
      this.paymentTypeForm
        .get('paymentType')
        ?.setValue(this.defaultData['paymentType']);
      this.pricingExpirationForm.markAllAsTouched();
      this.pricingExpirationForm.updateValueAndValidity();
      this.pricingUpdateValues = this.defaultData;
    }
  }

  onSubmit() {
    if (!this.isUpdateFieldsValid()) {
      this.toastService.show({
        body: 'Please provide all the required fields',
        type: 'error',
      });
      this.OnPricingFormSubmitted.emit(false);
    } else {
      this.updateFormDisplayedErrorMessageContent(false);
      this.isLoading = true;

      this.pricingUpdateValues['paymentType'] =
        this.paymentTypeForm?.get('paymentType')?.value;

      const pricingConfig = this.formatPricing(
        this.paymentTypeForm?.get('paymentType')?.value,
        this.pricingUpdateValues
      );

      this.serviceChangeRequestService
        .updateServiceChangeRequestPricing(
          this.changeRequest.id ?? '',
          pricingConfig
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: (res: IHttpSingleDataResponse<IServiceChangeRequest>) => {
            this.toastService.show({
              body: res.responseMessage,
              type: 'success',
            });
            this.OnPricingFormSubmitted.emit(this.isUpdateFieldsValid());
          },
          error: (error: HttpErrorResponse) => {
            const errorMessage: IHttpErrorResponseCodeAndMessage =
              ExtractHttpErrorResponseCodeAndMessage(
                error,
                `Failed to update change request pricing`
              );
            this.toastService.show({
              body: errorMessage.message,
              type: 'error',
            });
            this.OnPricingFormSubmitted.emit(false);
          },
        });
    }
  }

  isUpdateFieldsValid(): boolean {
    const paymentType = this.paymentTypeForm.get('paymentType')?.value;

    if (!this.paymentTypeForm.valid) {
      return false;
    }
    if (paymentType === EServicePaymentType.FREE) {
      return true;
    }

    const fieldsToValidate = [
      'pricing',
      'paymentAccount',
      'paymentCode',
      'paymentMerchant',
    ];

    return fieldsToValidate.every(field =>
      this.isPricingFieldValid(this.pricingUpdateValues[field])
    );
  }

  onPaymentTypeChange(paymentType: EServicePaymentType) {
    this.paymentTypeForm?.get('paymentType')?.setValue(paymentType);
  }

  onPaymentExpirationChange(event: any) {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    this.pricingUpdateValues['paymentExpirationHours'] = parseInt(
      event.target.value
    );
  }

  onPriceChange(price: Record<string, unknown>) {
    this.pricingUpdateValues['pricing'] = price;
  }

  onPaymentAccountIdentifierChange(paymentAccount: Record<string, unknown>) {
    this.pricingUpdateValues['paymentAccount'] = paymentAccount;
  }

  onPaymentCodeIdentifierChange(paymentCode: Record<string, unknown>) {
    this.pricingUpdateValues['paymentCode'] = paymentCode;
  }

  onPaymentMerchantChange(paymentMerchant: Record<string, unknown>) {
    this.pricingUpdateValues['paymentMerchant'] = paymentMerchant;
  }

  isPricingFieldValid(pricingField: any): boolean {
    const pricingType =
      pricingField?.dynamicConditions.length <= 0
        ? EServicePriceType.FIXED
        : EServicePriceType.DYNAMIC;
    switch (pricingType) {
      case EServicePriceType.FIXED:
        return Boolean(pricingField?.baseValue);
      case EServicePriceType.DYNAMIC:
        return true;
      default:
        return false;
    }
  }

  updateFormDisplayedErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title: string = '',
    message: string = ''
  ) {
    this.showConfigFormErrorMessage = show;
    this.formAlertContent = { type, title, message };
    if (show) {
      this.errorMessageElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  formatPricing(
    paymentType: EServicePaymentType,
    pricingValues: Record<string, unknown>
  ) {
    switch (paymentType) {
      case EServicePaymentType.PAID:
        return this.formatSenarioDynamicConditions(pricingValues);
      case EServicePaymentType.FREE:
      default:
        return {
          paymentType,
          pricing: null,
          paymentExpirationHours: null,
          paymentAccount: null,
          paymentCode: null,
          paymentMerchant: null,
        };
    }
  }

  formatSenarioDynamicConditions(obj: any): any {
    if (!this.isObject(obj)) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(item => this.formatSenarioDynamicConditions(item));
    }

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (this.isObject(obj[key])) {
          obj[key] = this.formatSenarioDynamicConditions(obj[key]);
        } else if (
          obj[key] === '' ||
          obj[key] === null ||
          obj[key] === undefined
        ) {
          delete obj[key];
        }
      }
    }

    if (this.hasConditionsProperty(obj)) {
      obj['conditions'] = this.filterIncompleteConditions(obj['conditions']);
    }

    return obj;
  }

  hasConditionsProperty(obj: Record<string, unknown>) {
    return (
      Object.prototype.hasOwnProperty.call(obj, 'conditions') &&
      Array.isArray(obj['conditions']) &&
      obj['valueFormula'] !== null
    );
  }

  isObject(value: unknown) {
    return typeof value === 'object' && value !== null;
  }

  isNotEmpty(value: unknown): boolean {
    return value !== null && value !== undefined && value !== '';
  }

  filterIncompleteConditions(conditions: Record<string, unknown>[]) {
    return conditions.filter((condition: Record<string, unknown>) => {
      return (
        this.isNotEmpty(condition['attribute']) &&
        this.isNotEmpty(condition['comparator']) &&
        this.isNotEmpty(condition['value']) &&
        this.isNotEmpty(condition['attributeType'])
      );
    });
  }
}
