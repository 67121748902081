<span
  [ngClass]="
    'irembogov-service-change-request-status-badge badge ' +
    getServiceChangeRequestStatusBadgeClass(requestStatus).bgClass
  ">
  <i
    [ngClass]="
      'fa-regular ' +
      getServiceChangeRequestStatusBadgeClass(requestStatus).icon
    "></i>
  {{ getServiceChangeRequestStatusBadgeClass(requestStatus).label }}
</span>
