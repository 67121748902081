import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { IServiceConfigStep } from '../../../../core/models/service-change-request-config-steps-forms.model';

@Component({
  selector: 'irembogov-configure-service-change-request-stepper',
  templateUrl: './configure-service-change-request.stepper.component.html',
  styleUrls: ['./configure-service-change-request.stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CdkStepper,
      useExisting: ConfigureServiceChangeRequestStepperComponent,
    },
  ],
})
export class ConfigureServiceChangeRequestStepperComponent extends CdkStepper {
  @Input() configSteps: IServiceConfigStep[] = [];
  @Input() showIndexInLabel = true;

  selectStepByIndex(index: number) {
    if (!this.configSteps[index].isComplete) return;
    this.selectedIndex = index;
  }
}
