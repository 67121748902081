import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  RoleEnumToLabelUtil,
  RoleLabelToEnumUtil,
  RolesEnum,
  ISidebarItem,
  ISiderInstitutionItem,
} from '@irembo-andela/irembogov3-common';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Subject, takeUntil } from 'rxjs';
import { INavbarAllowedRolesItem } from '../../core/models/navbar-allowed-roles-item.model';
import { AuthService } from '../../core/services/auth.service';
import { GetUserRoleNavigationMenuItems } from '../../core/utils/role.utils';

@Component({
  selector: 'irembogov-service-management-portal-home',
  templateUrl: './service-management-portal-home.component.html',
})
export class ServiceManagementPortalHomeComponent implements OnInit, OnDestroy {
  private userRoles: RolesEnum[] = [];
  RoleEnumToLabelUtil = RoleEnumToLabelUtil;

  keycloackProfile!: KeycloakProfile;
  userActiveRole?: RolesEnum;
  loggedIn = false;
  navbarItems: INavbarAllowedRolesItem[];
  showDropdownMenu = false;
  selectedInstitution?: ISiderInstitutionItem;
  userProfileImageUrl = '';

  userActiveRoleSubject$: Subject<void> = new Subject<void>();

  constructor(
    private keycloak: KeycloakService,
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.loadUserProfile().subscribe({
      next: (profile: any) => {
        this.keycloackProfile = profile;
      },
    });

    this.authService.userActiveRole
      .pipe(takeUntil(this.userActiveRoleSubject$))
      .subscribe({
        next: (role: RolesEnum) => {
          this.userActiveRole = role;
          this.getUserNavItems();
        },
      });

    this.navbarItems = [
      {
        name: 'Dashboard',
        icon: 'fas fa-regular fa-rectangle-list',
        routerLink: ['/'],
      },
      {
        name: 'Services',
        icon: 'fas fa-regular fa-rectangle-list',
        routerLink: ['/services'],
      },
      { name: 'Users', icon: 'fas fa-users', routerLink: ['users'] },
      {
        name: 'Reporting',
        icon: 'fa-regular fa-file-lines',
        routerLink: ['reporting'],
      },
    ];
  }

  ngOnInit(): void {
    this.loadUserOtherRoles();
  }

  private async loadUserOtherRoles(): Promise<void> {
    this.userRoles = await this.authService.loadUserIremboRoles();
  }

  getUserNavItems(): void {
    if (!this.userActiveRole) {
      this.navbarItems = [];
      return;
    }
    this.navbarItems = GetUserRoleNavigationMenuItems(this.userActiveRole);
  }

  getOtherRoles(): string[] {
    return this.userRoles.map((role: RolesEnum) => RoleEnumToLabelUtil(role));
  }

  onRoleChange(roleLabel: string) {
    this.authService.userActiveRole.next(RoleLabelToEnumUtil(roleLabel));
    this.reloadComponentAndRoute(true);
  }

  onActionCLick($event: ISidebarItem): void {
    if ($event.routerLink !== undefined) {
      this.router.navigate([$event.routerLink]);
    }
  }

  reloadComponentAndRoute(self: boolean, urlToNavigateTo?: string) {
    const url = self ? this.router.url : urlToNavigateTo;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]).then(() => {
        console.log(`Component reloaded.`);
      });
    });
  }

  reloadPage() {
    window.location.reload();
  }

  async logoutUser(): Promise<void> {
    await this.authService.logoutUser();
  }

  ngOnDestroy(): void {
    if (this.userActiveRoleSubject$) {
      this.userActiveRoleSubject$.next();
      this.userActiveRoleSubject$.complete();
    }
  }
}
