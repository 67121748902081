import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject, Observable, Subject, map } from 'rxjs';
import {
  FormlyFieldConfigForConverter,
  FormlyFields,
  IFormBuilderFieldsData,
} from '../models/form-builder-fields-data.model';
import {
  IBasicFieldLabelAndDetails,
  IBasicLabelValuePair,
} from '../models/basic-label-value-pair.model';
import { TFormlyFieldKey } from '../models/formly-key.type';
import { NonDataElementFormlyFieldTypes } from '../models/irembo-formly-non-data-field-types.enum';
import { EIremboFormlyFieldTypes } from '@irembo-andela/irembogov3-common';
import { ServiceChangeRequestService } from './service-change-request.service';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService
  ) {}

  deleteFieldAction$ = new Subject();
  duplicateFieldAction$ = new Subject();
  formlyFieldsSubject$: BehaviorSubject<IFormBuilderFieldsData> =
    new BehaviorSubject<IFormBuilderFieldsData>({
      fields: [],
    });
  reviewModeEnabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  configureFieldAction$ = new BehaviorSubject<FormlyFieldConfig | null>(null);

  formFieldBlockIdsSet$ = new BehaviorSubject<Set<string>>(new Set());

  removeItemAction$ = new Subject<number>();

  integrationPoint = new BehaviorSubject<any>([]);

  thirdPartyFormlyFieldsSubject$: BehaviorSubject<any> = new BehaviorSubject<
    FormlyFieldConfig[]
  >([]);

  public formUpdated$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public reviewFlagItemSubject$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  getEndPointByCode(code: string): Observable<any> {
    return this.serviceChangeRequestService.getIntegrationEndpoint(code).pipe(
      map((res: any) => {
        return {
          url: res.data.url,
          options: this.extractUrlAttributes(res.data.customConfigJson),
        };
      })
    );
  }

  extractUrlAttributes(attributes: any) {
    const options: IBasicLabelValuePair<Record<string, unknown>>[] = [];
    const parsedJson = JSON.parse(attributes);
    Object.keys(parsedJson).forEach(function (key) {
      options.push({
        label: key,
        value: parsedJson[key],
      });
    });
    return options;
  }

  loadJsonFormDataForPreview(jsonFormData: any): void {
    this.formFieldBlockIdsSet$.next(new Set());
    if (jsonFormData?.['fields']) {
      const fields: FormlyFieldConfig[] = jsonFormData['fields'];
      this.formlyFieldsSubject$.next({ fields });
      return;
    }

    this.formlyFieldsSubject$.next({ fields: [] });
  }

  refreshFormlyForm(clearConfiguredItem = true, forceReload = true): void {
    const fields: FormlyFieldConfig[] =
      this.formlyFieldsSubject$.getValue().fields;
    this.formlyFieldsSubject$.next({ fields: [...fields], forceReload });
    if (clearConfiguredItem) {
      this.configureFieldAction$.next(null);
    }
  }

  addToFormFieldBlockIdsSet(id: string) {
    const newSet: Set<string> = this.formFieldBlockIdsSet$.getValue().add(id);
    this.formFieldBlockIdsSet$.next(newSet);
  }

  removeFormFieldBlockIdsSet(id: string) {
    const existingSet: Set<string> = this.formFieldBlockIdsSet$.getValue();
    existingSet.delete(id);
    this.formFieldBlockIdsSet$.next(existingSet);
  }

  deleteFormField(fieldToDelete: FormlyFieldConfig) {
    const formlyFields = this.formlyFieldsSubject$.getValue();
    const fields = formlyFields?.fields;
    if (!fields || !fieldToDelete?.parent?.fieldGroup) {
      return;
    }

    const parentFieldGroup = fieldToDelete.parent.fieldGroup;
    if (!parentFieldGroup) {
      return;
    }

    switch (fieldToDelete.type) {
      case EIremboFormlyFieldTypes.block: {
        const parentBlock = fieldToDelete.parent.fieldGroup ?? [];
        const index = parentBlock.findIndex(
          field => field.id === fieldToDelete.id
        );
        if (index !== -1) {
          parentBlock.splice(index, 1);
          fieldToDelete.parent.fieldGroup = [...parentBlock];
        }
        break;
      }
      case EIremboFormlyFieldTypes.sections: {
        const sections = fields[0]?.fieldGroup ?? [];
        const sectionIndex = sections.findIndex(
          section => section.key === fieldToDelete.key
        );
        if (sectionIndex !== -1) {
          sections.splice(sectionIndex, 1);
          fields[0].fieldGroup = [...sections];
        }
        break;
      }
      default: {
        const fieldIndex = parentFieldGroup.findIndex(
          field => field.key === fieldToDelete.key
        );
        if (fieldIndex !== -1) {
          parentFieldGroup.splice(fieldIndex, 1);
          fieldToDelete.parent.fieldGroup = [...parentFieldGroup];
        }
        break;
      }
    }

    this.formlyFieldsSubject$.next({ ...formlyFields, forceReload: true });
    this.configureFieldAction$.next(null);
  }

  startOnlyFormPreviewSubjects() {
    this.initFormlyFieldsSubject();
    this.initFormFieldBlockIdsSetSubject();
    this.initreviewModeEnabledSubject();
  }

  completeOnlyFormPreviewSubjects() {
    this.formlyFieldsSubject$.complete();
    this.formFieldBlockIdsSet$.complete();
    this.reviewModeEnabled$.complete();
  }

  startFormBuilderConfigurationSubjects() {
    this.startOnlyFormPreviewSubjects();
    this.initConfigureFieldActionSubject();
  }

  completeFormBuilderConfigurationSubjects() {
    this.completeOnlyFormPreviewSubjects();
    this.configureFieldAction$.complete();
  }

  getFormFieldOptionLabelAndValue(fieldKey: any): FormlyFieldConfig {
    let optionsField!: FormlyFieldConfig;
    const fields = this.formlyFieldsSubject$.getValue().fields;
    return this.formFieldOptionsExtractor(
      fieldKey?.compareField?.value,
      optionsField,
      fields
    );
  }

  getAllFormFieldLabelAndDetails(
    dataEntryFieldsOnly = false,
    removeConfiguredKey = false,
    excludedFieldTypes: EIremboFormlyFieldTypes[] = [],
    excludeNonConfigurableFields = true
  ): IBasicFieldLabelAndDetails<TFormlyFieldKey>[] {
    const fields: FormlyFieldConfig[] =
      this.formlyFieldsSubject$.getValue().fields;
    let formFieldLabelAndDetails: IBasicFieldLabelAndDetails<TFormlyFieldKey>[] =
      [];
    formFieldLabelAndDetails = this.formFieldLabelAndDetailsExtractor(
      fields,
      formFieldLabelAndDetails,
      dataEntryFieldsOnly,
      removeConfiguredKey,
      excludedFieldTypes,
      excludeNonConfigurableFields
    );
    return formFieldLabelAndDetails;
  }

  getConfiguredFieldSiblingsFieldLabelAndDetails(
    dataEntryFieldsOnly = false,
    removeConfiguredKey = false,
    excludeNonConfigurableFields = true
  ): IBasicFieldLabelAndDetails<TFormlyFieldKey>[] {
    const siblingFields: FormlyFieldConfig[] =
      this.configureFieldAction$.getValue()?.parent?.fieldGroup || [];
    let siblingFieldsFieldLabelAndDetails: IBasicFieldLabelAndDetails<TFormlyFieldKey>[] =
      [];
    siblingFieldsFieldLabelAndDetails = this.formFieldLabelAndDetailsExtractor(
      siblingFields,
      siblingFieldsFieldLabelAndDetails,
      dataEntryFieldsOnly,
      removeConfiguredKey,
      [],
      excludeNonConfigurableFields
    );
    return siblingFieldsFieldLabelAndDetails;
  }

  private formFieldOptionsExtractor(
    fieldKey: string,
    optionsField: FormlyFieldConfig,
    fields: FormlyFieldConfig[]
  ): FormlyFieldConfig {
    const formFieldWithOptions = this.formFieldsExtractor(fields, []);
    const formlyFieldConfig: any = formFieldWithOptions.find(
      field => field.key == fieldKey
    );
    return formlyFieldConfig;
  }

  formFieldsExtractor(
    fields: FormlyFieldConfig[],
    resultFields: FormlyFieldConfig[]
  ): FormlyFieldConfig[] {
    const fieldsWithOptions: string[] = [
      EIremboFormlyFieldTypes.customdropdown,
      EIremboFormlyFieldTypes.radio,
      EIremboFormlyFieldTypes.checkbox,
      EIremboFormlyFieldTypes.multicheckbox,
      EIremboFormlyFieldTypes.customdropdownpaginated,
    ];
    fields.forEach(field => {
      if (field.fieldGroup && field.fieldGroup.length > 0) {
        this.formFieldsExtractor(field.fieldGroup, resultFields);
      } else {
        const fieldType = field.type;
        if (fieldsWithOptions.includes(<string>fieldType)) {
          resultFields.push(field);
        }
      }
    });
    return resultFields;
  }

  private formFieldLabelAndDetailsExtractor(
    fields: FormlyFieldConfig[],
    formFieldLabelAndDetails: IBasicFieldLabelAndDetails<TFormlyFieldKey>[],
    dataEntryFieldsOnly: boolean,
    removeConfiguredKey: boolean,
    excludedFieldTypes: EIremboFormlyFieldTypes[] = [],
    excludeNonConfigurableFields = true
  ): IBasicFieldLabelAndDetails<TFormlyFieldKey>[] {
    fields.forEach(field => {
      formFieldLabelAndDetails = this.dataEntryFieldsAllowedFieldsCheck(
        field,
        formFieldLabelAndDetails,
        dataEntryFieldsOnly,
        removeConfiguredKey,
        excludedFieldTypes,
        excludeNonConfigurableFields
      );

      if (field.fieldGroup) {
        this.formFieldLabelAndDetailsExtractor(
          field.fieldGroup,
          formFieldLabelAndDetails,
          dataEntryFieldsOnly,
          removeConfiguredKey,
          excludedFieldTypes,
          excludeNonConfigurableFields
        );
      }
    });

    return formFieldLabelAndDetails;
  }

  private dataEntryFieldsAllowedFieldsCheck(
    field: FormlyFieldConfig,
    formFieldLabelAndDetails: IBasicFieldLabelAndDetails<TFormlyFieldKey>[],
    dataEntryFieldsOnly: boolean,
    removeConfiguredKey: boolean,
    excludedFieldTypes: EIremboFormlyFieldTypes[],
    excludeNonConfigurableFields = true
  ): IBasicFieldLabelAndDetails<TFormlyFieldKey>[] {
    if (
      dataEntryFieldsOnly &&
      NonDataElementFormlyFieldTypes.indexOf(
        field.type as EIremboFormlyFieldTypes
      ) >= 0
    ) {
      return formFieldLabelAndDetails;
    }

    if (excludeNonConfigurableFields && field.props?.['nonConfigurable']) {
      return formFieldLabelAndDetails;
    }

    if (
      removeConfiguredKey &&
      field.key === this.configureFieldAction$.getValue()?.key
    ) {
      return formFieldLabelAndDetails;
    }
    if (
      field.type &&
      excludedFieldTypes.includes(<EIremboFormlyFieldTypes>field.type)
    ) {
      return formFieldLabelAndDetails;
    }

    if (field.key && field?.props?.label) {
      const stringPathArray: string[] = [];
      this.getFieldRootRef(field, stringPathArray);

      const rootModelPath = `${Array(stringPathArray.length + 1)
        .fill('parent?')
        .join('.')}`;
      const stringPathArrayReversed: string[] = [...stringPathArray].reverse();
      const fieldRefPath: string = stringPathArrayReversed.join('?.');

      formFieldLabelAndDetails.push({
        label: field?.props.label,
        value: field?.key,
        fieldType: field.type ? <string>field.type : undefined,
        propsType: field?.props?.type,
        rootModelPath: `field?.${rootModelPath}.model`,
        fieldRefPath: fieldRefPath ? `${fieldRefPath}` : undefined,
        fieldSubType: field.props?.['subType'] ?? undefined,
      });
    }
    return formFieldLabelAndDetails;
  }

  private getFieldRootRef(
    field: FormlyFieldConfig,
    pathArray: string[]
  ): string[] {
    if (field?.key) {
      pathArray.push(<string>field.key);
    }
    if (field?.parent?.key) {
      this.getFieldRootRef(field.parent, pathArray);
    }
    return pathArray;
  }

  private initFormlyFieldsSubject() {
    this.formlyFieldsSubject$ = new BehaviorSubject<IFormBuilderFieldsData>({
      fields: [],
    });
  }

  private initConfigureFieldActionSubject() {
    this.configureFieldAction$ = new BehaviorSubject<FormlyFieldConfig | null>(
      null
    );
  }

  private initFormFieldBlockIdsSetSubject() {
    this.formFieldBlockIdsSet$ = new BehaviorSubject<Set<string>>(new Set());
  }

  private initreviewModeEnabledSubject() {
    this.reviewModeEnabled$ = new BehaviorSubject<boolean>(false);
  }

  arrayToJsonObject = (
    arr: [string, { fields: FormlyFieldConfigForConverter[] }][]
  ): FormlyFields => {
    return arr.reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as FormlyFields);
  };
}
