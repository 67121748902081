export const environment = {
  production: false,
  authServiceUrl: 'https://hub-keycloak.uat.irembolabs.com/',
  authRealm: 'irembo',
  authClientId: 'service-management-portal',
  authSuccessRedirectUrl: 'https://service-portal.uat.irembolabs.com',
  apiGatewayBaseUrl: 'https://service-gateway.uat.irembolabs.com',
  enablekeycloakMockService: false,
  citizanApiGatewayBaseUrl: 'https://citizen-gateway.uat.irembolabs.com',
  DEFAULT_LOCALE: 'en-US',
  translationSourceUrl: 'https://service-gateway.uat.irembolabs.com',
};
