<form [formGroup]="fixedPricingForm">
  <div class="form-group mb-4">
    <label
      class="form-label pt-2 col-form-label col-form-label-sm"
      for="{{ label }}"
      >{{ label
      }}<i
        class="fa-solid fa-circle-info text-muted pl-3"
        placement="top"
        ngbTooltip="{{ label }}"></i
    ></label>
  </div>
  <div class="option-wrapper">
    <div class="form-group col-md-6" *ngIf="paymentMerchantCase">
      <input
        type="{{ isPricingNumberType ? 'number' : 'text' }}"
        class="form-control mb-2"
        placeholder="Input {{ label | lowercase }}"
        formControlName="baseValue"
        ngDefaultControl />
    </div>
    <div class="form-group col-md-6" *ngIf="!paymentMerchantCase">
      <ng-select
        formControlName="baseValue"
        [items]="servicePaymentMerchantsSearch"
        [virtualScroll]="true"
        [loading]="isLoadingServicePaymentMerchants"
        bindLabel="merchant"
        bindValue="merchant"
        placeholder="Select Merchant"
        #selectMerchant
        ngDefaultControl>
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm">
          <strong>{{ item.merchant }}</strong>
        </ng-template>
      </ng-select>
    </div>
    <div class="form-group col-md-2" *ngIf="addCurrencyProperty">
      <ng-select
        formControlName="baseCurrency"
        [items]="serviceCurrenciesBuffer"
        [virtualScroll]="true"
        [loading]="isLoadingServiceCurrencies"
        bindLabel="code"
        bindValue="code"
        placeholder="Choose the service currency"
        (scrollToEnd)="onFetchMoreServiceCurrencies()"
        (search)="onSearchServiceCurrency($event)"
        class="currency-selector"
        #selectCurrency>
        <ng-template
          ng-option-tmp
          let-item="item"
          let-index="index"
          let-search="searchTerm">
          <strong>{{ item.code }}</strong>
        </ng-template>
      </ng-select>
    </div>
  </div>
</form>
