<ng-container>
  <div
    class="irembogov-form-field-widgets-list my-3"
    cdkDropList
    [id]="EDragDropItemsIds.MainBuilderWidgetsListItems"
    [cdkDropListConnectedTo]="connectedBlockId">
    <div
      cdkDrag
      class="form-field-widgets rounded shadow px-3 py-2 mb-2 border border-light-gray text-muted d-flex align-items-center"
      *ngFor="let widget of formFieldWidgets"
      [cdkDragData]="widget.key">
      <i class="fa-solid fa-object-ungroup me-2"></i>
      <span>
        {{ widget.label }}
      </span>
      <i class="fa-solid fa-grip-vertical ms-auto"></i>
    </div>
  </div>
</ng-container>
