import { IServiceCurrency } from '../../../../core/models/service-currency.model';
import {
  Condition,
  PricingScenario,
} from '../configure-service-change-request-price-handler/configure-service-change-request-price-handler.component';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { BehaviorSubject, finalize, of, Subscription, switchMap } from 'rxjs';
import {
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  IFilterAndPaginationFieldsBase,
  ToastService,
  alphaNanoIdUtil,
  updateApiFilter,
} from '@irembo-andela/irembogov3-common';
import { IFieldFormGroup } from '../../../../core/models/service-change-requests-form-fields.model';
interface IFilterFields extends IFilterAndPaginationFieldsBase {
  name: string | null;
  keywords: string | null;
}

@Component({
  selector: 'irembogov-configure-service-dynamic-price-handler',
  templateUrl: './configure-service-dynamic-price-handler.component.html',
  styleUrls: ['./configure-service-dynamic-price-handler.component.scss'],
})
export class ConfigureServiceDynamicPriceHandlerComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() label = '';
  @Input() isPricingNumberType = false;
  @Input() formFields!: any;
  @Input() scenarios!: PricingScenario[] | null;
  @Input() addCurrencyProperty = false;
  @Output() addScenarios: EventEmitter<string> = new EventEmitter<string>();
  scenariosForm!: FormGroup;
  isLoadingServiceCurrencies = false;
  serviceCurrenciesBuffer: IServiceCurrency[] = [];
  serviceCurrenciesBufferCollection = 0;
  priceValue: boolean[] = [false];
  formFieldsValues: IFieldFormGroup[] = [];

  _filterObject: BehaviorSubject<IFilterFields> =
    new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 8,
      name: '',
      keywords: '',
    });
  serviceCurrenciesSubscription!: Subscription;
  priceOptions = [
    { label: 'Price Value', value: 'value' },
    { label: 'Price From Attribute', value: 'valueAttribute' },
    { label: 'Price From Formula', value: 'valueFormula' },
  ];
  formulaObject: Record<string, Record<string, unknown>[]> = {};
  operators = {
    0: '+',
    1: '-',
    2: '/',
    3: '*',
    4: '(',
    5: ')',
  };
  selectedPriceOption: Record<string, string> = {};
  selectedTagIndex: Record<string, string> = {};
  numberOnlyField: IFieldFormGroup[] = [];
  currentPriceOption = { key: '', index: 0 };
  lastPriceOption = '';

  constructor(
    private fb: FormBuilder,
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this._filterObject
      .asObservable()
      .pipe(
        switchMap((filter: IFilterFields) => {
          const queryParams: string =
            FilterFieldsToQueryParamsBuilder<IFilterFields>(filter);
          this.getServiceCurrencies(queryParams, filter.page ?? 0);
          return of();
        })
      )
      .subscribe();

    this.createForm(this.scenarios ?? []);
    this.scenariosForm.valueChanges.subscribe(values => {
      if (
        this.scenariosForm.valid ||
        this.currentPriceOption.key == 'valueFormula'
      ) {
        this.addScenarios.emit(values);
      }
    });
    this.intialiseSelected();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scenarios']?.currentValue) {
      const scenarios = changes['scenarios']?.currentValue;
      if (Array.isArray(scenarios) && scenarios.length == 0) {
        this.priceOptionClicked('value', 0);
      }
      this.updatelastPriceOption();
    }

    if (changes['formFields']?.currentValue) {
      this.formFields = changes['formFields'].currentValue;
      this.formFieldsValues = [];
      this.numberOnlyField = [];
      this.formFields?.fields?.forEach((field: any) => {
        field?.fieldGroup.forEach((fieldGroup: any) => {
          fieldGroup.fieldGroup.forEach((fg: any) => {
            fg.fieldGroup.forEach((element: IFieldFormGroup) => {
              if (element?.type === 'number') {
                this.numberOnlyField.push(element);
              }
              if (
                element?.type === 'customcascadingdropdowns' &&
                element.props?.configs
              ) {
                element.props.configs.forEach((config: any) => {
                  config['type'] = 'cascading_customdropdown';
                  this.formFieldsValues.push(config);
                });
                return;
              }
              return this.formFieldsValues.push(element);
            });
          });
        });
      });
    }
  }

  onFieldChange(event: string) {
    const formField = this.formFieldsValues?.find(
      field => field?.key === event
    );
    if (!formField) return;
  }

  createForm(scenarios: PricingScenario[]) {
    this.scenariosForm = this.fb.group({
      scenarios: this.populateScenariosFormArray(scenarios),
    });
  }

  getScenarios(form: any) {
    return form.controls.scenarios.controls;
  }

  getConditions(form: any) {
    return form.controls.conditions.controls;
  }

  trackConditionsByIndex(index: number) {
    return index;
  }

  fetchServiceCurrencies() {
    const page = this._filterObject.getValue().page ?? 0;
    const pageSize = this._filterObject.getValue().size ?? 8;

    if ((page + 1) * pageSize < this.serviceCurrenciesBufferCollection) {
      updateApiFilter<IFilterFields>(this._filterObject, { page: page + 1 });
    }
  }

  onSearchServiceCurrency(query: { term: string }) {
    updateApiFilter<IFilterFields>(this._filterObject, {
      page: 0,
      name: query?.term,
    });
  }

  getServiceCurrencies(queryString: string, page: number) {
    this.isLoadingServiceCurrencies = true;
    this.serviceCurrenciesSubscription = this.serviceChangeRequestService
      .getServiceCurrencies(queryString)
      .pipe(finalize(() => (this.isLoadingServiceCurrencies = false)))
      .subscribe({
        next: response => {
          this.handleServiceCurrenciesResponse(response, page);
        },
        error: err => {
          this.handleServiceCurrenciesError(err);
        },
      });
  }

  private populateScenariosFormArray(
    scenarios: PricingScenario[]
  ): FormArray<any> {
    const scenariosFormArray: FormArray = this.fb.array([]);
    if (scenarios && scenarios.length > 0) {
      scenarios.forEach((scenario: PricingScenario, index) => {
        const itemsCtrls = this.createConditionFormArray(scenario.conditions);
        if (scenario?.valueFormula) {
          this.formulaObject[`scenario-${index}`] = JSON.parse(
            scenario?.valueFormula
          );
        }
        scenariosFormArray.push(
          this.fb.group({
            selectedField: [null],
            constant: [0],
            valueFormula: [scenario?.valueFormula],
            valueAttribute: [scenario?.valueAttribute],
            value: [scenario?.value],
            conditions: itemsCtrls,
            ...(this.addCurrencyProperty && {
              currency: [scenario?.currency, this.defaultValidator()],
            }),
          })
        );
      });
    } else {
      scenariosFormArray.push(
        this.fb.group({
          selectedField: [''],
          constant: [0],
          valueFormula: [''],
          valueAttribute: [null],
          value: [null],
          ...(this.addCurrencyProperty && {
            currency: ['', this.defaultValidator()],
          }),
          conditions: this.fb.array([
            this.fb.group({
              attribute: [null, this.defaultValidator()],
              comparator: [null, this.defaultValidator()],
              value: ['', this.defaultValidator()],
              attributeType: ['', this.defaultValidator()],
            }),
          ]),
        })
      );
    }
    return scenariosFormArray;
  }

  private createConditionFormArray(items: Condition[]) {
    const formArrayCtrls: any = this.fb.array([]);
    items.forEach(item => {
      formArrayCtrls.push(this.createConditionFormGroup(item));
    });

    return formArrayCtrls;
  }

  private createConditionFormGroup(item?: Condition): FormGroup {
    const attributeType = item?.attributeType
      ? item.attributeType.toUpperCase()
      : '';
    return this.fb.group({
      attribute: [item?.attribute, this.defaultValidator()],
      comparator: [item?.comparator, this.defaultValidator()],
      value: [item?.value, this.defaultValidator()],
      attributeType: [attributeType, this.defaultValidator()],
    });
  }

  public defaultValidator() {
    return [Validators.minLength(1), Validators.required];
  }

  public addConditionFormGroup() {
    const conditions = this.scenariosForm.get(
      'scenarios.conditions'
    ) as FormArray;
    conditions.push(this.createConditionFormGroup());
  }

  public modifyCondition(event: { value: any; index: number; form: any }) {
    const conditions = event.form.controls.conditions as FormArray;
    conditions.at(event.index).setValue(event.value);
  }

  public onAddScenario() {
    if (this.currentPriceOption.key === 'valueFormula') {
      try {
        const formula = this.getField(
          this.currentPriceOption.index,
          'valueFormula'
        )?.value;
        this.verifyFormula(JSON.parse(formula));
      } catch (e: any) {
        this.toastService.show({
          body: 'Invalid formula provided',
          type: 'error',
        });
        console.error(e.message);
        return;
      }

      if (!this.getField(this.currentPriceOption.index, 'currency')?.valid) {
        this.toastService.show({
          body: 'Currency is required',
          type: 'error',
        });
        this.getField(
          this.currentPriceOption.index,
          'currency'
        )?.markAsTouched();
        return;
      }
    }

    const scenarios = this.scenariosForm.get('scenarios') as FormArray;
    const scenarioForm = this.fb.group({
      selectedField: [null],
      constant: [0],
      valueFormula: [''],
      valueAttribute: [''],
      value: [''],
      ...(this.addCurrencyProperty && {
        currency: [null, this.defaultValidator()],
      }),
      conditions: this.fb.array([
        this.fb.group({
          attribute: ['', this.defaultValidator()],
          comparator: ['', this.defaultValidator()],
          value: ['', this.defaultValidator()],
          attributeType: ['', this.defaultValidator()],
        }),
      ]),
    });

    scenarios.push(scenarioForm);
    this.priceOptionClicked('value', scenarios.length - 1);
  }

  public onRemoveScenario(i: number) {
    const scenarios = this.scenariosForm.get('scenarios') as FormArray;
    scenarios.removeAt(i);
    const index = i - 1;
    const key = this.selectedPriceOption['scenario-' + index];
    this.currentPriceOption = { key, index };
  }

  public removeCondition(event: any) {
    const conditions = event.form.controls.conditions as FormArray;
    conditions.removeAt(event.index);
  }

  public onAddCondition(form: any) {
    const control = form.controls.conditions as FormArray;
    const conditionForm = this.fb.group({
      attribute: new FormControl(null, Validators.required),
      comparator: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
      attributeType: new FormControl(null, Validators.required),
    });

    control.push(conditionForm);
    this.priceValue.push(false);
  }

  handleServiceCurrenciesResponse(response: any, page: number) {
    this.serviceCurrenciesBuffer =
      page > 0
        ? this.serviceCurrenciesBuffer?.concat(response?.data?.content)
        : response?.data?.content;
    this.serviceCurrenciesBufferCollection =
      response.data.totalPages * response.data.size;
  }

  handleServiceCurrenciesError(err: any) {
    const errorMessage = ExtractHttpErrorResponseMessage(
      err,
      'Error getting services'
    );
    this.toastService.show({ body: errorMessage, type: 'error' });
  }

  getPriceOptionStatus(scenario: FormGroup, key: string) {
    const obj: Record<string, unknown> = scenario.value;
    return obj[key] !== null && obj[key] !== undefined && obj[key] !== '';
  }

  priceOptionClicked(key: string, index: number) {
    this.currentPriceOption = { key, index };
    this.selectedPriceOption['scenario-' + index] = key;
  }

  updatelastPriceOption(): void {
    const keys = Object.keys(this.selectedPriceOption);
    const lastKey = keys[keys.length - 1];
    this.lastPriceOption = this.selectedPriceOption[lastKey];
  }

  private intialiseSelected() {
    const scenarios: Array<FormGroup> = this.getScenarios(this.scenariosForm);
    scenarios.forEach((scenario, index) => {
      this.priceOptions.forEach(x => {
        if (this.getPriceOptionStatus(scenario, x.value)) {
          this.priceOptionClicked(x.value, index);
        }
      });
    });
  }

  handleSelectedFieldChange(event: any, scenarioIndex: number) {
    if (event && event !== 'constant') {
      this.addField(
        this.getField(scenarioIndex, 'selectedField')?.value?.key,
        'field',
        this.getField(scenarioIndex, 'selectedField')?.value?.props?.label,
        1,
        this.generateRandomKey(),
        scenarioIndex
      );
    }
    this.getField(scenarioIndex, 'constant')?.setValue(null);
  }

  addField(
    fieldKey: string | null,
    type: string,
    displayValue: string | number,
    value: number | null,
    key: string,
    scenarioIndex: number
  ) {
    if (
      type == 'field' &&
      !this.getField(scenarioIndex, 'selectedField')?.value
    ) {
      this.toastService.show({
        body: 'Please provide value for field',
        type: 'error',
      });
      return;
    }

    if (
      type == 'constant' &&
      !this.getField(scenarioIndex, 'constant')?.value
    ) {
      this.toastService.show({
        body: 'Please provide value for constant',
        type: 'error',
      });
      this.getField(scenarioIndex, 'constant')?.setErrors(Validators.required);
      this.getField(scenarioIndex, 'constant')?.markAsTouched();
      return;
    }

    if (
      Object.prototype.hasOwnProperty.call(
        this.formulaObject,
        'scenario-' + scenarioIndex
      )
    ) {
      this.formulaObject['scenario-' + scenarioIndex] = [
        ...this.formulaObject['scenario-' + scenarioIndex],
        {
          fieldKey,
          type,
          displayValue,
          value,
          key,
        },
      ];
    } else {
      this.formulaObject['scenario-' + scenarioIndex] = [
        {
          fieldKey,
          type,
          displayValue,
          value,
          key,
        },
      ];
    }

    this.getField(scenarioIndex, 'selectedField')?.setValue(null);
    this.getField(scenarioIndex, 'constant')?.setValue(null);
    this.getField(scenarioIndex, 'valueAttribute')?.setValue(null);
    this.getField(scenarioIndex, 'value')?.setValue(null);
    this.updateFormula(scenarioIndex);
  }

  generateRandomKey(): string {
    return alphaNanoIdUtil(5);
  }

  updatedSelectedIndex(event: any) {
    this.selectedTagIndex = event;
  }

  onItemRemoved(event: any, scenarioIndex: number) {
    this.formulaObject[`scenario-${scenarioIndex}`] = this.formulaObject[
      `scenario-${scenarioIndex}`
    ].filter(item => item['key'] != event['key']);
    this.updateFormula(scenarioIndex);
  }

  updateFormula(index: number) {
    this.getField(index, 'valueFormula')?.setValue(
      JSON.stringify(this.formulaObject[`scenario-${index}`])
    );
  }

  getField(index: number, fieldName: string) {
    const items = this.scenariosForm.get('scenarios') as FormArray;
    return items.at(index).get(fieldName);
  }

  verifyFormula(expressionData: Record<string, unknown>[]) {
    const expressionStr = expressionData
      .map(e => {
        if (e['type'] == 'field') {
          return e['fieldKey'];
        }
        return e['displayValue'];
      })
      .join(' ');
    const scope: Record<string, unknown> = {};
    expressionData.forEach((item: Record<string, unknown>) => {
      if (item['type'] == 'field') {
        const key = item['fieldKey'] as string;
        scope[key] = item['value'];
      }
    });

    if (Object.keys(scope).length == 0) {
      throw new Error('Expression must have at least one field.');
    }

    const operands = expressionStr.split(/[-+*/^()]/).filter(Boolean);
    if (operands.length < 2) {
      throw new Error('Expression must have at least two operands.');
    }

    const expressionWithValue = this.addExpressionValues(expressionStr, scope);
    return this.evaluate(expressionWithValue);
  }

  evaluate(expression: string): number {
    // eslint-disable-next-line
    const reg = /(?:[a-z$_][a-z0-9$_]*)|(?:[;={}\[\]"'!&<>^\\?:])/gi;
    const sanitizedExp = expression.replace(reg, (fn: string) => {
      if (Object.prototype.hasOwnProperty.call(Math, fn)) {
        return 'Math.' + fn;
      } else if (Object.prototype.hasOwnProperty.call(Math, fn.toUpperCase())) {
        return 'Math.' + fn.toUpperCase();
      } else {
        throw new Error(`Unexpected token: ${fn}`);
      }
    });

    try {
      return eval(sanitizedExp);
    } catch (e) {
      throw new Error(`Invalid arithmetic expression: ${expression}`);
    }
  }

  addExpressionValues(
    expression: string,
    scope: Record<string, unknown>
  ): string {
    const pattern = /\b\w+\b/g;
    return expression.replace(pattern, match => {
      return Object.prototype.hasOwnProperty.call(scope, match)
        ? (scope[match] as string)
        : match;
    });
  }

  ngOnDestroy() {
    if (this.serviceCurrenciesSubscription) {
      this.serviceCurrenciesSubscription.unsubscribe();
    }
  }
}
