import {
  Component,
  ViewEncapsulation,
  Injector,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  alphaNanoIdUtil,
  EIremboFormlyFieldTypes,
  IIrembogovBasicLabelKeyPair,
  LOCATION_WIDGET_LEVELS,
} from '@irembo-andela/irembogov3-common';
import { FormBuilderManipulatorClass } from '../../../../core/utils/form-builder-manipulator-class.utils';
import { EDragDropItemsIds } from '../../../../core/models/form-builder-drag-item-ids.enum';
import * as LocationWidgetUtils from '../../../../core/utils/form-fields-formly-configurations/location-widget-components-generator.util';

@Component({
  selector: 'irembogov-form-field-widgets-list',
  templateUrl: './form-field-widgets-list.component.html',
  styleUrls: ['./form-field-widgets-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldWidgetsListComponent implements OnInit, OnDestroy {
  EDragDropItemsIds = EDragDropItemsIds;
  randomKey: string = alphaNanoIdUtil(5);

  formFieldWidgets: IIrembogovBasicLabelKeyPair<FormlyFieldConfig>[] = [
    {
      label: 'Location',
      key: LocationWidgetUtils.buildCascadeField(
        `Country${this.randomKey}`,
        <string>Object.keys(LOCATION_WIDGET_LEVELS).shift(),
        <string>Object.keys(LOCATION_WIDGET_LEVELS).pop(),
        'Location Widget',
        false,
        false
      ),
    },
  ];

  subscriptionControl$: Subject<void> = new Subject();

  connectedBlockId: string[] = [];

  formBuilderManipulatorClass!: FormBuilderManipulatorClass;

  EIremboFormlyFieldTypes = EIremboFormlyFieldTypes;

  constructor(
    private formBuilderService: FormBuilderService,
    private changeDetectorRef: ChangeDetectorRef,
    private injector: Injector
  ) {
    this.formBuilderManipulatorClass = new FormBuilderManipulatorClass(
      this.injector
    );
  }

  ngOnInit(): void {
    this.formBuilderService.formFieldBlockIdsSet$
      .pipe(takeUntil(this.subscriptionControl$))
      .subscribe((blockIdSet: Set<string>) => {
        this.connectedBlockId = Array.from(blockIdSet);
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscriptionControl$.next();
    this.subscriptionControl$.complete();
  }
}
