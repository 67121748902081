import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IServiceChangeRequest } from './../../../../core/models/service-change-request.model';
import {
  EIremboFormlyFieldTypes,
  ExtractHttpErrorResponseCodeAndMessage,
  IHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
  IUiAlertContent,
  TUiAlertTypes,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { ServiceChangeRequestService } from './../../../../core/services/service-change-request.service';
import {
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  IError,
  JsonEditorComponent,
  JsonEditorOptions,
} from '@maaxgr/ang-jsoneditor';
import { Subject, finalize } from 'rxjs';
import { FormBuilderService } from './../../../../core/services/form-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyFieldArrayToIremboFormlyFieldsConverter } from './../../../../core/utils/irembo-formly-field-allow-pops.utils';
import { IFieldError } from './../../../../core/models/service-change-request-config-steps-forms.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ExtractHttpErrorFieldErrorsUtil } from './../../../../core/utils/http-field-error-extractor.utils';

const FormDefaultData: Record<string, Record<string, FormlyFieldConfig[]>> = {
  FormKey: {
    fields: [],
  },
};

@Component({
  selector: 'irembogov-configuration-officer-form',
  templateUrl: './configuration-officer-form.component.html',
  styleUrls: ['./configuration-officer-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationOfficerFormComponent implements OnChanges, OnDestroy {
  @Input() changeRequest!: IServiceChangeRequest;
  @Input() changeRequestThirdPartyFormData!: Record<string, unknown>;
  @Output() officerFormSubmitted: EventEmitter<boolean> = new EventEmitter();

  isLoading = false;
  inReviiewMode = false;
  showConfigFormErrorMessage = false;
  @ViewChild('errorMessageElement') errorMessageElement!: ElementRef;
  @ViewChild(JsonEditorComponent, { static: false })
  editor!: JsonEditorComponent;

  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };
  formExistsForm: FormGroup = new FormGroup({
    formExists: new FormControl('', [Validators.required]),
  });
  jsonEditorValidationErrors: ValidationErrors[] = [];
  public editorOptions!: JsonEditorOptions;
  jsonEditorFormData: any = [];
  formlyFieldsSubjectControl$: Subject<void> = new Subject<void>();
  DefaultEmptyFormData: Record<string, Record<string, unknown>> =
    FormDefaultData;

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService,
    private formBuilderService: FormBuilderService
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code'];

    this.editorOptions.onValidate = (json: unknown) =>
      this.checkJsonFormDataIsNotEmpty(json as Record<string, unknown>);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequestThirdPartyFormData']?.currentValue) {
      this.changeRequestThirdPartyFormData =
        changes['changeRequestThirdPartyFormData'].currentValue;
      this.loadJsonEditorFormData(
        changes['changeRequestThirdPartyFormData'].currentValue
      );
      this.loadJsonFormDataForPreview(
        changes['changeRequestThirdPartyFormData'].currentValue
      );
    }
  }

  onFormExistenceChange(value: string): void {
    this.formExistsForm?.get('formExists')?.setValue(value);
    if (value === 'YES') {
      this.editor.setMode('code');
    }
  }

  loadJsonEditorFormData(
    formlyFields: Record<string, Record<string, FormlyFieldConfig[]>>
  ) {
    if (Object.keys(formlyFields).length === 0) {
      this.onFormExistenceChange('NO');
    } else {
      this.onFormExistenceChange('YES');
    }
    this.jsonEditorFormData = Object.entries(formlyFields);
  }

  loadJsonFormDataForPreview(
    jsonFormData: Record<string, Record<string, FormlyFieldConfig[]>>
  ): void {
    const fields: FormlyFieldConfig[] = [];
    if (Object.keys(jsonFormData).length > 0) {
      Object.entries(jsonFormData).forEach(entry => {
        const data: any = {};
        const [key, value] = entry;
        data['type'] = EIremboFormlyFieldTypes.sections;
        data['validators'] = null;
        data['fieldGroup'] = [
          {
            key: key,
            props: {
              label: key,
              disabled: true,
            },
            fieldGroup: value['fields'],
          },
        ];

        fields.push(data);
      });
    } else {
      Object.entries(FormDefaultData).forEach(entry => {
        const data: any = {};
        const [key, value] = entry;
        data['type'] = EIremboFormlyFieldTypes.sections;
        data['fieldGroup'] = [
          {
            key: key,
            props: {
              label: key,
              disabled: true,
            },
            fieldGroup: FormlyFieldArrayToIremboFormlyFieldsConverter(
              value['fields']
            ),
          },
        ];
        fields.push(data);
      });
    }

    this.formBuilderService.thirdPartyFormlyFieldsSubject$.next(fields);
  }

  async validateJsonEditor(): Promise<void> {
    const editorJson: any = this.editor.getEditor();
    try {
      this.jsonEditorValidationErrors = await editorJson.validate();
      if (this.jsonEditorValidationErrors.length > 0) return;
      const changedData = editorJson.get();
      if (Array.isArray(changedData)) {
        const jsonObject =
          this.formBuilderService.arrayToJsonObject(changedData);
        this.jsonEditorFormData = jsonObject;
        this.loadJsonFormDataForPreview(jsonObject);
      } else {
        this.loadJsonFormDataForPreview(changedData);
        this.jsonEditorFormData = changedData;
      }
    } catch (error) {
      this.jsonEditorFormData = [];
    }
  }

  private checkJsonFormDataIsNotEmpty(json: Record<string, unknown>): IError[] {
    const errors: IError[] = [];
    const propertiesArray: string[] = Object.keys(json);
    if (propertiesArray.includes('isTrusted')) {
      propertiesArray.splice(propertiesArray.indexOf('isTrusted'), 1);
    }

    if (propertiesArray.length < 1) {
      errors.push({
        path: [1],
        message: 'JSON Object can not be empty.',
      });
    }

    return errors;
  }

  async onThirdPartyFormSubmit() {
    if (this.formExistsForm.get('formExists')?.value === 'YES') {
      await this.validateJsonEditor();
      if (!this.changeRequest.id) return;

      this.updateFormDisplayedErrorMessageContent(false);
      const formDefination = {
        thirdPartyForm: this.editor.get() as unknown as Record<string, unknown>,
      };
      this.isLoading = true;
      this.serviceChangeRequestService
        .updateServiceChangeRequestThirdPartyFormDefinition(
          this.changeRequest.id,
          formDefination
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: (res: IHttpSingleDataResponse<Record<string, unknown>>) => {
            this.toastService.show({
              body: res.responseMessage,
              type: 'success',
            });
            this.officerFormSubmitted.emit(true);
          },
          error: (error: HttpErrorResponse) => {
            const errorMessageAndCode: IHttpErrorResponseCodeAndMessage =
              ExtractHttpErrorResponseCodeAndMessage(
                error,
                `Failed to update Change request third party form properties`
              );

            this.toastService.show({
              body: errorMessageAndCode.message,
              type: 'error',
            });

            const fieldErrors: IFieldError[] =
              ExtractHttpErrorFieldErrorsUtil(error);

            this.updateFormDisplayedErrorMessageContent(
              true,
              'danger',
              'Form Definition Not Updated',
              errorMessageAndCode.message,
              fieldErrors
            );

            this.officerFormSubmitted.emit(false);
          },
        });
    } else {
      this.toastService.show({
        body: 'Change request third party form successfully updated',
        type: 'success',
      });
      this.isLoading = false;
      this.officerFormSubmitted.emit(true);
    }
  }

  updateFormDisplayedErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title = '',
    message = '',
    fieldErrors: IFieldError[] | null = null
  ): void {
    let extraMessage = '';
    this.showConfigFormErrorMessage = show;

    if (fieldErrors) {
      extraMessage = fieldErrors
        .map((field: IFieldError) => `${field.field}: ${field?.errorCode}`)
        .join(`<br/>\n`);
    }

    this.formAlertContent = { type, title, message, extraMessage };

    if (show) {
      this.errorMessageElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  ngOnDestroy(): void {
    this.formlyFieldsSubjectControl$.next();
    this.formlyFieldsSubjectControl$.complete();
    this.formBuilderService.configureFieldAction$.next(null);
  }
}
