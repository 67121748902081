<div class="irembogov-reviewer-issues-list-widget w-100 bg-white border-0">
  <div class="body">
    <div class="issues-nav-items small rounded mb-2">
      <div
        class="navs-configuration d-flex flex-row justify-content-center align-items-center bg-white border-bottom pb-0 border-2">
        <button
          *ngFor="let status of statusFilterOptions; let last = last"
          [class]="{
            selected: defaultFetchByStatus === status.key,
            linkitem: true
          }"
          (click)="updateToGetIssuesByStatus(status.key)">
          <i
            class="fa-solid fa-clock me-2"
            *ngIf="
              status.key === EServiceChangeRequestReviewStatus.PENDING
            "></i>
          <i
            class="fa-solid fa-check-circle me-2"
            *ngIf="
              status.key === EServiceChangeRequestReviewStatus.RESOLVED
            "></i
          ><br />
          <span>{{ status.label }}</span>
        </button>
      </div>
    </div>
    <div class="issues-list">
      <ng-container>
        <irembogov-irembo-section-message
          type="info"
          *ngIf="!isLoadingFeedbackList && collectionSize < 1"
          class="w-80 mx-auto mt-5 mb-5 pb-3 d-block"
          title=""
          message="No {{ defaultFetchByStatus | lowercase }} feedback issues."
          [dismissible]="false">
        </irembogov-irembo-section-message>

        <ng-container class="m-0">
          <div
            class="issue-container rounded issue-item border border-bottom-secondary p-2 {{
              feedback.status === EServiceChangeRequestReviewStatus.PENDING
                ? 'bg-transparent'
                : 'bg-white'
            }}"
            *ngFor="let feedback of feedbackList; let index = index">
            <div class="body">
              <small
                *ngIf="feedback.isProcessing"
                class="spinner-border text-muted spinner-border-sm"
                role="status"></small>
              <h6 class="d-flex w-100 flex-column">
                <div
                  class="d-flex w-100 justify-content-end font-weight-700 align-items-center mb-2">
                  <strong class="me-auto overflow-hidden">{{
                    feedback.title
                  }}</strong>
                  <span class="ms-1 text-nowrap">{{
                    feedback.dateCreated | reviewIssueCreatedDatePipe
                  }}</span>
                </div>
                <irembogov-reviewer-issue-status-badge-widget
                  [requestStatus]="
                    feedback.status
                  "></irembogov-reviewer-issue-status-badge-widget>
              </h6>
              <div class="w-100 overflow-hidden description">
                <p>{{ feedback.description }}</p>
              </div>
            </div>
          </div>
          <div
            class="small pointer-cursor text-center text-muted mb-3"
            *ngIf="
              !isLoadingFeedbackList && feedbackList.length < collectionSize
            "
            (click)="loadMoreIsues()">
            Load more
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="isLoadingFeedbackList" class="w-100 text-center">
        <div
          class="spinner-border spinner-border-sm mx-auto text-primary"
          role="status"></div>
      </div>
    </div>
  </div>
</div>
