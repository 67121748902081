import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  Injector,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldConfigTypeAsStringPipe } from '../../../../core/pipes/field-config-type-as-string.pipe';
import {
  EIremboFormlyFieldSubTypes,
  EIremboFormlyFieldTypes,
  IIrembogovBasicLabelKeyPair,
  TIdType,
} from '@irembo-andela/irembogov3-common';
import {
  IFormbuilderFieldTypeConfiguration,
  TConfigurationFormModelTypes,
} from '../../../../core/models/form-builder-types-configurations.model';
import { FormGroup } from '@angular/forms';
import { FormBuilderManipulatorClass } from '../../../../core/utils/form-builder-manipulator-class.utils';
import { IFormFieldsValidationModel } from '../../../../core/models/form-fields-formly-validation.model';
import { FormBuilderFieldConfigurationParserAndSetterClass } from '../../../../core/utils/form-fields-formly-configurations/form-builder-configuration-parser-and-setter.class';
import { IFormFieldsDisplayModel } from '../../../../core/models/form-fields-formly-display.model';

enum EConfigurationTabTemplateKeys {
  Settings = 'Settings',
  Validation = 'Validation',
  Display = 'Display',
}

@Component({
  selector: 'irembogov-form-field-configurator-settings',
  templateUrl: './form-field-configurator-settings.component.html',
  styleUrls: ['./form-field-configurator-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldConfiguratorSettingsComponent
  implements OnInit, OnDestroy
{
  configurationTabs: IIrembogovBasicLabelKeyPair<EConfigurationTabTemplateKeys>[] =
    [];

  selectedConfigurationTab: EConfigurationTabTemplateKeys =
    EConfigurationTabTemplateKeys.Settings;

  EConfigurationTabTemplateKeys = EConfigurationTabTemplateKeys;

  EIremboFormlyFieldTypes = EIremboFormlyFieldTypes;

  configuredField!: FormlyFieldConfig | null;

  fieldConfigTypeAsStringPipe: FieldConfigTypeAsStringPipe =
    new FieldConfigTypeAsStringPipe();

  fieldType?: string;

  configureFieldActionSubControl$: Subject<void> = new Subject<void>();

  fieldConfigurationConfig!: IFormbuilderFieldTypeConfiguration;

  settingsForm: FormGroup = new FormGroup({});
  settingsModel: TConfigurationFormModelTypes = {};

  validationForm: FormGroup = new FormGroup({});
  validationModel: IFormFieldsValidationModel = {};

  formlyFormOptions: Record<string, unknown> = {
    formState: { hideBuilderWrapper: true },
  };

  displayForm: FormGroup = new FormGroup({});
  displayModel: IFormFieldsDisplayModel = {};
  formBuilderManipulator!: FormBuilderManipulatorClass;
  configurationParserAndSetterClass!: FormBuilderFieldConfigurationParserAndSetterClass;

  constructor(
    private formBuilderService: FormBuilderService,
    private injector: Injector,
    private cdRef: ChangeDetectorRef
  ) {
    this.formBuilderManipulator = new FormBuilderManipulatorClass(
      this.injector
    );
    this.configurationParserAndSetterClass =
      new FormBuilderFieldConfigurationParserAndSetterClass(this.injector);
  }

  ngOnInit(): void {
    this.formBuilderService.configureFieldAction$
      .pipe(takeUntil(this.configureFieldActionSubControl$))
      .subscribe((configuredField: FormlyFieldConfig | null) => {
        this.configuredField = configuredField;
        this.setConfigTabsForType();
      });

    this.configuredField =
      this.formBuilderService.configureFieldAction$.getValue();
    this.setConfigTabsForType();
  }

  setConfigTabsForType(): void {
    if (!this.configuredField) return;
    this.configurationTabs = [];
    this.displayModel = {};
    this.validationModel = {};
    this.displayModel = {};
    this.fieldType = this.fieldConfigTypeAsStringPipe.transform(
      this.configuredField
    );

    let idType: TIdType | undefined = this.configuredField.props?.['idType'];
    let subtype: EIremboFormlyFieldSubTypes | undefined;

    if (this.fieldType === EIremboFormlyFieldTypes.customidinput) {
      const url: string | undefined = this.configuredField?.props?.['url'];
      switch (url) {
        case (idType =
          'NIN' ||
          (url && url.toLowerCase().indexOf('identity/v1/nin-info') > -1)):
          subtype = EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_NIN;
          break;
        case (idType =
          'NIN' ||
          (url && url.toLowerCase().indexOf('identity/v1/nid-info') > -1)):
          subtype = EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_NID;
          break;
        case (idType =
          'CHILD_ID' ||
          (url && url.toLowerCase().indexOf('identity/v1/child-id-info') > -1)):
          subtype = EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_CHILD_ID;
      }
    }
    if (
      this.fieldType === EIremboFormlyFieldTypes.customdropdownpaginated &&
      this.configuredField.props?.['subType'] ===
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH_PAGINATED
    ) {
      subtype = EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH_PAGINATED;
    }

    if (
      this.fieldType === EIremboFormlyFieldTypes.customdropdown &&
      this.configuredField.props?.['subType'] ===
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH
    ) {
      subtype = EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH;
    }

    if (
      this.fieldType === EIremboFormlyFieldTypes.customdropdownpaginated &&
      this.configuredField.props?.['subType'] ===
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED
    ) {
      subtype = EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED;
    }

    if (
      this.fieldType === EIremboFormlyFieldTypes.customdropdown &&
      this.configuredField.props?.['subType'] ===
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET
    ) {
      subtype = EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET;
    }

    this.fieldConfigurationConfig =
      this.configurationParserAndSetterClass.getFormlyFieldConfigurationConfigByType(
        this.fieldType,
        subtype
      );

    if (this.fieldConfigurationConfig.settings) {
      this.configurationTabs.push({
        label: 'Settings',
        key: EConfigurationTabTemplateKeys.Settings,
        icon: 'fa-solid fa-gear',
      });
      this.settingsModel =
        this.configurationParserAndSetterClass.parseFieldIntoSettingsFormModelByType(
          this.configuredField,
          this.fieldType
        );
    }

    if (this.fieldConfigurationConfig.validations) {
      this.configurationTabs.push({
        label: 'Validation',
        key: EConfigurationTabTemplateKeys.Validation,
        icon: 'fa-regular fa-circle-check',
      });
      this.validationModel =
        this.configurationParserAndSetterClass.parseFieldIntoValidationFormModelByType(
          this.configuredField,
          this.fieldType
        );
    }

    if (this.fieldConfigurationConfig.display) {
      this.configurationTabs.push({
        label: 'Display',
        key: EConfigurationTabTemplateKeys.Display,
        icon: 'fa-solid fa-palette',
      });
      this.displayModel =
        this.configurationParserAndSetterClass.parseFieldIntoDisplayFormModel(
          this.configuredField,
          this.fieldType
        );
    }

    this.cdRef.detectChanges();
  }

  private getFormlyFieldConfigurationsByType(): void {
    if (!this.configuredField) return;
    let subtype: EIremboFormlyFieldSubTypes | undefined;

    if (this.fieldType === EIremboFormlyFieldTypes.customidinput) {
      const url: string | undefined = this.configuredField?.props?.['url'];
      switch (url) {
        case url && url.toLowerCase().indexOf('identity/v1/nin-info') > -1:
          subtype = EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_NIN;
          break;
        case url && url.toLowerCase().indexOf('identity/v1/nid-info') > -1:
          subtype = EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_NID;
          break;
        case url && url.toLowerCase().indexOf('identity/v1/child-id-info') > -1:
          subtype = EIremboFormlyFieldSubTypes.CUSTOM_ID_INPUT_CHILD_ID;
      }
    }
    if (
      this.fieldType === EIremboFormlyFieldTypes.customdropdown &&
      this.configuredField.props?.['subType'] ===
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH
    ) {
      subtype = EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH;
    }

    if (
      this.fieldType === EIremboFormlyFieldTypes.customdropdown &&
      this.configuredField.props?.['subType'] ===
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET
    ) {
      subtype = EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET;
    }

    this.fieldConfigurationConfig =
      this.configurationParserAndSetterClass.getFormlyFieldConfigurationConfigByType(
        this.fieldType,
        subtype
      );
  }

  saveFieldConfigurationSettings() {
    if (!(this.configuredField?.props && this.settingsForm.valid)) return;

    if (
      this.configuredField.type ===
      EIremboFormlyFieldTypes.customcascadingdropdowns
    ) {
      const updatedFields: FormlyFieldConfig[] =
        this.configurationParserAndSetterClass.generateWidgetFieldsFromSettingsFormModel(
          this.settingsModel,
          this.configuredField,
          this.fieldType
        );
      this.formBuilderManipulator.replaceConfiguredFieldItemWithFields(
        updatedFields
      );
      this.formBuilderService.configureFieldAction$.next(null);
      return;
    }

    this.configuredField =
      this.configurationParserAndSetterClass.updateFieldFromSettingsFormModel(
        this.settingsModel,
        this.configuredField,
        this.fieldType
      );
    this.formBuilderManipulator.updateConfiguredFieldItem(this.configuredField);
    this.formBuilderService.configureFieldAction$.next(null);
  }

  saveFieldConfigurationValidation() {
    if (!(this.configuredField?.props && this.validationForm.valid)) return;

    this.configuredField =
      this.configurationParserAndSetterClass.updateFieldFromValidationsFormModel(
        this.validationModel,
        this.configuredField,
        this.fieldType
      );

    this.formBuilderManipulator.updateConfiguredFieldItem(this.configuredField);
    this.formBuilderService.configureFieldAction$.next(null);
  }

  saveFieldConfigurationDisplay() {
    if (!(this.configuredField?.props && this.displayForm.valid)) return;

    this.configuredField =
      this.configurationParserAndSetterClass.updateFieldFromDisplayFormModel(
        this.displayModel,
        this.configuredField,
        this.fieldType
      );

    this.formBuilderManipulator.updateConfiguredFieldItem(this.configuredField);
    this.formBuilderService.configureFieldAction$.next(null);
  }

  ngOnDestroy(): void {
    this.configureFieldActionSubControl$.next();
    this.configureFieldActionSubControl$.complete();
  }
}
