<div
  class="irembogov-view-workflow-template-details-widget px-5 py-3"
  *ngIf="workflowTemplate">
  <div class="w-100 text-end mb-3" (click)="activeModal.dismiss('Cross click')">
    <span class="btn btn-primary close-button">
      <i class="fa-regular fa-circle-xmark me-2"></i>
      <small>Close</small>
    </span>
  </div>

  <div class="mb-5 workflow-image-section">
    <h5 class="text-dark">{{ workflowTemplate.workflowName }}</h5>

    <div class="workflow-image" *ngIf="workflowTemplate.workflowDiagramImage">
      <img
        class="w-100"
        src="{{ fileBaseURL }}/v1/file-manager?fileName={{
          workflowTemplate.workflowDiagramImage
        }}"
        [alt]="workflowTemplate.workflowName" />
    </div>
  </div>

  <div class="mb-5 workflow-description-section">
    <h6>Workflow Description</h6>
    <div class="w-100">
      <div [innerHTML]="workflowTemplate.workflowDescription"></div>
    </div>
  </div>

  <div
    class="mb-5 workflow-definition-section"
    *ngIf="workflowTemplate.workflowDefinition">
    <h6>Workflow Definition</h6>
    <div class="w-100 bg-white rounded text-muted p-3">
      <pre
        class="overflow-auto"
        [innerHTML]="
          workflowTemplate.workflowDefinition
            | prettyjson : { escapeHtmlBracket: true }
        "></pre>
    </div>
  </div>
</div>
