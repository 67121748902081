<div class="review-pricing w-100 d-flex p-3" *ngIf="pricingData">
  <div class="flex-grow-1 me-5 mw-70">
    <div class="card border-0 mb-3" *ngIf="serviceRequestPricing.pricing">
      <div class="card-head border-bottom px-3 py-2">
        <h6>Details</h6>
      </div>
      <div class="card-body">
        <div class="my-1">
          Payment Expiration Time
          <span class="badge pricing-badge">
            {{ serviceRequestPricing.paymentExpirationHours }}
            Hour<span *ngIf="serviceRequestPricing.paymentExpirationHours > 1"
              >s</span
            >
          </span>
        </div>
        <div class="my-1">
          Payment Merchant
          <span class="badge pricing-badge">{{
            serviceRequestPricing.paymentMerchant.baseValue
          }}</span>
        </div>
      </div>
    </div>

    <div class="card border-0 mb-3">
      <div class="card-head border-bottom px-3 py-2">
        <span class="h6 my-0 me-2">Payment Type</span>
        <span class="text-primary">{{
          serviceRequestPricing.paymentType ?? 'N/A'
        }}</span>
      </div>
    </div>

    <ng-container *ngIf="serviceRequestPricing.pricing">
      <div class="card border-0 mb-3">
        <div class="card-head border-bottom px-3 py-2">
          <span class="h6 my-0 me-2">Price</span>
          <span class="text-primary">{{
            serviceRequestPricing.pricing.priceType | serviceTypeEnumToLabel
          }}</span>
        </div>
        <div class="card-body">
          <irembogov-service-request-item-pricing-widget
            setPropertyLabelName="Price"
            [serviceRequestPricingBase]="
              serviceRequestPricing.pricing
            "></irembogov-service-request-item-pricing-widget>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="serviceRequestPricing.paymentAccount">
      <div class="card border-0 mb-3">
        <div class="card-head border-bottom px-3 py-2">
          <span class="h6 my-0 me-2">Payment Account Identifier</span>
          <span class="text-primary">{{
            serviceRequestPricing.paymentAccount.priceType
              | serviceTypeEnumToLabel
          }}</span>
        </div>
        <div class="card-body">
          <irembogov-service-request-item-pricing-widget
            setPropertyLabelName="Bank Account Identifier"
            [serviceRequestPricingBase]="
              serviceRequestPricing.paymentAccount
            "></irembogov-service-request-item-pricing-widget>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="serviceRequestPricing.paymentCode">
      <div class="card border-0 mb-3">
        <div class="card-head border-bottom px-3 py-2">
          <span class="h6 my-0 me-2">Service Payment Code Identifier</span>
          <span class="text-primary">{{
            serviceRequestPricing.paymentCode.priceType | serviceTypeEnumToLabel
          }}</span>
        </div>
        <div class="card-body">
          <irembogov-service-request-item-pricing-widget
            setPropertyLabelName="Bank Account Identifier"
            [serviceRequestPricingBase]="
              serviceRequestPricing.paymentCode
            "></irembogov-service-request-item-pricing-widget>
        </div>
      </div>
    </ng-container>
  </div>
  <irembogov-reviewer-issues-entry-form-widget
    class="justify-self-end w-30"
    [changeRequestId]="changeRequest.id"
    [reviewSection]="EServiceChangeRequestReviewSection.PRICING"
    (goToNext)="
      reviewPricingCompleted.emit($event)
    "></irembogov-reviewer-issues-entry-form-widget>
</div>
