/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  IBaseConfigurationFormModel,
  ICustomIdInputFieldSettingsModel,
  IGenericDataFetchFieldsConfigFormModel,
  IGenericLabelValueFieldsConfigFormModel,
  ILocationWidgetConfigurationFormModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  IBasicFieldLabelAndDetails,
  IBasicLabelValuePair,
} from '../../../models/basic-label-value-pair.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  EIremboFormlyFieldSubTypes,
  EIremboFormlyFieldTypes,
  LOCATION_WIDGET_LEVELS,
  LOCATION_COUNTRY_KEY,
  alphaNanoIdUtil,
} from '@irembo-andela/irembogov3-common';
import { FormBuilderService } from '../../../services/form-builder.service';
import { ICustomIdFieldPopulateFieldItem } from '../../../models/custom-id-input-field-populates-item.model';
import { filter, tap } from 'rxjs';
import * as LocationWidgetUtils from '../location-widget-components-generator.util';
import { AbstractControl } from '@angular/forms';
import { TFormlyFieldKey } from '../../../models/formly-key.type';
import {
  LOCATION_WIDGET_CITY_LABEL,
  LOCATION_WIDGET_COUNTRY_LABEL,
  LOCATION_WIDGET_LOCAL_LABEL,
} from '../../../config/location-widget-field-labels.config';

export const getFieldLabelSettingsConfigFormly = (
  formBuilderServiceInstance: FormBuilderService
): FormlyFieldConfig[] => {
  const availableLabels: (string | null)[] = formBuilderServiceInstance
    .getAllFormFieldLabelAndDetails(false, true, [], false)
    .map(
      (field: IBasicFieldLabelAndDetails<TFormlyFieldKey>) =>
        field.label?.toLowerCase() ?? null
    );
  return [
    {
      key: 'fieldLabel',
      type: EIremboFormlyFieldTypes.input,
      props: {
        label: 'Field Label',
        hideBuilderWrapper: true,
        required: true,
        placeholder: 'Field Label',
      },
      id: 'fieldLabel',
      validation: {
        messages: {
          required: 'Field Label is required',
        },
      },
      validators: {
        duplicateLabel: {
          expression: (c: AbstractControl) => {
            if (!c.value) return null;
            let noMatchedLabel = true;
            [
              c.value,
              `${c.value}: ${LOCATION_WIDGET_COUNTRY_LABEL}`.toLowerCase(),
              `${c.value}: ${LOCATION_WIDGET_LOCAL_LABEL}`.toLowerCase(),
              `${c.value}: ${LOCATION_WIDGET_CITY_LABEL}`.toLowerCase(),
            ].some((value: string) => {
              if (value && availableLabels.includes(value.toLowerCase())) {
                noMatchedLabel = false;
                return true;
              }
              return false;
            });
            return noMatchedLabel;
          },
          message: (error: any, field: FormlyFieldConfig) =>
            `"${field.formControl?.value}" is already used by another field.`,
        },
      },
    },
  ];
};

export const getPlaceholderSettingsConfigFormly = (): FormlyFieldConfig[] => {
  return [
    {
      key: 'placeholder',
      type: EIremboFormlyFieldTypes.input,
      props: {
        label: 'Placeholder',
        disabled: false,
        required: false,
        placeholder: 'Placeholder',
      },
      id: 'placeholder',
      validation: {
        messages: {
          required: 'Placeholder is required',
        },
      },
    },
  ];
};

export const getDefaultBaseSettingsConfigFormly = (
  formBuilderServiceInstance: FormBuilderService
): FormlyFieldConfig[] => {
  return [
    ...getFieldLabelSettingsConfigFormly(formBuilderServiceInstance),
    ...getPlaceholderSettingsConfigFormly(),
    {
      key: 'tooltip',
      type: EIremboFormlyFieldTypes.input,
      props: {
        label: 'Tooltip',
        disabled: false,
        required: false,
        placeholder: 'Tooltip',
      },
      id: 'tooltip',
    },
    {
      key: 'hint',
      type: 'input',
      props: {
        label: 'Hint',
        disabled: false,
        required: false,
        placeholder: 'Hint',
      },
      id: 'hint',
    },
    {
      key: 'hintPosition',
      type: EIremboFormlyFieldTypes.customdropdown,
      props: {
        label: 'Hint Position',
        disabled: false,
        required: false,
        placeholder: 'Hint Position',
        multiple: false,
        bindValue: 'value',
        bindLabel: 'label',
        options: [
          {
            label: 'Top',
            value: 'TOP',
          },
          {
            label: 'Bottom',
            value: 'BOTTOM',
          },
        ],
      },
      id: 'hint',
    },
    {
      key: 'internalDescription',
      type: 'input',
      props: {
        label: 'Internal Description',
        disabled: false,
        required: false,
        placeholder: 'Internal Description',
      },
      id: 'internalDescription',
    },
    {
      key: 'readonly',
      type: EIremboFormlyFieldTypes.checkbox,
      props: {
        label: 'Read Only',
        hideHeader: true,
        placeholder: 'Read Only',
        required: false,
        formCheck: 'switch',
      },
      id: 'readonly',
    },
  ];
};

export const baseFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  return {
    fieldLabel: field.props?.label ?? '',
    placeholder: field.props?.placeholder ?? '',
    hint: field.props?.['hint'] ?? '',
    hintPosition: field.props?.['hintPosition'] ?? undefined,
    tooltip: field.props?.['tooltip'] || '',
    internalDescription: field.props?.['internalDescription'] ?? '',
    readonly: field.props?.['readonly'] ?? false,
  };
};

export const baseFieldUpdateFromSettingsFormModel = (
  model: IBaseConfigurationFormModel,
  baseField: FormlyFieldConfig
): FormlyFieldConfig => {
  if (baseField.props) {
    baseField.props['label'] = model.fieldLabel ?? undefined;
    baseField.props['hint'] = model.hint;
    baseField.props['hintPosition'] = model.hintPosition;
    baseField.props['placeholder'] = model.placeholder ?? undefined;
    baseField.props['tooltip'] = model.tooltip;
    baseField.props['internalDescription'] = model.internalDescription;
    baseField.props['readonly'] = model.readonly;
  } else {
    baseField['props'] = {
      label: model.fieldLabel ?? undefined,
      hint: model.hint,
      hintPosition: model.hintPosition,
      placeholder: model.placeholder ?? undefined,
      tooltip: model.tooltip,
      internalDescription: model.internalDescription,
      readonly: model.readonly,
    };
  }

  baseField = generateNewFieldKeyFromSettingsLabel(baseField);

  return baseField;
};

export const generateGenericLabelValueSettingsContentConfig = (
  repeaterFieldLabel: string = 'Field Option',
  withMultiple: boolean = false
): FormlyFieldConfig => {
  const config: FormlyFieldConfig = {
    key: 'contentSection',
    type: EIremboFormlyFieldTypes['formly-group'],
    className: 'settings_content_section_wrapper',
    props: {
      label: 'Content',
      placeholder: 'Content',
    },
    id: 'contentSection',
    wrappers: ['field-wrapper'],
  };

  config.fieldGroup = [
    {
      key: 'optionLabelValuePairs',
      type: EIremboFormlyFieldTypes.customlabelvaluerepeater,
      className: 'custom_label_value_repeater_field',
      props: {
        hideHeader: true,
        addText: 'Add',
        label: repeaterFieldLabel,
      },
      fieldArray: {
        type: EIremboFormlyFieldTypes['formly-group'],
        fieldGroup: [
          {
            key: 'label',
            type: EIremboFormlyFieldTypes.input,
            props: {
              label: 'Label',
              placeholder: 'Label',
              required: true,
            },
          },
          {
            key: 'value',
            type: EIremboFormlyFieldTypes.input,
            props: {
              label: 'Value',
              placeholder: 'Value',
              required: true,
            },
          },
        ],
      },
    },
  ];

  if (withMultiple) {
    config.fieldGroup.unshift({
      key: 'multiple',
      type: EIremboFormlyFieldTypes.radio,
      className: 'content_multiple_selector_radio_field',
      props: {
        label: '',
        placeholder: '',
        hideHeader: true,
        required: false,
        options: [
          { label: 'Single Selector', value: false },
          { label: 'Multiple Selector', value: true },
        ],
      },
      id: 'multiple',
    });
  }

  return config;
};

export const genericLabelValueFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IGenericLabelValueFieldsConfigFormModel => {
  let optionLabelValuePairs: IBasicLabelValuePair<string>[] = [];

  if (field.props?.['options']) {
    optionLabelValuePairs = Array.from(
      field.props?.['options'] as IBasicLabelValuePair<string>[]
    ).map(item => {
      return { value: item.value, label: item.label };
    });
  }

  const model: IGenericLabelValueFieldsConfigFormModel = {
    ...baseFieldParseIntoSettingsFormModel(field),
    contentSection: {
      optionLabelValuePairs,
    },
    readonly: field.props?.['readonly'] ?? false,
    disabled: field.props?.['disabled'] ?? false,
  };

  return model;
};

export const genericLabelValueFieldUpdateFromSettingsFormModel = (
  model: IGenericLabelValueFieldsConfigFormModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  let options: IBasicLabelValuePair<string | null>[] = [];

  if (model?.contentSection?.optionLabelValuePairs) {
    options = model.contentSection.optionLabelValuePairs.map(
      (valueItem: IBasicLabelValuePair<string | null>) => {
        return { value: valueItem.value, label: valueItem.label };
      }
    );
  }

  if (field.props) {
    field.props['label'] = model.fieldLabel ?? undefined;
    field.props['placeholder'] = model.placeholder ?? undefined;
    field.props['hint'] = model.hint;
    field.props['tooltip'] = model.tooltip;
    field.props['internalDescription'] = model.internalDescription;
    field.props['options'] = options;
    field.props['readonly'] = model.readonly;
    field.props['disabled'] = model.disabled;
  } else {
    field['props'] = {
      label: model.fieldLabel ?? undefined,
      placeholder: model.placeholder ?? undefined,
      hint: model.hint,
      internalDescription: model.internalDescription,
      options,
      tooltip: model.tooltip,
    };
  }

  field = generateNewFieldKeyFromSettingsLabel(field);

  return field;
};

export const generateNewFieldKeyFromSettingsLabel = (
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  if (field?.props?.['label']) {
    const newKeyString: string = (
      field.props['label'].replace(/[^a-zA-Z0-9_]/g, '').trim() ||
      'unNamedField'
    )
      .split(' ')
      .map(x => `${x[0].toUpperCase()}${x.slice(1).toLowerCase()}`)
      .join('');
    const newKey: string = newKeyString + alphaNanoIdUtil(5);
    field.props['oldKey'] = field.key;
    field.key = newKey;
  }
  return field;
};

export const getDateFieldSettingsConfigFormly = (
  formBuilderServiceInstance: FormBuilderService
): FormlyFieldConfig[] => [
  ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
  {
    key: 'dateFormat',
    type: EIremboFormlyFieldTypes.customdropdown,
    props: {
      label: 'Date Format',
      placeholder: 'Select Date Format',
      multiple: false,
      bindValue: 'value',
      bindLabel: 'label',
      options: [
        {
          label: 'DD MM YYYY',
          value: 'DD MM YYYY',
        },
        {
          label: 'MM DD YYYY',
          value: 'MM DD YYYY',
        },
      ],
    },
  },
  {
    key: 'delimeter',
    type: EIremboFormlyFieldTypes.customdropdown,
    props: {
      label: 'Delimeter',
      placeholder: 'Select Date Delimeter',
      multiple: false,
      bindValue: 'value',
      bindLabel: 'label',
      options: [
        {
          label: '/',
          value: '/',
        },
        {
          label: '-',
          value: '-',
        },
      ],
    },
  },
];

export const getPopulatesPropsSettingsConfigFormly = (
  formBuilderServiceInstance: FormBuilderService,
  dataFieldProps: string[]
): FormlyFieldConfig[] => {
  return [
    {
      key: 'populates',
      type: EIremboFormlyFieldTypes.customrepeater,
      props: {
        label: 'Populate Fields',
        hideLabel: true,
        required: false,
      },
      fieldArray: {
        fieldGroup: [
          {
            key: 'valueKey',
            type: EIremboFormlyFieldTypes.customdropdown,
            props: {
              options: dataFieldProps.map((prop: string) => {
                return { label: prop, value: prop };
              }),
              placeholder: 'Enter key of ID data field',
              bindLabel: 'label',
              bindValue: 'value',
              hideHeader: true,
            },
          },
          {
            key: 'targetKey',
            type: EIremboFormlyFieldTypes.customdropdown,
            props: {
              placeholder: 'Select target Field to populate data',
              options:
                formBuilderServiceInstance.getConfiguredFieldSiblingsFieldLabelAndDetails(
                  true,
                  true
                ),
              multiple: false,
              bindLabel: 'label',
              bindValue: 'value',
              required: false,
              hideHeader: true,
            },
          },
        ],
      },
    },
  ];
};

export const populatesPropsUpdateFromSettingsFormModel = (
  model: ICustomIdInputFieldSettingsModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  if (field?.props?.['populates']) {
    delete field.props['populates'];
  }

  if (!model.populates || model.populates.length < 1) return field;

  if (field.props) {
    field.props['populates'] = model.populates;
  } else {
    field.props = {
      populates: model.populates,
    };
  }

  return field;
};

export const populatesPropsParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): ICustomIdInputFieldSettingsModel => {
  const populateFields: ICustomIdFieldPopulateFieldItem[] = [];
  if (
    field?.props?.['populates'] &&
    Array.isArray(field?.props?.['populates'])
  ) {
    field.props['populates'].forEach(
      (populateItem: ICustomIdFieldPopulateFieldItem) => {
        populateFields.push(populateItem);
      }
    );
  }

  return {
    ...baseFieldParseIntoSettingsFormModel(field),
    populates: populateFields,
  };
};

export const generateDataFetchDataSettingsConfig = (): FormlyFieldConfig => {
  const config: FormlyFieldConfig = {
    key: 'datasetSection',
    wrappers: ['panel-wrapper'],
    props: {
      label: 'Data',
      required: false,
    },
  };

  config.fieldGroup = [
    {
      key: 'code',
      type: EIremboFormlyFieldTypes.customdropdownpaginated,
      props: {
        useBaseUrl: true,
        label: 'Integration',
        placeholder: 'Integration',
        required: true,
        bindLabel: 'name',
        bindValue: 'code',
        options: [],
        dataset: {
          url: '/integration/v1/endpoints',
          params: {
            page: 0,
            size: 10,
            adapterCode: 'FFORWARD',
          },
          pagination: true,
          searching: true,
          dataField: 'data.content',
          searchField: 'name',
        },
      },
    },
    {
      key: 'url',
      type: EIremboFormlyFieldTypes.input,
      defaultValue: '/integration/v1/fetch/sync',
      props: {
        label: 'Url',
        hideHeader: true,
        placeholder: 'Url',
        required: true,
        disabled: true,
      },
      id: 'url',
      expressions: {
        hide: 'true',
      },
    },
    {
      key: 'dataField',
      type: EIremboFormlyFieldTypes.input,
      props: {
        label: 'Data Field',
        placeholder: 'Data Field',
        required: false,
        disabled: false,
      },
      id: 'dataField',
    },
    {
      key: 'bindLabel',
      type: EIremboFormlyFieldTypes.input,
      props: {
        label: 'Data Label',
        placeholder: 'Data Label',
        bindLabel: 'label',
        bindValue: 'value',
        required: true,
        options: [],
      },
      id: 'bindLabel',
    },
    {
      key: 'bindValue',
      type: EIremboFormlyFieldTypes.input,
      props: {
        label: 'Data Value',
        placeholder: 'Data Value',
        bindLabel: 'label',
        bindValue: 'value',
        required: true,
        options: [],
      },
      id: 'bindValue',
    },
  ];

  return config;
};

export const generateDatasetSettingsConfig = (): FormlyFieldConfig => {
  const config: FormlyFieldConfig = {
    key: 'datasetSection',
    wrappers: ['panel-wrapper'],
    props: {
      label: 'Data',
      required: false,
    },
  };

  config.fieldGroup = [
    {
      key: 'code',
      type: EIremboFormlyFieldTypes.customdropdownpaginated,
      props: {
        label: 'Datasets',
        placeholder: 'Select dataset',
        bindLabel: 'name',
        bindValue: 'id',
        virtualScroll: true,
        required: true,
        useBaseUrl: true,
        dataset: {
          url: `/admin/v1/datasets`,
          params: {
            page: 0,
            size: 10,
            sortDirection: 'DESC',
            sort: 'createdDate',
          },
          pagination: true,
          searching: true,
          dataField: 'data.content',
          searchField: 'name',
        },
      },
    },
    {
      key: 'bindLabel',
      type: EIremboFormlyFieldTypes.input,
      defaultValue: 'label',
      props: {
        label: 'Data Label',
        hideHeader: true,
        placeholder: 'Data Label',
        disabled: true,
      },
      id: 'bindLabel',
      expressions: {
        hide: 'true',
      },
    },
    {
      key: 'bindValue',
      type: EIremboFormlyFieldTypes.input,
      defaultValue: '',
      props: {
        label: 'Data Value',
        hideHeader: true,
        placeholder: 'Data Value',
        disabled: true,
      },
      id: 'bindValue',
      expressions: {
        hide: 'true',
      },
    },
  ];
  return config;
};

export const generateNonPaginatedDataSetSettingsConfig =
  (): FormlyFieldConfig => {
    const config: FormlyFieldConfig = {
      key: 'datasetSection',
      wrappers: ['panel-wrapper'],
      props: {
        label: 'Data',
        required: false,
      },
    };

    config.fieldGroup = [
      {
        key: 'code',
        type: EIremboFormlyFieldTypes.customdropdownpaginated,
        props: {
          label: 'Datasets',
          placeholder: 'Select dataset',
          bindLabel: 'name',
          bindValue: 'code',
          virtualScroll: true,
          required: true,
          useBaseUrl: true,
          dataset: {
            url: `/admin/v1/datasets`,
            params: {
              page: 0,
              size: 10,
              sortDirection: 'DESC',
              sort: 'createdDate',
            },
            pagination: true,
            searching: true,
            dataField: 'data.content',
            searchField: 'name',
          },
        },
      },
      {
        key: 'bindLabel',
        type: EIremboFormlyFieldTypes.input,
        defaultValue: 'label',
        props: {
          label: 'Data Label',
          hideHeader: true,
          placeholder: 'Data Label',
          disabled: true,
        },
        id: 'bindLabel',
        expressions: {
          hide: 'true',
        },
      },
      {
        key: 'bindValue',
        type: EIremboFormlyFieldTypes.input,
        defaultValue: '',
        props: {
          label: 'Data Value',
          hideHeader: true,
          placeholder: 'Data Value',
          disabled: true,
        },
        id: 'bindValue',
        expressions: {
          hide: 'true',
        },
      },
    ];
    return config;
  };

export const genericDataFieldFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IGenericDataFetchFieldsConfigFormModel => {
  const dataSetSection = {
    url: field.props?.['dataset']?.url,
    pagination: field.props?.['dataset']?.pagination,
    searching: field.props?.['dataset']?.searching,
    dataField: field.props?.['dataset']?.dataField,
    searchField:
      field.props?.['dataset']?.searchField ?? field.props?.['bindLabel'],
    bindValue: field.props?.['bindValue'],
    bindLabel: field.props?.['bindLabel'],
    params: field.props?.['dataset']?.params,
    code: field.props?.['code'],
  };
  const model = {
    fieldLabel: field.props?.label ?? '',
    placeholder: field.props?.placeholder ?? '',
    hint: field.props?.['hint'] ?? '',
    tooltip: field.props?.['tooltip'] ?? '',
    internalDescription: field.props?.['internalDescription'] ?? '',
    options: [],
    datasetSection: dataSetSection,
    bindLabel: field.props?.['bindLabel'] ?? 'label',
    bindValue: field.props?.['bindValue'] ?? '',
    readonly: field.props?.['readonly'] ?? false,
    disabled: field.props?.['disabled'] ?? false,
  };
  return model;
};

export const genericDataFieldUpdateFromSettingsFormModel = (
  model: IGenericDataFetchFieldsConfigFormModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  const params: Record<string, any> = {};
  const dataset: Record<string, any> = {};

  switch (field?.props?.['subType']) {
    case EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET:
      dataset[
        'url'
      ] = `/admin/v1/dataset-items/by-dataset-code/${model.datasetSection?.code}`;
      dataset['dataField'] = 'data';
      break;
    case EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED:
      params['datasetId'] = model.datasetSection?.code;
      dataset['dataField'] = 'data.content';
      dataset['url'] = '/admin/v1/dataset-items';
      dataset['pagination'] = true;
      dataset['searching'] = true;
      params['page'] = 0;
      params['size'] = 10;
      dataset['params'] = params;
      break;
    case EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH:
      dataset['httpMethod'] = 'POST';
      dataset['dataField'] = model.datasetSection?.dataField ?? '';
      dataset['url'] = model.datasetSection?.url;
      break;
    default:
      dataset['dataField'] = 'data.content';
      dataset['url'] = model.datasetSection?.url;
  }

  if (field.props) {
    field.props['label'] = model.fieldLabel ?? undefined;
    field.props['placeholder'] = model.placeholder ?? undefined;
    field.props['hint'] = model.hint;
    field.props['tooltip'] = model.tooltip;
    field.props['internalDescription'] = model.internalDescription;
    field.props['options'] = model.options;
    field.props['readonly'] = model.readonly;
    field.props['disabled'] = model.disabled;
    field.props['bindLabel'] = model.datasetSection?.bindLabel ?? 'label';
    field.props['bindValue'] = model.datasetSection?.bindValue ?? '';
    field.props['dataset'] = dataset;
    field.props['code'] = model.datasetSection?.code ?? undefined;
  } else {
    field['props'] = {
      label: model.fieldLabel ?? undefined,
      placeholder: model.placeholder ?? undefined,
      hint: model.hint,
      internalDescription: model.internalDescription,
      options: model.options,
      tooltip: model.tooltip,
      bindLabel: model.datasetSection.bindLabel ?? 'label',
      bindValue: model.datasetSection.bindValue ?? '',
      dataset: dataset,
      readonly: model.readonly,
      disabled: model.disabled,
      internal: model.internal,
      code: model.datasetSection.code ?? undefined,
    };
  }
  field = generateNewFieldKeyFromSettingsLabel(field);
  return field;
};

export const getLocationWidgetFieldSettingsConfig = (): FormlyFieldConfig[] => {
  return [
    {
      key: 'locationFieldContentConfig',
      id: 'locationFieldContentConfig',
      type: EIremboFormlyFieldTypes['formly-group'],
      fieldGroupClassName: 'location-widget-formly-group',
      props: {
        label: 'Content',
        dragDisabled: true,
      },
      fieldGroup: [
        {
          key: 'locationFirstLevel',
          type: EIremboFormlyFieldTypes.customdropdown,
          defaultValue: Object.keys(LOCATION_WIDGET_LEVELS).shift(),
          props: {
            options: getLocationFieldLevelFieldOptions(true),
            label: 'Location First Level',
            placeholder: 'Select location first level',
            bindLabel: 'label',
            bindValue: 'value',
            required: true,
          },
          hooks: {
            onInit: field => {
              return field?.options?.fieldChanges?.pipe(
                filter(e => {
                  return e.type === 'valueChanges' && e.field.key === field.key;
                }),
                tap(() => {
                  if (
                    !validateLocationFieldLevelFieldValuesIndex(
                      field.formControl?.value,
                      field.parent?.model?.['locationLastLevel']
                    )
                  ) {
                    field.parent?.formControl
                      ?.get('locationLastLevel')
                      ?.setValue(undefined);
                  }
                })
              );
            },
          },
        },
        {
          key: 'locationLastLevel',
          type: EIremboFormlyFieldTypes.customdropdown,
          defaultValue: Object.keys(LOCATION_WIDGET_LEVELS).pop(),
          className: 'location-last-level-formly-group',
          props: {
            options: [],
            label: 'Location last Level',
            placeholder: 'Select location last level',
            internalDescriptionasHtml: true,
            bindLabel: 'label',
            bindValue: 'value',
            required: true,
          },
          expressions: {
            'props.options': (field: FormlyFieldConfig) => {
              const options: IBasicLabelValuePair<string>[] = [];
              Object.keys(LOCATION_WIDGET_LEVELS)
                .reverse()
                .some((key: string) => {
                  if (
                    key !== field.parent?.model['locationFirstLevel'] &&
                    key !== Object.keys(LOCATION_WIDGET_LEVELS)[0]
                  ) {
                    options.push({
                      value: key,
                      label: LOCATION_WIDGET_LEVELS[key],
                    });
                    return false;
                  }
                  return true;
                });
              return options.reverse();
            },
            hide: (field: FormlyFieldConfig) => {
              return (
                field?.parent?.model?.['locationFirstLevel'] ==
                Object.keys(LOCATION_WIDGET_LEVELS).pop()
              );
            },
            'props.internalDescription': (field: FormlyFieldConfig) => {
              if (
                validateLocationFieldLevelFieldValuesIndex(
                  field.parent?.model?.['locationFirstLevel'],
                  field.formControl?.value
                )
              ) {
                const treeList: string[] = [
                  LOCATION_COUNTRY_KEY,
                  ...Object.keys(LOCATION_WIDGET_LEVELS),
                ];
                const firstLevelIndex = treeList.indexOf(
                  field.parent?.model?.['locationFirstLevel'] ??
                    LOCATION_COUNTRY_KEY
                );
                const lastLevelIndex = treeList.indexOf(
                  field.parent?.model?.['locationLastLevel'] ??
                    Object.keys(LOCATION_WIDGET_LEVELS).pop()
                );
                const neededLevels: string[] = treeList.filter(
                  (_key: string, index: number) => {
                    return index >= firstLevelIndex && index <= lastLevelIndex;
                  }
                );
                return neededLevels
                  .map((level: string, index: number) => {
                    return Array(index).fill('-').join('') + level;
                  })
                  .join('<br/>');
              }
              return null;
            },
          },
        },
        {
          key: 'international',
          type: EIremboFormlyFieldTypes['formly-group'],
          fieldGroupClassName: 'international-section-formly-group',
          props: {
            label: 'International',
            dragDisabled: true,
          },
          fieldGroup: [
            {
              key: 'enableInternationalCity',
              id: 'enableInternationalCity',
              type: EIremboFormlyFieldTypes.checkbox,
              defaultValue: false,
              className: 'international-city-formly-group',
              props: {
                label: 'City',
                placeholder: 'City',
                required: false,
              },
            },
          ],
          expressions: {
            hide: (field: FormlyFieldConfig) => {
              return (
                field?.parent?.model?.['locationFirstLevel'].toLowerCase() !==
                'country'
              );
            },
          },
        },
      ],
    },
  ];
};

export const locationLevelOptionsParseIntoSettingsFormModel = (
  field: FormlyFieldConfig,
  model: ILocationWidgetConfigurationFormModel
): ILocationWidgetConfigurationFormModel => {
  const locationFirstLevel: string =
    field?.props?.['locationFirstLevel'] ??
    Object.keys(LOCATION_WIDGET_LEVELS).shift();
  const locationLastLevel: string =
    field?.props?.['locationLastLevel'] ??
    Object.keys(LOCATION_WIDGET_LEVELS).pop();
  const enableInternationalCity: boolean =
    field?.props?.['enableInternationalCity'] ?? false;

  model.locationFieldContentConfig = {
    international: {
      enableInternationalCity,
    },
    locationFirstLevel,
    locationLastLevel,
  };
  return model;
};

export const locationLevelFieldUpdateFromSettingsFormModel = (
  model: ILocationWidgetConfigurationFormModel,
  field: FormlyFieldConfig
): FormlyFieldConfig[] => {
  delete field.props?.['locationFirstLevel'];
  delete field.props?.['locationLastLevel'];
  delete field.props?.['enableInternationalCity'];

  field.form?.reset();

  const dataseKeyOptions: string[] = Object.keys(LOCATION_WIDGET_LEVELS);
  const enableInternationalCity =
    model?.locationFieldContentConfig?.international?.enableInternationalCity ??
    false;

  const locationFirstLevel: string | undefined =
    model?.locationFieldContentConfig?.locationFirstLevel ??
    [...dataseKeyOptions].shift();

  const locationLastLevel: string | undefined =
    model?.locationFieldContentConfig?.locationLastLevel ??
    [...dataseKeyOptions].pop();

  const countryFieldKey: string = `Country${alphaNanoIdUtil(5)}`;

  if (
    locationFirstLevel?.toLowerCase() === LOCATION_COUNTRY_KEY.toLowerCase() ||
    enableInternationalCity
  ) {
    const parentFieldLabel: string = field.props?.label ?? 'Location';

    const countryField: FormlyFieldConfig =
      LocationWidgetUtils.buildWidgetCountryField(
        countryFieldKey,
        `${parentFieldLabel}: ${LOCATION_WIDGET_COUNTRY_LABEL}`,
        locationFirstLevel
      );

    const locationCascadeField: FormlyFieldConfig =
      LocationWidgetUtils.buildCascadeField(
        countryFieldKey,
        <string>locationFirstLevel,
        <string>locationLastLevel,
        `${parentFieldLabel}: ${LOCATION_WIDGET_LOCAL_LABEL}`
      );

    const cityField: FormlyFieldConfig =
      LocationWidgetUtils.buildWidgetCityField(
        countryFieldKey,
        <string>locationFirstLevel,
        enableInternationalCity,
        `${parentFieldLabel}: ${LOCATION_WIDGET_CITY_LABEL}`
      );

    return [countryField, locationCascadeField, cityField];
  }

  const locationCascadeFieldOnly: FormlyFieldConfig =
    LocationWidgetUtils.buildCascadeField(
      countryFieldKey,
      <string>locationFirstLevel,
      <string>locationLastLevel,
      field.props?.label ?? LOCATION_WIDGET_LOCAL_LABEL
    );

  delete locationCascadeFieldOnly.props?.['nonConfigurable'];

  if (locationCascadeFieldOnly.props) {
    locationCascadeFieldOnly.props['locationFirstLevel'] = locationFirstLevel;
    locationCascadeFieldOnly.props['locationLastLevel'] = locationLastLevel;
    locationCascadeFieldOnly.props['enableInternationalCity'] =
      enableInternationalCity;
  }

  return [locationCascadeFieldOnly];
};

const validateLocationFieldLevelFieldValuesIndex = (
  firstLevelValue: string,
  lastLevelValue: string
): boolean => {
  const datasetOptions: string[] = Object.keys(LOCATION_WIDGET_LEVELS);
  return (
    datasetOptions.indexOf(firstLevelValue) <
    datasetOptions.indexOf(lastLevelValue)
  );
};

const getLocationFieldLevelFieldOptions = (
  includeCountry: boolean = false
): IBasicLabelValuePair<string>[] => {
  const fieldOptions: IBasicLabelValuePair<string>[] = Object.keys(
    LOCATION_WIDGET_LEVELS
  ).map((key: string) => {
    return {
      label: LOCATION_WIDGET_LEVELS[key],
      value: key,
    };
  });

  if (includeCountry) {
    fieldOptions.unshift({
      label: 'Country',
      value: LOCATION_COUNTRY_KEY,
    });
  }
  return fieldOptions;
};
