const baseResponseResponseProps: Set<string> = new Set([
  'documentNumber',
  'documentType',
  'surname',
  'fatherName',
  'motherName',
  'issueNumber',
  'dateOfIssue',
  'dateOfExpiry',
  'placeOfIssue',
  'sex',
  'dateOfBirth',
  'placeOfBirth',
  'countryOfBirth',
  'villageId',
  'village',
  'applicantType',
  'photo',
  'nationality',
  'applicationNumber',
  'birthCountry',
  'maritalStatus',
  'civilStatus',
  'cell',
  'sector',
  'district',
  'province',
  'signature',
  'status',
  'timeSubmitted',
  'email',
  'forename',
  'nin',
  'registrationStatus',
  'nid',
  'domicileCountry',
  'domicileDistrict',
  'domicileProvince',
  'domicileSector',
  'domicileCell',
  'domicileVillage',
  'spouse',
  'citizenStatus',
  'postNames',
]);

export const nidChildNidResponseProps: Set<string> = new Set([
  ...baseResponseResponseProps,
]);

export const ninIdResponseProps: Set<string> = new Set([
  ...baseResponseResponseProps,
  'postNames',
]);
