import { CdkDrag, CdkDragDrop } from '@angular/cdk/drag-drop';
import { CdkStepper } from '@angular/cdk/stepper';
import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormBuilderService } from '../../../core/services/form-builder.service';
import { Subject, takeUntil } from 'rxjs';
import { TFormlyFieldKey } from '../../../core/models/formly-key.type';
import { FormBuilderManipulatorClass } from '../../../core/utils/form-builder-manipulator-class.utils';
import { EDragDropItemsIds } from '../../../core/models/form-builder-drag-item-ids.enum';
import {
  alphaNanoIdUtil,
  EIremboFormlyFieldTypes,
} from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-formly-field-stepper',
  templateUrl: './formly-field-stepper.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class FormlyFieldStepperComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  stepperUniqueId!: string;
  isSelected = false;
  selectedFieldStep!: TFormlyFieldKey;
  reviewModeEnabled = false;
  reviewModeEnabledSubControl$: Subject<void> = new Subject<void>();
  configureFieldActionSubControl$: Subject<void> = new Subject<void>();

  EDragDropItemsIds = EDragDropItemsIds;

  collapsedSteps: Set<TFormlyFieldKey> = new Set();

  formBuilderManipulator: FormBuilderManipulatorClass;

  constructor(
    private formBuilderService: FormBuilderService,
    private injector: Injector
  ) {
    super();
    this.formBuilderManipulator = new FormBuilderManipulatorClass(
      this.injector
    );
  }

  @ViewChild('stepper', { static: false }) stepper!: CdkStepper;

  ngOnInit(): void {
    this.formBuilderService.reviewModeEnabled$
      .pipe(takeUntil(this.reviewModeEnabledSubControl$))
      .subscribe((enabled: boolean) => (this.reviewModeEnabled = enabled));

    this.formBuilderService.configureFieldAction$
      .pipe(takeUntil(this.configureFieldActionSubControl$))
      .subscribe((configuredField: FormlyFieldConfig | null) => {
        if (!configuredField) {
          this.selectedFieldStep = undefined;
          return;
        }
        this.selectedFieldStep = configuredField.key;
      });

    this.stepperUniqueId = `irembogov-formly-field-stepper-${alphaNanoIdUtil()}`;
  }

  isValid(field: FormlyFieldConfig): boolean {
    if (field.key && field.formControl) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every(f => this.isValid(f))
      : true;
  }

  dropStep(event: CdkDragDrop<any[]>) {
    this.formBuilderManipulator.moveSectionInSectionsList(event);
  }

  dropStepPredicate(event: CdkDrag<FormlyFieldConfig>) {
    return event.data.key === 'step';
  }

  dropBlock(event: CdkDragDrop<FormlyFieldConfig[]>) {
    this.formBuilderManipulator.moveBlock(event);
  }

  dropBlockPredicate(event: CdkDrag<FormlyFieldConfig>) {
    return event.data.type === EIremboFormlyFieldTypes.block;
  }

  addNewBlockToSectionFields(sectionIndex: number) {
    const key = `block${alphaNanoIdUtil(5)}`;
    const label = `New Block`;
    const newBlock: FormlyFieldConfig = {
      key,
      type: EIremboFormlyFieldTypes.block,
      props: {
        label,
        placeholder: '',
      },
      fieldGroup: [],
    };
    this.formBuilderManipulator.addNewBlockToFields(newBlock, sectionIndex);
  }

  toggleStepContent(key: TFormlyFieldKey) {
    if (!key) return;
    if (this.collapsedSteps.has(key)) {
      this.collapsedSteps.delete(key);
      return;
    }

    this.collapsedSteps.add(key);
  }

  getBlockListIds(len: number | undefined, index: number): string[] {
    if (!len) return [];
    const arr: string[] = [];
    for (let i = 0; i < len; i++) {
      if (i !== index) {
        arr.push(`${EDragDropItemsIds.DefaultBlockCdkDragListItems}${i}`);
      }
    }
    return arr;
  }

  ngOnDestroy(): void {
    this.reviewModeEnabledSubControl$.next();
    this.reviewModeEnabledSubControl$.complete();

    this.configureFieldActionSubControl$.next();
    this.configureFieldActionSubControl$.complete();
  }
}
