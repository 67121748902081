import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../core/services/auth.service';
import { ServiceChangeRequestService } from '../../../core/services/service-change-request.service';
import { EServiceChangeRequestStatus } from '../../../core/models/service-change-request-status.enum';
import {
  IIrembogovBasicLabelKeyPair,
  RolesEnum,
} from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-manage-service-change-requests',
  templateUrl: './manage-service-change-requests.component.html',
  styleUrls: ['./manage-service-change-requests.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ManageServiceChangeRequestsComponent implements OnInit, OnDestroy {
  private userActiveRole$ = new Subject<void>();
  userActiveRole?: string;
  RolesEnum = RolesEnum;
  isLoading = false;

  requestStatuses: IIrembogovBasicLabelKeyPair<EServiceChangeRequestStatus>[] =
    [
      {
        label: 'All',
        key: EServiceChangeRequestStatus._UNKNOWN_,
        icon: 'fas fa-list-alt',
      },
      {
        label: 'In Development',
        key: EServiceChangeRequestStatus.IN_DEVELOPMENT,
        icon: 'fas fa-edit',
      },
      {
        label: 'In QA',
        key: EServiceChangeRequestStatus.IN_QA,
        icon: 'fas fa-search',
      },
      {
        label: 'Ready To Go Live',
        key: EServiceChangeRequestStatus.LIVE_READY,
        icon: 'fas fa-cloud-upload-alt',
      },
      {
        label: 'Live',
        key: EServiceChangeRequestStatus.LIVE,
        icon: 'fas fa-play',
      },
    ];

  selectedRequestStatus: EServiceChangeRequestStatus =
    this.requestStatuses[0].key;

  constructor(
    private authService: AuthService,
    private serviceChangeRequestService: ServiceChangeRequestService
  ) {}

  ngOnInit() {
    this.authService.userActiveRole
      .pipe(takeUntil(this.userActiveRole$))
      .subscribe({
        next: userActiveRole => {
          this.userActiveRole = userActiveRole;
        },
      });
  }

  onSelectStatus(status: EServiceChangeRequestStatus) {
    this.selectedRequestStatus = status;
  }

  ngOnDestroy(): void {
    this.userActiveRole$.next();
    this.userActiveRole$.complete();
  }
}
