<div class="card" *ngIf="issue">
  <div class="card-body">
    <form [formGroup]="issueEditForm">
      <div class="mb-1">
        <input type="hidden" formControlName="id" />
        <div class="form-group">
          <label for="title" class="form-label text-muted"> Issue Title </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control form-control-sm text-weight-900"
              id="title"
              placeholder="Issue Title"
              formControlName="title"
              #title />
            <div
              class="form-error w-100 small text-danger"
              *ngIf="
                issueEditForm.controls['title'].touched &&
                !issueEditForm.controls['title'].valid
              ">
              Title is required.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="description" class="form-label text-muted">
            Issue Description
          </label>
          <div class="input-group mb-3">
            <textarea
              class="form-control form-control-sm text-weight-900"
              id="description"
              name="description"
              formControlName="description"
              placeholder="Issue Description"
              #description></textarea>
            <div
              class="form-error w-100 small text-danger"
              *ngIf="
                issueEditForm.controls['description'].touched &&
                !issueEditForm.controls['description'].valid
              ">
              Description is required.
            </div>
          </div>
        </div>
        <div class="mt-1">
          <button
            (click)="onIssueEditSave()"
            class="btn btn-sm btn-primary px-3"
            type="submit"
            [disabled]="issueEditForm.invalid">
            <i class="fa fa-check"></i>
            Save
            <div
              *ngIf="isEditIssueSaving"
              class="spinner-border spinner-border-sm mr-2"
              role="status"></div>
          </button>
          <button
            (click)="onCloseForm()"
            class="btn btn-sm px-3 ms-3 text-white close-button">
            <i class="fa fa-times" aria-hidden="true"></i>
            Cancel
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
