import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { RolesEnum } from '@irembo-andela/irembogov3-common';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { UserCanConfigureServiceChangeRequests } from '../../../../core/config/service-change-request.config';
import { EServiceChangeRequestType } from '../../../../core/models/service-change-request-type.enum';

@Component({
  selector: 'irembogov-service-change-request-configurer-action-widget',
  templateUrl:
    './service-change-request-configurer-action-widget.component.html',
})
export class ServiceChangeRequestConfigurerActionWidgetComponent
  implements OnInit, OnDestroy
{
  @Input() changeRequest!: IServiceChangeRequest;
  userActiveRole!: RolesEnum;
  @Input() isProcessing = false;
  private userActiveRole$ = new Subject<void>();

  EServiceChangeRequestType = EServiceChangeRequestType;

  @Output() OnConfigureClicked: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.userActiveRole
      .pipe(takeUntil(this.userActiveRole$))
      .subscribe({
        next: userActiveRole => {
          this.userActiveRole = userActiveRole;
        },
      });
  }

  checkIfCanConfigureChangeRequests() {
    if (!this.userActiveRole) return false;
    return (
      UserCanConfigureServiceChangeRequests.indexOf(this.userActiveRole) > -1
    );
  }

  ngOnDestroy(): void {
    this.userActiveRole$.next();
    this.userActiveRole$.complete();
  }
}
