import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IBaseConfigurationFormModel,
  IFormbuilderFieldTypeConfiguration,
  ISectionConfigurationFormModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  generateNewFieldKeyFromSettingsLabel,
  getFieldLabelSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { FormBuilderService } from '../../../services/form-builder.service';

const defaultSectionConfigFormModel: ISectionConfigurationFormModel = {
  fieldLabel: '',
};

export const sectionFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultSectionConfigFormModel,
      formlyForm: [
        ...getFieldLabelSettingsConfigFormly(formBuilderServiceInstance),
      ],
    },
  };
};

export const sectionFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  return {
    fieldLabel: field.props?.label ?? '',
  };
};

export const sectionFieldUpdateFromSettingsFormModel = (
  model: IBaseConfigurationFormModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  if (field.props) {
    field.props['label'] = model.fieldLabel ?? undefined;
  } else {
    field['props'] = {
      label: model.fieldLabel ?? undefined,
    };
  }

  field = generateNewFieldKeyFromSettingsLabel(field);

  return field;
};
