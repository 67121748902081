<main class="container irembogov-manage-service-change-requests">
  <div class="row">
    <div class="col-md-12">
      <h4 class="mb-4">Change Requests</h4>

      <irembogov-button-group
        class="irembogov-button-group"
        [itemList]="requestStatuses"
        [selectedItem]="selectedRequestStatus"
        (itemChange)="onSelectStatus($event)"></irembogov-button-group>
    </div>

    <irembogov-service-change-requests-list-table-widget
      *ngIf="userActiveRole === RolesEnum.ROLE_SERVICE_MANAGER"
      [changeRequestStatus]="selectedRequestStatus"
      [viewAsRole]="
        RolesEnum.ROLE_SERVICE_MANAGER
      "></irembogov-service-change-requests-list-table-widget>

    <irembogov-service-change-requests-list-table-widget
      *ngIf="userActiveRole === RolesEnum.ROLE_QA_REVIEWER"
      [changeRequestStatus]="selectedRequestStatus"
      [viewAsRole]="
        RolesEnum.ROLE_QA_REVIEWER
      "></irembogov-service-change-requests-list-table-widget>
  </div>
</main>
