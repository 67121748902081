import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IWorkflowTemplate } from '../../../../core/models/workflow-templates.model';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'irembogov-view-workflow-template-image-widget',
  templateUrl: './view-workflow-template-details-widget.component.html',
  styleUrls: ['./view-workflow-template-details-widget.component.scss'],
})
export class ViewWorkflowTemplateDetailsWidgetComponent {
  @Input() workflowTemplate!: IWorkflowTemplate;
  fileBaseURL = environment.apiGatewayBaseUrl + '/admin';

  constructor(public activeModal: NgbActiveModal) {}
}
