<ng-container>
  <div class="mb-3 ms-3" *ngIf="showToggleButton">
    <irembogov-toggle-button
      *ngIf="showToggleButton"
      [options]="pricingTypes"
      [selectedOption]="selectedPricingType"
      (selectOption)="onSelectPricingType($event)"></irembogov-toggle-button>
  </div>
  <ng-container *ngIf="selectedPricingType === EPricingType.FIXED">
    <irembogov-configure-service-fixed-price-handler
      [isPricingNumberType]="isPricingNumberType"
      (formChange)="onSetScenario($event)"
      [paymentMerchantCase]="showToggleButton"
      [addCurrencyProperty]="withCurrency"
      [defaultData]="defaultData"
      [label]="label"></irembogov-configure-service-fixed-price-handler>
  </ng-container>
  <ng-container *ngIf="selectedPricingType === EPricingType.DYNAMIC">
    <irembogov-configure-service-dynamic-price-handler
      [isPricingNumberType]="isPricingNumberType"
      [label]="label"
      [formFields]="formFields"
      [scenarios]="defaultData?.dynamicConditions"
      [addCurrencyProperty]="withCurrency"
      (addScenarios)="
        onAddScenarios($event)
      "></irembogov-configure-service-dynamic-price-handler>
  </ng-container>
</ng-container>
