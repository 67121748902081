<irembogov-irembo-section-message
  type="info"
  *ngIf="!isLoadingChangeRequestList && collectionSize < 1"
  class="w-80 mx-auto mt-5 d-block"
  title=""
  [message]="
    changeRequestStatus === EServiceChangeRequestStatus._UNKNOWN_
      ? 'You have\'nt been assigned a service yet'
      : 'No assigned service in ' +
        (changeRequestStatus | changeRequestStatusToLabel | uppercase)
  "
  [dismissible]="false">
</irembogov-irembo-section-message>

<irembogov-custom-table-wrapper
  [isSortVisible]="true"
  [sortFieldOptions]="sortFieldOptions"
  [loading]="isLoadingChangeRequestList || isLoadingOrganizationList"
  [paginationVisible]="true"
  [totalItems]="collectionSize"
  [itemsPerPage]="(_filterObject | async)?.size || 10"
  [currentPage]="((_filterObject | async)?.page || 0) + 1"
  (paginationChange)="
    updateApiFilter(_filterObject, {
      page: $event.pageNumber - 1,
      size: $event?.pageSize
    })
  "
  (sortChange)="
    updateApiFilter(_filterObject, {
      sort: $event.column,
      sortDirection: $event.direction
    })
  ">
  <table body aria-label="Irembo Institutions Table" class="table">
    <thead>
      <tr>
        <th scope="col">
          <input
            type="checkbox"
            class="form-check-input"
            [(ngModel)]="selectAllChangeRequests"
            (click)="toggleSelectAllChangeRequests()" />
        </th>
        <th scope="col" class="text-nowrap">Service Name</th>
        <th scope="col" class="text-nowrap">Organization</th>
        <th scope="col text-nowrap">Type</th>
        <th scope="col text-nowrap">Status</th>
        <th scope="col">Created Date</th>
        <th scope="col text-nowrap" class="text-nowrap">
          Requirement Doc Link
        </th>
        <th scope="col text-nowrap">Action</th>
      </tr>
    </thead>
    <tbody class="rounded bg-white" *ngIf="!isLoadingChangeRequestList">
      <tr *ngFor="let changeRequest of changeRequestList; let index = index">
        <th scope="row">
          <input
            type="checkbox"
            class="form-check-input"
            [id]="changeRequest.id"
            [(ngModel)]="changeRequest.selectedInView"
            [value]="changeRequest.id" />
        </th>
        <td class="text-nowrap">
          <strong>
            {{ changeRequest.serviceName }}
          </strong>
        </td>
        <td
          class="text-nowrap"
          style="
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          ">
          <strong>
            {{ changeRequest?.organization?.name || 'N/A' }}
          </strong>
        </td>
        <td class="text-nowrap">
          <irembogov-service-change-request-type-badge
            [requestType]="
              changeRequest.type
            "></irembogov-service-change-request-type-badge>
        </td>
        <td class="text-nowrap">
          <irembogov-service-change-request-status-badge
            [requestStatus]="
              changeRequest.status
            "></irembogov-service-change-request-status-badge>
        </td>
        <td>
          <irembogov-table-inline-user-info
            name="{{ changeRequest.dateCreated | date : 'dd/MM/yyyy' }}"
            [nameAsUpperCase]="false"
            [showThumbnail]="false"
            [nameActiveLink]="false"
            [namePointerCursor]="false"
            [emailActiveLink]="false"></irembogov-table-inline-user-info>
        </td>
        <td class="text-nowrap">
          <span class="text-primary text-decoration-underline">
            <em> Requirement Doc. </em>
            <i class="fas fa-file-download"></i>
          </span>
        </td>
        <td class="text-nowrap">
          <irembogov-service-change-request-reviewer-action-widget
            *ngIf="
              viewAsRole === RolesEnum.ROLE_QA_REVIEWER &&
              changeRequest.status === EServiceChangeRequestStatus.IN_QA
            "
            [changeRequest]="changeRequest"
            (OnReviewClicked)="
              onReviewRequestClick($event)
            "></irembogov-service-change-request-reviewer-action-widget>

          <irembogov-service-change-request-configurer-action-widget
            (OnConfigureClicked)="onConfigureRequestClick($event)"
            [changeRequest]="changeRequest"
            *ngIf="
              viewAsRole === RolesEnum.ROLE_SERVICE_MANAGER &&
              (changeRequest.status ===
                EServiceChangeRequestStatus.IN_DEVELOPMENT ||
                changeRequest.status ===
                  EServiceChangeRequestStatus.IN_REDEVELOPMENT)
            "></irembogov-service-change-request-configurer-action-widget>
        </td>
      </tr>
    </tbody>
  </table>
</irembogov-custom-table-wrapper>
