import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  Subscription,
  finalize,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { AuthService } from '../../../../core/services/auth.service';
import { UserCanReviewServiceChangeRequests } from '../../../../core/config/service-change-request.config';
import {
  EServiceChangeRequestReviewSection,
  EServiceChangeRequestReviewStatus,
  IServiceChangeRequestReviewIssue,
} from '../../../../core/models/service-change-request-review-issue.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import {
  IFilterAndPaginationFieldsBase,
  IIrembogovBasicLabelKeyPair,
  ToastService,
  RolesEnum,
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  IHttpPagedResponse,
  updateApiFilter,
} from '@irembo-andela/irembogov3-common';
import { HttpErrorResponse } from '@angular/common/http';
import { ISaveServiceChangeRequestReviewIssueRequest } from '../../../../core/models/http/save-service-change-request-review-issue-request.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface IGetReviewIssuesParamsFields extends IFilterAndPaginationFieldsBase {
  section: EServiceChangeRequestReviewSection | null;
  status: EServiceChangeRequestReviewStatus[] | null;
  changeRequestId: string | null;
}
@Component({
  selector: 'irembogov-reviewer-issues-entry-form-widget',
  templateUrl: './reviewer-issues-entry-form-widget.component.html',
  styleUrls: ['./reviewer-issues-entry-form-widget.component.scss'],
})
export class ReviewerIssuesEntryFormWidgetWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() changeRequestId?: string;
  @Input() reviewSection!: EServiceChangeRequestReviewSection;
  @Input() presetFieldTitle!: string;
  @Output() goToNext: EventEmitter<boolean> = new EventEmitter<boolean>();
  activeFetchByStatus: EServiceChangeRequestReviewStatus =
    EServiceChangeRequestReviewStatus.PENDING;

  isLoadingReviewIssuesList = false;
  isSavingIssue = false;
  subscriptions!: Subscription;

  reviewIssuesList: IServiceChangeRequestReviewIssue[] = [];
  userActiveRole!: RolesEnum;

  collectionSize = 0;
  pageSize = 10;
  page = 0;

  defaultParamsFields: IGetReviewIssuesParamsFields = {
    page: 0,
    size: 10,
    section: this.reviewSection,
    status: [
      EServiceChangeRequestReviewStatus.PENDING,
      EServiceChangeRequestReviewStatus.NEW,
    ],
    changeRequestId: this.changeRequestId ?? null,
  };

  EServiceChangeRequestReviewStatus = EServiceChangeRequestReviewStatus;

  updateApiFilter = updateApiFilter;

  statusFilterOptions: IIrembogovBasicLabelKeyPair<EServiceChangeRequestReviewStatus>[] =
    [
      { label: 'Pending', key: EServiceChangeRequestReviewStatus.PENDING },
      { label: 'Resolved', key: EServiceChangeRequestReviewStatus.RESOLVED },
    ];

  queryParamsSubscription$!: Subscription;
  _filterObject: BehaviorSubject<IGetReviewIssuesParamsFields> =
    new BehaviorSubject<IGetReviewIssuesParamsFields>(this.defaultParamsFields);

  private userActiveRole$ = new Subject<void>();

  @Output() OnReviewClicked: EventEmitter<string> = new EventEmitter<string>();

  issueForm: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });

  issueEditForm: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
  });

  constructor(
    private authService: AuthService,
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.authService.userActiveRole
      .pipe(takeUntil(this.userActiveRole$))
      .subscribe({
        next: userActiveRole => {
          this.userActiveRole = userActiveRole;
        },
      });

    this.queryParamsSubscription$ = this._filterObject
      .asObservable()
      .pipe(
        switchMap((paramsFields: IGetReviewIssuesParamsFields) => {
          this.getReviewIssuesForSection(paramsFields);
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequestId']?.currentValue) {
      this.changeRequestId = changes['changeRequestId'].currentValue;
      this.updateApiFilter(this._filterObject, {
        changeRequestId: this.changeRequestId,
      });
    }

    if (changes['reviewSection']?.currentValue) {
      this.reviewSection = changes['reviewSection'].currentValue;
      this.updateApiFilter(this._filterObject, { section: this.reviewSection });
    }
    if (changes['presetFieldTitle']?.currentValue) {
      this.issueForm.controls['title'].setValue(
        changes['presetFieldTitle'].currentValue
      );
      this.issueForm.controls['title'].markAsTouched();
      this.issueForm.updateValueAndValidity();
    }
  }

  getReviewIssuesForSection(paramsFields: IGetReviewIssuesParamsFields): void {
    if (!(paramsFields.section && paramsFields.changeRequestId)) return;
    this.isLoadingReviewIssuesList = true;

    const queryParams: string =
      FilterFieldsToQueryParamsBuilder<IGetReviewIssuesParamsFields>(
        paramsFields
      );
    this.serviceChangeRequestService
      .getChangeRequestReviewIssues(queryParams)
      .pipe(finalize(() => (this.isLoadingReviewIssuesList = false)))
      .subscribe({
        next: (
          response: IHttpPagedResponse<IServiceChangeRequestReviewIssue>
        ) => {
          this.reviewIssuesList = this.reviewIssuesList.concat(
            response.data.content
          );
          this.collectionSize = response.data.totalElements;
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Failed to fetch list of review issues.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  loadMoreIsues(): void {
    if (this.reviewIssuesList.length >= this.collectionSize) return;
    this.page++;
    console.log(this.page);
    updateApiFilter(this._filterObject, {
      page: this.page,
      size: this.pageSize,
    });
  }

  updateToGetIssuesByStatus(
    status: EServiceChangeRequestReviewStatus,
    forceReload = false
  ) {
    if (this.activeFetchByStatus === status && !forceReload) return;

    this.reviewIssuesList = [];
    this.activeFetchByStatus = status;
    this.updateApiFilter(this._filterObject, {
      status:
        this.activeFetchByStatus === EServiceChangeRequestReviewStatus.PENDING
          ? [this.activeFetchByStatus, EServiceChangeRequestReviewStatus.NEW]
          : [this.activeFetchByStatus],
      page: this.defaultParamsFields.page,
      size: this.defaultParamsFields.size,
    });
  }

  saveNewIssue(): void {
    if (!this.changeRequestId) return;

    this.isSavingIssue = true;

    const request: ISaveServiceChangeRequestReviewIssueRequest = {
      changeRequestId: this.changeRequestId,
      title: this.issueForm.controls['title'].value,
      description: this.issueForm.controls['description'].value,
      section: this.reviewSection,
    };

    this.serviceChangeRequestService
      .saveChangeRequestReviewIssue(request)
      .pipe(finalize(() => (this.isSavingIssue = false)))
      .subscribe({
        next: () => {
          this.toastService.show({
            body: 'Review issue saved',
            type: 'success',
          });
          this.issueForm.reset();
          this.updateToGetIssuesByStatus(
            EServiceChangeRequestReviewStatus.PENDING,
            true
          );
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Failed to fetch list of review issues.'
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  deleteNewIssue(
    issue: IServiceChangeRequestReviewIssue,
    issueListIndex: number
  ): void {
    if (!issue.id) return;

    this.reviewIssuesList[issueListIndex].isProcessing = true;

    this.serviceChangeRequestService
      .deleteChangeRequestReviewIssue(issue.id)
      .pipe(
        finalize(
          () => (this.reviewIssuesList[issueListIndex].isProcessing = false)
        )
      )
      .subscribe({
        next: () => {
          this.toastService.show({
            body: 'Review issue removed',
            type: 'success',
          });
          this.issueForm.reset();
          this.updateToGetIssuesByStatus(
            EServiceChangeRequestReviewStatus.PENDING,
            true
          );
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            `Failed to remove issue.`
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  resolvePendingIssue(
    issue: IServiceChangeRequestReviewIssue,
    issueListIndex: number
  ): void {
    if (!issue.id) return;
    this.reviewIssuesList[issueListIndex].isProcessing = true;
    this.serviceChangeRequestService
      .resolveChangeRequestReviewIssue(issue.id)
      .pipe(
        finalize(
          () => (this.reviewIssuesList[issueListIndex].isProcessing = false)
        )
      )
      .subscribe({
        next: () => {
          this.toastService.show({
            body: 'Review issue resolved',
            type: 'success',
          });
          this.updateToGetIssuesByStatus(
            EServiceChangeRequestReviewStatus.PENDING,
            true
          );
        },
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            `Failed to resolve issue.`
          );
          this.toastService.show({
            body: `ERR: ${errorMessage}`,
            type: 'error',
          });
        },
      });
  }

  unResolveResolvedIssue(
    issue: IServiceChangeRequestReviewIssue,
    issueListIndex: number
  ): void {
    console.log(`todo: unresolve issue ${issue.id} index ${issueListIndex}`);
  }

  editNewIssue(index: number): void {
    this.reviewIssuesList[index].isProcessing = true;
  }

  updateNewIssue(
    issue: IServiceChangeRequestReviewIssue,
    issueListIndex: number
  ) {
    if (!this.changeRequestId) {
      return;
    }

    const req: ISaveServiceChangeRequestReviewIssueRequest = {
      changeRequestId: this.changeRequestId,
      id: issue.id,
      title: issue.title,
      description: issue.description,
      section: this.reviewSection,
    };

    const updateIssue$ =
      this.serviceChangeRequestService.updateChangeRequestReviewIssue(req);

    const showErrorToast = (errorMessage: string) => {
      this.toastService.show({ body: `ERR: ${errorMessage}`, type: 'error' });
    };

    const showSuccessToastAndRefreshIssues = () => {
      this.toastService.show({ body: 'Issue edit success', type: 'success' });
      this.updateToGetIssuesByStatus(
        EServiceChangeRequestReviewStatus.PENDING,
        true
      );
    };

    const updateIssueSubscription = updateIssue$
      .pipe(
        finalize(
          () => (this.reviewIssuesList[issueListIndex].isProcessing = false)
        )
      )
      .subscribe({
        next: showSuccessToastAndRefreshIssues,
        error: (err: HttpErrorResponse) => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            `Failed to edit issue.`
          );
          showErrorToast(errorMessage);
        },
      });

    this.subscriptions.add(updateIssueSubscription);
  }

  checkIfCanReviewChangeRequests() {
    if (!this.userActiveRole) return false;
    return UserCanReviewServiceChangeRequests.indexOf(this.userActiveRole) > -1;
  }

  onIssueEntrySave() {
    return;
  }

  closeEditForm(event: boolean, index: number) {
    this.reviewIssuesList[index].isProcessing = false;
  }

  ngOnDestroy(): void {
    this.userActiveRole$.next();
    this.userActiveRole$.complete();

    if (this.queryParamsSubscription$) {
      this.queryParamsSubscription$.unsubscribe();
    }

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }
}
