<div class="irembogov-workflow-template-selector-list-widget">
  <div class="card bg-transparent border-0">
    <div class="card-header bg-transparent border-0">Choose Workflow</div>
    <div class="card-body bg-white border-0">
      <irembogov-custom-table-wrapper
        wapperTopPaddingClass="pt-0"
        wapperTopMarginClass="mt-1"
        [isSortVisible]="false"
        [isSeachVisible]="false"
        [sortFieldOptions]="sortFieldOptions"
        [loading]="isLoadingWorkflowTemplatesList"
        [paginationVisible]="true"
        [pageSizes]="[5, 10, 50, 100]"
        [itemsPerPage]="5"
        [totalItems]="collectionSize"
        [itemsPerPage]="pageSize"
        [currentPage]="((_filterObject | async)?.page || 0) + 1"
        (paginationChange)="
          updateApiFilter(_filterObject, {
            page: $event.pageNumber - 1,
            size: $event?.pageSize
          })
        "
        (searchChange)="updateApiFilter(_filterObject, { query: $event })"
        (sortChange)="updateApiFilter(_filterObject, { sort: $event })">
        <table
          body
          aria-label="Irembo Workflow Templates Table"
          class="table"
          *ngIf="!isLoadingWorkflowTemplatesList">
          <thead class="d-none">
            <th></th>
            <th>Name</th>
            <th>View</th>
          </thead>
          <tbody>
            <tr
              *ngFor="let template of workflowTemplates; let index = index"
              [class]="
                selectedTemplate && selectedTemplate.id === template.id
                  ? { selected: true }
                  : { selected: false }
              ">
              <td class="text-center">
                <input
                  type="checkbox"
                  class="form-check-input"
                  [id]="template.id"
                  [checked]="
                    selectedTemplate && selectedTemplate.id === template.id
                  " />
                <label
                  [for]="template.id"
                  [class]="
                    selectedTemplate && selectedTemplate.id === template.id
                      ? { selected: true, radioselector: true }
                      : { selected: false, radioselector: true }
                  "
                  (click)="onSelectTemplate(template)"></label>
              </td>
              <td class="text-wrap">
                <strong>
                  {{
                    template.workflowName && template.workflowName.length > 50
                      ? (template.workflowName | slice : 0 : 50) + '...'
                      : template.workflowName
                  }}
                </strong>
                <small>
                  {{
                    template.workflowDescription &&
                    template.workflowDescription.length > 60
                      ? (template.workflowDescription | slice : 0 : 60) + '...'
                      : template.workflowDescription
                  }}
                </small>
              </td>
              <td class="text-nowrap">
                <button
                  class="btn btn-sm btn-light border-0 bg-transparent"
                  (click)="openViewWorkflowImageModal(template)">
                  <i class="fa-regular fa-eye text-muted"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </irembogov-custom-table-wrapper>
    </div>
  </div>
</div>
