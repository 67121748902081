import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportingRoutingModule } from './reporting-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ReportingRoutingModule],
})
export class ReportingModule {}
