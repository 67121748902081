import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IBaseConfigurationFormModel,
  IDatePickerFieldsConfigFormModel,
  IFormbuilderFieldTypeConfiguration,
} from '../../../../core/models/form-builder-types-configurations.model';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';
import {
  baseFieldParseIntoSettingsFormModel,
  baseFieldUpdateFromSettingsFormModel,
  getDateFieldSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { FormBuilderService } from '../../../../core/services/form-builder.service';

const defaultInputConfigFormModel: IDatePickerFieldsConfigFormModel = {
  ...fieldsBaseConfigurationFormModel(),
  fieldLabel: '',
  placeholder: '',
  hint: '',
  tooltip: '',
  internalDescription: '',
  dateFormat: 'DD MM YYYY',
  delimeter: '/',
};

export const datePickerFieldGetSettingsConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultInputConfigFormModel,
      formlyForm: [
        ...getDateFieldSettingsConfigFormly(formBuilderServiceInstance),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [],
    },
    display: {
      formModel: {},
      formlyForm: [],
    },
  };
};

export const datePickerFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IDatePickerFieldsConfigFormModel => {
  const baseSetting: IBaseConfigurationFormModel =
    baseFieldParseIntoSettingsFormModel(field);
  return {
    ...baseSetting,
    dateFormat: field.props?.['dateFormat'],
    delimeter: field.props?.['delimeter'],
  };
};

export const datePickerFieldUpdateFromSettingsFormModel = (
  model: IDatePickerFieldsConfigFormModel,
  datePickerField: FormlyFieldConfig
): FormlyFieldConfig => {
  baseFieldUpdateFromSettingsFormModel(model, datePickerField);

  if (datePickerField.props) {
    datePickerField.props['dateFormat'] = model.dateFormat ?? undefined;
    datePickerField.props['delimeter'] = model.delimeter ?? undefined;
  } else {
    datePickerField.props = {
      dateFormat: model.dateFormat,
      delimeter: model.delimeter,
    };
  }

  return datePickerField;
};
