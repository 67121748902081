import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  IBaseConfigurationFormModel,
  ILocationWidgetConfigurationFormModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  baseFieldParseIntoSettingsFormModel,
  baseFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
  getLocationWidgetFieldSettingsConfig,
  locationLevelOptionsParseIntoSettingsFormModel,
  locationLevelFieldUpdateFromSettingsFormModel,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import {
  getRequiredValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import { parseRequiredValidatorParseFieldIntoModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import { setRequiredValidatorUpdateFieldFromModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';

const defaultLocationWidgetFieldConfigFormModel: ILocationWidgetConfigurationFormModel =
  {
    ...fieldsBaseConfigurationFormModel(),
    locationFieldContentConfig: {},
  };

export const locationWidgetFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultLocationWidgetFieldConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
        ...getLocationWidgetFieldSettingsConfig(),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const locationWidgetFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  let model: ILocationWidgetConfigurationFormModel =
    baseFieldParseIntoSettingsFormModel(field);
  model = locationLevelOptionsParseIntoSettingsFormModel(field, model);
  return model;
};

export const locationWidgetFieldUpdateFromSettingsFormModel = (
  model: ILocationWidgetConfigurationFormModel,
  locationWidgetField: FormlyFieldConfig
): FormlyFieldConfig[] => {
  locationWidgetField = baseFieldUpdateFromSettingsFormModel(
    model,
    locationWidgetField
  );
  return locationLevelFieldUpdateFromSettingsFormModel(
    model,
    locationWidgetField
  );
};

export const locationWidgetFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, field);

  return model;
};

export const locationWidgetFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  locationWidgetField: FormlyFieldConfig
): FormlyFieldConfig => {
  locationWidgetField = setRequiredValidatorUpdateFieldFromModel(
    model,
    locationWidgetField
  );

  return locationWidgetField;
};

export const locationWidgetFieldParseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const locationWidgetFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
