export const FormBuilderFieldValidationPatterns: IFormBuilderFieldValidationPatterns =
  {
    emailsValidationRegex:
      /^[^<>()[\]\\,;:%#^\s"$&!@]+@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$/,
    lettersAtoZInputValidationRegex: /^[a-zA-Z]*$/,
    numbers0To9InputValidationRegex: /^\d*$/,
    symbolsInputValidationRegex: /^[!@#$%^&*()_\-+?’”;:,<>]*$/,
    lettersAndNumbersInputValidationRegex: /^(\d|[a-zA-Z])*$/,
    lettersAndNumbersAndSymbolsInputValidationRegex:
      /^([!@#$%^&*()_\-+?’”;:,<>]|\d|[a-zA-Z])*$/,
  };

interface IFormBuilderFieldValidationPatterns {
  emailsValidationRegex: RegExp;
  lettersAtoZInputValidationRegex: RegExp;
  numbers0To9InputValidationRegex: RegExp;
  symbolsInputValidationRegex: RegExp;
  lettersAndNumbersInputValidationRegex: RegExp;
  lettersAndNumbersAndSymbolsInputValidationRegex: RegExp;
}
