import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'irembogov-field-wrapper',
  templateUrl: './field-wrapper.component.html',
})
export class FieldWrapperComponent
  extends FieldWrapper
  implements OnInit, OnDestroy
{
  configureFieldActionSubControl$: Subject<void> = new Subject<void>();

  isSelected = false;

  reviewModeEnabled = false;

  reviewModeEnabledSub$: Subject<void> = new Subject<void>();

  constructor(private formBuilderService: FormBuilderService) {
    super();
  }

  ngOnInit(): void {
    this.formBuilderService.reviewModeEnabled$
      .pipe(takeUntil(this.reviewModeEnabledSub$))
      .subscribe((isEnabled: boolean) => {
        this.reviewModeEnabled = isEnabled;
      });
  }

  ngOnDestroy(): void {
    this.reviewModeEnabledSub$.next();
    this.reviewModeEnabledSub$.complete();
  }
}
