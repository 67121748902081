<div
  class="review-formbuilder-json-editor w-100 d-flex p-3"
  *ngIf="changeRequest">
  <div class="flex-grow-1 mw-70">
    <div class="w-100 px-3">
      <irembogov-preview-service-change-request-json-formdata-widget></irembogov-preview-service-change-request-json-formdata-widget>
    </div>
  </div>

  <div class="justify-self w-30">
    <nav
      ngbNav
      #nav="ngbNav"
      class="nav-tabs"
      [activeId]="activeId"
      (activeIdChange)="activeId = $event">
      <ng-container [ngbNavItem]="1">
        <a ngbNavLink>Details</a>
        <ng-template ngbNavContent>
          <pre
            class="border bg-white overflow-auto w-100 p-3"
            [innerHTML]="
              changeRequestJsonFormData
                | prettyjson : { escapeHtmlBracket: true }
            "
            *ngIf="changeRequestJsonFormData"></pre>
        </ng-template>
      </ng-container>

      <ng-container [ngbNavItem]="2">
        <a ngbNavLink>Issues</a>
        <ng-template ngbNavContent>
          <irembogov-reviewer-issues-entry-form-widget
            [changeRequestId]="changeRequest.id"
            [reviewSection]="EServiceChangeRequestReviewSection.FORM"
            (goToNext)="reviewFormBuilderJsonEditorCompleted.emit($event)"
            [presetFieldTitle]="
              presetIssueFieldTitle
            "></irembogov-reviewer-issues-entry-form-widget>
        </ng-template>
      </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
