import {
  EIremboFormlyFieldTypes,
  ICascadingConfig,
  LOCATION_COUNTRY_KEY,
  LOCATION_WIDGET_LEVELS,
  alphaNanoIdUtil,
} from '@irembo-andela/irembogov3-common';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { LOCATION_WIDGET_LOCAL_LABEL } from '../../config/location-widget-field-labels.config';

export const buildWidgetCountryField = (
  countryFieldKey: string,
  label: string,
  locationFirstLevel: string | undefined
): FormlyFieldConfig => {
  return {
    type: EIremboFormlyFieldTypes.customdropdown,
    key: countryFieldKey,
    id: countryFieldKey,
    props: {
      label,
      nonConfigurable: true,
      dragDisabled: true,
      useBaseUrl: true,
      subType: 'CUSTOM_DROPDOWN_DATASET',
      multiple: false,
      dataset: {
        url: `/admin/v1/dataset-items/by-dataset-code/COUNTRY`,
        params: { size: 50 },
        dataField: 'data',
        pagination: 'true',
        searching: false,
      },
      bindLabel: 'label',
      bindValue: 'label',
    },
    expressions: {
      hide: `${locationFirstLevel?.toLowerCase() !== 'country'}`,
    },
  };
};

export const buildWidgetCityField = (
  countryFieldKey: string,
  locationFirstLevel: string,
  enableInternationalCity: boolean,
  label: string
): FormlyFieldConfig => {
  const cityFieldKey = `LocationCity${alphaNanoIdUtil(5)}`;
  const cityField: FormlyFieldConfig = {
    key: cityFieldKey,
    id: cityFieldKey,
    type: EIremboFormlyFieldTypes.input,
    props: {
      nonConfigurable: true,
      dragDisabled: true,
      label,
      disabled: false,
      required: false,
    },
    expressions: {
      hide: 'false',
      'props.hideField': `false`,
    },
  };

  if (
    enableInternationalCity &&
    locationFirstLevel.toUpperCase() === LOCATION_COUNTRY_KEY
  ) {
    cityField.expressions = {
      hide: `model?.${countryFieldKey}?.toLowerCase() === 'rwanda'`,
      'props.required': `model?.${countryFieldKey}?.toLowerCase() !== 'rwanda'`,
    };
  } else {
    cityField.expressions = {
      hide: 'true',
    };
  }

  return cityField;
};

export const buildCascadeField = (
  countryFieldKey: string,
  locationFirstLevel: string,
  locationLastLevel: string,
  label: string = LOCATION_WIDGET_LOCAL_LABEL,
  required = false,
  nonConfigurable = true
): FormlyFieldConfig => {
  const locationWidgetCascadeFieldKey = `LocalLocation${alphaNanoIdUtil(5)}`;
  const configs: ICascadingConfig[] = generateDatasetConfigs(
    locationFirstLevel,
    locationLastLevel,
    label,
    required
  );
  const cascadeField: FormlyFieldConfig = {
    type: EIremboFormlyFieldTypes.customcascadingdropdowns,
    key: locationWidgetCascadeFieldKey,
    id: locationWidgetCascadeFieldKey,
    props: {
      label,
      configs,
      required,
      nonConfigurable: nonConfigurable,
      dragDisabled: true,
    },
  };
  if (locationFirstLevel.toUpperCase() === LOCATION_COUNTRY_KEY) {
    return {
      ...cascadeField,
      expressions: {
        hide: `model?.${countryFieldKey}?.toLowerCase() !== 'rwanda'`,
      },
    };
  }
  return cascadeField;
};

export const generateDatasetConfigs = (
  firstLevel: string,
  lastLevel: string | undefined,
  labelPrefix: string,
  required: boolean
): ICascadingConfig[] => {
  const datasetOptions: string[] = Object.keys(LOCATION_WIDGET_LEVELS);
  const firstLevelIndex: number | undefined =
    datasetOptions.indexOf(firstLevel);

  const randomKey: string = alphaNanoIdUtil(5);

  const datasetConfigs: ICascadingConfig[] = [];
  const datasetFieldKeys: Record<string, string> = {};

  datasetOptions.every((option: string, i: number) => {
    datasetFieldKeys[option] = `${option}`;
    if (i < firstLevelIndex) {
      return true;
    }

    if (
      option.toLowerCase() === firstLevel?.toLowerCase() ||
      option.toLowerCase() ===
        Object.keys(LOCATION_WIDGET_LEVELS)[0].toLowerCase()
    ) {
      datasetConfigs.push(
        createDataset(
          `${datasetFieldKeys[option]}${randomKey}`,
          `${labelPrefix} ${LOCATION_WIDGET_LEVELS[option]}`,
          option,
          required
        )
      );
      return true;
    }

    datasetConfigs.push(
      createDataset(
        `${datasetFieldKeys[option]}${randomKey}`,
        `${labelPrefix} ${LOCATION_WIDGET_LEVELS[option]}`,
        null,
        required,
        `${datasetFieldKeys[datasetOptions[i - 1]]}${randomKey}` ?? null
      )
    );

    if (option.toLowerCase() === lastLevel?.toLowerCase()) {
      return false;
    }
    return true;
  });

  return datasetConfigs;
};

export const createDataset = (
  key: string,
  label: string,
  datasetCode: string | null,
  required: boolean,
  parent: string | null = null
): ICascadingConfig => {
  const url = datasetCode
    ? `/admin/v1/dataset-items/by-dataset-code/${datasetCode}`
    : '/admin/v1/dataset-items/by-details';
  const dataset: ICascadingConfig = {
    key,
    label,
    parent,
    dataset: {
      url,
      dataField: 'data',
      useBaseUrl: true,
    },
    required,
    bindlabel: 'label',
    bindvalue: '',
    placeholder: `Select ${label}`,
  };

  if (!datasetCode) {
    dataset['parentBindKey'] = 'id';
  }

  return dataset;
};
