export enum EServiceChangeRequestStatus {
  IN_QA = 'IN_QA',
  LIVE_READY = 'LIVE_READY',
  REJECTED_REVIEW = 'REJECTED_REVIEW',
  REJECTED_REQUEST = 'REJECTED_REQUEST',
  WAITING_APPROVER = 'WAITING_APPROVER',
  IN_DEVELOPMENT = 'IN_DEVELOPMENT',
  IN_REDEVELOPMENT = 'IN_REDEVELOPMENT',
  LIVE = 'LIVE',
  _UNKNOWN_ = '_UNKNOWN_',
}
