<div
  class="row border border-primary bg-light-blue rounded-1 bg-light p-1 w-100"
  style="margin-left: 1px">
  <button
    *ngFor="let option of options; index as i"
    (click)="onSelectOption(option.key)"
    class="btn btn-sm d-flex flex-grow-1 justify-content-center text-center {{
      options.length > 1 ? 'col-lg-' + buttonColumn : ''
    }}"
    [class.btn-primary]="selectedOption === option.key"
    [class.btn-transparent]="selectedOption !== option.key">
    {{ option.label }}
  </button>
</div>
