<div
  [formGroup]="scenariosForm"
  class="border border-light-gray rounded-1 p-3"
  *ngIf="scenarios">
  <ng-container formArrayName="scenarios">
    <ng-container
      *ngFor="
        let scenarioForm of getScenarios(scenariosForm);
        let i = index;
        trackBy: trackConditionsByIndex
      ">
      <div [formGroupName]="i">
        <div class="border border-dark rounded-1 p-3">
          <div class="d-flex">
            <h6 class="mb-4 font-weight-bold text-primary">
              Scenario {{ i + 1 }}
            </h6>
            <span class="text-muted ms-auto" *ngIf="i !== 0">
              <a style="cursor: pointer" (click)="onRemoveScenario(i)">
                <i class="fa fa-trash"></i>
              </a>
            </span>
          </div>

          <div class="form-row mb-4 form-group">
            <div class="form-group row">
              <label
                class="form-label pt-2 col-sm-2 col-form-label col-form-label-sm"
                for="{{ label }}"
                >Set {{ label }} To<i
                  class="fa-solid fa-circle-info text-muted pl-3"
                  placement="top"
                  ngbTooltip="{{ label }}"></i
              ></label>

              <!--Price Options-->
              <ng-container *ngIf="label === 'Price'">
                <div class="form-group col-md-12 mb-3">
                  <div
                    *ngFor="let priceOption of priceOptions; let index = index"
                    class="form-check">
                    <input
                      class="form-check-input"
                      (click)="priceOptionClicked(priceOption.value, i)"
                      type="radio"
                      name="{{ 'priceOption-' + i }}"
                      id="{{ 'priceOption-' + index + '-' + i }}"
                      value="{{ priceOption.value }}"
                      [checked]="
                        getPriceOptionStatus(scenarioForm, priceOption.value) ||
                        index === 0
                      " />
                    <label
                      class="form-check-label"
                      for="{{ 'priceOption-' + index + '-' + i }}">
                      {{ priceOption.label }}
                    </label>
                  </div>
                </div>
              </ng-container>

              <div
                class="row"
                *ngIf="selectedPriceOption['scenario-' + i] === 'valueFormula'">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-6">
                      <ng-select
                        formControlName="selectedField"
                        class="currency-select ms-0"
                        placeholder="Select a field"
                        (change)="handleSelectedFieldChange($event, i)">
                        <ng-option [value]="'constant'" class="border-0">
                          Constant
                        </ng-option>
                        <ng-option
                          *ngFor="let field of numberOnlyField"
                          [value]="field"
                          class="border-0">
                          {{ field?.props?.label ?? field?.label }}
                        </ng-option>
                      </ng-select>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      scenarioForm.get('selectedField')?.value === 'constant'
                    ">
                    <div class="col-md-6">
                      <input
                        formControlName="constant"
                        type="number"
                        class="form-control mb-2 mt-3"
                        placeholder="Enter constant" />
                    </div>
                    <div class="col-md-4">
                      <button
                        class="btn btn-primary mb-2 mt-3"
                        (click)="
                          addField(
                            null,
                            'constant',
                            scenarioForm.get('constant')?.value,
                            scenarioForm.get('constant')?.value,
                            generateRandomKey(),
                            i
                          )
                        ">
                        Add Constant
                      </button>
                    </div>
                  </div>
                  <div class="row mt-2 mb-4">
                    <label class="mb-2">Arithimetic Operators</label>
                    <div class="col-md-6">
                      <div
                        class="custom-btn-grp"
                        role="group"
                        aria-label="arithimetic Operators">
                        <button
                          *ngFor="let item of operators | keyvalue"
                          type="button"
                          (click)="
                            addField(
                              null,
                              'operator',
                              item.value,
                              null,
                              generateRandomKey(),
                              i
                            )
                          "
                          class="btn btn-secondary">
                          {{ item.value }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="option-wrapper">
                <div
                  class="form-group col-md-6 custom-tags"
                  *ngIf="
                    selectedPriceOption['scenario-' + i] === 'valueFormula'
                  ">
                  <tag-input
                    [@.disabled]="true"
                    [(ngModel)]="formulaObject['scenario-' + i]"
                    [ngModelOptions]="{ standalone: true }"
                    [ripple]="false"
                    (onRemove)="onItemRemoved($event, i)">
                    <ng-template let-item="item" let-index="index">
                      <span (click)="updatedSelectedIndex(index)">
                        {{ item.displayValue }}
                      </span>
                    </ng-template>
                  </tag-input>
                </div>

                <div
                  class="form-group col-md-6"
                  *ngIf="selectedPriceOption['scenario-' + i] === 'value'">
                  <input
                    type="{{ isPricingNumberType ? 'number' : 'text' }}"
                    class="form-control mb-2"
                    placeholder="Input {{ label | lowercase }}"
                    formControlName="value" />
                </div>
                <div
                  class="form-group col-md-6"
                  *ngIf="
                    selectedPriceOption['scenario-' + i] === 'valueAttribute'
                  ">
                  <ng-select
                    class="currency-select"
                    formControlName="valueAttribute"
                    (change)="onFieldChange($event)"
                    placeholder="Field"
                    #fieldSelector>
                    <ng-option
                      *ngFor="let field of formFieldsValues"
                      [value]="field?.key"
                      class="border-0">
                      {{ field?.props?.label ?? field?.label }}
                    </ng-option>
                  </ng-select>
                </div>
                <div class="form-group col-md-3" *ngIf="addCurrencyProperty">
                  <ng-select
                    formControlName="currency"
                    [items]="serviceCurrenciesBuffer"
                    [virtualScroll]="true"
                    [loading]="isLoadingServiceCurrencies"
                    bindLabel="code"
                    bindValue="code"
                    placeholder="Choose the service currency"
                    (scrollToEnd)="fetchServiceCurrencies()"
                    (search)="onSearchServiceCurrency($event)"
                    #select>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm">
                      <strong>{{ item.code }}</strong>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
          </div>
          <div
            formArrayName="conditions"
            class="border border-light-gray bg-light rounded-1 p-3">
            <ng-container
              *ngFor="
                let condition of getConditions(scenarioForm);
                let index = index
              ">
              <div [formGroupName]="index">
                <irembogov-configure-service-dynamic-pricing-form
                  [index]="index"
                  [conditionsForm]="scenarioForm"
                  [formGroup]="condition"
                  [formFields]="formFields"
                  (changeCondition)="modifyCondition($event)"
                  (removeCondition)="removeCondition($event)">
                </irembogov-configure-service-dynamic-pricing-form>
              </div>
            </ng-container>
            <button
              (click)="onAddCondition(scenarioForm)"
              class="mt-2 btn btn-sm btn-outline-primary"
              [attr.disabled]="scenarioForm.status === 'INVALID' ? '' : null">
              + Add Condition
            </button>
          </div>
        </div>
        <br />
      </div>
    </ng-container>
    <button
      class="mt-2 btn btn-primary mb-3"
      (click)="onAddScenario()"
      [attr.disabled]="
        scenariosForm.status === 'INVALID' &&
        ['value', 'valueAttribute'].includes(lastPriceOption)
          ? ''
          : null
      ">
      + Add Scenario
    </button>
  </ng-container>
</div>
