import { IServiceCurrency } from './../../../../core/models/service-currency.model';
import { ServiceChangeRequestService } from './../../../../core/services/service-change-request.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  IFilterAndPaginationFieldsBase,
  ToastService,
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  updateApiFilter,
} from '@irembo-andela/irembogov3-common';
import { IServicePaymentMerchant } from './../../../../core/models/http/service-payment-mercant.model';
import { BehaviorSubject, finalize, of, switchMap } from 'rxjs';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  name: string | null;
  keywords: string | null;
}
@Component({
  selector: 'irembogov-configure-service-fixed-price-handler',
  templateUrl: './configure-service-fixed-price-handler.component.html',
  styleUrls: ['./configure-service-fixed-price-handler.component.scss'],
})
export class ConfigureServiceFixedPriceHandlerComponent implements OnInit {
  @Input() defaultData?: any;
  @Input() label = '';
  @Input() isPricingNumberType = false;
  @Input() paymentMerchantCase = false;
  @Input() addCurrencyProperty = false;
  @Output() formChange: EventEmitter<Record<string, any>> = new EventEmitter<
    Record<string, any>
  >();

  isLoadingServiceCurrencies = false;
  isLoadingServicePaymentMerchants = false;
  serviceCurrencies: IServiceCurrency[] = [];
  serviceCurrenciesBuffer: IServiceCurrency[] = [];
  servicePaymentMerchants: IServicePaymentMerchant[] = [];
  servicePaymentMerchantsSearch: IServicePaymentMerchant[] = [];
  serviceCurrenciesBufferCollection = 0;
  paymentMerchantsCollectionSize = 0;

  _filterObject: BehaviorSubject<IFilterFields> =
    new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 8,
      name: '',
      keywords: '',
    });
  fixedPricingForm!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.fixedPricingForm = this.initFixedPricingForm(this.defaultData);
    this.fixedPricingForm.valueChanges.subscribe(values => {
      this.formChange.emit({ ...values, dynamicConditions: {} });
    });

    this._filterObject
      .asObservable()
      .pipe(
        switchMap((filter: IFilterFields) => {
          const queryParams: string =
            FilterFieldsToQueryParamsBuilder<IFilterFields>(filter);
          this.getServiceCurrenciesByPage(queryParams, filter.page ?? 0);
          this.getServicePaymentMerchants(queryParams, filter.page ?? 0);
          return of();
        })
      )
      .subscribe();
  }

  onFetchMoreServiceCurrencies() {
    const currentPage = this._filterObject.getValue().page ?? 0;
    const currentSize = this._filterObject.getValue().size ?? 8;
    if (
      (currentPage + 1) * currentSize <
      this.serviceCurrenciesBufferCollection
    ) {
      updateApiFilter<IFilterFields>(this._filterObject, {
        page: currentPage + 1,
      });
    }
  }

  onSearchServiceCurrency(query: { term: string }) {
    updateApiFilter<IFilterFields>(this._filterObject, {
      page: 0,
      name: query?.term,
    });
  }

  getServiceCurrenciesByPage(queryString: string, page: number) {
    this.isLoadingServiceCurrencies = true;
    this.serviceChangeRequestService
      .getServiceCurrencies(queryString)
      .pipe(finalize(() => (this.isLoadingServiceCurrencies = false)))
      .subscribe({
        next: response => {
          this.serviceCurrenciesBuffer =
            page > 0
              ? this.serviceCurrenciesBuffer?.concat(response?.data?.content)
              : response?.data?.content;
          this.serviceCurrenciesBufferCollection =
            response.data.totalPages * response.data.size;
        },
        error: err => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Error getting services'
          );
          this.toastService.show({ body: errorMessage, type: 'error' });
        },
      });
  }

  getServicePaymentMerchants(queryString: string, page: number) {
    this.isLoadingServicePaymentMerchants = true;
    this.serviceChangeRequestService
      .getPaymentMerchants(queryString)
      .pipe(finalize(() => (this.isLoadingServicePaymentMerchants = false)))
      .subscribe({
        next: response => {
          this.servicePaymentMerchantsSearch =
            page > 0
              ? this.servicePaymentMerchantsSearch?.concat(
                  response?.data?.content
                )
              : response?.data?.content;
          this.paymentMerchantsCollectionSize =
            response.data.totalPages * response.data.size;
        },
        error: err => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Error getting payment merchants'
          );
          this.toastService.show({ body: errorMessage, type: 'error' });
        },
      });
  }

  private initFixedPricingForm(fixedPricing: any) {
    const recordForm: FormGroup = this.fb.group({
      baseValue: [
        fixedPricing?.baseValue || '',
        Validators.compose(this.defaultValidator()),
      ],
    });

    if (this.addCurrencyProperty) {
      recordForm.addControl(
        'baseCurrency',
        new FormControl(
          fixedPricing?.baseCurrency || '',
          Validators.compose(this.defaultValidator())
        )
      );
    }

    return recordForm;
  }

  public defaultValidator() {
    return [Validators.required];
  }
}
