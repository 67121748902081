import { Injector } from '@angular/core';
import { FormBuilderService } from '../services/form-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { EIremboFormlyFieldTypes } from '@irembo-andela/irembogov3-common';

export class FormBuilderManipulatorClass {
  formBuilderService!: FormBuilderService;
  constructor(private injector: Injector) {
    this.formBuilderService = this.injector.get(FormBuilderService);
  }

  addNewSectionToFields(newSection: FormlyFieldConfig) {
    const fields: FormlyFieldConfig[] =
      this.formBuilderService.formlyFieldsSubject$.getValue().fields;
    if (fields[0]?.fieldGroup) {
      fields[0].fieldGroup = [...fields[0].fieldGroup, newSection];
    } else {
      fields[0] = {
        type: EIremboFormlyFieldTypes.sections,
        fieldGroup: [newSection],
      };
    }
    this.formBuilderService.formlyFieldsSubject$.next({
      fields,
      forceReload: true,
    });
    this.formBuilderService.configureFieldAction$.next(newSection);
  }

  moveSectionInSectionsList(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    this.formBuilderService.refreshFormlyForm();
  }

  addNewBlockToFields(newBlock: FormlyFieldConfig, sectionIndex: number) {
    const fields: FormlyFieldConfig[] =
      this.formBuilderService.formlyFieldsSubject$.getValue().fields;
    if (
      !(fields[0]?.fieldGroup && fields[0].fieldGroup[sectionIndex]?.fieldGroup)
    )
      return;

    const sectionBlocks: FormlyFieldConfig[] =
      fields[0].fieldGroup[sectionIndex].fieldGroup ?? [];

    fields[0].fieldGroup[sectionIndex].fieldGroup = [
      ...sectionBlocks,
      newBlock,
    ];
    this.formBuilderService.configureFieldAction$.next(newBlock);
    this.formBuilderService.formlyFieldsSubject$.next({
      fields,
      forceReload: true,
    });
  }

  moveBlock(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    this.formBuilderService.refreshFormlyForm();
  }

  updateConfiguredSectionField(configuredField: FormlyFieldConfig) {
    const fields: FormlyFieldConfig[] =
      this.formBuilderService.formlyFieldsSubject$.getValue().fields;
    if (!fields[0]?.fieldGroup) return;
    const availableSections: FormlyFieldConfig[] = fields[0].fieldGroup;

    [...fields[0].fieldGroup].some(
      (section: FormlyFieldConfig, index: number) => {
        if (section.key === configuredField.key) {
          availableSections[index] = { ...configuredField };
          return true;
        }

        return false;
      }
    );

    fields[0].fieldGroup = [...availableSections];
    this.formBuilderService.formlyFieldsSubject$.next({
      fields,
      forceReload: true,
    });
    this.formBuilderService.configureFieldAction$.next(null);
  }

  updateConfiguredBlockField(configuredField: FormlyFieldConfig) {
    if (!configuredField?.parent?.fieldGroup) return;
    const parentSectionFieldGroup: FormlyFieldConfig[] =
      configuredField.parent.fieldGroup;

    [...parentSectionFieldGroup].some(
      (block: FormlyFieldConfig, index: number) => {
        if (block.key === configuredField.key) {
          parentSectionFieldGroup[index] = { ...configuredField };
          return true;
        }

        return false;
      }
    );

    configuredField.parent.fieldGroup = [...parentSectionFieldGroup];
    this.updateConfiguredSectionField(configuredField.parent);
  }

  updateConfiguredFieldItem(configuredFieldItem: FormlyFieldConfig) {
    if (!configuredFieldItem?.parent?.fieldGroup) return;
    const parentBlock: FormlyFieldConfig[] =
      configuredFieldItem.parent.fieldGroup;

    [...parentBlock].some((field: FormlyFieldConfig, index: number) => {
      if (
        field.key === configuredFieldItem.key ||
        field.key === configuredFieldItem.props?.['oldKey']
      ) {
        delete configuredFieldItem.props?.['oldKey'];
        parentBlock[index] = { ...configuredFieldItem };
        return true;
      }

      return false;
    });

    configuredFieldItem.parent.fieldGroup = [...parentBlock];
    this.updateConfiguredBlockField(configuredFieldItem.parent);
  }

  replaceConfiguredFieldItemWithFields(fieldItems: FormlyFieldConfig[]) {
    const configuredFieldItem: FormlyFieldConfig | null =
      this.formBuilderService.configureFieldAction$.getValue();

    if (!configuredFieldItem?.parent?.fieldGroup || fieldItems.length < 1) {
      return;
    }
    const parentBlock: FormlyFieldConfig[] =
      configuredFieldItem.parent.fieldGroup;

    [...parentBlock].some((field: FormlyFieldConfig, index: number) => {
      if (
        field.key === configuredFieldItem.key ||
        field.key === configuredFieldItem.props?.['oldKey']
      ) {
        delete configuredFieldItem.props?.['oldKey'];
        parentBlock.splice(index, 1, ...fieldItems);
        return true;
      }

      return false;
    });

    configuredFieldItem.parent.fieldGroup = [...parentBlock];
    this.updateConfiguredBlockField(configuredFieldItem.parent);
  }
}
