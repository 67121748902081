import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  ExtractHttpErrorResponseCodeAndMessage,
  IHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { finalize, tap } from 'rxjs';
import { IServiceChangeRequest } from '../../../core/models/service-change-request.model';
import { ServiceChangeRequestService } from '../../../core/services/service-change-request.service';
import { IServiceConfigStep } from '../../../core/models/service-change-request-config-steps-forms.model';
import { CdkStepper, StepperSelectionEvent } from '@angular/cdk/stepper';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DefaultEmptyFormData } from '../../../core/models/form-builder-fields-data.model';
import { FormBuilderService } from '../../../core/services/form-builder.service';

@Component({
  selector: 'irembogov-configure-service-change-request',
  templateUrl: './configure-service-change-request.component.html',
  providers: [{ provide: CdkStepper }],
})
export class ConfigureServiceChangeRequestComponent
  implements OnInit, OnDestroy
{
  isLoadingChangeRequest = false;
  loadRequestDetailsFailed = false;
  isLoadingDetails = false;
  loadFailedErrorCodeMessage!: IHttpErrorResponseCodeAndMessage;
  requestId!: string;

  isSubmittingForQA = false;

  showFormPreview = false;

  @ViewChild('overview', { static: true }) overviewTemplate!: TemplateRef<any>;
  @ViewChild('formbuilder', { static: false })
  formbuilderTemplate!: TemplateRef<any>;
  @ViewChild('pricing', { static: false }) pricingTemplate!: TemplateRef<any>;
  @ViewChild('officerform', { static: false })
  officerformTemplate!: TemplateRef<any>;
  @ViewChild('workflow', { static: false }) workflowTemplate!: TemplateRef<any>;
  @ViewChild('translation', { static: false })
  translationTemplate!: TemplateRef<any>;

  configSteps: IServiceConfigStep[] = [
    {
      label: 'Overview',
      key: 'overview',
      icon: 'fas fa-clock',
      isComplete: false,
      hasError: false,
    },
    {
      label: 'Form Builder',
      key: 'formbuilder',
      icon: 'fas fa-border-none',
      isComplete: false,
      hasError: false,
    },
    {
      label: 'Pricing',
      key: 'pricing',
      icon: 'fas fa-money-bill-1-wave',
      isComplete: false,
      hasError: false,
    },
    {
      label: 'Workflow',
      key: 'workflow',
      icon: 'fas fa-diagram-project',
      isComplete: false,
      hasError: false,
    },
    {
      label: 'Officer Form',
      key: 'officerform',
      icon: 'fas fa-file',
      isComplete: false,
      hasError: false,
    },
    {
      label: 'Translation',
      key: 'translation',
      icon: 'fas fa-language',
      isComplete: false,
      hasError: false,
    },
  ];

  currentConfigStep = 0;

  changeRequest!: IServiceChangeRequest;

  changeRequestJsonFormData!: Record<string, FormlyFieldConfig[]>;

  changeRequestJsonWorkflowData!: Record<string, unknown>;

  changeRequestTranslationsData!: Record<string, Record<string, unknown>>;

  serviceChangeRequestFormFields!: any;

  serviceChangeRequestPricingData!: Record<string, unknown>;

  readyForSaveSubmitForReview = false;

  changeRequestThirdPartyFormData!: Record<string, unknown>;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private serviceChangeRequestService: ServiceChangeRequestService,
    private formBuilderService: FormBuilderService,
    public toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const paramRequestId: string | null = params.get('requestId');
      if (paramRequestId) {
        this.requestId = paramRequestId;
        this.isLoadingChangeRequest = true;
        this.getChangeRequest();
      }
    });
    this.formBuilderService.startFormBuilderConfigurationSubjects();
  }

  getChangeRequestPricingData() {
    this.isLoadingDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestPricingDetails(this.requestId)
      .pipe(finalize(() => (this.isLoadingDetails = false)))
      .subscribe({
        next: (response: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.serviceChangeRequestPricingData = response.data;
        },
        error: () => {
          this.serviceChangeRequestPricingData = {};
        },
      });
  }

  getChangeRequest() {
    this.isLoadingDetails = true;
    this.serviceChangeRequestService
      .getAssignedServiceChangeRequestById(this.requestId)
      .pipe(
        finalize(() => {
          this.isLoadingChangeRequest = false;
          this.isLoadingDetails = false;
        }),
        tap(() => {
          this.getChangeRequestFormDefinition();
          this.getChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime();
          this.getChangeRequestPricingData();
          this.getChangeRequestTranslationsData();
          this.getChangeRequestThirdPartyFormDefination();
        })
      )
      .subscribe({
        next: (response: IHttpSingleDataResponse<IServiceChangeRequest>) => {
          this.changeRequest = response.data;
        },
        error: (error: HttpErrorResponse) => {
          this.loadRequestDetailsFailed = true;
          this.loadFailedErrorCodeMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              `Failed to load Service Change Request: #${this.requestId}`
            );
        },
      });
  }

  getChangeRequestFormDefinition() {
    this.isLoadingDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestFormDefinition(this.requestId)
      .pipe(
        finalize(() => {
          this.isLoadingDetails = false;
        })
      )
      .subscribe({
        next: (
          response: IHttpSingleDataResponse<Record<string, FormlyFieldConfig[]>>
        ) => {
          this.changeRequestJsonFormData = response.data;
          this.serviceChangeRequestFormFields = response.data;
          this.formBuilderService.loadJsonFormDataForPreview(
            this.changeRequestJsonFormData
          );
        },
        error: () => {
          this.changeRequestJsonFormData = DefaultEmptyFormData;
        },
      });
  }

  getChangeRequestWorkflowDefinition() {
    this.isLoadingDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestWorkflowDefinition(this.requestId)
      .pipe(
        finalize(() => {
          this.isLoadingDetails = false;
        })
      )
      .subscribe({
        next: (response: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.changeRequestJsonWorkflowData = response.data;
        },
        error: () => {
          this.changeRequestJsonWorkflowData = {};
        },
      });
  }

  getChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime() {
    this.isLoadingDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime(
        this.requestId
      )
      .pipe(
        finalize(() => {
          this.isLoadingDetails = false;
        })
      )
      .subscribe({
        next: (response: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.changeRequestJsonWorkflowData = response.data;
        },
        error: () => {
          this.changeRequestJsonWorkflowData = {};
        },
      });
  }

  getChangeRequestThirdPartyFormDefination() {
    this.isLoadingDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestThirdPartyFormDefination(this.requestId)
      .pipe(
        finalize(() => {
          this.isLoadingDetails = false;
        })
      )
      .subscribe({
        next: (response: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.changeRequestThirdPartyFormData = response.data;
        },
        error: () => {
          this.changeRequestThirdPartyFormData = {};
        },
      });
  }

  getChangeRequestTranslationsData() {
    this.isLoadingDetails = true;
    this.serviceChangeRequestService
      .getServiceChangeRequestTranslation(this.requestId)
      .pipe(finalize(() => (this.isLoadingDetails = false)))
      .subscribe({
        next: (
          response: IHttpSingleDataResponse<
            Record<string, Record<string, unknown>>
          >
        ) => {
          this.changeRequestTranslationsData = response.data;
        },
        error: () => {
          this.changeRequestTranslationsData = {};
        },
      });
  }

  getCurrentTemplate(index: number): TemplateRef<ElementRef> {
    if (index > 0 && index <= this.configSteps.length) {
      return this[
        `${this.configSteps[index].key}Template` as keyof ConfigureServiceChangeRequestComponent
      ] as TemplateRef<ElementRef>;
    }
    return this.overviewTemplate;
  }

  onStepFormSubmitted(isValid: boolean) {
    this.configSteps[this.currentConfigStep].isComplete = isValid;
    this.handleNextStepValidity(isValid);
  }

  onWorkflowStepSubmitted(data: Record<string, unknown> | undefined) {
    this.configSteps[this.currentConfigStep].isComplete = data !== undefined;
    this.handleNextStepValidity(data !== undefined);
    this.changeRequestJsonWorkflowData = data ?? {};
  }

  handleNextStepValidity(stepIsValid: boolean): void {
    if (stepIsValid) {
      this.getChangeRequest();
      this.goToNextStep();
    }
  }

  goToNextStep(): void {
    if (this.currentConfigStep < this.configSteps.length - 1) {
      this.currentConfigStep++;
      return;
    }
    if (this.currentConfigStep >= this.configSteps.length - 1) {
      this.readyForSaveSubmitForReview = true;
    }
  }

  toggleFormPreview(event: boolean): void {
    this.showFormPreview = event;
  }

  OnStepperChange(event: StepperSelectionEvent) {
    this.currentConfigStep = event.selectedIndex;
  }

  navigateToPage(route: string[]): void {
    this.router
      .navigate(route)
      .then()
      .catch(() => {
        return;
      });
  }

  OnSubmitToQA() {
    this.isSubmittingForQA = true;
    this.serviceChangeRequestService
      .submitChangeRequestToReviewer(this.requestId)
      .pipe(finalize(() => (this.isSubmittingForQA = false)))
      .subscribe({
        next: (res: IHttpSingleDataResponse<IServiceChangeRequest>) => {
          this.toastService.show({
            body: `${res.responseMessage}: Change request submitted for review.`,
            type: 'success',
          });
          this.navigateToPage(['/services']);
        },
        error: (error: HttpErrorResponse) => {
          const errorCodeAndMessage: IHttpErrorResponseCodeAndMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              'Failed to submit request for review.'
            );

          this.toastService.show({
            body: errorCodeAndMessage.message,
            type: 'error',
          });
        },
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  viewRequirementDocs(event: boolean) {
    return this.openFileFromServiceRequirementDocument(this.changeRequest);
  }

  openFileFromServiceRequirementDocument(changeRequest: IServiceChangeRequest) {
    if (!changeRequest.id) {
      return;
    }
    const file = changeRequest.serviceRequirementDocument.file;
    const byteArray = new Uint8Array(
      window
        .atob(file)
        .split('')
        .map(char => char.charCodeAt(0))
    );
    const blob = new Blob([byteArray], {
      type: changeRequest.serviceRequirementDocument.contentType,
    });
    const fileUrl = URL.createObjectURL(blob);
    window.open(fileUrl, '_blank');
  }

  ngOnDestroy(): void {
    this.formBuilderService.completeFormBuilderConfigurationSubjects();
  }
}
