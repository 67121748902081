import { EIremboFormlyFieldTypes } from '@irembo-andela/irembogov3-common';

export enum EFormBuilderDisplayConditionComparator {
  AND = 'AND',
  OR = 'OR',
}

export enum EFormBuilderDisplayFieldComparator {
  IS_ABOVE = 'is Above',
  IS_BELOW = 'is Below',
  IS_EQUAL_TO = 'is Equal To',
  IS_NOT_EQUAL_TO = 'is Not Equal To',
  IS = 'is',
}

export enum EFormBuilderDisplayComparatorActions {
  SHOW = 'show',
  HIDE = 'hide',
}

export const FormBuilderFieldsWithOptions: EIremboFormlyFieldTypes[] = [
  EIremboFormlyFieldTypes.customdropdown,
  EIremboFormlyFieldTypes.radio,
  EIremboFormlyFieldTypes.checkbox,
  EIremboFormlyFieldTypes.multicheckbox,
];

export const FormBuilderFieldsWithDataSet: EIremboFormlyFieldTypes[] = [
  EIremboFormlyFieldTypes.customdropdownpaginated,
];
