<ng-container *ngIf="configuredField">
  <div class="form-field-configurator-settings py-2">
    <irembogov-button-group
      class="w-100"
      [itemList]="configurationTabs"
      [selectedItem]="selectedConfigurationTab"
      (itemChange)="selectedConfigurationTab = $event"
      [fullWidth]="true"
      [iconAboveLabel]="true"
      uiStyle="flat-tabs"
      theme="irembo-blue-theme"></irembogov-button-group>

    <div class="p-3">
      <ng-container
        *ngIf="
          selectedConfigurationTab === EConfigurationTabTemplateKeys.Settings
        ">
        <form [formGroup]="settingsForm" class="field-config-settings-form">
          <formly-form
            [form]="settingsForm"
            [options]="formlyFormOptions"
            [model]="settingsModel"
            [fields]="
              fieldConfigurationConfig.settings.formlyForm
            "></formly-form>

          <div class="pb-4">
            <button
              [disabled]="settingsForm.invalid"
              class="btn btn-primary bt-sm mt-1"
              type="button"
              (click)="saveFieldConfigurationSettings()">
              Save
            </button>
          </div>
        </form>
      </ng-container>

      <ng-container
        *ngIf="
          selectedConfigurationTab === EConfigurationTabTemplateKeys.Validation
        ">
        <form [formGroup]="validationForm" class="field-config-validation-form">
          <formly-form
            [form]="validationForm"
            [options]="formlyFormOptions"
            [model]="validationModel"
            [fields]="
              fieldConfigurationConfig.validations?.formlyForm || []
            "></formly-form>

          <div class="pb-4">
            <button
              [disabled]="validationForm.invalid"
              class="btn btn-primary bt-sm mt-1"
              type="button"
              (click)="saveFieldConfigurationValidation()">
              Save
            </button>
          </div>
        </form>
      </ng-container>

      <ng-container
        *ngIf="
          selectedConfigurationTab === EConfigurationTabTemplateKeys.Display
        ">
        <form [formGroup]="displayForm" class="field-config-display-form">
          <formly-form
            [form]="displayForm"
            [options]="formlyFormOptions"
            [model]="displayModel"
            [fields]="
              fieldConfigurationConfig.display?.formlyForm || []
            "></formly-form>

          <div class="pb-4">
            <button
              [disabled]="displayForm.invalid"
              class="btn btn-primary bt-sm mt-1"
              type="button"
              (click)="saveFieldConfigurationDisplay()">
              Save
            </button>
          </div>
        </form>
      </ng-container>
    </div>
  </div>
</ng-container>
