<div
  class="irembogov-reviewer-issues-entry-form-widget w-100 card bg-white rounded border-0 px-2"
  *ngIf="checkIfCanReviewChangeRequests()">
  <div class="card-body">
    <form [formGroup]="issueForm" (submit)="onIssueEntrySave()">
      <div class="mb-3">
        <div class="form-group">
          <label for="title" class="form-label"> Issue Title </label>
          <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              id="title"
              placeholder="Issue Title"
              formControlName="title"
              #title />
            <div
              class="form-error w-100 small text-danger"
              *ngIf="
                issueForm.controls['title'].touched &&
                !issueForm.controls['title'].valid
              ">
              Title is required.
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="description" class="form-label">
            Issue Description
          </label>
          <div class="input-group mb-3">
            <textarea
              class="form-control"
              id="description"
              rows="3"
              name="description"
              formControlName="description"
              placeholder="Issue Description"
              #description></textarea>
            <div
              class="form-error w-100 small text-danger"
              *ngIf="
                issueForm.controls['description'].touched &&
                !issueForm.controls['description'].valid
              ">
              Description is required.
            </div>
          </div>
        </div>
        <div class="mt-1">
          <button
            (click)="saveNewIssue()"
            class="btn btn-sm btn-primary px-3"
            type="submit"
            [disabled]="issueForm.invalid">
            Save
            <div
              *ngIf="isSavingIssue"
              class="spinner-border spinner-border-sm mr-2"
              role="status"></div>
          </button>
        </div>
      </div>
    </form>

    <div class="issues-list">
      <div
        class="issues-tab-buttons small rounded p-1 mb-3 border border-primary">
        <button
          type="button"
          *ngFor="let status of statusFilterOptions; let last = last"
          class="btn btn-sm px-2 w-50 border-0"
          [class]="{
            'btn-primary': activeFetchByStatus === status.key,
            'text-muted': activeFetchByStatus !== status.key,
            'border-end': !last
          }"
          (click)="updateToGetIssuesByStatus(status.key)">
          {{ status.label }}
        </button>
      </div>

      <ng-container>
        <irembogov-irembo-section-message
          type="info"
          *ngIf="!isLoadingReviewIssuesList && collectionSize < 1"
          class="w-80 mx-auto mt-5 d-block"
          title=""
          message="No {{
            activeFetchByStatus | reviewIssueStatusToLabel | lowercase
          }} Review Issues."
          [dismissible]="false">
        </irembogov-irembo-section-message>
        <ng-container>
          <div
            body
            class="card border-0 border-bottom issue-item {{
              issue.status === EServiceChangeRequestReviewStatus.PENDING
                ? 'lightblue_bg'
                : ''
            }}"
            *ngFor="let issue of reviewIssuesList; let index = index">
            <div class="card-body p-2">
              <small
                *ngIf="issue.isProcessing"
                class="spinner-border text-muted spinner-border-sm"
                role="status"></small>
              <h6 class="d-flex w-100 flex-column">
                <div
                  class="d-flex w-100 justify-content-end font-weight-700 align-items-center">
                  <strong class="me-auto overflow-hidden">{{
                    issue.title
                  }}</strong>
                  <span class="ms-1 text-nowrap">{{
                    issue.dateCreated | reviewIssueCreatedDatePipe
                  }}</span>
                  <div class="ms-1 text-danger delete-issue-icon">
                    <div ngbDropdown container="body">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-sm border-0 issue-dropdown-button"
                        ngbDropdownToggle>
                        <i class="fa-solid fa-ellipsis"></i>
                      </button>
                      <div ngbDropdownMenu>
                        <ng-container
                          *ngIf="
                            issue.status ===
                            EServiceChangeRequestReviewStatus.NEW
                          ">
                          <button ngbDropdownItem (click)="editNewIssue(index)">
                            <small>
                              <i
                                class="fa-regular fa-pen-to-square small me-1 text-warning"></i>
                              Edit
                            </small>
                          </button>

                          <button
                            ngbDropdownItem
                            (click)="deleteNewIssue(issue, index)">
                            <small>
                              <i
                                class="fa-regular fa-trash-can small me-1 text-danger"></i>
                              Delete
                            </small>
                          </button>
                        </ng-container>

                        <button
                          ngbDropdownItem
                          *ngIf="
                            issue.status ===
                            EServiceChangeRequestReviewStatus.PENDING
                          "
                          (click)="resolvePendingIssue(issue, index)">
                          <small>
                            <i
                              class="fa-regular fa-circle-check small me-1 text-success"></i>
                            Resolve
                          </small>
                        </button>

                        <button
                          ngbDropdownItem
                          *ngIf="
                            issue.status ===
                            EServiceChangeRequestReviewStatus.RESOLVED
                          "
                          (click)="unResolveResolvedIssue(issue, index)">
                          <small>
                            <i
                              class="fa-regular fa-circle-xmark small me-1 text-primary"></i>
                            Unresolve
                          </small>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <irembogov-reviewer-issue-status-badge-widget
                  [requestStatus]="
                    issue.status
                  "></irembogov-reviewer-issue-status-badge-widget>
              </h6>
              <div class="w-100 pb-2 overflow-hidden">
                {{ issue.description }}
              </div>
            </div>
            <div
              class="issue-edit-placeholder"
              *ngIf="
                issue.isProcessing &&
                issue.status === EServiceChangeRequestReviewStatus.NEW
              ">
              <irembogov-review-issues-edit-form-widget
                [issue]="issue"
                [index]="index"
                (editIssueChange)="updateNewIssue($event, index)"
                (closeForm)="
                  closeEditForm($event, index)
                "></irembogov-review-issues-edit-form-widget>
            </div>
          </div>
          <div
            class="small pointer-cursor text-center text-muted mt-2"
            *ngIf="
              !isLoadingReviewIssuesList &&
              reviewIssuesList.length < collectionSize
            "
            (click)="loadMoreIsues()">
            Load more
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="isLoadingReviewIssuesList" class="w-100 text-center">
        <div
          class="spinner-border spinner-border-sm mx-auto text-primary"
          role="status"></div>
      </div>
    </div>
    <div class="py-3">
      <button class="btn btn-primary w-100" (click)="goToNext.emit(true)">
        Next
      </button>
    </div>
  </div>
</div>
