import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'reviewIssueCreatedDatePipe' })
export class ReviewIssueCreatedDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(createdDate: string | undefined): string | null {
    if (!createdDate) return '';
    const hoursAgo: number =
      (Date.now() - Date.parse(createdDate)) / (1000 * 60 * 60);
    return hoursAgo >= 24
      ? this.datePipe.transform(createdDate, 'MMM d')
      : this.datePipe.transform(createdDate, 'h:mm a');
  }
}
