import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import {
  IUiAlertContent,
  TUiAlertTypes,
  ToastService,
  ExtractHttpErrorResponseCodeAndMessage,
  IHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
  IIrembogovBasicLabelKeyPair,
} from '@irembo-andela/irembogov3-common';
import {
  IError,
  JsonEditorComponent,
  JsonEditorOptions,
} from '@maaxgr/ang-jsoneditor';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { IWorkflowTemplate } from '../../../../core/models/workflow-templates.model';
import {
  IFieldError,
  IServiceConfigStep,
} from '../../../../core/models/service-change-request-config-steps-forms.model';
import { ExtractHttpErrorFieldErrorsUtil } from '../../../../core/utils/http-field-error-extractor.utils';
import { EServiceChangeRequestSection } from '../../../../core/models/service-change-request-sections.enum';
import { EServiceChangeRequestStatus } from './../../../../core/models/service-change-request-status.enum';
import { EServiceChangeRequestToggleHeaders } from './../../../../core/models/service-change-request-toggle-header-section.enum';
import { IServiceChangeRequestWorkflow } from './../../../../core/models/service-change-request-workflow.model';
import { QuillEditorComponent } from 'ngx-quill';

interface IWorkflowNextStep {
  title: string;
  description: string;
}
@Component({
  selector: 'irembogov-configuration-workflow-json-editor-form',
  templateUrl: './configuration-workflow-json-editor-form.component.html',
  styleUrls: ['./configuration-workflow-json-editor-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationWorkflowJsonEditorFormComponent
  implements AfterViewInit, OnChanges, OnInit
{
  @Input() changeRequestJsonWorkflowData!: Record<string, unknown>;
  @Input() changeRequest!: IServiceChangeRequest;
  @Output() jsonWorkflowFormSubmitted: EventEmitter<
    Record<string, unknown> | undefined
  > = new EventEmitter<Record<string, unknown> | undefined>();

  @ViewChild('errorMessageElement') errorMessageElement!: ElementRef;
  @ViewChild(JsonEditorComponent, { static: false })
  jsonEditor!: JsonEditorComponent;

  showConfigWorkflowErrorMessage = false;
  jsonEditorValidationErrors: ValidationErrors[] = [];
  EServiceChangeRequestSection = EServiceChangeRequestSection;
  EServiceChangeRequestStatus = EServiceChangeRequestStatus;
  EServiceChangeRequestToggleHeaders = EServiceChangeRequestToggleHeaders;
  selectedOption: EServiceChangeRequestToggleHeaders =
    EServiceChangeRequestToggleHeaders.WIZARD;
  changeRequestStatus!: string;

  headerOptions: IIrembogovBasicLabelKeyPair<EServiceChangeRequestToggleHeaders>[] =
    [
      { label: 'Wizard', key: EServiceChangeRequestToggleHeaders.WIZARD },
      {
        label: 'Code Editor',
        key: EServiceChangeRequestToggleHeaders.CODE_EDITOR,
      },
    ];

  workflowSteps: IServiceConfigStep[] = [
    {
      icon: 'fa-diagram-project fas',
      label: 'Workflow Config',
      subLabel: 'Workflow',
      key: 'config',
    },
    {
      icon: 'fa-clock fas',
      label: 'SLA',
      subLabel: 'SLA of status changes',
      key: 'sla',
    },
    {
      icon: 'fa-comment fas',
      label: 'Notification',
      subLabel: 'Inform users of status changes',
      key: 'notification',
    },
    {
      icon: 'fa-building fas',
      label: 'Office Assignment',
      subLabel: 'Define offices to process the application',
      key: 'office-assignments',
    },
    {
      icon: 'fa-gears fas',
      label: 'Integrations',
      subLabel: 'Define 3rd party integrations required with your service',
      key: 'integrations',
    },
    {
      icon: 'fa-file fas',
      label: 'Documents',
      subLabel: 'Create certificate using template bulder',
      key: 'documents',
    },
  ];

  currentConfigStep = 0;

  public editorOptions: JsonEditorOptions;
  initialData: Record<string, unknown> = this.changeRequestJsonWorkflowData;

  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };
  isLoading = false;
  nextStepsForm!: FormGroup;
  hide = false;
  backgroundColor = '#ffffff';

  @ViewChild(QuillEditorComponent, { static: true })
  quillEditor!: QuillEditorComponent;
  slaForm = this.fb.group({
    expectedProcessingDays: [0, [Validators.required, Validators.min(1)]],
  });

  constructor(
    private toastService: ToastService,
    private serviceChangeRequestService: ServiceChangeRequestService,
    private fb: FormBuilder
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code'];

    this.editorOptions.onValidate = (json: unknown) =>
      this.checkJsonFormDataIsNotEmpty(json as Record<string, unknown>);
    this.createNextStepsForm([]);
  }

  ngOnInit() {
    this.nextStepsForm.valueChanges.subscribe(data => {
      this.changeRequestJsonWorkflowData['nextSteps'] = data.nextSteps;
      if (this.changeRequestJsonWorkflowData) {
        this.initialData = this.changeRequestJsonWorkflowData;
        this.jsonEditor.update(this.changeRequestJsonWorkflowData as any);
      }
    });

    this.slaForm.valueChanges.subscribe(sla => {
      this.changeRequestJsonWorkflowData['expectedProcessingDays'] =
        sla.expectedProcessingDays;
      if (this.changeRequestJsonWorkflowData) {
        this.initialData = this.changeRequestJsonWorkflowData;
        this.jsonEditor.update(this.changeRequestJsonWorkflowData as any);
      }
    });
  }

  quillConfig = {
    toolbar: [['bold', 'italic', 'underline'], ['link']],
  };

  createNextStepsForm(steps: IWorkflowNextStep[]) {
    this.nextStepsForm = this.fb.group({
      nextSteps: this.prepopulateNextStepsFormAray(steps),
    });
  }

  prepopulateNextStepsFormAray(steps: IWorkflowNextStep[]): FormArray<any> {
    const formArray: FormArray = this.fb.array([]);
    if (steps && steps.length > 0) {
      steps.forEach(step => {
        formArray.push(
          this.fb.group({
            title: [step.title, [Validators.required, Validators.minLength(1)]],
            description: [
              step.description,
              [Validators.required, Validators.minLength(1)],
            ],
          })
        );
      });
    } else {
      formArray.push(
        this.fb.group({
          title: [null, [Validators.required, Validators.minLength(1)]],
          description: [null, [Validators.required, Validators.minLength(1)]],
        })
      );
    }
    return formArray;
  }

  get nextSteps(): FormArray {
    return this.nextStepsForm.get('nextSteps') as FormArray;
  }

  getSteps(form: any) {
    return form.controls.nextSteps.controls;
  }

  trackStepsByIndex(index: number) {
    return index;
  }

  addNextStep() {
    this.nextSteps.push(
      this.fb.group({
        title: ['', Validators.required],
        description: ['', Validators.required],
      })
    );
  }

  removeNextStep(i: number) {
    this.nextSteps.removeAt(i);
  }

  ngAfterViewInit(): void {
    this.jsonEditor.setMode('code');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequestJsonWorkflowData']?.currentValue) {
      this.changeRequestJsonWorkflowData =
        changes['changeRequestJsonWorkflowData'].currentValue;
      this.initialData = changes['changeRequestJsonWorkflowData'].currentValue;
      this.slaForm
        .get('expectedProcessingDays')
        ?.setValue(
          Object.keys(this.changeRequestJsonWorkflowData).length > 0
            ? parseInt(
                this.changeRequestJsonWorkflowData[
                  'expectedProcessingDays'
                ] as any
              )
            : 0
        );
      this.updateNextSteps(
        (this.changeRequestJsonWorkflowData[
          'nextSteps'
        ] as IWorkflowNextStep[]) ?? []
      );
    }
    this.changeRequestStatus = this.changeRequest.status;
  }

  async validateJsonEditor(): Promise<void> {
    const editorJson: any = this.jsonEditor.getEditor();
    this.jsonEditorValidationErrors = await editorJson.validate();
    if (this.jsonEditorValidationErrors.length > 0) {
      return;
    }
    this.changeRequestJsonWorkflowData =
      this.jsonEditor.get() as unknown as Record<string, unknown>;
  }

  async onWorkflowSubmit(): Promise<void> {
    this.isLoading = true;
    await this.validateJsonEditor();

    if (
      !this.changeRequest.id ||
      !this.checkIfStepIsValid() ||
      !this.nextStepsForm.valid
    ) {
      this.isLoading = false;
      return;
    }

    this.updateFormDisplayedErrorMessageContent(false);
    this.serviceChangeRequestService
      .updateServiceChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime(
        this.changeRequest.id,
        this.changeRequestJsonWorkflowData
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.toastService.show({
            body: res.responseMessage,
            type: 'success',
          });
          this.jsonWorkflowFormSubmitted.emit(
            this.checkIfStepIsValid()
              ? this.changeRequestJsonWorkflowData
              : undefined
          );
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          const errorMessageAndCode: IHttpErrorResponseCodeAndMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              `Failed to update Change request workflow definition`
            );

          this.toastService.show({
            body: errorMessageAndCode.message,
            type: 'error',
          });

          const fieldErrors: IFieldError[] =
            ExtractHttpErrorFieldErrorsUtil(error);

          this.updateFormDisplayedErrorMessageContent(
            true,
            'danger',
            'Workflow Definition Not Updated',
            errorMessageAndCode.message,
            fieldErrors
          );
        },
      });
  }

  updateWorkflowData(selectedOption: EServiceChangeRequestToggleHeaders) {
    if (selectedOption === EServiceChangeRequestToggleHeaders.CODE_EDITOR) {
      //update code editor nextSteps
      this.changeRequestJsonWorkflowData['nextSteps'] =
        this.nextStepsForm.value.nextSteps;
      if (this.changeRequestJsonWorkflowData) {
        this.initialData = this.changeRequestJsonWorkflowData;
        this.jsonEditor.update(this.initialData as any);
      }
    } else {
      //update next steps with workflow changes
      const workflowChanges = this.jsonEditor.get() as unknown as Record<
        string,
        unknown
      >;
      this.changeRequestJsonWorkflowData = workflowChanges;
      this.updateNextSteps(workflowChanges['nextSteps'] as IWorkflowNextStep[]);
      this.slaForm
        .get('expectedProcessingDays')
        ?.setValue(workflowChanges['expectedProcessingDays'] as number);
    }
  }

  updateNextSteps(steps: IWorkflowNextStep[]) {
    this.nextSteps.clear();
    steps.forEach(step => {
      this.nextSteps.push(this.fb.group(step));
    });
    this.nextStepsForm.controls['nextSteps'].markAllAsTouched();
    this.nextStepsForm.controls['nextSteps'].updateValueAndValidity();
  }

  updateFormDisplayedErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    title: string = '',
    // eslint-disable-next-line @typescript-eslint/no-inferrable-types
    message: string = '',
    fieldErrors: IFieldError[] | null = null
  ): void {
    let extraMessage = '';
    this.showConfigWorkflowErrorMessage = show;

    if (fieldErrors) {
      extraMessage = fieldErrors
        .map((field: IFieldError) => `${field.field}: ${field?.errorCode}`)
        .join(`\n`);
    }

    this.formAlertContent = { type, title, message, extraMessage };
    if (show) {
      this.errorMessageElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  private checkIfStepIsValid(): boolean {
    if (
      this.jsonEditorValidationErrors.length > 0 ||
      !this.changeRequestJsonWorkflowData ||
      !this.nextStepsForm?.valid ||
      !this.slaForm.valid
    ) {
      this.updateFormDisplayedErrorMessageContent(
        true,
        'danger',
        'Invalid JSON Workflow Entry!',
        'Please ensure you have filled a valid JSON workflow entry.'
      );
      return false;
    }

    return true;
  }

  private checkJsonFormDataIsNotEmpty(json: Record<string, unknown>): IError[] {
    const errors: IError[] = [];
    const workFlowData: IServiceChangeRequestWorkflow = {
      ...(json as unknown as IServiceChangeRequestWorkflow),
    };
    const propertiesArray: string[] = Object.keys(json);
    if (propertiesArray.includes('isTrusted')) {
      propertiesArray.splice(propertiesArray.indexOf('isTrusted'), 1);
    }

    if (propertiesArray.length < 1) {
      errors.push({
        path: [1],
        message: 'JSON Object can not be empty.',
      });
    }

    if (!workFlowData.workflow || !workFlowData.nextSteps) {
      errors.push({
        path: [2],
        message:
          'Invalid workflow!. Workflow, nextSteps and expectedProcessingDays must be specified',
      });
    }

    if (
      (workFlowData.workflow && workFlowData.workflow.length === 0) ||
      (workFlowData.nextSteps && workFlowData.nextSteps.length === 0) ||
      workFlowData.expectedProcessingDays === null ||
      workFlowData.expectedProcessingDays === 0
    ) {
      errors.push({
        path: [3],
        message:
          'Invalid workflow!. Workflow and nextSteps cant be empty and expectedProcessingDays must be greater than zero',
      });
    }

    return errors;
  }

  onSelectWorkflowTemplate(template: IWorkflowTemplate): void {
    const templateWorkflowDefination = JSON.parse(
      template?.workflowDefinition as unknown as string
    );
    this.changeRequestJsonWorkflowData['workflow'] =
      templateWorkflowDefination.workflow;
    this.updateNextSteps(
      (template?.workflowDefinition['nextSteps'] as IWorkflowNextStep[]) ?? []
    );
    this.slaForm
      .get('expectedProcessingDays')
      ?.setValue(
        parseInt(template?.workflowDefinition['expectedProcessingDays'] as any)
      );

    this.toastService.show({
      body: `Workflow template "${template.workflowName}" loaded`,
      type: 'warning',
    });
  }

  onSelectOptionChange(option: EServiceChangeRequestToggleHeaders) {
    this.selectedOption = option;
    if (option === EServiceChangeRequestToggleHeaders.CODE_EDITOR) {
      this.jsonEditor.setMode('code');
    }
    this.updateWorkflowData(option);
  }

  OnStepperChange(index: number) {
    this.currentConfigStep = index;
  }
  OnUpdateProcessingDays() {
    this.isLoading = true;
    if (
      !this.changeRequest.id ||
      !this.checkIfStepIsValid() ||
      !this.nextStepsForm.valid ||
      !this.slaForm.valid
    ) {
      this.isLoading = false;
      return;
    }

    this.updateFormDisplayedErrorMessageContent(false);
    this.serviceChangeRequestService
      .updateServiceChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime(
        this.changeRequest.id,
        this.changeRequestJsonWorkflowData
      )
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.toastService.show({
            body: res.responseMessage,
            type: 'success',
          });
          this.currentConfigStep++;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          const errorMessageAndCode: IHttpErrorResponseCodeAndMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              `Failed to update Change request workflow definition`
            );

          this.toastService.show({
            body: errorMessageAndCode.message,
            type: 'error',
          });

          const fieldErrors: IFieldError[] =
            ExtractHttpErrorFieldErrorsUtil(error);

          this.updateFormDisplayedErrorMessageContent(
            true,
            'danger',
            'Workflow Definition Not Updated',
            errorMessageAndCode.message,
            fieldErrors
          );
        },
      });
  }

  goToNextStep(): void {
    if (this.currentConfigStep < this.workflowSteps.length - 1) {
      switch (this.currentConfigStep) {
        case 0:
          this.onWorkflowSubmit();
          break;
        case 1:
          this.OnUpdateProcessingDays();
          break;
        case 2:
          this.currentConfigStep++;
          break;
        case 3:
          this.currentConfigStep++;
          break;
        case 4:
          this.currentConfigStep++;
          break;
        case 5:
          this.currentConfigStep++;
          break;
      }
      return;
    }
    if (this.currentConfigStep >= this.workflowSteps.length - 1) {
      // Go to transition step
      this.jsonWorkflowFormSubmitted.emit(this.changeRequestJsonWorkflowData);
    }
  }
}
