import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IGenericLabelValueFieldsConfigFormModel,
  IFormbuilderFieldTypeConfiguration,
} from '../../../models/form-builder-types-configurations.model';
import {
  generateGenericLabelValueSettingsContentConfig,
  genericLabelValueFieldParseIntoSettingsFormModel,
  genericLabelValueFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { EIremboFormlyFieldTypes } from '@irembo-andela/irembogov3-common';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import {
  getRequiredValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import { parseRequiredValidatorParseFieldIntoModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import { setRequiredValidatorUpdateFieldFromModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';

const defaultCustomDropDownConfigFormModel: IGenericLabelValueFieldsConfigFormModel =
  {
    ...fieldsBaseConfigurationFormModel(),
    contentSection: {
      optionLabelValuePairs: [{ label: null, value: null }],
      multiple: false,
    },
    disabled: false,
  };

export const customDropDownFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultCustomDropDownConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
        {
          key: 'disabled',
          type: EIremboFormlyFieldTypes.checkbox,
          props: {
            label: 'Disabled',
            hideHeader: true,
            placeholder: 'Disabled',
            required: false,
            formCheck: 'switch',
          },
          id: 'disabled',
        },
        generateGenericLabelValueSettingsContentConfig(
          'Dropdown Options',
          true
        ),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const customDropDownFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IGenericLabelValueFieldsConfigFormModel => {
  const model: IGenericLabelValueFieldsConfigFormModel =
    genericLabelValueFieldParseIntoSettingsFormModel(field);
  model.contentSection.multiple = field.props?.['multiple'] ?? false;
  return model;
};

export const customDropDownFieldUpdateFromSettingsFormModel = (
  model: IGenericLabelValueFieldsConfigFormModel,
  dropdownField: FormlyFieldConfig
): FormlyFieldConfig => {
  const field: FormlyFieldConfig =
    genericLabelValueFieldUpdateFromSettingsFormModel(model, dropdownField);
  if (field.props) {
    field.props['multiple'] = model?.contentSection?.multiple ?? false;
  } else {
    field.props = {
      multiple: model?.contentSection?.multiple ?? false,
    };
  }
  return field;
};

export const customDropDownFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, field);

  return model;
};

export const customDropDownFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  inputField: FormlyFieldConfig
): FormlyFieldConfig => {
  inputField = setRequiredValidatorUpdateFieldFromModel(model, inputField);

  return inputField;
};

export const customDropDownFieldParseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const customDropDownFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
