import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FormlyFieldConfig,
  FormlyForm,
  FormlyFormOptions,
} from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilderService } from './../../../../../../core/services/form-builder.service';

@Component({
  selector: 'irembogov-officer-form-preview-json-formdata-widget',
  templateUrl: './officer-form-preview-json-formdata-widget.component.html',
  styleUrls: ['./officer-form-preview-json-formdata-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class OfficerFormPreviewJsonFormdataWidgetComponent
  implements OnInit, OnDestroy
{
  previewDataFields: FormlyFieldConfig[] = [];
  subjectControl$: Subject<void> = new Subject<void>();

  form = new FormGroup({});
  model: unknown = {};
  options: FormlyFormOptions = {};

  @ViewChild('formly') formly!: FormlyForm;

  constructor(
    public formBuilderService: FormBuilderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.form.disable();
  }

  ngOnInit(): void {
    this.formBuilderService.thirdPartyFormlyFieldsSubject$
      .pipe(takeUntil(this.subjectControl$))
      .subscribe((formBuilderFieldsData: FormlyFieldConfig[]) => {
        this.form.reset();
        this.previewDataFields = [...formBuilderFieldsData];
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.subjectControl$.next();
    this.subjectControl$.complete();
  }
}
