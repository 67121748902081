<div class="d-flex">
  <div class="ms-5 me-5 mt-5 {{ inReviewMode ? 'w-70' : 'w-100' }}">
    <div
      class="configuration-pricing-form mx-auto {{
        inReviewMode ? 'col-lg-8' : 'col-lg-6'
      }} ">
      <div #errorMessageElement>
        <irembogov-irembo-section-message
          *ngIf="showConfigFormErrorMessage"
          [type]="formAlertContent.type"
          [title]="formAlertContent.title"
          [message]="
            formAlertContent.message
          "></irembogov-irembo-section-message>
      </div>
      <div class="" *ngIf="defaultData && changeRequest">
        <div class="container">
          <div class="row mt-2">
            <div class="col-12">
              <form></form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="bg-white rounded-1 p-3 col-12" *ngIf="paymentTypeForm">
            <form [formGroup]="paymentTypeForm" class="row mb-3">
              <h5 class="mb-4 font-weight-bold border-bottom-secondary">
                Payment Type
              </h5>
              <div class="form-group row">
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-xs-3">
                  <div
                    class="card mb-2"
                    [ngClass]="
                      paymentTypeForm.controls['paymentType'].value === 'FREE'
                        ? 'card-selected'
                        : ''
                    ">
                    <div
                      class="card-body"
                      role="button"
                      (click)="onPaymentTypeChange(EServicePaymentType.FREE)">
                      <input
                        name="paymentType"
                        [id]="EServicePaymentType.FREE"
                        [value]="EServicePaymentType.FREE"
                        formControlName="paymentType"
                        class="form-check-input"
                        type="radio" />
                      <label
                        [for]="EServicePaymentType.FREE"
                        style="padding-left: 12px">
                        Free
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-xs-3">
                  <div
                    class="card mb-2"
                    [ngClass]="
                      paymentTypeForm.controls['paymentType'].value === 'PAID'
                        ? 'card-selected'
                        : ''
                    ">
                    <div
                      class="card-body"
                      role="button"
                      (click)="onPaymentTypeChange(EServicePaymentType.PAID)">
                      <input
                        name="paymentType"
                        [id]="EServicePaymentType.PAID"
                        [value]="EServicePaymentType.PAID"
                        formControlName="paymentType"
                        class="form-check-input"
                        type="radio" />
                      <label
                        [for]="EServicePaymentType.PAID"
                        style="padding-left: 12px">
                        Paid
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <ng-container
          *ngIf="paymentTypeForm?.controls?.['paymentType']?.value === 'PAID'">
          <div class="row mt-3">
            <div
              class="bg-white rounded-1 p-3 col-12"
              *ngIf="pricingExpirationForm">
              <form [formGroup]="pricingExpirationForm" class="row mb-3">
                <h5 class="mb-4 font-weight-bold border-bottom-secondary">
                  Payment Expiration Time
                </h5>
                <div class="ml-3">
                  <label class="text-muted form-label"
                    >Payment Expiration Time<i
                      class="fa-solid fa-circle-info text-muted pl-3"
                      placement="top"
                      ngbTooltip="Payment Expiration Time"></i
                  ></label>
                  <input
                    formControlName="paymentExpirationHours"
                    type="number"
                    min="0"
                    class="form-control col-xl-3 mr-1"
                    placeholder="Payment Expiration Time"
                    (change)="onPaymentExpirationChange($event)"
                    #paymentExpirationHours />
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="bg-white rounded-1 p-3 col-12">
              <h5 class="mb-4 font-weight-bold">Price</h5>
              <div class="ml-3">
                <irembogov-configure-service-change-request-price-handler
                  [defaultData]="defaultData['pricing']"
                  [formFields]="formFields"
                  label="Price"
                  [withCurrency]="true"
                  [showToggleButton]="true"
                  [isPricingNumberType]="true"
                  (valueChange)="onPriceChange($event)"
                  buttonName="pricing">
                </irembogov-configure-service-change-request-price-handler>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="bg-white rounded-1 p-3 mt-3 col-12">
              <h5 class="mb-4 font-weight-bold">Payment Account Identifier</h5>
              <div class="ml-3">
                <irembogov-configure-service-change-request-price-handler
                  (valueChange)="onPaymentAccountIdentifierChange($event)"
                  [defaultData]="defaultData['paymentAccount']"
                  [formFields]="formFields"
                  [showToggleButton]="true"
                  label="Payment Account Identifier"
                  buttonName="payment-account"></irembogov-configure-service-change-request-price-handler>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="bg-white rounded-1 p-3 mt-3 col-12">
              <h5 class="mb-4 font-weight-bold">
                Service Payment Code Identifier
              </h5>
              <div class="ml-3">
                <irembogov-configure-service-change-request-price-handler
                  (valueChange)="onPaymentCodeIdentifierChange($event)"
                  [defaultData]="defaultData['paymentCode']"
                  [formFields]="formFields"
                  [showToggleButton]="true"
                  label="Service Payment Code Identifier"
                  buttonName="payment-code"></irembogov-configure-service-change-request-price-handler>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="bg-white rounded-1 p-3 mt-3 col-12">
              <h5 class="mb-4 font-weight-bold">Payment Merchant</h5>
              <div class="ml-3">
                <irembogov-configure-service-change-request-price-handler
                  (valueChange)="onPaymentMerchantChange($event)"
                  [defaultData]="defaultData['paymentMerchant']"
                  [showToggleButton]="false"
                  label="Payment Merchant"
                  buttonName="payment-merchant"></irembogov-configure-service-change-request-price-handler>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="row">
          <div class="text-end mt-3 px-0">
            <button
              class="btn btn-primary mx-auto px-5"
              type="submit"
              [disabled]="isLoading"
              (click)="onSubmit()">
              <div
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"></div>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 pt-5 ms-3 me-3 w-30" *ngIf="inReviewMode">
    <irembogov-service-change-request-feedback-handler-widget
      [changeRequestId]="changeRequest.id ?? ''"
      [serviceChangeRequestSection]="
        EServiceChangeRequestSection.PRICING
      "></irembogov-service-change-request-feedback-handler-widget>
  </div>
</div>
