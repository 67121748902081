import { Pipe, PipeTransform } from '@angular/core';
import { EServiceChangeRequestReviewStatus } from '../models/service-change-request-review-issue.model';

@Pipe({ name: 'reviewIssueStatusToLabel' })
export class ReviewIssueStatusToLabelPipe implements PipeTransform {
  transform(status: EServiceChangeRequestReviewStatus | undefined): string {
    switch (status) {
      case 'NEW':
        return 'New';
      case 'PENDING':
        return 'Pending';
      case 'RESOLVED':
        return 'Resolved';
      case 'REMOVED':
        return 'Removed';
      default:
        return '';
    }
  }
}
