import { EIremboFormlyValidationTypes } from '@irembo-andela/irembogov3-common';

export const ValidationTypeDefaultMessage = (
  validationType: EIremboFormlyValidationTypes,
  param: string | undefined = undefined
): string => {
  switch (validationType) {
    case EIremboFormlyValidationTypes.email:
      return `Enter a valid email address`;
    case EIremboFormlyValidationTypes.minLength:
      return `Minimum length not met`;
    case EIremboFormlyValidationTypes.maxLength:
      return `Maximum length exceeded`;
    case EIremboFormlyValidationTypes.required:
      return `Field is required`;
    case EIremboFormlyValidationTypes.requiredTrue:
      return 'Field is must be True';
    case EIremboFormlyValidationTypes.invalidNumberFormat:
      return 'Number format is invalid';
    case EIremboFormlyValidationTypes.invalidNumber:
      return 'Provided number is invalid';
    case EIremboFormlyValidationTypes.pattern:
      return 'Invalid input';
    case EIremboFormlyValidationTypes.uniqueField:
      return 'Field must have a unique value';
    case EIremboFormlyValidationTypes.invalidInput:
      return 'Entered input is invalid';
    case EIremboFormlyValidationTypes.invalidId:
      return 'Entered Id is invalid';
    case EIremboFormlyValidationTypes.minimumUploadSize:
      return param
        ? `Minimum upload size is ${param}kb`
        : 'Minimum upload size not met';
    case EIremboFormlyValidationTypes.maximumUploadSize:
      return param
        ? `Maximum upload size is ${param}kb`
        : 'Maximum upload size exceeded';
    case EIremboFormlyValidationTypes.invalidfileformat:
      return 'Invalid file format';
    default:
      return 'Invalid field';
  }
};
