import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  ICustomIdInputFieldSettingsModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  baseFieldUpdateFromSettingsFormModel,
  populatesPropsParseIntoSettingsFormModel,
  populatesPropsUpdateFromSettingsFormModel,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import {
  parsePopulatesFieldValidatorFormDataIntoModel,
  parseRequiredValidatorParseFieldIntoModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import {
  setRequiredValidatorUpdateFieldFromModel,
  setPopulatesFieldValidatorFormDataIntoField,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { getGenericCustomIdFieldTypeconfigurationConfig } from './shared.configuration';
import { nidChildNidResponseProps } from '../../../models/custom-id-input-responses.model';

export const nidFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return getGenericCustomIdFieldTypeconfigurationConfig(
    formBuilderServiceInstance,
    [...Array.from(nidChildNidResponseProps)]
  );
};

export const nidFieldParseIntoSettingsFormModel = (
  nidField: FormlyFieldConfig
): ICustomIdInputFieldSettingsModel => {
  return populatesPropsParseIntoSettingsFormModel(nidField);
};

export const nidFieldUpdateFromSettingsFormModel = (
  model: ICustomIdInputFieldSettingsModel,
  nidField: FormlyFieldConfig
): FormlyFieldConfig => {
  nidField = baseFieldUpdateFromSettingsFormModel(model, nidField);
  nidField = populatesPropsUpdateFromSettingsFormModel(model, nidField);

  return nidField;
};

export const nidFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, field);
  model = parsePopulatesFieldValidatorFormDataIntoModel(model, field);

  return model;
};

export const nidFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  nidInput: FormlyFieldConfig
): FormlyFieldConfig => {
  nidInput = setRequiredValidatorUpdateFieldFromModel(model, nidInput);
  nidInput = setPopulatesFieldValidatorFormDataIntoField(model, nidInput);

  return nidInput;
};

export const nidFieldParseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const nidFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
