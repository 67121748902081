import { IFieldFormGroup } from './../../../../core/models/service-change-requests-form-fields.model';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServiceComparatorOptions } from './../../../../core/constants/comparator-options.constants';
import { EServiceChangeRequestComparator } from './../../../../core/models/service-change-request-comparator.enum';
import { ServiceChangeRequestService } from './../../../../core/services/service-change-request.service';
import {
  EIremboFormlyFieldTypes,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../../environments/environment';
import { Subscription } from 'rxjs';
interface IMobileCountryCodes {
  name: string;
  flag: string;
  code: string;
  dial_code: string;
}
@Component({
  selector: 'irembogov-configure-service-dynamic-pricing-form',
  templateUrl: './configure-service-dynamic-pricing-form.component.html',
  styleUrls: ['./configure-service-dynamic-pricing-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigureServiceDynamicPricingFormComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() index = 0;
  @Input() isDisabled = false;
  @Input() conditionsForm!: FormGroup;
  @Input() formFields: any = [];
  @Input() formGroup!: FormGroup;
  @Output() changeCondition: EventEmitter<{
    value: any;
    index: number;
    form: FormGroup;
  }> = new EventEmitter<{
    value: any;
    index: number;
    form: FormGroup;
  }>();
  @Output() removeCondition: EventEmitter<{ index: number; form: FormGroup }> =
    new EventEmitter<{
      index: number;
      form: FormGroup;
    }>();
  inputType!: string | undefined;
  inputOptions: any[] | null = [];
  bindLabel!: string;
  bindValue!: string;
  comparatorOptions: { label: string; value: string }[] = [];
  EServiceChangeRequestComparator!: EServiceChangeRequestComparator;

  formFieldsValues: IFieldFormGroup[] = [];
  attributeType!: string | undefined;
  countryCodes: IMobileCountryCodes[] = [];

  form: FormGroup = this.fb.group({
    attribute: this.fb.control(null, [Validators.required]),
    comparator: this.fb.control(null, [Validators.required]),
    value: this.fb.control(null, [Validators.required]),
    attributeType: this.fb.control('', [Validators.required]),
  });

  sameValueComparators: EIremboFormlyFieldTypes | string[] = [
    EIremboFormlyFieldTypes.input,
    EIremboFormlyFieldTypes.checkbox,
    EIremboFormlyFieldTypes.multicheckbox,
    EIremboFormlyFieldTypes.radio,
    EIremboFormlyFieldTypes.customphonenumber,
    EIremboFormlyFieldTypes.customdropdown,
    EIremboFormlyFieldTypes.customdropdownpaginated,
    EIremboFormlyFieldTypes.customcascadingdropdowns,
  ];
  inputTypesWithOptions: EIremboFormlyFieldTypes | string[] = [
    EIremboFormlyFieldTypes.checkbox,
    EIremboFormlyFieldTypes.multicheckbox,
    EIremboFormlyFieldTypes.radio,
  ];
  cascadingDropdownFielValues!: { step: number; value: any };

  EIremboFormlyFieldTypes = EIremboFormlyFieldTypes;

  private subcriptions = new Subscription();

  constructor(
    private fb: FormBuilder,
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService,
    private http: HttpClient
  ) {}

  ngOnDestroy(): void {
    this.subcriptions.unsubscribe();
  }

  ngOnInit() {
    this.subcriptions.add(
      this.serviceChangeRequestService.getCountryCodesWithFlags().subscribe({
        next: (response: any) => {
          this.countryCodes = response;
        },
        error: () => {
          this.handleError('Failed to fetch country codes');
        },
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formFields']?.currentValue) {
      this.formFields = changes['formFields'].currentValue;
      this.comparatorOptions = this.getComparisonOptions('');
      this.formFieldsValues = [];
      this.formFields?.fields?.forEach((field: any) => {
        field?.fieldGroup.forEach((fieldGroup: any) => {
          fieldGroup.fieldGroup.forEach((fg: any) => {
            fg.fieldGroup.forEach((element: IFieldFormGroup) => {
              if (
                element?.type === 'customcascadingdropdowns' &&
                element.props?.configs
              ) {
                element.props.configs.forEach((config: any) => {
                  config['type'] = 'cascading_customdropdown';
                  this.formFieldsValues.push(config);
                });
                return;
              }
              return this.formFieldsValues.push(element);
            });
          });
        });
      });

      const formField = this.formFieldsValues.find(
        (field: IFieldFormGroup) =>
          field.key ===
          (field?.type === 'cascading_customdropdown'
            ? this.formGroup.get('attribute')?.value?.replace('.value', '')
            : this.formGroup.get('attribute')?.value)
      );
      this.inputType = formField?.type ?? undefined;
      this.form.patchValue(this.formGroup?.value);

      this.loadInputOptions(formField);

      this.subcriptions.add(
        this.form.valueChanges.subscribe(value => {
          this.changeCondition.emit({
            value: value,
            index: this.index,
            form: this.conditionsForm,
          });
        })
      );
    }
  }

  getComparisonOptions(inputType: EIremboFormlyFieldTypes | string) {
    if (
      inputType &&
      [
        EIremboFormlyFieldTypes.customdatepicker,
        EIremboFormlyFieldTypes.customtimepicker,
      ].includes(inputType as EIremboFormlyFieldTypes)
    ) {
      return ServiceComparatorOptions.filter(comparisonOption =>
        [
          EServiceChangeRequestComparator.EQ,
          EServiceChangeRequestComparator.GT,
          EServiceChangeRequestComparator.GTE,
          EServiceChangeRequestComparator.LT,
          EServiceChangeRequestComparator.LTE,
        ].includes(comparisonOption.value)
      );
    } else if (
      this.sameValueComparators.includes(
        inputType as EIremboFormlyFieldTypes
      ) ||
      inputType === 'cascading_customdropdown'
    ) {
      if (this.attributeType === 'number') {
        return ServiceComparatorOptions.filter(comparisonOption =>
          [
            EServiceChangeRequestComparator.EQ,
            EServiceChangeRequestComparator.GT,
            EServiceChangeRequestComparator.GTE,
            EServiceChangeRequestComparator.LT,
            EServiceChangeRequestComparator.LTE,
          ].includes(comparisonOption.value)
        );
      } else {
        return ServiceComparatorOptions.filter(comparisonOption =>
          EServiceChangeRequestComparator.EQ.includes(comparisonOption.value)
        );
      }
    } else {
      return ServiceComparatorOptions;
    }
  }
  trackByKey(index: number, field: IFieldFormGroup) {
    return field.key;
  }
  trackByValue(index: number, option: { label: string; value: any }) {
    return option.value;
  }
  onRemoveCondition(index: number) {
    this.removeCondition.emit({ index: index, form: this.conditionsForm });
  }

  onFieldChange(event: string) {
    const formField = this.formFieldsValues?.find(
      field => field?.key === event
    );
    if (!formField) return;

    this.inputType = formField.type;
    this.inputOptions = [];
    this.attributeType =
      formField.props?.type === 'number' ? 'number' : 'string';
    this.form.get('attributeType')?.setValue(this.attributeType.toUpperCase());

    if (
      formField.type === 'cascading_customdropdown' &&
      !formField.bindValue?.length
    ) {
      this.form.get('attribute')?.setValue(`${event}.value`);
    }

    this.loadInputOptions(formField);

    this.comparatorOptions = this.getComparisonOptions(formField.type ?? '');
  }

  private handleError(message: string) {
    this.toastService.show({ body: message, type: 'error' });
  }

  private fetchDataFromInputUrl(field: IFieldFormGroup) {
    const { dataset } = field?.props || field;
    if (dataset) {
      const url = `${environment.apiGatewayBaseUrl}${dataset['url']}`;
      const params = dataset['params'];
      const headers = dataset['headers'];
      const dataField = dataset['dataField'];
      this.bindLabel = field?.props?.bindLabel ?? 'label';
      this.bindValue = field?.props?.bindValue ?? '';

      this.getDataByGetMethod(url, dataField, params, headers);
    }
  }

  getDataByGetMethod(
    url: string,
    dataField: string,
    params: Record<string, unknown> = {},
    headers: Record<string, string> = {}
  ) {
    const queryParams = Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key] as string)}`)
      .join('&');

    this.subcriptions.add(
      this.http
        .get<Record<string, unknown> | []>(`${url}?${queryParams}`, { headers })
        .subscribe({
          next: res => {
            this.inputOptions = Array.isArray(res)
              ? res
              : this.getValueFromNestedObject(res, dataField);
          },
        })
    );
  }

  private getValueFromNestedObject(
    obj: Record<string, unknown>,
    keyString: string
  ) {
    const keys = keyString.split('.');
    let value = obj;
    for (const key of keys) {
      value = value[key] as Record<string, unknown>;
      if (value === undefined) {
        throw new Error(`Invalid key string: ${keyString}`);
      }
    }
    return value as unknown as Record<string, unknown>[];
  }

  private loadInputOptions(formField?: IFieldFormGroup) {
    if (this.inputTypesWithOptions?.includes(formField?.type || '')) {
      this.inputOptions = formField?.props?.options ?? [];
    } else if (formField?.type === EIremboFormlyFieldTypes.customdropdown) {
      if (!formField.props?.options?.length) {
        this.fetchDataFromInputUrl(formField);
      } else {
        this.inputOptions = formField.props.options;
        this.bindLabel = formField.props.bindLabel ?? 'label';
        this.bindValue = formField.props.bindValue ?? 'value';
      }
    }
  }
}
