<button
  class="btn btn-sm btn-primary"
  *ngIf="changeRequest && checkIfCanConfigureChangeRequests()"
  (click)="OnConfigureClicked.emit(changeRequest.id)">
  <i class="fas fa-pencil-alt"></i>
  {{
    changeRequest.type &&
    changeRequest.type === EServiceChangeRequestType.SERVICE_UPDATE
      ? 'Edit'
      : 'Configure'
  }}
</button>
