/* eslint-disable @typescript-eslint/no-inferrable-types */
import { IServiceLanguage } from './../../../../../../core/models/service-language.model';
import { ExtractHttpErrorFieldErrorsUtil } from '../../../../../../core/utils/http-field-error-extractor.utils';
import { IServiceChangeRequest } from '../../../../../../core/models/service-change-request.model';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, ValidationErrors } from '@angular/forms';
import {
  IUiAlertContent,
  TUiAlertTypes,
  ToastService,
  ExtractHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
} from '@irembo-andela/irembogov3-common';
import { IFieldError } from '../../../../../../core/models/service-change-request-config-steps-forms.model';
import { ServiceChangeRequestService } from '../../../../../../core/services/service-change-request.service';
import { Subscription, finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'irembogov-translation-service-overview',
  templateUrl: './translation-service-overview.component.html',
  styleUrls: ['./translation-service-overview.component.scss'],
})
export class TranslationServiceOverviewComponent
  implements OnDestroy, OnChanges
{
  @Input() changeRequest!: IServiceChangeRequest;
  @Input() defaultTranslations!: Record<string, Record<string, unknown>>;
  @Input() selectedLanguage!: IServiceLanguage;
  @Input() selectedLanguageData!: Record<string, unknown>;
  @Output() formSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('errorMessageElement') errorMessageElement!: ElementRef;

  showConfigWorkflowErrorMessage = false;
  jsonEditorValidationErrors: ValidationErrors[] = [];
  initialTranslationsData: any = this.defaultTranslations;
  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };
  isLoading = false;
  subscriptions = new Subscription();
  translationsForm = this.fb.group({
    locale: [],
    translations: this.fb.array([]),
  });

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService,
    private fb: FormBuilder
  ) {}

  get translations() {
    return this.translationsForm.controls['translations'] as FormArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['defaultTranslations']?.currentValue) {
      this.defaultTranslations = changes['defaultTranslations'].currentValue;
      this.initialTranslationsData =
        changes['defaultTranslations'].currentValue;
    }

    if (changes['selectedLanguageData']?.currentValue) {
      this.selectedLanguageData = changes['selectedLanguageData'].currentValue;
      this.initializeTranslationForm(this.selectedLanguageData);
    }
  }

  initializeTranslationForm(selectedTranslation: Record<string, unknown>) {
    this.translations.clear();
    for (const [key, value] of Object.entries(selectedTranslation)) {
      const translation = this.fb.group({
        word: [key],
        translation: [value],
      });
      this.translations.push(translation);
    }
  }

  async onTranslationsSubmit(): Promise<void> {
    this.isLoading = true;
    if (!this.changeRequest.id) {
      this.isLoading = false;
      return;
    }

    this.updateErrorMessageContent(false);
    const resultObject: any = {};

    resultObject['translations'] =
      this.initialTranslationsData?.translations ?? {};
    resultObject['translations'][this.selectedLanguage.locale] = {};
    const translated_words: any = {};
    this.translationsForm.value.translations?.forEach((translation: any) => {
      translated_words[translation.word] = translation.translation;
    });
    resultObject['translations'][this.selectedLanguage.locale] =
      translated_words;
    const updateTranslation$ =
      this.serviceChangeRequestService.updateServiceChangeRequestTranslation(
        this.changeRequest.id,
        resultObject
      );
    const updateTranslationSubscription = updateTranslation$
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.toastService.show({
            body: res.responseMessage,
            type: 'success',
          });
          this.formSubmitted.emit(true);
        },
        error: this.handleError,
      });

    this.subscriptions.add(updateTranslationSubscription);
  }

  private handleError(error: HttpErrorResponse): void {
    const errorMessageAndCode = ExtractHttpErrorResponseCodeAndMessage(
      error,
      'Failed to update Change request Translations'
    );
    const fieldErrors = ExtractHttpErrorFieldErrorsUtil(error);

    this.toastService.show({
      body: errorMessageAndCode.message,
      type: 'error',
    });
    this.updateErrorMessageContent(
      true,
      'danger',
      'Translation Not Updated',
      errorMessageAndCode.message,
      fieldErrors
    );
    this.formSubmitted.emit(false);
  }

  private updateErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title: string = '',
    message: string = '',
    fieldErrors: IFieldError[] | null = null
  ): void {
    this.showConfigWorkflowErrorMessage = show;

    let extraMessage = '';
    if (fieldErrors) {
      extraMessage = fieldErrors
        .map(({ field, errorCode }) => `${field}: ${errorCode}`)
        .join('\n');
    }

    this.formAlertContent = {
      type,
      title,
      message,
      extraMessage,
    };

    if (show) {
      this.scrollErrorMessageIntoView();
    }
  }

  private scrollErrorMessageIntoView(): void {
    this.errorMessageElement.nativeElement.scrollIntoView({
      behavior: 'smooth',
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
