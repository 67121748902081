/* eslint-disable @typescript-eslint/no-inferrable-types */
import { EServiceChangeRequestStatus } from './../../../../core/models/service-change-request-status.enum';
import { EServiceChangeRequestSection } from './../../../../core/models/service-change-request-sections.enum';
import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  OnInit,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  IFilterAndPaginationFieldsBase,
  IUiAlertContent,
  TUiAlertTypes,
  ToastService,
  ExtractHttpErrorResponseCodeAndMessage,
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  IHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
  updateApiFilter,
} from '@irembo-andela/irembogov3-common';
import { IServiceCategory } from '../../../../core/models/service-category.model';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { BehaviorSubject, finalize, of, switchMap } from 'rxjs';
import { IConfigStepChangeRequestMetaData } from '../../../../core/models/service-change-request-config-steps-forms.model';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { HttpErrorResponse } from '@angular/common/http';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  name: string | null;
  keywords: string | null;
}

@Component({
  selector: 'irembogov-configuration-metadata-overview-form',
  templateUrl: './configuration-metadata-overview-form.component.html',
  styleUrls: ['configuration-metadata-overview-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationMetadataOverviewFormComponent
  implements OnInit, OnChanges
{
  @Input() changeRequest!: IServiceChangeRequest;
  @Input() inReviewMode = false;
  @Output() closeForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() metadataFormSubmitted: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('errorMessageElement') errorMessageElement!: ElementRef;
  EServiceChangeRequestSection = EServiceChangeRequestSection;
  EServiceChangeRequestStatus = EServiceChangeRequestStatus;

  isLoading = false;

  _filterObject: BehaviorSubject<IFilterFields> =
    new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 8,
      name: '',
      keywords: '',
    });

  showConfigFormErrorMessage = false;

  serviceCategories: IServiceCategory[] = [];
  serviceCategoriesBuffer: IServiceCategory[] = [];
  isLoadingServiceCategories = false;
  changeRequestStatus!: string;

  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };

  requestOverviewForm: FormGroup = new FormGroup({
    serviceName: new FormControl<string>('', [Validators.required]),
    serviceSummary: new FormControl<string>('', [Validators.required]),
    categoryId: new FormControl<string>('', [Validators.required]),
    applicantType: new FormControl<string[]>([], this.minSelectedCheckboxes(1)),
  });

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this._filterObject
      .asObservable()
      .pipe(
        switchMap((filter: IFilterFields) => {
          FilterFieldsToQueryParamsBuilder<IFilterFields>(filter);
          this.getServiceCategoriesByPage();
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequest']?.currentValue) {
      this.populateForm(this.changeRequest);
      this.changeRequestStatus = this.changeRequest.status;
    }

    if (changes['inReviewMode']?.currentValue) {
      this.inReviewMode = changes['inReviewMode'].currentValue;
      this.disableFormForReview();
    }
  }

  disableFormForReview() {
    if (this.inReviewMode) {
      this.requestOverviewForm.disable();
    }
  }

  onSearchServiceCategory(query: { term: string }) {
    updateApiFilter<IFilterFields>(this._filterObject, {
      page: 0,
      name: query?.term,
    });
  }

  getServiceCategoriesByPage() {
    this.isLoadingServiceCategories = true;
    this.serviceChangeRequestService
      .getAllServiceCategories()
      .pipe(finalize(() => (this.isLoadingServiceCategories = false)))
      .subscribe({
        next: response => {
          this.serviceCategoriesBuffer = response?.data;
        },
        error: err => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Error getting services'
          );
          this.toastService.show({ body: errorMessage, type: 'error' });
        },
      });
  }

  onCheckboxChange(event: any) {
    const applicantTypeControl = this.requestOverviewForm.get(
      'applicantType'
    ) as FormControl;
    const value = event.target.value;
    let selectedValues = applicantTypeControl.value as string[];

    if (event.target.checked) {
      selectedValues.push(value);
    } else {
      selectedValues = selectedValues.filter(v => v !== value);
    }

    applicantTypeControl.setValue(selectedValues);
    applicantTypeControl.updateValueAndValidity();
  }

  private populateForm(changeRequest: IServiceChangeRequest) {
    this.requestOverviewForm.controls['serviceName'].setValue(
      this.changeRequest.serviceName
    );
    this.requestOverviewForm.controls['serviceSummary'].setValue(
      this.changeRequest.serviceSummary
    );
    this.requestOverviewForm.controls['categoryId'].setValue(
      this.changeRequest.categoryId
    );

    const applicantTypeControl = this.requestOverviewForm.get(
      'applicantType'
    ) as FormControl;
    const selectedValues: string[] = [];
    if (
      changeRequest.applicantType === 'BOTH' ||
      changeRequest.applicantType === 'INDIVIDUAL'
    ) {
      selectedValues.push('INDIVIDUAL');
    }
    if (
      changeRequest.applicantType === 'BOTH' ||
      changeRequest.applicantType === 'BUSINESS'
    ) {
      selectedValues.push('BUSINESS');
    }

    applicantTypeControl.setValue(selectedValues);
    this.requestOverviewForm.markAllAsTouched();
    this.requestOverviewForm.updateValueAndValidity();
  }

  private minSelectedCheckboxes(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedValues = control.value as string[];
      return selectedValues.length >= min ? null : { required: true };
    };
  }

  onFormSubmit() {
    if (!this.changeRequest.id) return;
    if (!this.checkIfConfigFormValid(this.requestOverviewForm)) return;

    this.updateFormDisplayedErrorMessageContent(false);
    this.isLoading = true;

    const applicantTypeArray: string[] =
      this.requestOverviewForm.controls['applicantType'].value;
    let applicantType: 'INDIVIDUAL' | 'BUSINESS' | 'BOTH' | '' = '';

    if (
      applicantTypeArray.includes('INDIVIDUAL') &&
      applicantTypeArray.includes('BUSINESS')
    ) {
      applicantType = 'BOTH';
    } else if (applicantTypeArray.includes('INDIVIDUAL')) {
      applicantType = 'INDIVIDUAL';
    } else if (applicantTypeArray.includes('BUSINESS')) {
      applicantType = 'BUSINESS';
    } else {
      applicantType = '';
    }

    const metadata: IConfigStepChangeRequestMetaData = {
      serviceName: this.requestOverviewForm.controls['serviceName'].value,
      serviceSummary: this.requestOverviewForm.controls['serviceSummary'].value,
      categoryId: this.requestOverviewForm.controls['categoryId'].value,
      trafficClass: 'regular',
      applicantType: applicantType,
    };

    this.serviceChangeRequestService
      .updateServiceChangeRequestMetadata(this.changeRequest.id, metadata)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res: IHttpSingleDataResponse<IServiceChangeRequest>) => {
          this.toastService.show({
            body: res.responseMessage,
            type: 'success',
          });
          this.metadataFormSubmitted.emit(this.requestOverviewForm.valid);
        },
        error: (error: HttpErrorResponse) => {
          const errorMessageAndCode: IHttpErrorResponseCodeAndMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              `Failed to update Change request metadata`
            );
          this.toastService.show({
            body: errorMessageAndCode.message,
            type: 'error',
          });

          this.updateFormDisplayedErrorMessageContent(
            true,
            'danger',
            'Metadata Not Saved',
            errorMessageAndCode.message
          );

          this.metadataFormSubmitted.emit(false);
        },
      });
  }

  updateFormDisplayedErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title: string = '',
    message: string = ''
  ) {
    this.showConfigFormErrorMessage = show;
    this.formAlertContent = { type, title, message };
    if (show) {
      this.errorMessageElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  private checkIfConfigFormValid(configForm: FormGroup): boolean {
    if (configForm.invalid) {
      this.updateFormDisplayedErrorMessageContent(
        true,
        'danger',
        'Invalid fields!',
        'Please ensure you have filled in all the required fields.'
      );
      return false;
    }
    return true;
  }
}
