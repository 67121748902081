import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { FormBuilderService } from '../../../core/services/form-builder.service';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilderManipulatorClass } from '../../../core/utils/form-builder-manipulator-class.utils';
import { EDragDropItemsIds } from '../../../core/models/form-builder-drag-item-ids.enum';
import { alphaNanoIdUtil } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-formly-field-group',
  templateUrl: './formly-field-group.component.html',
  styleUrls: ['./formly-field-group.component.scss'],
})
export class FormlyFieldGroupComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  reviewModeEnabledSub$: Subject<void> = new Subject<void>();
  reviewModeEnabled = false;
  isSelected = false;
  fieldBlockDragListId = `${
    EDragDropItemsIds.DefaultFieldListListItems
  }${alphaNanoIdUtil(5)}`;
  connectedBlockIds: string[] = [];
  formBuilderManipulator!: FormBuilderManipulatorClass;

  constructor(
    private formBuilderService: FormBuilderService,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
    this.formBuilderManipulator = new FormBuilderManipulatorClass(
      this.injector
    );
  }

  ngOnInit(): void {
    this.formBuilderService.addToFormFieldBlockIdsSet(
      this.fieldBlockDragListId
    );

    this.formBuilderService.reviewModeEnabled$
      .pipe(takeUntil(this.reviewModeEnabledSub$))
      .subscribe((isEnabled: boolean) => {
        this.reviewModeEnabled = isEnabled;
      });

    this.formBuilderService.formFieldBlockIdsSet$
      .pipe(takeUntil(this.reviewModeEnabledSub$))
      .subscribe((blockIdSet: Set<string>) => {
        const fieldIds = new Set(Array.from(blockIdSet));
        fieldIds.delete(this.fieldBlockDragListId);
        fieldIds.add(EDragDropItemsIds.MainBuilderFieldTypesListItems);
        fieldIds.add(EDragDropItemsIds.MainBuilderWidgetsListItems);
        this.connectedBlockIds = Array.from(fieldIds);
        this.changeDetectorRef.detectChanges();
      });
  }

  isValid(field: FormlyFieldConfig): boolean {
    if (field.key && field.formControl) {
      return field.formControl.valid;
    }

    return field.fieldGroup
      ? field.fieldGroup.every(f => this.isValid(f))
      : true;
  }

  dropBlock(event: CdkDragDrop<any[]>) {
    if (
      event.previousContainer.id ===
        <string>EDragDropItemsIds.MainBuilderFieldTypesListItems ||
      event.previousContainer.id ===
        <string>EDragDropItemsIds.MainBuilderWidgetsListItems
    ) {
      const newFieldItem: FormlyFieldConfig = event.item.data;
      this.addNewFieldItemToFormlyForm(newFieldItem);
      return;
    }

    this.formBuilderManipulator.moveBlock(event);
    return;
  }

  private addNewFieldItemToFormlyForm(
    newFieldItem: FormlyFieldConfig,
    setAsConfiguredItem = true
  ): void {
    newFieldItem.key = `${newFieldItem.key}${alphaNanoIdUtil(5)}`;
    if (this.field.fieldGroup) {
      this.field.fieldGroup.push(newFieldItem);
    } else {
      this.field.fieldGroup = [newFieldItem];
    }
    this.formBuilderService.refreshFormlyForm();

    if (setAsConfiguredItem) {
      this.formBuilderService.configureFieldAction$.next({
        ...newFieldItem,
        parent: this.field,
      });
    }
  }

  ngOnDestroy(): void {
    this.reviewModeEnabledSub$.next();
    this.reviewModeEnabledSub$.complete();
  }
}
