<section>
  <header class="d-flex align-items-center justify-content-end pb-3">
    <div class="settings d-flex align-items-center">
      <i
        aria-hidden="true"
        class="fas fa-trash text-danger me-2 cursor"
        (click)="remove()"></i>
    </div>
  </header>
  <div>
    <ng-container #fieldComponent></ng-container>
  </div>
</section>
