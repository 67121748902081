import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  IBaseConfigurationFormModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  baseFieldParseIntoSettingsFormModel,
  baseFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import {
  getFileSizeValidatorFormConfig,
  getRequiredValidatorFormConfig,
  getAllowedFileFormatsValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import {
  parseAllowedFileFormatsValidatorParseFieldIntoModel,
  parseFileSizeValidatorParseFieldIntoModel,
  parseRequiredValidatorParseFieldIntoModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import {
  setRequiredValidatorUpdateFieldFromModel,
  setFileSizeValidatorFieldFromModel,
  setAllowedFileFormatsValidatorFieldFromModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';

const defaultcustomFileUploadConfigFormModel: IBaseConfigurationFormModel = {
  ...fieldsBaseConfigurationFormModel(),
};

export const customFileUploadFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultcustomFileUploadConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getFileSizeValidatorFormConfig(),
        getAllowedFileFormatsValidatorFormConfig(),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const customFileUploadFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  return baseFieldParseIntoSettingsFormModel(field);
};

export const customFileUploadFieldUpdateFromSettingsFormModel = (
  model: IBaseConfigurationFormModel,
  inputField: FormlyFieldConfig
): FormlyFieldConfig => {
  return baseFieldUpdateFromSettingsFormModel(model, inputField);
};

export const customFileUploadFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, field);
  model = parseFileSizeValidatorParseFieldIntoModel(model, field);
  model = parseAllowedFileFormatsValidatorParseFieldIntoModel(model, field);

  return model;
};

export const customFileUploadFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  inputField: FormlyFieldConfig
): FormlyFieldConfig => {
  inputField = setRequiredValidatorUpdateFieldFromModel(model, inputField);
  inputField = setFileSizeValidatorFieldFromModel(model, inputField);
  inputField = setAllowedFileFormatsValidatorFieldFromModel(model, inputField);

  return inputField;
};

export const customFileUploadFieldParseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const customFileUploadFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
