import { FormlyFieldConfig } from '@ngx-formly/core';

export const DefaultEmptyFormData: Record<string, FormlyFieldConfig[]> = {
  fields: [],
};
export interface IFormBuilderFieldsData {
  fields: FormlyFieldConfig[];
  forceReload?: boolean;
}

export type FormlyFieldConfigForConverter = {
  type: string;
  validators: any | null;
  fieldGroup: any[];
  [key: string]: any;
};

export type FormlyFields = Record<string, { fields: FormlyFieldConfig[] }>;
