import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivate,
} from '@angular/router';
import { RolesEnum } from '@irembo-andela/irembogov3-common';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RolesGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private keyCloakService: KeycloakService
  ) {}

  async canActivate(
    _: ActivatedRouteSnapshot,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const allowedRoles: RolesEnum[] = _.data['roles'];
    const forceLogout: boolean | undefined = _.data['forceLogout'];

    if (!(await this.keyCloakService.isLoggedIn())) {
      await this.redirectToLogin();

      return false;
    }
    await this.authService.loadUserIremboRoles();
    return this.checkRoles(allowedRoles, forceLogout);
  }

  async checkRoles(
    allowedRoles: RolesEnum[],
    forceLogout: boolean | undefined
  ): Promise<boolean> {
    const userActiveRole: RolesEnum =
      this.authService.userActiveRole.getValue();

    if (allowedRoles.indexOf(userActiveRole) === -1) {
      return await this.checkForceLogout(forceLogout);
    }

    return true;
  }

  async checkForceLogout(forceLogout: boolean | undefined): Promise<boolean> {
    if (forceLogout) {
      await this.authService.logoutUser();
    } else {
      this.router.navigate(['/']);
    }
    return false;
  }

  async redirectToLogin(): Promise<void> {
    await this.keyCloakService.login({
      redirectUri:
        window.location.origin + this.router.routerState.snapshot.url,
    });
  }
}
