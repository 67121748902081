import { RolesEnum } from '@irembo-andela/irembogov3-common';
import { MainNavigationBarLinkItems } from '../config/nav-bar-link-items.config';
import { INavbarAllowedRolesItem } from '../models/navbar-allowed-roles-item.model';

export const GetUserRoleNavigationMenuItems = (
  role: RolesEnum
): INavbarAllowedRolesItem[] => {
  const navItems: INavbarAllowedRolesItem[] = [];
  Object.keys(MainNavigationBarLinkItems).forEach((key: string) => {
    if (!MainNavigationBarLinkItems[key].allowedRoles) {
      navItems.push(MainNavigationBarLinkItems[key]);
      return;
    }

    if (MainNavigationBarLinkItems[key].allowedRoles?.includes(role)) {
      navItems.push(MainNavigationBarLinkItems[key]);
    }
  });

  return navItems;
};
