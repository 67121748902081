import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  EFieldValidationPatternConfigOptions,
  IFormFieldsValidationModel,
} from '../../../models/form-fields-formly-validation.model';
import { FieldValidationPatternConfigRegexToEnum } from '../../field-validation-pattern-config-conversion.utils';
import { EIremboFormlyValidationTypes } from '@irembo-andela/irembogov3-common';
import { ValidationTypeDefaultMessage } from '../form-builder-validation-type-message.utils';
import { AttachmentFieldFileFormat } from '../../../constants/attachment-field-file-format-emum-to-types.enum';

export const parseRequiredValidatorParseFieldIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const message = field.validation?.messages?.[
    EIremboFormlyValidationTypes.required
  ]
    ? String(
        field.validation?.messages?.[EIremboFormlyValidationTypes.required]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.required,
    message ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.required)
  );

  const requriedStatus: boolean | undefined = field?.props?.['defaultRequired']
    ? true
    : field?.props?.required;

  model.required = requriedStatus;
  return model;
};

export const parseMinLengthValidatorParseFieldIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const message = field.validation?.messages?.[
    EIremboFormlyValidationTypes.minLength
  ]
    ? String(
        field.validation?.messages?.[EIremboFormlyValidationTypes.minLength]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.minLength,
    message ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.minLength)
  );

  model.minLength = field.props?.minLength ?? undefined;

  return model;
};

export const parseMaxLengthValidatorParseFieldIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const message = field.validation?.messages?.[
    EIremboFormlyValidationTypes.maxLength
  ]
    ? String(
        field.validation?.messages?.[EIremboFormlyValidationTypes.maxLength]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.maxLength,
    message ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.maxLength)
  );

  model.maxLength = field.props?.maxLength ?? undefined;

  return model;
};

export const parsePatternValidatorParseFieldIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const patternType: EFieldValidationPatternConfigOptions =
    FieldValidationPatternConfigRegexToEnum(field?.props?.pattern);

  const message = field.validation?.messages?.[
    EIremboFormlyValidationTypes.pattern
  ]
    ? String(field.validation?.messages?.[EIremboFormlyValidationTypes.pattern])
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.pattern,
    message ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.pattern)
  );

  if (field?.props?.pattern) {
    model.patternValidation = {
      patternType: patternType,
      customPattern: String(field.props.pattern),
    };
  }

  return model;
};

export const parseUniqueFieldValidatorFormDataIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let fieldKeys: string[] = [];
  if (field?.validators?.validation) {
    field.validators.validation.map((v: any) => {
      if (
        v?.name === EIremboFormlyValidationTypes.uniqueField &&
        v?.options?.fieldKeys
      ) {
        const keyItems: string[] = v.options.fieldKeys;
        fieldKeys = [...fieldKeys, ...keyItems];
      }
    });
  }

  const message = field.validation?.messages?.[
    EIremboFormlyValidationTypes.uniqueField
  ]
    ? String(
        field.validation?.messages?.[EIremboFormlyValidationTypes.uniqueField]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.uniqueField,
    message ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.uniqueField)
  );

  model.uniqueField = fieldKeys.length > 0 ? fieldKeys : undefined;

  return model;
};

export const parsePopulatesFieldValidatorFormDataIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const invalidIdMessage = field.validation?.messages?.[
    EIremboFormlyValidationTypes.invalidId
  ]
    ? String(
        field.validation?.messages?.[EIremboFormlyValidationTypes.invalidId]
      )
    : undefined;
  const invalidInputMessage = field.validation?.messages?.[
    EIremboFormlyValidationTypes.invalidInput
  ]
    ? String(
        field.validation?.messages?.[EIremboFormlyValidationTypes.invalidInput]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.invalidId,
    invalidIdMessage ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.invalidId)
  );
  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.invalidInput,
    invalidInputMessage ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.invalidInput)
  );

  return model;
};

export const parseFileSizeValidatorParseFieldIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const minimumUploadSizeMessage = field.validation?.messages?.[
    EIremboFormlyValidationTypes.minimumUploadSize
  ]
    ? String(
        field.validation?.messages?.[
          EIremboFormlyValidationTypes.minimumUploadSize
        ]
      )
    : undefined;

  const maximumUploadSizeMessage = field.validation?.messages?.[
    EIremboFormlyValidationTypes.maximumUploadSize
  ]
    ? String(
        field.validation?.messages?.[
          EIremboFormlyValidationTypes.maximumUploadSize
        ]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.minimumUploadSize,
    minimumUploadSizeMessage ??
      ValidationTypeDefaultMessage(
        EIremboFormlyValidationTypes.minimumUploadSize
      )
  );

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.maximumUploadSize,
    maximumUploadSizeMessage ??
      ValidationTypeDefaultMessage(
        EIremboFormlyValidationTypes.maximumUploadSize
      )
  );

  model.fileSize = {
    minimumUploadSize: field.props?.['minimumUploadSize'] ?? undefined,
    maximumUploadSize: field.props?.['maximumUploadSize'] ?? undefined,
  };

  return model;
};

export const parseAllowedFileFormatsValidatorParseFieldIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const allowedFileFormatsMessage = field.validation?.messages?.[
    EIremboFormlyValidationTypes.invalidfileformat
  ]
    ? String(
        field.validation?.messages?.[
          EIremboFormlyValidationTypes.invalidfileformat
        ]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.invalidfileformat,
    allowedFileFormatsMessage ??
      ValidationTypeDefaultMessage(
        EIremboFormlyValidationTypes.invalidfileformat
      )
  );

  if (!field.props?.['allowedFormats']) {
    model.fileFormats = undefined;
  } else {
    Object.keys(AttachmentFieldFileFormat).forEach((key: string) => {
      if (!model.fileFormats) {
        model.fileFormats = {};
      }
      model.fileFormats[key] = checkIfFieldHasAttachmentEnumFormatFileType(
        key,
        field?.props?.['allowedFormats']
      );
    });
  }

  return model;
};

export const parseInvalidIntlPhoneNumberFormatValidatorParseFieldIntoModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  const invalidNumberFormatMessage = field.validation?.messages?.[
    EIremboFormlyValidationTypes.invalidNumberFormat
  ]
    ? String(
        field.validation?.messages?.[
          EIremboFormlyValidationTypes.invalidNumberFormat
        ]
      )
    : undefined;

  const invalidNumberMessage = field.validation?.messages?.[
    EIremboFormlyValidationTypes.invalidNumber
  ]
    ? String(
        field.validation?.messages?.[EIremboFormlyValidationTypes.invalidNumber]
      )
    : undefined;

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.invalidNumberFormat,
    invalidNumberFormatMessage ??
      ValidationTypeDefaultMessage(
        EIremboFormlyValidationTypes.invalidNumberFormat
      )
  );

  updatedValidationMessages(
    model,
    EIremboFormlyValidationTypes.invalidNumber,
    invalidNumberMessage ??
      ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.invalidNumber)
  );

  model.minLength = field.props?.minLength ?? undefined;

  return model;
};

const updatedValidationMessages = (
  model: IFormFieldsValidationModel,
  messageKey: EIremboFormlyValidationTypes,
  message: string | undefined
): IFormFieldsValidationModel => {
  if (!message) {
    return clearValidationMessageByKey(model, messageKey);
  }

  if (model.validationMessages) {
    model.validationMessages[messageKey] = message;
    return model;
  }

  if (!model.validationMessages) {
    model.validationMessages = {};
    model.validationMessages[messageKey] = message;
    return model;
  }

  return model;
};

const clearValidationMessageByKey = (
  model: IFormFieldsValidationModel,
  messageKey: EIremboFormlyValidationTypes
): IFormFieldsValidationModel => {
  if (model.validationMessages) {
    delete model.validationMessages[messageKey];
  }
  return model;
};

const checkIfFieldHasAttachmentEnumFormatFileType = (
  format: string,
  fieldFileFormats: string[] | undefined
): boolean => {
  let fieldHasFileFormat = false;
  if (!fieldFileFormats) return fieldHasFileFormat;

  const fileTypes: string[] = AttachmentFieldFileFormat[format].value;

  fileTypes.some((type: string) => {
    if (fieldFileFormats.includes(type)) {
      fieldHasFileFormat = true;
      return true;
    }
    return false;
  });

  return fieldHasFileFormat;
};
