/* eslint-disable @typescript-eslint/no-inferrable-types */
import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  IUiAlertContent,
  TUiAlertTypes,
  ToastService,
  ExtractHttpErrorResponseCodeAndMessage,
  IHttpErrorResponseCodeAndMessage,
  IHttpSingleDataResponse,
} from '@irembo-andela/irembogov3-common';
import {
  IError,
  JsonEditorComponent,
  JsonEditorOptions,
} from '@maaxgr/ang-jsoneditor';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { ServiceChangeRequestService } from '../../../../core/services/service-change-request.service';
import { Subject, finalize, takeUntil } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ValidationErrors } from '@angular/forms';
import { IFieldError } from '../../../../core/models/service-change-request-config-steps-forms.model';
import { ExtractHttpErrorFieldErrorsUtil } from '../../../../core/utils/http-field-error-extractor.utils';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import {
  DefaultEmptyFormData,
  IFormBuilderFieldsData,
} from '../../../../core/models/form-builder-fields-data.model';
import { EServiceChangeRequestSection } from '../../../../core/models/service-change-request-sections.enum';
import { EServiceChangeRequestToggleHeaders } from '../../../../core/models/service-change-request-toggle-header-section.enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IIremboFormlyFieldOnlyAllowedProps } from '../../../../core/models/irembo-formly-field-allowed-props.type';
import { FormlyFieldArrayToIremboFormlyFieldsConverter } from '../../../../core/utils/irembo-formly-field-allow-pops.utils';

@Component({
  selector: 'irembogov-configuration-form-builder',
  templateUrl: './configuration-form-builder.component.html',
  styleUrls: ['./configuration-form-builder.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationFormBuilderComponent implements OnInit, OnDestroy {
  @Input() changeRequest!: IServiceChangeRequest;
  @Output() jsonFormBuilderFormSubmitted: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('errorMessageElement') errorMessageElement!: ElementRef;
  @ViewChild(JsonEditorComponent) editor!: JsonEditorComponent;

  DefaultEmptyFormData = DefaultEmptyFormData;

  EServiceChangeRequestSection = EServiceChangeRequestSection;
  EServiceChangeRequestToggleHeaders = EServiceChangeRequestToggleHeaders;

  showConfigFormErrorMessage = false;
  jsonEditorValidationErrors: ValidationErrors[] = [];

  public editorOptions: JsonEditorOptions;
  jsonEditorFormData!: Record<string, IIremboFormlyFieldOnlyAllowedProps[]>;
  isDirtyPreview = false;
  previewLoaded = false;

  formlyFieldsSubjectControl$: Subject<void> = new Subject<void>();

  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };
  isLoading = false;

  constructor(
    private toastService: ToastService,
    private serviceChangeRequestService: ServiceChangeRequestService,
    private formBuilderService: FormBuilderService
  ) {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code'];

    this.editorOptions.onValidate = (json: unknown) =>
      this.checkJsonFormDataIsNotEmpty(json as Record<string, unknown>);
  }

  ngOnInit(): void {
    this.loadJsonEditorFormData({
      fields: this.formBuilderService.formlyFieldsSubject$.getValue().fields,
    });

    this.formBuilderService.formlyFieldsSubject$
      .pipe(takeUntil(this.formlyFieldsSubjectControl$))
      .subscribe((formBuilderFieldsData: IFormBuilderFieldsData) => {
        if (formBuilderFieldsData.forceReload) {
          this.loadJsonEditorFormData({
            fields: [...formBuilderFieldsData.fields],
          });
        }
      });

    this.formBuilderService.reviewModeEnabled$.next(false);
  }

  async validateJsonEditor(): Promise<void> {
    this.isDirtyPreview = true;
    this.previewLoaded = false;
    const editorJson: any = this.editor.getEditor();
    this.jsonEditorValidationErrors = await editorJson.validate();
    if (this.jsonEditorValidationErrors.length > 0) return;
    this.formBuilderService.loadJsonFormDataForPreview(editorJson.get());
  }

  async onFormSubmit(): Promise<void> {
    this.isLoading = true;
    await this.validateJsonEditor();

    if (!this.changeRequest.id) return;
    if (!this.checkIfStepIsValid()) return;

    this.updateFormDisplayedErrorMessageContent(false);

    const { fields } = this.formBuilderService.formlyFieldsSubject$.getValue();

    this.serviceChangeRequestService
      .updateServiceChangeRequestFormDefinition(this.changeRequest.id, {
        fields,
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (res: IHttpSingleDataResponse<Record<string, unknown>>) => {
          this.toastService.show({
            body: res.responseMessage,
            type: 'success',
          });
          this.jsonFormBuilderFormSubmitted.emit(this.checkIfStepIsValid());
        },
        error: (error: HttpErrorResponse) => {
          const errorMessageAndCode: IHttpErrorResponseCodeAndMessage =
            ExtractHttpErrorResponseCodeAndMessage(
              error,
              `Failed to update Change request form definition`
            );

          this.toastService.show({
            body: errorMessageAndCode.message,
            type: 'error',
          });

          const fieldErrors: IFieldError[] =
            ExtractHttpErrorFieldErrorsUtil(error);

          this.updateFormDisplayedErrorMessageContent(
            true,
            'danger',
            'Form Definition Not Updated',
            errorMessageAndCode.message,
            fieldErrors
          );

          this.jsonFormBuilderFormSubmitted.emit(false);
        },
      });
  }

  updateFormDisplayedErrorMessageContent(
    show: boolean,
    type: TUiAlertTypes = 'warning',
    title: string = '',
    message: string = '',
    fieldErrors: IFieldError[] | null = null
  ): void {
    let extraMessage = '';
    this.showConfigFormErrorMessage = show;

    if (fieldErrors) {
      extraMessage = fieldErrors
        .map((field: IFieldError) => `${field.field}: ${field?.errorCode}`)
        .join(`<br/>\n`);
    }

    this.formAlertContent = { type, title, message, extraMessage };

    if (show) {
      this.errorMessageElement.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  private checkIfStepIsValid(): boolean {
    if (
      this.jsonEditorValidationErrors.length > 0 ||
      !this.jsonEditorFormData
    ) {
      this.updateFormDisplayedErrorMessageContent(
        true,
        'danger',
        'Invalid JSON Entry!',
        'Please ensure you have filled a valid JSON form entry.'
      );
      return false;
    }

    return true;
  }

  loadJsonEditorFormData(formlyFields: Record<string, FormlyFieldConfig[]>) {
    if (!formlyFields['fields']) {
      this.jsonEditorFormData = { fields: [] };
    }
    this.jsonEditorFormData = {
      fields:
        FormlyFieldArrayToIremboFormlyFieldsConverter(formlyFields['fields']) ??
        [],
    };
  }

  private checkJsonFormDataIsNotEmpty(json: Record<string, unknown>): IError[] {
    const errors: IError[] = [];

    const propsArray: string[] = Object.keys(json);
    if (propsArray.includes('isTrusted')) {
      propsArray.splice(propsArray.indexOf('isTrusted'), 1);
    }

    if (propsArray.length < 1) {
      errors.push({
        path: [1],
        message: 'JSON Object can not be empty.',
      });
    }

    if (propsArray.indexOf('fields') < 0) {
      errors.push({
        path: [1],
        message: 'Missing top level "fields" property.',
      });
    }

    return errors;
  }

  onTabSelectChanged(event: EServiceChangeRequestToggleHeaders) {
    if (event === EServiceChangeRequestToggleHeaders.CODE_EDITOR) {
      this.editor.setMode('code');
    }
    if (event !== EServiceChangeRequestToggleHeaders.WIZARD) {
      this.formBuilderService.reviewModeEnabled$.next(true);
    } else {
      this.formBuilderService.reviewModeEnabled$.next(false);
    }
    this.formBuilderService.refreshFormlyForm();
  }

  ngOnDestroy(): void {
    this.formlyFieldsSubjectControl$.next();
    this.formlyFieldsSubjectControl$.complete();
    this.formBuilderService.configureFieldAction$.next(null);
  }
}
