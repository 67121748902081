<div class="irembogov-configuration-form-builder mt-5 w-100">
  <div class="w-100">
    <div #errorMessageElement class="px-5">
      <irembogov-irembo-section-message
        *ngIf="showConfigFormErrorMessage"
        [type]="formAlertContent.type"
        [title]="formAlertContent.title"
        [message]="formAlertContent.message"
        [extraMessage]="formAlertContent.extraMessage"
        [extraMessageAsHtml]="true"></irembogov-irembo-section-message>
    </div>
  </div>
  <div class="w-100 d-flex p-3">
    <div class="flex-grow-1 me-3 preview-section-wrapper">
      <div class="preview-section">
        <div class="mb-3">
          <div class="preview-box">
            <div
              class="preview-box-invalid"
              *ngIf="this.jsonEditorValidationErrors.length > 0">
              Invalid Json Object in Code Editor
            </div>
            <div class="preview-box-view">
              <irembogov-preview-service-change-request-json-formdata-widget></irembogov-preview-service-change-request-json-formdata-widget>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="justify-self w-40 side-pane-wrapper">
      <irembogov-service-change-request-feedback-handler-widget
        *ngIf="changeRequest"
        (selectOptionChanged)="onTabSelectChanged($event)"
        [changeRequestId]="changeRequest.id ?? ''"
        [serviceChangeRequestSection]="EServiceChangeRequestSection.FORM"
        [selectedOption]="EServiceChangeRequestToggleHeaders.WIZARD">
        <div wizard>
          <irembogov-form-field-configurator></irembogov-form-field-configurator>
        </div>
        <div codeEditor>
          <div class="code-editor-section">
            <div class="mb-3">
              <div class="form-group">
                <div class="input-group mb-3">
                  <json-editor
                    [options]="editorOptions"
                    id="jsonFormData"
                    class="form-control p-0 json-form-data-field"
                    (change)="validateJsonEditor()"
                    inputMode="code"
                    [data]="
                      jsonEditorFormData
                        ? jsonEditorFormData
                        : DefaultEmptyFormData
                    "
                    #jsonFormData>
                  </json-editor>
                </div>
                <div
                  class="form-error w-100 small text-danger"
                  *ngIf="jsonEditorValidationErrors.length > 0">
                  JSON input is invalid
                  <ol>
                    <li
                      class="w-100 small"
                      *ngFor="
                        let error of jsonEditorValidationErrors;
                        let index = index
                      ">
                      <em class="text-muted"> Line {{ error['line'] }}</em>
                      <br />
                      <small [innerHTML]="error['message']"></small>
                    </li>
                  </ol>
                </div>
                <div
                  class="form-error w-100 small text-danger"
                  *ngIf="!jsonEditorFormData">
                  Please enter a JSON Form entry
                </div>
              </div>
            </div>
          </div>
        </div>
      </irembogov-service-change-request-feedback-handler-widget>

      <div class="px-0">
        <div class="text-end">
          <button
            (click)="onFormSubmit()"
            class="btn btn-primary mt-1 mx-auto px-5"
            type="button"
            [disabled]="
              isLoading ||
              jsonEditorValidationErrors.length > 0 ||
              !jsonEditorFormData
            ">
            <div
              *ngIf="isLoading"
              class="spinner-border spinner-border-sm mr-2"
              role="status"></div>
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
