<div
  class="irembogov-settings-toolbar settings d-flex justify-content-end align-items-center">
  <i aria-hidden="true" class="fas fa-clone ms-3 config-setting"></i>
  <i
    aria-hidden="true"
    class="fas fa-cog ms-3 config-setting"
    (click)="configureField()"></i>
  <i
    aria-hidden="true"
    class="fas fa-trash-can ms-3 config-setting"
    (click)="deleteAction()"></i>
  <i
    aria-hidden="true"
    class="fas fa-flag pointer-cursor ms-3 review-setting"
    (click)="setIssueTitleForField(label, type)"></i>
</div>
