<div class="irembogov-change-request-configuration-header-widget">
  <header class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <span
        class="list-services-back-button h5 me-2 mb-0 border-end align-self-center"
        placement="end"
        ngbTooltip="Back to List of Services"
        (click)="goBackToServices()">
        <i class="fa-solid fa-angles-left me-2"></i>
      </span>
      <span class="me-2"
        ><i aria-hidden="true" class="fas fa-file-lines"></i
      ></span>
      <span
        class="requirement-doc-link cursor"
        (click)="OnClickRequirementDoc.emit(true)">
        Requirement Doc
      </span>
    </div>

    <div *ngIf="request">
      <div>
        <span class="me-2">{{ request.serviceName }}</span>
        <span>
          <irembogov-service-change-request-type-badge
            [requestType]="
              request.type
            "></irembogov-service-change-request-type-badge>
        </span>
      </div>
      <p class="mb-0 text-center">
        {{ formPersistenceStatus }}
        <i
          *ngIf="formPersistenceStatus === 'Saving Changes...'"
          class="fas fa-spinner fa-spin"></i>
      </p>
    </div>

    <div>
      <span
        class="d-flex align-items-center {{
          jsonFormFields ? [] : 'opacity-50'
        }}">
        Preview Form
        <irembogov-basic-toggle-switch
          (switchChanged)="OnPreviewClick.emit($event)"
          uniqueId="previewRef"
          [disabled]="
            jsonFormFields ? false : true
          "></irembogov-basic-toggle-switch>

        <ng-container *ngIf="!inReviewMode">
          <button class="save sm-btn mx-2" (click)="OnSaveAndClose.emit(true)">
            Save & Close
            <i
              *ngIf="isSaveAndCloseLoading"
              aria-hidden="true"
              class="fas fa-spinner fa-spin ms-2"></i>
          </button>
          <button
            class="submit sm-btn btn"
            [disabled]="!readyForSaveSubmit"
            (click)="OnSaveSubmit.emit(true)">
            Submit to QA
          </button>
        </ng-container>
      </span>
    </div>
  </header>
</div>
