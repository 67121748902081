import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { EServiceChangeRequestReviewSection } from '../../../../core/models/service-change-request-review-issue.model';

@Component({
  selector: 'irembogov-review-metadata-overview',
  templateUrl: './review-metadata-overview.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ReviewMetadataOverviewComponent {
  @Input() changeRequest!: IServiceChangeRequest;
  @Output() reviewMetadataCompleted: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  EServiceChangeRequestReviewSection = EServiceChangeRequestReviewSection;
}
