import { FormlyFieldConfig } from '@ngx-formly/core';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';

export const parseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  let displayRuleModel: IFormFieldsDisplayModel = {};
  if (field?.props?.['displayRuleModel']) {
    displayRuleModel = <IFormFieldsDisplayModel>field.props['displayRuleModel'];
  }

  return displayRuleModel;
};
