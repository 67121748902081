import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ServiceManagementPortalHomeComponent } from './components/service-management-portal-home/service-management-portal-home.component';
import { AuthGuard, RolesEnum } from '@irembo-andela/irembogov3-common';
import { RolesGuard } from './core/guards/roles.guard';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    component: ServiceManagementPortalHomeComponent,
    canActivate: [AuthGuard, RolesGuard],
    data: {
      roles: [RolesEnum.ROLE_QA_REVIEWER, RolesEnum.ROLE_SERVICE_MANAGER],
      forceLogout: true,
    },
    children: [
      { path: '', redirectTo: 'services', pathMatch: 'full' },
      {
        path: 'services',
        loadChildren: () =>
          import('./modules/services/services.module').then(
            m => m.ServicesModule
          ),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then(m => m.UsersModule),
      },
      {
        path: 'reporting',
        loadChildren: () =>
          import('./modules/reporting/reporting.module').then(
            m => m.ReportingModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
