import { ServiceChangeRequestService } from './../../../../core/services/service-change-request.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IServiceChangeRequest } from './../../../../core/models/service-change-request.model';
import { BehaviorSubject, finalize, of, switchMap } from 'rxjs';
import { IServiceLanguage } from './../../../../core/models/service-language.model';
import {
  ExtractHttpErrorResponseMessage,
  FilterFieldsToQueryParamsBuilder,
  updateApiFilter,
  IFilterAndPaginationFieldsBase,
  IUiAlertContent,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { EServiceChangeRequestReviewSection } from './../../../../core/models/service-change-request-review-issue.model';

interface IFilterFields extends IFilterAndPaginationFieldsBase {
  name: string | null;
  keywords: string | null;
}

@Component({
  selector: 'irembogov-review-translations',
  templateUrl: './review-translations.component.html',
  styleUrls: ['./review-translations.component.scss'],
})
export class ReviewTranslationsComponent implements OnChanges, OnInit {
  @Input() changeRequest!: IServiceChangeRequest;
  @Input() translationsData!: Record<string, Record<string, unknown>>;
  @Output() reviewTranslationsCompleted: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  isLoadingLanguages = false;
  serviceLanguages: IServiceLanguage[] = [];
  serviceLanguagesCollectionSize = 0;
  activeLanguage!: IServiceLanguage | undefined;
  languageKeys: string[] = [];
  translations: Record<string, any> = {};
  selectedTranslation: Record<string, unknown> = {};
  EServiceChangeRequestReviewSection = EServiceChangeRequestReviewSection;
  page = 0;
  pageSize = 8;

  showConfigFormErrorMessage = false;
  _filterObject: BehaviorSubject<IFilterFields> =
    new BehaviorSubject<IFilterFields>({
      page: 0,
      size: 8,
      name: '',
      keywords: '',
    });

  formAlertContent: IUiAlertContent = {
    title: '',
    message: '',
    type: 'warning',
  };

  constructor(
    private serviceChangeRequestService: ServiceChangeRequestService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this._filterObject
      .asObservable()
      .pipe(
        switchMap((filter: IFilterFields) => {
          const queryParams: string =
            FilterFieldsToQueryParamsBuilder<IFilterFields>(filter);
          this.getServiceLanguages(queryParams, filter.page ?? 0);
          return of();
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['translationsData']?.currentValue) {
      this.translationsData = changes['translationsData'].currentValue;
      this.languageKeys = Object.keys(this.translationsData['translations']);
      this.translations = this.translationsData['translations'];
    }
  }

  getServiceLanguages(queryString: string, page: number) {
    this.isLoadingLanguages = true;
    this.serviceChangeRequestService
      .getServiceLanguages(queryString)
      .pipe(finalize(() => (this.isLoadingLanguages = false)))
      .subscribe({
        next: response => {
          this.serviceLanguages =
            page > 0
              ? this.serviceLanguages?.concat(response?.data?.content)
              : response?.data?.content;
          this.serviceLanguagesCollectionSize =
            response.data.totalPages * response.data.size;
          console.log(this.serviceLanguagesCollectionSize);
          this.activeLanguage = this.serviceLanguages[0];
          this.updateSelectedTranslation(this.activeLanguage?.locale ?? '');
        },
        error: err => {
          const errorMessage = ExtractHttpErrorResponseMessage(
            err,
            'Error getting services'
          );
          this.toastService.show({ body: errorMessage, type: 'error' });
        },
      });
  }
  private updateSelectedTranslation(langLocale: string) {
    if (langLocale === '') return;
    this.selectedTranslation = this.translations[langLocale];
  }

  updateLanguageTranslationsByLanguage(language: IServiceLanguage) {
    this.activeLanguage = language;
    this.updateSelectedTranslation(language.locale);
  }

  loadMoreLanguages(): void {
    if (this.serviceLanguages.length >= this.serviceLanguagesCollectionSize)
      return;
    this.page++;
    updateApiFilter(this._filterObject, {
      page: this.page,
      size: this.pageSize,
    });
  }
}
