import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  IBaseConfigurationFormModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  baseFieldParseIntoSettingsFormModel,
  baseFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import {
  getRequiredValidatorFormConfig,
  getUniqueFieldValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import {
  parseInvalidIntlPhoneNumberFormatValidatorParseFieldIntoModel,
  parseRequiredValidatorParseFieldIntoModel,
  parseUniqueFieldValidatorFormDataIntoModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import {
  setInvalidIntlPhoneNumberFormatUpdateFieldFromModel,
  setRequiredValidatorUpdateFieldFromModel,
  setUniqueFieldValidatorFormDataIntoField,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';

const defaultnternationalPhoneNumberConfigFormModel: IBaseConfigurationFormModel =
  {
    ...fieldsBaseConfigurationFormModel(),
  };

export const internationalPhoneNumberFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultnternationalPhoneNumberConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getUniqueFieldValidatorFormConfig(formBuilderServiceInstance),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const internationalPhoneNumberFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  return baseFieldParseIntoSettingsFormModel(field);
};

export const internationalPhoneNumberFieldUpdateFromSettingsFormModel = (
  model: IBaseConfigurationFormModel,
  internationalPhoneNumberField: FormlyFieldConfig
): FormlyFieldConfig => {
  return baseFieldUpdateFromSettingsFormModel(
    model,
    internationalPhoneNumberField
  );
};

export const internationalPhoneNumberFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, field);
  model = parseUniqueFieldValidatorFormDataIntoModel(model, field);
  model = parseInvalidIntlPhoneNumberFormatValidatorParseFieldIntoModel(
    model,
    field
  );

  return model;
};

export const internationalPhoneNumberFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  internationalPhoneNumberField: FormlyFieldConfig
): FormlyFieldConfig => {
  internationalPhoneNumberField = setRequiredValidatorUpdateFieldFromModel(
    model,
    internationalPhoneNumberField
  );

  internationalPhoneNumberField = setUniqueFieldValidatorFormDataIntoField(
    model,
    internationalPhoneNumberField
  );

  internationalPhoneNumberField =
    setInvalidIntlPhoneNumberFormatUpdateFieldFromModel(
      model,
      internationalPhoneNumberField
    );

  return internationalPhoneNumberField;
};

export const internationalPhoneNumberFieldParseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const internationalPhoneNumberFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
