import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  EFieldDisplayComparedValueCheckedStatus,
  IFormFieldsDisplayModel,
  IFormFieldsDisplayRule,
} from '../../../models/form-fields-formly-display.model';
import {
  EFormBuilderDisplayComparatorActions,
  EFormBuilderDisplayConditionComparator,
  EFormBuilderDisplayFieldComparator,
} from '../../../models/form-builder-display-comparator.enum';
import {
  EIremboFormlyFieldSubTypes,
  EIremboFormlyFieldTypes,
} from '@irembo-andela/irembogov3-common';

export const setDisplayHideExpressionFromModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  if (!model?.displayRules || !model.action || model.displayRules.length < 1) {
    delete field?.expressions?.['hide'];
    delete field?.props?.['displayRuleModel'];
    return field;
  }
  const displayRuleAction: EFormBuilderDisplayComparatorActions = model.action;

  const displayRuleTypes: Record<
    EFormBuilderDisplayConditionComparator,
    IFormFieldsDisplayRule[]
  > = {
    [EFormBuilderDisplayConditionComparator.AND]: [],
    [EFormBuilderDisplayConditionComparator.OR]: [],
  };

  model.displayRules.forEach((rule: IFormFieldsDisplayRule) => {
    if (!rule.comparator) {
      rule.comparator = EFormBuilderDisplayConditionComparator.OR;
    }
    if (rule.comparator === EFormBuilderDisplayConditionComparator.OR) {
      displayRuleTypes[EFormBuilderDisplayConditionComparator.OR].push(rule);
    }
    if (rule.comparator === EFormBuilderDisplayConditionComparator.AND) {
      displayRuleTypes[EFormBuilderDisplayConditionComparator.AND].push(rule);
    }
  });

  if (field?.props) {
    field.props['displayRuleModel'] = model;
  } else {
    field.props = {
      displayRuleActions: model,
    };
  }

  let propsHideFunction = generateAndAndOrQueries(displayRuleTypes);

  if (displayRuleAction === EFormBuilderDisplayComparatorActions.SHOW) {
    propsHideFunction = `!(${propsHideFunction})`;
  }

  if (field?.expressions) {
    field.expressions['hide'] = <string>propsHideFunction;
  } else {
    field.expressions = {
      hide: <string>propsHideFunction,
    };
  }
  return field;
};

const generateAndAndOrQueries = (
  actionDisplayGroup: Record<
    EFormBuilderDisplayConditionComparator,
    IFormFieldsDisplayRule[]
  >
): string => {
  const andQueries: string[] = [];
  actionDisplayGroup[EFormBuilderDisplayConditionComparator.AND].forEach(
    actionDisplayRule => {
      return displayExpressionBuilder(actionDisplayRule, andQueries);
    }
  );
  const orQueries: string[] = [];
  actionDisplayGroup[EFormBuilderDisplayConditionComparator.OR].forEach(
    actionDisplayRule => {
      return displayExpressionBuilder(actionDisplayRule, orQueries);
    }
  );

  if (orQueries.length < 1) {
    return andQueries.join(' && ');
  }

  if (andQueries.length < 1) {
    return orQueries.join(' || ');
  }

  return `(${orQueries.join(' || ')}) && (${andQueries.join(' && ')})`;
};

const displayExpressionBuilder = (
  displayRule: IFormFieldsDisplayRule,
  collationArray: string[]
): string[] => {
  const expRootModelPath: string | null | undefined =
    displayRule.compareField.rootModelPath;
  const expCompareFieldRefPath: string | null | undefined =
    displayRule.compareField.fieldRefPath;
  let expCondition = '';
  let expComparedValue = '';
  switch (displayRule.condition) {
    case EFormBuilderDisplayFieldComparator.IS: {
      expCondition = '===';
      if (displayRule.comparedValues.isChecked) {
        expComparedValue =
          displayRule.comparedValues.isChecked ===
          EFieldDisplayComparedValueCheckedStatus.isChecked
            ? 'true'
            : 'false';
        break;
      }
      expComparedValue = displayRule.comparedValues.isValue;
      break;
    }
    case EFormBuilderDisplayFieldComparator.IS_ABOVE: {
      expCondition = '>';
      expComparedValue = displayRule.comparedValues.isValue;
      break;
    }
    case EFormBuilderDisplayFieldComparator.IS_BELOW: {
      expCondition = '<';
      expComparedValue = displayRule.comparedValues.isValue;
      break;
    }
    case EFormBuilderDisplayFieldComparator.IS_EQUAL_TO: {
      expCondition = '===';
      expComparedValue = displayRule.comparedValues.isValue;
      break;
    }
    case EFormBuilderDisplayFieldComparator.IS_NOT_EQUAL_TO: {
      expCondition = '!==';
      expComparedValue = displayRule.comparedValues.isValue;
      break;
    }
  }

  if (
    !(
      expRootModelPath &&
      expCompareFieldRefPath &&
      expCondition &&
      expComparedValue
    )
  )
    return collationArray;

  if (displayRule.compareFieldType === EIremboFormlyFieldTypes.multicheckbox) {
    if (
      displayRule.condition ===
      EFormBuilderDisplayFieldComparator.IS_NOT_EQUAL_TO
    ) {
      collationArray.push(
        `!${expRootModelPath}?.${expCompareFieldRefPath}?.includes('${expComparedValue}')`
      );
    } else {
      collationArray.push(
        `${expRootModelPath}?.${expCompareFieldRefPath}?.includes('${expComparedValue}')`
      );
    }
  } else if (
    [
      EIremboFormlyFieldTypes.customdropdown,
      EIremboFormlyFieldTypes.customdropdownpaginated,
    ].includes(displayRule.compareFieldType as EIremboFormlyFieldTypes)
  ) {
    if (
      [
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET,
        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED,
      ].includes(displayRule.compareField.fieldSubType)
    ) {
      collationArray.push(
        `${expRootModelPath}?.${expCompareFieldRefPath}?.value ${expCondition} '${displayRule.comparedValues.isValue.value}'`
      );
    } else {
      collationArray.push(
        `${expRootModelPath}?.${expCompareFieldRefPath} ${expCondition} '${expComparedValue}'`
      );
    }
  } else {
    if (typeof expComparedValue === 'number') {
      collationArray.push(
        `${expRootModelPath}?.${expCompareFieldRefPath} ${expCondition} ${expComparedValue}`
      );
    }

    collationArray.push(
      `${expRootModelPath}?.${expCompareFieldRefPath} ${expCondition} '${expComparedValue}'`
    );
  }

  return collationArray;
};
