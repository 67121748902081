import { FormlyFieldConfig } from '@ngx-formly/core';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import {
  FieldValidationPatternConfigEnumToDescription,
  FieldValidationPatternConfigEnumToRegexString,
} from '../../field-validation-pattern-config-conversion.utils';
import { EIremboFormlyValidationTypes } from '@irembo-andela/irembogov3-common';
import { ValidationTypeDefaultMessage } from '../form-builder-validation-type-message.utils';
import { AttachmentFieldFileFormat } from '../../../constants/attachment-field-file-format-emum-to-types.enum';

export const setRequiredValidatorUpdateFieldFromModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.required);

  const requiredStatus: boolean = model.required ?? false;

  if (field.props) {
    field.props.required = requiredStatus;
    field.props['defaultRequired'] = requiredStatus;
  } else {
    field.props = {
      required: requiredStatus,
      defaultRequired: requiredStatus,
    };
  }

  const propsRequiredExpression =
    '!field?.props?.hideField && field?.props?.defaultRequired';

  if (field.expressions) {
    field.expressions = {
      ...field.expressions,
      'props.required': propsRequiredExpression,
    };
  } else {
    field.expressions = {
      'props.required': propsRequiredExpression,
    };
  }

  const requiredMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.required] ??
    ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.required);

  if (requiredStatus) {
    updateFieldMessages(
      field,
      EIremboFormlyValidationTypes.required,
      requiredMessage
    );
  }

  return field;
};

export const setMinLengthValidatorUpdateFieldFromModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.minLength);
  let minLength: number | undefined = model.minLength ?? undefined;
  minLength = minLength === 0 ? undefined : minLength;
  if (field.props) {
    field.props.minLength = minLength;
  } else {
    field.props = {
      minLength,
    };
  }

  const minLengthMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.minLength] ??
    ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.minLength);

  if (minLength) {
    updateFieldMessages(
      field,
      EIremboFormlyValidationTypes.minLength,
      minLengthMessage
    );
  }

  return field;
};

export const setMaxLengthValidatorUpdateFieldFromModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.maxLength);
  let maxLength: number | undefined = model.maxLength ?? undefined;
  maxLength = maxLength === 0 ? undefined : maxLength;

  if (field.props) {
    field.props.maxLength = maxLength;
  } else {
    field.props = {
      maxLength,
    };
  }

  const maxLengthMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.maxLength] ??
    ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.maxLength);

  if (maxLength) {
    updateFieldMessages(
      field,
      EIremboFormlyValidationTypes.maxLength,
      maxLengthMessage
    );
  }

  return field;
};

export const setPatternValidatorUpdateFieldFromModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.pattern);

  let regexPattern: string | undefined =
    FieldValidationPatternConfigEnumToRegexString(
      model.patternValidation?.patternType,
      model.patternValidation?.customPattern
    );
  let patternDescription: string | undefined =
    FieldValidationPatternConfigEnumToDescription(
      model.patternValidation?.patternType
    ) ?? `pattern match ${model.patternValidation?.customPattern}`;

  if (!model.patternValidation?.patternType) {
    regexPattern = undefined;
    patternDescription = undefined;
  }

  const patternMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.pattern] ??
    `${ValidationTypeDefaultMessage(
      EIremboFormlyValidationTypes.pattern
    )} : accepts ${patternDescription}`;

  if (regexPattern) {
    updateFieldMessages(
      field,
      EIremboFormlyValidationTypes.pattern,
      patternMessage
    );
  }

  if (field.props) {
    field.props.pattern = regexPattern;
  } else {
    field.props = {
      pattern: regexPattern,
    };
  }
  return field;
};

export const setUniqueFieldValidatorFormDataIntoField = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.uniqueField);

  if (field?.validators?.validation) {
    const filteredValidators: Record<string, unknown>[] =
      field.validators.validation.filter(
        (v: any) => v?.name !== EIremboFormlyValidationTypes.uniqueField
      );
    field.validators.validation = filteredValidators;
  } else if (field.validators) {
    field.validators.validation = [];
  } else {
    field.validators = { validation: [] };
  }

  if (!model.uniqueField || model.uniqueField.length < 1) return field;

  field.validators.validation.push({
    name: EIremboFormlyValidationTypes.uniqueField,
    options: { fieldKeys: model.uniqueField },
  });

  const uniqueFieldMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.uniqueField] ??
    ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.uniqueField);

  updateFieldMessages(
    field,
    EIremboFormlyValidationTypes.uniqueField,
    uniqueFieldMessage
  );

  return field;
};

export const setPopulatesFieldValidatorFormDataIntoField = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.invalidId);
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.invalidInput);

  const invalidIdMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.invalidId] ??
    ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.invalidId);

  updateFieldMessages(
    field,
    EIremboFormlyValidationTypes.invalidId,
    invalidIdMessage
  );

  const invalidInputMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.invalidInput] ??
    ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.invalidInput);
  updateFieldMessages(
    field,
    EIremboFormlyValidationTypes.invalidInput,
    invalidInputMessage
  );

  return field;
};

export const setFileSizeValidatorFieldFromModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.minimumUploadSize);
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.maximumUploadSize);

  const minimumUploadSize: number | undefined =
    model.fileSize?.minimumUploadSize ?? undefined;
  const maximumUploadSize: number | undefined =
    model.fileSize?.maximumUploadSize ?? undefined;

  if (field.props) {
    field.props['minimumUploadSize'] = minimumUploadSize;
  } else {
    field.props = {
      minimumUploadSize,
    };
  }

  if (field.props) {
    field.props['maximumUploadSize'] = maximumUploadSize;
  } else {
    field.props = {
      maximumUploadSize,
    };
  }

  if (minimumUploadSize) {
    const minimumUploadSizeMessage: string =
      model.validationMessages?.[
        EIremboFormlyValidationTypes.minimumUploadSize
      ] ??
      ValidationTypeDefaultMessage(
        EIremboFormlyValidationTypes.minimumUploadSize
      );

    field = updateFieldMessages(
      field,
      EIremboFormlyValidationTypes.minimumUploadSize,
      minimumUploadSizeMessage
    );
  }

  if (maximumUploadSize) {
    const maximumUploadSizeMessage: string =
      model.validationMessages?.[
        EIremboFormlyValidationTypes.maximumUploadSize
      ] ??
      ValidationTypeDefaultMessage(
        EIremboFormlyValidationTypes.maximumUploadSize
      );

    field = updateFieldMessages(
      field,
      EIremboFormlyValidationTypes.maximumUploadSize,
      maximumUploadSizeMessage
    );
  }

  return field;
};

export const setAllowedFileFormatsValidatorFieldFromModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(field, EIremboFormlyValidationTypes.invalidfileformat);

  let allowedFormats: string[] | undefined = [];

  Object.keys(AttachmentFieldFileFormat).forEach(key => {
    if (model?.fileFormats?.[key]) {
      allowedFormats = [
        ...AttachmentFieldFileFormat[key].value,
        ...(allowedFormats ?? []),
      ];
    }
  });

  if (field.props) {
    field.props['allowedFormats'] = allowedFormats;
  } else {
    field.props = {
      allowedFormats,
    };
  }

  if (allowedFormats.length > 0) {
    const allowedFormatsMessage: string =
      model.validationMessages?.[
        EIremboFormlyValidationTypes.invalidfileformat
      ] ??
      ValidationTypeDefaultMessage(
        EIremboFormlyValidationTypes.invalidfileformat
      );

    field = updateFieldMessages(
      field,
      EIremboFormlyValidationTypes.invalidfileformat,
      allowedFormatsMessage
    );
  }

  return field;
};
export const setInvalidIntlPhoneNumberFormatUpdateFieldFromModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  clearFieldMessageByKey(
    field,
    EIremboFormlyValidationTypes.invalidNumberFormat
  );

  const invalidNumberFormatMessage: string =
    model.validationMessages?.[
      EIremboFormlyValidationTypes.invalidNumberFormat
    ] ??
    ValidationTypeDefaultMessage(
      EIremboFormlyValidationTypes.invalidNumberFormat
    );

  const invalidNumberMessage: string =
    model.validationMessages?.[EIremboFormlyValidationTypes.invalidNumber] ??
    ValidationTypeDefaultMessage(EIremboFormlyValidationTypes.invalidNumber);

  updateFieldMessages(
    field,
    EIremboFormlyValidationTypes.invalidNumberFormat,
    invalidNumberFormatMessage
  );

  updateFieldMessages(
    field,
    EIremboFormlyValidationTypes.invalidNumber,
    invalidNumberMessage
  );
  return field;
};

const updateFieldMessages = (
  field: FormlyFieldConfig,
  messageKey: EIremboFormlyValidationTypes,
  message: string | undefined
): FormlyFieldConfig => {
  if (!message) {
    return clearFieldMessageByKey(field, messageKey);
  }

  if (field.validation?.messages) {
    field.validation.messages[messageKey] = message;
    return field;
  }

  if (!field.validation?.messages) {
    const messages: Record<string, string> = {};
    messages[messageKey] = message;
    field.validation = { messages };
    return field;
  }

  return field;
};

const clearFieldMessageByKey = (
  field: FormlyFieldConfig,
  messageKey: EIremboFormlyValidationTypes
): FormlyFieldConfig => {
  if (field.validation?.messages) {
    delete field.validation.messages[messageKey];
  }
  return field;
};
