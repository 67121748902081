<div
  class="irembogov-formly-field-group card mb-4"
  cdkDrag
  [cdkDragData]="field"
  [cdkDragDisabled]="reviewModeEnabled || field.props?.['dragDisabled']"
  [ngClass]="{ 'item-selected-highlight': isSelected }">
  <div class="card-header bg-transparent border-bottom h5 my-3 d-flex">
    <span>{{ field.props?.label }}</span>
    <irembogov-settings-toolbar
      *ngIf="!reviewModeEnabled"
      class="ms-auto d-flex"
      [label]="field.props?.label"
      [type]="field | fieldTypeAsString"
      [field]="field"
      (fieldConfigActionEvent)="
        isSelected = $event
      "></irembogov-settings-toolbar>
  </div>
  <div
    class="card-body border-0"
    cdkDropList
    [cdkDropListData]="field.fieldGroup ?? []"
    (cdkDropListDropped)="dropBlock($event)"
    [cdkDropListDisabled]="reviewModeEnabled"
    [cdkDropListConnectedTo]="connectedBlockIds"
    [id]="fieldBlockDragListId"
    #droplists>
    <div class="mb-3" *ngIf="field.props?.placeholder">
      {{ field.props?.placeholder }}
    </div>

    <div
      class="irembogov-formly-block"
      cdkDrag
      [cdkDragDisabled]="reviewModeEnabled || childField.props?.['dragDisabled']"
      *ngFor="
        let childField of field.fieldGroup;
        let index = index;
        let last = last
      ">
      <formly-field [field]="childField"></formly-field>
    </div>
  </div>
</div>
