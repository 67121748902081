import { Pipe, PipeTransform } from '@angular/core';
import { EServiceChangeRequestReviewSection } from '../models/service-change-request-review-issue.model';

@Pipe({ name: 'reviewSectionToLabelPipe' })
export class ReviewSectionToLabelPipe implements PipeTransform {
  transform(status: EServiceChangeRequestReviewSection | undefined): string {
    switch (status) {
      case 'OVERVIEW':
        return 'Overview';
      case 'FORM':
        return 'Form';
      case 'PRICING':
        return 'Pricing';
      case 'WORKFLOW':
        return 'Workflow';
      default:
        return '';
    }
  }
}
