import { FormBuilderFieldValidationPatterns } from '../config/form-builder-field-validation-patterns.config';
import { EFieldValidationPatternConfigOptions } from '../models/form-fields-formly-validation.model';

export const FieldValidationPatternConfigRegexToEnum = (
  string: RegExp | string | undefined
): EFieldValidationPatternConfigOptions => {
  switch (string) {
    case FormBuilderFieldValidationPatterns.emailsValidationRegex.source:
      return EFieldValidationPatternConfigOptions.EMAIL;
    case FormBuilderFieldValidationPatterns.lettersAtoZInputValidationRegex
      .source:
      return EFieldValidationPatternConfigOptions.LETTERS_A_TO_Z;
    case FormBuilderFieldValidationPatterns.numbers0To9InputValidationRegex
      .source:
      return EFieldValidationPatternConfigOptions.NUMBERS_0_TO_9;
    case FormBuilderFieldValidationPatterns.symbolsInputValidationRegex.source:
      return EFieldValidationPatternConfigOptions.SYMBOLS;
    case FormBuilderFieldValidationPatterns
      .lettersAndNumbersInputValidationRegex.source:
      return EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS;
    case FormBuilderFieldValidationPatterns
      .lettersAndNumbersAndSymbolsInputValidationRegex.source:
      return EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS_AND_SYMBOLS;
    default:
      return EFieldValidationPatternConfigOptions.CUSTOM;
  }
};

export const FieldValidationPatternConfigEnumToRegexString = (
  configOptionEnum: EFieldValidationPatternConfigOptions | undefined,
  customPattern?: string
): string | undefined => {
  switch (configOptionEnum) {
    case EFieldValidationPatternConfigOptions.EMAIL:
      return FormBuilderFieldValidationPatterns.emailsValidationRegex.source;
    case EFieldValidationPatternConfigOptions.LETTERS_A_TO_Z:
      return FormBuilderFieldValidationPatterns.lettersAtoZInputValidationRegex
        .source;
    case EFieldValidationPatternConfigOptions.NUMBERS_0_TO_9:
      return FormBuilderFieldValidationPatterns.numbers0To9InputValidationRegex
        .source;
    case EFieldValidationPatternConfigOptions.SYMBOLS:
      return FormBuilderFieldValidationPatterns.symbolsInputValidationRegex
        .source;
    case EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS:
      return FormBuilderFieldValidationPatterns
        .lettersAndNumbersInputValidationRegex.source;
    case EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS_AND_SYMBOLS:
      return FormBuilderFieldValidationPatterns
        .lettersAndNumbersAndSymbolsInputValidationRegex.source;
    default:
      return customPattern;
  }
};

export const FieldValidationPatternConfigEnumToDescription = (
  configOptionEnum: EFieldValidationPatternConfigOptions | undefined
): string | undefined => {
  switch (configOptionEnum) {
    case EFieldValidationPatternConfigOptions.EMAIL:
      return 'only valid email';
    case EFieldValidationPatternConfigOptions.LETTERS_A_TO_Z:
      return 'letters a - z';
    case EFieldValidationPatternConfigOptions.NUMBERS_0_TO_9:
      return 'digits 0 - 9';
    case EFieldValidationPatternConfigOptions.SYMBOLS:
      return `symbols([!@#$%^&*()_-+?’”;:,<>])`;
    case EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS:
      return 'digits 0 - 9 and letters a - z';
    case EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS_AND_SYMBOLS:
      return 'letters, digits and and symbols';
    default:
      return undefined;
  }
};
