import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  ICustomIdInputFieldSettingsModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  baseFieldUpdateFromSettingsFormModel,
  populatesPropsParseIntoSettingsFormModel,
  populatesPropsUpdateFromSettingsFormModel,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import {
  parsePopulatesFieldValidatorFormDataIntoModel,
  parseRequiredValidatorParseFieldIntoModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import {
  setRequiredValidatorUpdateFieldFromModel,
  setPopulatesFieldValidatorFormDataIntoField,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { getGenericCustomIdFieldTypeconfigurationConfig } from './shared.configuration';
import { ninIdResponseProps } from '../../../models/custom-id-input-responses.model';

export const ninFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return getGenericCustomIdFieldTypeconfigurationConfig(
    formBuilderServiceInstance,
    [...Array.from(ninIdResponseProps)]
  );
};

export const ninFieldParseIntoSettingsFormModel = (
  ninFieldField: FormlyFieldConfig
): ICustomIdInputFieldSettingsModel => {
  return populatesPropsParseIntoSettingsFormModel(ninFieldField);
};

export const ninFieldUpdateFromSettingsFormModel = (
  model: ICustomIdInputFieldSettingsModel,
  ninFieldField: FormlyFieldConfig
): FormlyFieldConfig => {
  ninFieldField = baseFieldUpdateFromSettingsFormModel(model, ninFieldField);
  ninFieldField = populatesPropsUpdateFromSettingsFormModel(
    model,
    ninFieldField
  );

  return ninFieldField;
};

export const ninFieldParseIntoValidationFormModel = (
  ninFieldField: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, ninFieldField);
  model = parsePopulatesFieldValidatorFormDataIntoModel(model, ninFieldField);

  return model;
};

export const ninFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  ninFieldField: FormlyFieldConfig
): FormlyFieldConfig => {
  ninFieldField = setRequiredValidatorUpdateFieldFromModel(
    model,
    ninFieldField
  );
  ninFieldField = setPopulatesFieldValidatorFormDataIntoField(
    model,
    ninFieldField
  );

  return ninFieldField;
};

export const ninFieldParseFieldIntoDisplayFormModel = (
  ninFieldField: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(ninFieldField);
};

export const ninFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  ninFieldField: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, ninFieldField);
};
