import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IGenericLabelValueFieldsConfigFormModel,
  IFormbuilderFieldTypeConfiguration,
} from '../../../models/form-builder-types-configurations.model';
import {
  generateGenericLabelValueSettingsContentConfig,
  genericLabelValueFieldParseIntoSettingsFormModel,
  genericLabelValueFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import { parseRequiredValidatorParseFieldIntoModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import { setRequiredValidatorUpdateFieldFromModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { getRequiredValidatorFormConfig } from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';

const defaultMultiCheckboxConfigFormModel: IGenericLabelValueFieldsConfigFormModel =
  {
    ...fieldsBaseConfigurationFormModel(),
    contentSection: { optionLabelValuePairs: [{ label: '', value: '' }] },
    disabled: false,
  };

export const multiCheckboxFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultMultiCheckboxConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
        generateGenericLabelValueSettingsContentConfig('Checkbox Options'),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [getRequiredValidatorFormConfig()],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const multiCheckboxFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IGenericLabelValueFieldsConfigFormModel => {
  return genericLabelValueFieldParseIntoSettingsFormModel(field);
};

export const multiCheckboxFieldUpdateFromSettingsFormModel = (
  model: IGenericLabelValueFieldsConfigFormModel,
  multiCheckboxField: FormlyFieldConfig
): FormlyFieldConfig => {
  return genericLabelValueFieldUpdateFromSettingsFormModel(
    model,
    multiCheckboxField
  );
};

export const multiCheckboxFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};
  model = parseRequiredValidatorParseFieldIntoModel(model, field);
  return model;
};

export const multiCheckboxFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  inputField: FormlyFieldConfig
): FormlyFieldConfig => {
  inputField = setRequiredValidatorUpdateFieldFromModel(model, inputField);
  return inputField;
};

export const multiCheckboxFieldParseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const multiCheckboxFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
