<div class="review-metadata-overview w-100 d-flex p-3" *ngIf="changeRequest">
  <div class="flex-grow-1 mw-70">
    <irembogov-configuration-metadata-overview-form
      [inReviewMode]="true"
      [changeRequest]="changeRequest"
      class="col-md-8">
    </irembogov-configuration-metadata-overview-form>
  </div>
  <irembogov-reviewer-issues-entry-form-widget
    class="justify-self-end w-30"
    [changeRequestId]="changeRequest.id"
    [reviewSection]="EServiceChangeRequestReviewSection.OVERVIEW"
    (goToNext)="
      reviewMetadataCompleted.emit($event)
    "></irembogov-reviewer-issues-entry-form-widget>
</div>
