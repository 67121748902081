import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  EIremboFormlyFieldTypes,
  EIremboFormlyFieldSubTypes,
} from '@irembo-andela/irembogov3-common';
import { GetEnumToLabelAndValueOptions } from '../../form-builder-field-comparator-dropdown-options.utils';
import {
  EFormBuilderDisplayComparatorActions,
  EFormBuilderDisplayConditionComparator,
  EFormBuilderDisplayFieldComparator,
  FormBuilderFieldsWithDataSet,
  FormBuilderFieldsWithOptions,
} from '../../../models/form-builder-display-comparator.enum';
import { TFormlyFieldKey } from '../../../models/formly-key.type';
import { IBasicLabelValuePair } from '../../../models/basic-label-value-pair.model';
import { FormBuilderService } from '../../../services/form-builder.service';

export const getFieldDisplayFormConfig = (
  formBuilderServiceInstance: FormBuilderService
): FormlyFieldConfig[] => {
  return [
    {
      key: 'action',
      type: EIremboFormlyFieldTypes.customdropdown,
      className: 'form-builder-display-action-selector',
      props: {
        label: 'This field when',
        placeholder: 'Show or Hide',
        required: true,
        options: GetEnumToLabelAndValueOptions(
          EFormBuilderDisplayComparatorActions
        ),
        bindLabel: 'label',
        bindValue: 'value',
      },
    },
    {
      key: 'displayRules',
      type: EIremboFormlyFieldTypes.customrepeater,
      className: 'form-builder-display-rules',
      props: {
        hideHeader: true,
        required: false,
        addText: 'Add Condition',
      },
      fieldArray: {
        key: 'displayRulesGroup',
        className: 'form-builder-display-rules-group',
        type: EIremboFormlyFieldTypes['formly-group'],
        props: {
          hideHeader: true,
          placeholder: 'Display List',
          required: false,
        },
        fieldGroup: [
          {
            key: 'comparator',
            type: EIremboFormlyFieldTypes.customdropdown,
            props: {
              hideHeader: true,
              placeholder: 'AND or OR',
              required: false,
              options: GetEnumToLabelAndValueOptions(
                EFormBuilderDisplayConditionComparator
              ),
              bindLabel: 'label',
              bindValue: 'value',
            },
            expressions: {
              'props.required': (field: FormlyFieldConfig) => {
                return field?.parent?.key !== '0';
              },
              hide: (field: FormlyFieldConfig) => {
                return field?.parent?.key === '0';
              },
            },
          },
          {
            key: 'compareField',
            type: EIremboFormlyFieldTypes.select,
            props: {
              hideHeader: true,
              placeholder: 'Select Field',
              required: true,
              compareWith: (
                o1: IBasicLabelValuePair<TFormlyFieldKey>,
                o2: IBasicLabelValuePair<TFormlyFieldKey>
              ) => {
                return o1?.value === o2?.value;
              },
              options: formBuilderServiceInstance
                .getAllFormFieldLabelAndDetails(true, true, [
                  EIremboFormlyFieldTypes.customidinput,
                  EIremboFormlyFieldTypes.customcascadingdropdowns,
                ])
                .map((field: IBasicLabelValuePair<TFormlyFieldKey>) => {
                  return {
                    label: field.label,
                    value: field,
                  };
                }),
            },
          },
          {
            key: 'compareFieldType',
            type: EIremboFormlyFieldTypes.input,
            hide: true,
            props: {
              hideHeader: true,
              required: true,
            },
            expressions: {
              'model.compareFieldType': (field: FormlyFieldConfig) =>
                field.parent?.model?.compareField?.fieldType,
            },
          },
          {
            key: 'compareFieldPropsType',
            type: EIremboFormlyFieldTypes.input,
            hide: true,
            props: {
              hideHeader: true,
              required: true,
            },
            expressions: {
              'model.compareFieldPropsType': (field: FormlyFieldConfig) =>
                field.parent?.model?.compareField?.propsType,
            },
          },
          {
            key: 'condition',
            type: EIremboFormlyFieldTypes.select,
            props: {
              hideHeader: true,
              placeholder: 'select condition',
              required: true,
              options: GetEnumToLabelAndValueOptions(
                EFormBuilderDisplayFieldComparator
              ),
            },
            expressions: {
              'props.options': (field: FormlyFieldConfig) => {
                const options: IBasicLabelValuePair<string>[] =
                  GetEnumToLabelAndValueOptions(
                    EFormBuilderDisplayFieldComparator
                  );
                const compareFieldType: EIremboFormlyFieldTypes =
                  field.parent?.model?.compareField?.fieldType;
                if (!compareFieldType) {
                  return;
                }
                if (
                  FormBuilderFieldsWithOptions.includes(compareFieldType) ||
                  FormBuilderFieldsWithDataSet.includes(compareFieldType) ||
                  [
                    EIremboFormlyFieldTypes.customidinput,
                    EIremboFormlyFieldTypes.customphonenumber,
                    EIremboFormlyFieldTypes.textarea,
                  ].includes(compareFieldType)
                ) {
                  const allowedOptions: IBasicLabelValuePair<string>[] = [];
                  options.forEach(option => {
                    if (
                      [
                        EFormBuilderDisplayFieldComparator.IS_EQUAL_TO,
                        EFormBuilderDisplayFieldComparator.IS_NOT_EQUAL_TO,
                      ].includes(
                        option.value as EFormBuilderDisplayFieldComparator
                      )
                    ) {
                      allowedOptions.push(option);
                    }
                  });
                  return allowedOptions;
                } else if (compareFieldType === EIremboFormlyFieldTypes.input) {
                  const allowedOptions: IBasicLabelValuePair<string>[] = [];
                  options.forEach(option => {
                    if (
                      [
                        EFormBuilderDisplayFieldComparator.IS_EQUAL_TO,
                        EFormBuilderDisplayFieldComparator.IS_NOT_EQUAL_TO,
                        EFormBuilderDisplayFieldComparator.IS_ABOVE,
                        EFormBuilderDisplayFieldComparator.IS_BELOW,
                      ].includes(
                        option.value as EFormBuilderDisplayFieldComparator
                      )
                    ) {
                      allowedOptions.push(option);
                    }
                  });
                  return allowedOptions;
                } else {
                  return options;
                }
              },
              'model.condition': 'model?.condition',
            },
          },
          {
            key: 'comparedValues',
            type: EIremboFormlyFieldTypes['formly-group'],
            props: {
              hideHeader: true,
              placeholder: 'Compared Value',
            },
            fieldGroup: [
              {
                key: 'isValue',
                type: EIremboFormlyFieldTypes.customdropdownpaginated,
                props: {
                  hideHeader: true,
                  placeholder: 'Select',
                  options: [],
                  useBaseUrl: true,
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    !FormBuilderFieldsWithDataSet.includes(
                      field.parent?.parent?.model?.compareField?.fieldType
                    ) ||
                    FormBuilderFieldsWithOptions.includes(
                      field.parent?.parent?.model?.compareField?.fieldType
                    ) ||
                    field.parent?.parent?.model?.compareField?.subType ===
                      EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH,
                  'props.required': (field: FormlyFieldConfig) =>
                    checkForInputWithOptionsAllowedComparators(
                      field.parent?.parent?.model?.condition
                    ),
                  'props.dataset': (field: FormlyFieldConfig) => {
                    if (
                      field.parent?.parent?.model?.compareField?.fieldType ===
                      EIremboFormlyFieldTypes.customdropdownpaginated
                    ) {
                      return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                        field.parent?.parent?.model
                      )?.props?.['dataset'];
                    }
                  },
                  'props.bindLabel': (field: FormlyFieldConfig) => {
                    if (
                      field.parent?.parent?.model?.compareField?.fieldType ===
                      EIremboFormlyFieldTypes.customdropdownpaginated
                    ) {
                      return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                        field.parent?.parent?.model
                      )?.props?.['bindLabel'];
                    }
                  },
                  'props.bindValue': (field: FormlyFieldConfig) => {
                    return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                      field.parent?.parent?.model
                    )?.props?.['bindValue'];
                  },
                },
              },
              {
                key: 'isValue',
                type: EIremboFormlyFieldTypes.input,
                props: {
                  hideHeader: true,
                  placeholder: 'Compared Value',
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    FormBuilderFieldsWithOptions.includes(
                      field.parent?.parent?.model?.compareField?.fieldType
                    ) ||
                    FormBuilderFieldsWithDataSet.includes(
                      field.parent?.parent?.model?.compareField?.fieldType
                    ),
                  'props.required': (field: FormlyFieldConfig) =>
                    !FormBuilderFieldsWithOptions.includes(
                      field.parent?.parent?.model?.compareField?.fieldType
                    ),
                },
              },
              {
                key: 'isValue',
                type: EIremboFormlyFieldTypes.select,
                props: {
                  hideHeader: true,
                  placeholder: 'Select',
                  options: [],
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    !(
                      FormBuilderFieldsWithOptions.includes(
                        field.parent?.parent?.model?.compareField?.fieldType
                      ) &&
                      field.parent?.parent?.model?.compareField
                        ?.fieldSubType !==
                        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH &&
                      field.parent?.parent?.model?.compareField
                        ?.fieldSubType !==
                        EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET
                    ) ||
                    FormBuilderFieldsWithDataSet.includes(
                      field.parent?.parent?.model?.compareField?.fieldType
                    ),
                  'props.required': (field: FormlyFieldConfig) =>
                    checkForInputWithOptionsAllowedComparators(
                      field.parent?.parent?.model?.condition
                    ),
                  'props.options': (field: FormlyFieldConfig) => {
                    return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                      field.parent?.parent?.model
                    )?.props?.options;
                  },
                },
              },
              {
                key: 'isValue',
                type: EIremboFormlyFieldTypes.customdropdown,
                props: {
                  hideHeader: true,
                  placeholder: 'Select',
                  options: [],
                  useBaseUrl: true,
                  subType: EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH,
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    field.parent?.parent?.model?.compareField?.fieldSubType !==
                    EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH,
                  'props.required': (field: FormlyFieldConfig) =>
                    checkForInputWithOptionsAllowedComparators(
                      field.parent?.parent?.model?.condition
                    ),
                  'props.dataset': (field: FormlyFieldConfig) => {
                    if (
                      field.parent?.parent?.model?.compareField
                        ?.fieldSubType ===
                      EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH
                    ) {
                      return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                        field.parent?.parent?.model
                      )?.props?.['dataset'];
                    }
                  },
                  'props.bindLabel': (field: FormlyFieldConfig) => {
                    return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                      field.parent?.parent?.model
                    )?.props?.['bindLabel'];
                  },
                  'props.bindValue': (field: FormlyFieldConfig) => {
                    return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                      field.parent?.parent?.model
                    )?.props?.['bindValue'];
                  },
                  'props.code': (field: FormlyFieldConfig) => {
                    return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                      field.parent?.parent?.model
                    )?.props?.['code'];
                  },
                },
              },
              {
                key: 'isValue',
                type: EIremboFormlyFieldTypes.customdropdown,
                props: {
                  hideHeader: true,
                  placeholder: 'Select',
                  options: [],
                  useBaseUrl: true,
                  subType: EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET,
                },
                expressions: {
                  hide: (field: FormlyFieldConfig) =>
                    field.parent?.parent?.model?.compareField?.fieldSubType !==
                    EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET,
                  'props.required': (field: FormlyFieldConfig) =>
                    checkForInputWithOptionsAllowedComparators(
                      field.parent?.parent?.model?.condition
                    ),
                  'props.dataset': (field: FormlyFieldConfig) => {
                    if (
                      field.parent?.parent?.model?.compareField
                        ?.fieldSubType ===
                      EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET
                    ) {
                      return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                        field.parent?.parent?.model
                      )?.props?.['dataset'];
                    }
                  },
                  'props.bindLabel': (field: FormlyFieldConfig) => {
                    return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                      field.parent?.parent?.model
                    )?.props?.['bindLabel'];
                  },
                  'props.bindValue': (field: FormlyFieldConfig) => {
                    return formBuilderServiceInstance.getFormFieldOptionLabelAndValue(
                      field.parent?.parent?.model
                    )?.props?.['bindValue'];
                  },
                },
              },
            ],
          },
        ],
      },
    },
  ];
};

const checkForInputWithOptionsAllowedComparators = (value: string): boolean => {
  const allowedOptions: string[] = [
    EFormBuilderDisplayFieldComparator.IS_EQUAL_TO,
    EFormBuilderDisplayFieldComparator.IS_NOT_EQUAL_TO,
  ];
  return allowedOptions.indexOf(value) > -1;
};
