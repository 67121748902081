import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IIremboOrganization } from '../models/irembo-organization.model';
import { IHttpPagedResponse } from '@irembo-andela/irembogov3-common';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  getOrganizations(
    queryParams: string
  ): Observable<IHttpPagedResponse<IIremboOrganization>> {
    return this.http.get<IHttpPagedResponse<IIremboOrganization>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/organizations?${queryParams}`
    );
  }
}
