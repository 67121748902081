<ng-container *ngIf="requestStatus">
  <small
    class="badge me-auto irembogov-reviewer-issue-status-badge-widget"
    [ngClass]="{
      resolved: requestStatus === EServiceChangeRequestReviewStatus.RESOLVED,
      pending: requestStatus === EServiceChangeRequestReviewStatus.PENDING,
      removed: requestStatus === EServiceChangeRequestReviewStatus.REMOVED,
      new: requestStatus === EServiceChangeRequestReviewStatus.NEW
    }">
    {{ requestStatus | reviewIssueStatusToLabel }}
  </small>
</ng-container>
