<form [formGroup]="form" class="row mb-3" *ngIf="formFields && formGroup">
  <input formControlName="attributeType" type="hidden" />
  <div class="row pricing-fields">
    <div class="col-sm-2">
      <span class="col-xl-2 mt-3 mr-2 text-muted" *ngIf="index === 0"
        >When</span
      >
      <div
        *ngIf="index !== 0"
        class="mt-2 col-sm-2 ml-3"
        aria-label="Select Option">
        <span style="margin-left: 24px" class="col-xl-2 mt-3 mr-2 text-muted"
          >And</span
        >
      </div>
    </div>
    <div class="col-sm-3" id="form-fields">
      <ng-select
        class="currency-select"
        formControlName="attribute"
        (change)="onFieldChange($event)"
        placeholder="Field"
        #fieldSelector>
        <ng-option
          *ngFor="let field of formFieldsValues; trackBy: trackByKey"
          [value]="field?.key"
          class="border-0">
          {{ field?.props?.label ?? field?.label }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-sm-3">
      <ng-select
        class="currency-select"
        formControlName="comparator"
        placeholder="Comparator"
        #comparatorSelector
        style="width: auto">
        <ng-option
          *ngFor="let options of comparatorOptions; trackBy: trackByValue"
          [value]="options?.value"
          class="ml-2 border-0">
          {{ options?.label }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-sm-3">
      <input
        *ngIf="inputType === 'input'"
        formControlName="value"
        type="{{ attributeType }}"
        class="form-control form-control-sm mt-2"
        placeholder="Value" />
      <input
        *ngIf="inputType === 'date'"
        formControlName="value"
        type="date"
        class="form-control"
        placeholder="Value" />
      <irembogov-time-picker
        *ngIf="inputType === 'customtimepicker'"
        formControlName="value"
        type="tel"
        class="time-picker w-100"
        placeholder="Value"></irembogov-time-picker>
      <ng-select
        *ngIf="inputType === 'customphonenumber'"
        formControlName="value"
        class="phone-number w-100"
        placeholder="Value"
        #selectCountryCode>
        <ng-option
          *ngFor="let country of countryCodes"
          [value]="country.dial_code"
          class="ml-2 border-0">
          {{ country.flag + ' ' + country.dial_code }}
        </ng-option>
      </ng-select>
      <ng-select
        *ngIf="inputType === 'checkbox'"
        [items]="inputOptions"
        bindLabel="label"
        bindValue="value"
        class="radio"
        formControlName="value"
        [multiple]="false"
        placeholder="Value">
        <ng-template
          ng-optgroup-tmp
          let-item="item"
          let-item$="item$"
          let-index="index">
        </ng-template>
      </ng-select>
      <ng-select
        *ngIf="inputType === 'radio'"
        [items]="inputOptions"
        bindLabel="label"
        bindValue="value"
        class="radio"
        formControlName="value"
        [multiple]="false"
        placeholder="Value">
        <ng-template
          ng-optgroup-tmp
          let-item="item"
          let-item$="item$"
          let-index="index">
        </ng-template>
      </ng-select>
      <ng-select
        *ngIf="inputType === 'multicheckbox'"
        [items]="inputOptions"
        bindLabel="label"
        bindValue="value"
        class="multicheckbox"
        formControlName="value"
        placeholder="Value"
        [multiple]="false">
        <ng-template
          ng-optgroup-tmp
          let-item="item"
          let-item$="item$"
          let-index="index">
        </ng-template>
      </ng-select>
      <irembogov-irembo-date-picker
        *ngIf="inputType === 'customdatepicker'"
        formControlName="value"></irembogov-irembo-date-picker>
      <input
        *ngIf="inputType === 'cascading_customdropdown'"
        formControlName="value"
        class="form-control"
        placeholder="Value" />
      <irembogov-select
        *ngIf="inputType === 'customdropdown'"
        formControlName="value"
        [items]="inputOptions"
        placeholder="value"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"></irembogov-select>
      <input
        *ngIf="inputType === 'number'"
        formControlName="value"
        type="{{ attributeType }}"
        class="form-control form-control-sm mt-2"
        placeholder="Value" />
    </div>
    <span class="col-xl-1 pt-2 text-muted mt-1 ml-2" *ngIf="index !== 0">
      <a style="cursor: pointer" (click)="onRemoveCondition(index)">
        <i class="fa fa-trash"></i>
      </a>
    </span>
  </div>
</form>
