<div
  class="irembogov-service-request-item-pricing-widget w-100"
  *ngIf="serviceRequestPricingBase">
  <ng-container
    *ngIf="serviceRequestPricingBase.priceType === EServicePriceType.FIXED">
    <div class="my-1">
      Set {{ setPropertyLabelName }} to
      <span class="badge pricing-badge">{{
        serviceRequestPricingBase.baseValue
      }}</span>
    </div>
  </ng-container>

  <ng-container
    *ngIf="serviceRequestPricingBase.priceType === EServicePriceType.DYNAMIC">
    <div
      class="m-3"
      *ngFor="
        let scenario of serviceRequestPricingBase.dynamicConditions;
        let index = index
      ">
      <h6 class="text-primaryScenario text-primary">
        Scenario {{ index + 1 }}
      </h6>
      <div class="my-1">
        Set {{ setPropertyLabelName }} to
        <span class="badge pricing-badge">{{
          getDisplayPriceValue(scenario)
        }}</span>
      </div>
      <div
        class="my-1"
        *ngFor="
          let condition of scenario.conditions;
          let conditionIndex = index
        ">
        <span *ngIf="conditionIndex > 0">and</span> When
        <span class="badge pricing-badge">{{ condition.attribute }}</span> is
        <span class="badge pricing-badge me-1">{{
          condition.comparator | pricingDynamicOperatorToLabel
        }}</span>
        <span class="badge pricing-badge">{{ condition.value }}</span>
      </div>
    </div>
  </ng-container>
</div>
