import { Component, OnInit } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { FormBuilderService } from '../../../../core/services/form-builder.service';

@Component({
  selector: 'irembogov-formly-repeat',
  templateUrl: './formly-repeat.component.html',
  styleUrls: ['./formly-repeat.component.scss'],
})
export class FormlyRepeatComponent extends FieldArrayType implements OnInit {
  constructor(private formBuilderService: FormBuilderService) {
    super();
  }
  ngOnInit(): void {
    this.formBuilderService.removeItemAction$.subscribe(index => {
      this.remove(index);
    });
  }
}
