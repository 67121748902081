import { Component, Input } from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';
import { EServiceChangeRequestStatus } from '../../../../core/models/service-change-request-status.enum';
import { GetServiceChangeRequestStatusEnumLabelKeyPair } from '../../../../core/utils/change-request-status.utils';

@Component({
  selector: 'irembogov-service-change-request-status-badge',
  templateUrl: './service-change-request-status-badge.component.html',
  styleUrls: ['./service-change-request-status-badge.component.scss'],
})
export class ServiceChangeRequestStatusBadgeComponent {
  @Input() requestStatus!: EServiceChangeRequestStatus;

  getServiceChangeRequestStatusBadgeClass(
    requestStatus: EServiceChangeRequestStatus
  ): IIrembogovBasicLabelKeyPair<EServiceChangeRequestStatus> {
    return GetServiceChangeRequestStatusEnumLabelKeyPair(requestStatus);
  }
}
