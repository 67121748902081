import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IBaseConfigurationFormModel,
  IFormbuilderFieldTypeConfiguration,
} from '../../../models/form-builder-types-configurations.model';
import {
  generateNewFieldKeyFromSettingsLabel,
  getFieldLabelSettingsConfigFormly,
  getPlaceholderSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { FormBuilderService } from '../../../services/form-builder.service';

const defaultBlockConfigFormModel: IBaseConfigurationFormModel = {
  fieldLabel: null,
  placeholder: null,
};

export const blockFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultBlockConfigFormModel,
      formlyForm: [
        ...getFieldLabelSettingsConfigFormly(formBuilderServiceInstance),
        ...getPlaceholderSettingsConfigFormly(),
      ],
    },
  };
};

export const blockFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  return {
    placeholder: field.props?.placeholder ?? '',
    fieldLabel: field.props?.label ?? '',
  };
};

export const blockFieldUpdateFromSettingsFormModel = (
  model: IBaseConfigurationFormModel,
  blockField: FormlyFieldConfig
): FormlyFieldConfig => {
  if (blockField.props) {
    blockField.props['placeholder'] = model.placeholder ?? undefined;
    blockField.props['label'] = model.fieldLabel ?? undefined;
  } else {
    blockField['props'] = {
      label: model.fieldLabel ?? undefined,
      placeholder: model.placeholder ?? undefined,
    };
  }

  blockField = generateNewFieldKeyFromSettingsLabel(blockField);

  return blockField;
};
