import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { EServiceChangeRequestReviewSection } from '../../../../core/models/service-change-request-review-issue.model';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { Subject, takeUntil } from 'rxjs';
import { IFormBuilderFieldsData } from '../../../../core/models/form-builder-fields-data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'irembogov-review-formbuilder-json-editor',
  templateUrl: './review-formbuilder-json-editor.component.html',
  styleUrls: ['./review-formbuilder-json-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewFormbuilderJsonEditorComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() changeRequest!: IServiceChangeRequest;

  @Output() reviewFormBuilderJsonEditorCompleted: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  changeRequestJsonFormData!: Record<string, FormlyFieldConfig[]>;

  presetIssueFieldTitle = '';

  activeId: 1 | 2 = 1;

  EServiceChangeRequestReviewSection = EServiceChangeRequestReviewSection;

  private reviewFlagItemSubscription$ = new Subject<void>();

  constructor(private formBuilderService: FormBuilderService) {}

  ngOnInit(): void {
    this.formBuilderService.reviewFlagItemSubject$
      .pipe(takeUntil(this.reviewFlagItemSubscription$))
      .subscribe((fieldName: string) => {
        this.activeId = 2;
        this.presetIssueFieldTitle = fieldName;
      });
    this.formBuilderService.formlyFieldsSubject$
      .pipe(takeUntil(this.reviewFlagItemSubscription$))
      .subscribe((fieldsData: IFormBuilderFieldsData) => {
        this.activeId = 2;
        this.changeRequestJsonFormData = {
          fields: fieldsData.fields,
        };
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['changeRequestJsonFormData']?.currentValue) {
      this.formBuilderService.loadJsonFormDataForPreview(
        changes['changeRequestJsonFormData'].currentValue
      );
    }
  }

  ngOnDestroy(): void {
    this.reviewFlagItemSubscription$.next();
    this.reviewFlagItemSubscription$.complete();
  }
}
