import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IServiceChangeRequest } from '../models/service-change-request.model';
import {
  IHttpPagedResponse,
  IHttpSingleDataResponse,
  IHttpUnpagedResponse,
  RolesEnum,
} from '@irembo-andela/irembogov3-common';
import { IServiceCategory } from '../models/service-category.model';
import { IConfigStepChangeRequestMetaData } from '../models/service-change-request-config-steps-forms.model';
import { IWorkflowTemplate } from '../models/workflow-templates.model';
import { IServiceCurrency } from '../models/service-currency.model';
import { ISaveServiceChangeRequestReviewIssueRequest } from '../models/http/save-service-change-request-review-issue-request.model';
import { IServiceChangeRequestReviewIssue } from '../models/service-change-request-review-issue.model';
import { IServicePaymentMerchant } from '../models/http/service-payment-mercant.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IServiceLanguage } from '../models/service-language.model';
import { IIntegrationEndpoint } from '../models/http/integration-endpoints.model';
import { IInternalDataset } from '../models/internal-dataset.model';
@Injectable({
  providedIn: 'root',
})
export class ServiceChangeRequestService {
  saveForm$ = new Subject<string>();
  serviceFormSection = new Subject();
  saveFormFields = new Subject<void>();
  constructor(private http: HttpClient) {}

  /**
   *
   * @deprecated
   *
   */
  openDoc(data: string): void {
    const win = window.open();
    win?.document.write(
      '<iframe src="' +
        data +
        '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
    );
  }

  getAssignedServiceChangeRequests(
    queryParams: string,
    role: RolesEnum.ROLE_QA_REVIEWER | RolesEnum.ROLE_SERVICE_MANAGER
  ): Observable<IHttpPagedResponse<IServiceChangeRequest>> {
    const apiEnpointForRole: string =
      role === RolesEnum.ROLE_QA_REVIEWER ? 'qa-reviewer' : 'service-manager';
    return this.http.get<IHttpPagedResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${apiEnpointForRole}?${queryParams}`
    );
  }

  getAssignedServiceChangeRequestById(
    requestId: string
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequest>> {
    return this.http.get<IHttpSingleDataResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}`
    );
  }

  getServiceChangeRequestDetails(
    requestId: string
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequest>> {
    return this.http.get<IHttpSingleDataResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-request-details/${requestId}`
    );
  }

  updateServiceChangeRequestMetadata(
    requestId: string,
    metadata: IConfigStepChangeRequestMetaData
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequest>> {
    return this.http.put<IHttpSingleDataResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/meta-data`,
      metadata
    );
  }

  getServiceChangeRequestFormDefinition(
    requestId: string
  ): Observable<IHttpSingleDataResponse<Record<string, FormlyFieldConfig[]>>> {
    return this.http.get<
      IHttpSingleDataResponse<Record<string, FormlyFieldConfig[]>>
    >(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/form-definition`
    );
  }

  updateServiceChangeRequestFormDefinition(
    requestId: string,
    formDefinition: Record<string, unknown>
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http.put<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/form-definition`,
      formDefinition,
      { headers }
    );
  }

  getServiceChangeRequestWorkflowDefinition(
    requestId: string
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    return this.http.get<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/workflow-definition`
    );
  }

  updateServiceChangeRequestWorkflowDefinition(
    requestId: string,
    workflowDefinition: Record<string, unknown>
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http.put<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/workflow-definition`,
      workflowDefinition,
      { headers }
    );
  }

  getServiceChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime(
    requestId: string
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    return this.http.get<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/workflow-next-steps-processing-time`
    );
  }

  updateServiceChangeRequestWorkflowDefinitionAndNextStepsAndProcessingTime(
    requestId: string,
    workflowDefinition: Record<string, unknown>
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http.put<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/workflow-next-steps-processing-time`,
      workflowDefinition,
      { headers }
    );
  }

  updateServiceChangeRequestPricing(
    requestId: string,
    pricingData: Record<string, unknown>
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequest>> {
    return this.http.put<IHttpSingleDataResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/pricing`,
      pricingData
    );
  }

  getServiceChangeRequestPricingDetails(
    requestId: string
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    return this.http.get<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/pricing`
    );
  }

  getServiceCategories(
    queryString: string
  ): Observable<IHttpPagedResponse<IServiceCategory>> {
    return this.http.get<IHttpPagedResponse<IServiceCategory>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/categories?${queryString}`
    );
  }

  getServiceCategoryById(
    categoryId: string
  ): Observable<IHttpSingleDataResponse<IServiceCategory>> {
    return this.http.get<IHttpSingleDataResponse<IServiceCategory>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/categories/${categoryId}`
    );
  }

  getServiceCurrencies(
    queryString: string
  ): Observable<IHttpPagedResponse<IServiceCurrency>> {
    return this.http.get<IHttpPagedResponse<IServiceCurrency>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/currencies?${queryString}`
    );
  }

  getServiceLanguages(
    queryString: string
  ): Observable<IHttpPagedResponse<IServiceLanguage>> {
    return this.http.get<IHttpPagedResponse<IServiceLanguage>>(
      `${environment.translationSourceUrl}/admin/v1/languages?${queryString}`
    );
  }

  getPaymentMerchants(
    queryString: string
  ): Observable<IHttpPagedResponse<IServicePaymentMerchant>> {
    return this.http.get<IHttpPagedResponse<IServicePaymentMerchant>>(
      `${environment.apiGatewayBaseUrl}/application/v1/payment-merchants?${queryString}`
    );
  }

  getWorkflowTemplates(
    queryString: string
  ): Observable<IHttpPagedResponse<IWorkflowTemplate>> {
    return this.http.get<IHttpPagedResponse<IWorkflowTemplate>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/public/workflow-templates?${queryString}`
    );
  }

  getServiceChangeRequestTranslation(
    requestId: string
  ): Observable<
    IHttpSingleDataResponse<Record<string, Record<string, unknown>>>
  > {
    return this.http.get<
      IHttpSingleDataResponse<Record<string, Record<string, unknown>>>
    >(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/translation`
    );
  }

  getServiceChangeRequestThirdPartyFormDefination(
    requestId: string
  ): Observable<
    IHttpSingleDataResponse<Record<string, Record<string, unknown>>>
  > {
    return this.http.get<
      IHttpSingleDataResponse<Record<string, Record<string, unknown>>>
    >(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/third-party-form-definition`
    );
  }

  updateServiceChangeRequestTranslation(
    requestId: string,
    translations: Record<string, unknown>
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http.put<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/translation`,
      translations,
      { headers }
    );
  }

  submitChangeRequestToReviewer(
    changeRequestId: string
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequest>> {
    return this.http.put<IHttpSingleDataResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${changeRequestId}/action/send-to-qa`,
      {}
    );
  }

  approveChangeRequestByReviewer(
    changeRequestId: string
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequest>> {
    return this.http.put<IHttpSingleDataResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${changeRequestId}/action/ready-to-go-live`,
      {}
    );
  }

  rejectChangeRequestByReviewer(
    changeRequestId: string
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequest>> {
    return this.http.put<IHttpSingleDataResponse<IServiceChangeRequest>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${changeRequestId}/action/reject-from-qa`,
      {}
    );
  }

  saveChangeRequestReviewIssue(
    request: ISaveServiceChangeRequestReviewIssueRequest
  ): Observable<IHttpSingleDataResponse<IServiceChangeRequestReviewIssue>> {
    return this.http.post<
      IHttpSingleDataResponse<IServiceChangeRequestReviewIssue>
    >(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-request-feedbacks`,
      request
    );
  }

  updateChangeRequestReviewIssue(
    request: ISaveServiceChangeRequestReviewIssueRequest
  ): Observable<IServiceChangeRequestReviewIssue> {
    return this.http.put<IServiceChangeRequestReviewIssue>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-request-feedbacks/${request.id}`,
      { title: request.title, description: request.description }
    );
  }

  getChangeRequestReviewIssues(
    queryString: string
  ): Observable<IHttpPagedResponse<IServiceChangeRequestReviewIssue>> {
    return this.http.get<IHttpPagedResponse<IServiceChangeRequestReviewIssue>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-request-feedbacks?${queryString}`
    );
  }

  deleteChangeRequestReviewIssue(
    issueId: string
  ): Observable<IServiceChangeRequestReviewIssue> {
    return this.http.delete<IServiceChangeRequestReviewIssue>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-request-feedbacks/${issueId}`,
      {}
    );
  }

  resolveChangeRequestReviewIssue(
    issueId: string
  ): Observable<IServiceChangeRequestReviewIssue> {
    return this.http.put<IServiceChangeRequestReviewIssue>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-request-feedbacks/${issueId}/resolve`,
      {}
    );
  }

  getCountryCodesWithFlags(): Observable<any> {
    const currentDataPath = '../../../assets/data/country-codes.json';
    return this.http.get<IHttpPagedResponse<any>>(currentDataPath);
  }

  getIntegrationEndpoints(
    queryStrings: string
  ): Observable<IHttpPagedResponse<IIntegrationEndpoint>> {
    return this.http.get<IHttpPagedResponse<IIntegrationEndpoint>>(
      `${environment.apiGatewayBaseUrl}/integration/v1/endpoints?${queryStrings}`,
      {}
    );
  }

  getInternalDatasets(
    queryParams: string
  ): Observable<IHttpPagedResponse<IInternalDataset>> {
    return this.http.get<IHttpPagedResponse<IInternalDataset>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/datasets?${queryParams}&sortDirection=DESC`,
      {}
    );
  }

  getIntegrationEndpoint(
    code: string
  ): Observable<IHttpSingleDataResponse<IIntegrationEndpoint>> {
    return this.http.get<IHttpSingleDataResponse<IIntegrationEndpoint>>(
      `${environment.apiGatewayBaseUrl}/integration/v1/endpoints/${code}/get`,
      {}
    );
  }

  updateServiceChangeRequestThirdPartyFormDefinition(
    requestId: string,
    formDefinition: Record<string, Record<string, unknown>>
  ): Observable<IHttpSingleDataResponse<Record<string, unknown>>> {
    const headers: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http.put<IHttpSingleDataResponse<Record<string, unknown>>>(
      `${environment.apiGatewayBaseUrl}/service/api/v1/change-requests/${requestId}/third-party-form-definition`,
      formDefinition,
      { headers }
    );
  }

  getAllServiceCategories(): Observable<
    IHttpUnpagedResponse<IServiceCategory>
  > {
    return this.http.get<IHttpUnpagedResponse<IServiceCategory>>(
      `${environment.apiGatewayBaseUrl}/admin/v1/categories/all`
    );
  }
}
