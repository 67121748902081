<div class="field-configurator" *ngIf="configuredField">
  <h6 class="mb-2 mt-3">
    <i
      class="fa-solid fa-arrow-left pointer-cursor text-primary me-2"
      (click)="cancelFieldConfiguration()"></i>
    Field Configurations
  </h6>
  <div class="card bg-white rounded p-0 mb-3 border-0">
    <irembogov-form-field-configurator-settings></irembogov-form-field-configurator-settings>
  </div>
</div>

<div class="form-field-configurator" *ngIf="!configuredField">
  <h6 class="mb-2 mt-3">Form Elements</h6>
  <irembogov-button-group
    class="w-100"
    [itemList]="configurationTabs"
    [selectedItem]="selectedConfigurationTab"
    (itemChange)="selectedConfigurationTab = $event"
    [fullWidth]="true"
    [iconAboveLabel]="true"
    uiStyle="flat-tabs"
    theme="irembo-blue-theme"></irembogov-button-group>

  <ng-container
    #fieldTypes
    *ngIf="selectedConfigurationTab === EWizardTabTemplateKeys.Fields">
    <irembogov-form-field-types-list></irembogov-form-field-types-list>
  </ng-container>

  <ng-container
    #widgets
    *ngIf="selectedConfigurationTab === EWizardTabTemplateKeys.Widgets">
    <irembogov-form-field-widgets-list></irembogov-form-field-widgets-list>
  </ng-container>

  <ng-container
    #templates
    *ngIf="selectedConfigurationTab === EWizardTabTemplateKeys.Templates">
    Templates
  </ng-container>
</div>
