import { Component, Input } from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';
import { EServiceChangeRequestType } from '../../../../core/models/service-change-request-type.enum';
import { GetServiceChangeRequestTypeEnumLabelKeyPair } from '../../../../core/utils/change-request-type.utils';

@Component({
  selector: 'irembogov-service-change-request-type-badge',
  templateUrl: './service-change-request-type-badge.component.html',
  styleUrls: ['./service-change-request-type-badge.component.scss'],
})
export class ServiceChangeRequestTypeBadgeComponent {
  @Input() requestType!: EServiceChangeRequestType;

  getServiceChangeRequestTypeBadgeClass(
    requestType: EServiceChangeRequestType
  ): IIrembogovBasicLabelKeyPair<EServiceChangeRequestType> {
    return GetServiceChangeRequestTypeEnumLabelKeyPair(requestType);
  }
}
