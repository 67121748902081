<div class="irembogov-section-feedbacks">
  <irembogov-service-request-header-togger-widget
    *ngIf="serviceChangeRequestSection === EServiceChangeRequestSection.FORM"
    [options]="headerOptions"
    [selectedOption]="selectedOption"
    (selectOption)="onSelectOptionChange($event)"
    class="pl-2"></irembogov-service-request-header-togger-widget>
  <ng-container
    *ngIf="selectedOption === EServiceChangeRequestToggleHeaders.REVIEW">
    <irembogov-reviewer-issues-list-widget
      [changeRequestId]="changeRequestId ?? ''"
      [changeRequestSection]="
        serviceChangeRequestSection
      "></irembogov-reviewer-issues-list-widget>
  </ng-container>
  <ng-container
    *ngIf="selectedOption === EServiceChangeRequestToggleHeaders.CODE_EDITOR">
    <ng-content select="[codeEditor]"></ng-content>
  </ng-container>
  <ng-container
    *ngIf="selectedOption === EServiceChangeRequestToggleHeaders.WIZARD">
    <ng-content select="[wizard]"></ng-content>
  </ng-container>
</div>
