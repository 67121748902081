import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  IServiceRequestPricingBase,
  IServiceRequestPricingDynamicCondition,
} from '../../../../core/models/service-pricing.model';
import { EServicePriceType } from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-service-request-item-pricing-widget',
  templateUrl: './service-request-item-pricing-widget.component.html',
  styleUrls: ['./service-request-item-pricing-widget.component.scss'],
})
export class ServiceRequestItemPricingWidgetComponent implements OnChanges {
  @Input() setPropertyLabelName = '';
  @Input() serviceRequestPricingBase!: IServiceRequestPricingBase;
  @Input() paymentExpirationHours!: string;

  EServicePriceType = EServicePriceType;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['serviceRequestPricingBase']?.currentValue) {
      this.serviceRequestPricingBase =
        changes['serviceRequestPricingBase'].currentValue;
    }
  }

  getDisplayPriceValue(scenario: IServiceRequestPricingDynamicCondition) {
    const properties: ('value' | 'valueAttribute' | 'valueFormula')[] = [
      'value',
      'valueAttribute',
      'valueFormula',
    ];

    for (const prop of properties) {
      if (
        Object.prototype.hasOwnProperty.call(scenario, prop) &&
        scenario[prop] !== undefined &&
        scenario[prop] !== null
      ) {
        if (prop == 'valueFormula') {
          const formula: Record<string, unknown>[] = JSON.parse(
            scenario[prop] as string
          );
          return formula.map(ele => ele['displayValue']).join(' ');
        }

        return scenario[prop];
      }
    }

    return null;
  }
}
