<main
  class="container-fluid px-0 irembogov-configure-service-change-request mt-n5 pt-1">
  <irembogov-irembo-loading-circle-spinner
    *ngIf="isLoadingChangeRequest"></irembogov-irembo-loading-circle-spinner>

  <ng-container *ngIf="!isLoadingChangeRequest">
    <ng-container *ngIf="loadRequestDetailsFailed">
      <div class="col-8 mx-auto mt-5 text-center">
        <irembogov-irembo-section-message
          title="Error Encountered: {{ loadFailedErrorCodeMessage.code }}"
          [message]="loadFailedErrorCodeMessage.message"
          [type]="'danger'"></irembogov-irembo-section-message>
        <a [routerLink]="['/services']">
          &lt;&lt; Back to Change Requests List</a
        >
      </div>
    </ng-container>

    <ng-container *ngIf="!loadRequestDetailsFailed">
      <irembogov-change-request-configuration-header-widget
        (OnPreviewClick)="toggleFormPreview($event)"
        (OnSaveSubmit)="OnSubmitToQA()"
        (OnClickRequirementDoc)="viewRequirementDocs($event)"
        [request]="changeRequest"
        [readyForSaveSubmit]="
          readyForSaveSubmitForReview
        "></irembogov-change-request-configuration-header-widget>

      <irembogov-irembo-loading-circle-spinner
        marginTop="8rem"
        *ngIf="
          !changeRequest || isSubmittingForQA
        "></irembogov-irembo-loading-circle-spinner>

      <ng-container *ngIf="changeRequest && !isSubmittingForQA">
        <div
          class="mt-5 p-5 container rounded bg-white"
          *ngIf="showFormPreview">
          <irembogov-preview-service-change-request-json-formdata-widget
            class="mx-auto"></irembogov-preview-service-change-request-json-formdata-widget>
        </div>

        <irembogov-configure-service-change-request-stepper
          *ngIf="!showFormPreview"
          [configSteps]="configSteps"
          [selectedIndex]="currentConfigStep"
          (selectionChange)="OnStepperChange($event)">
          <cdk-step
            [label]="item.label"
            *ngFor="let item of configSteps; let index = index"
            [hasError]="configSteps[index].hasError"
            [completed]="configSteps[index].isComplete">
            <ng-container
              *ngTemplateOutlet="getCurrentTemplate(index)"></ng-container>
          </cdk-step>
        </irembogov-configure-service-change-request-stepper>
      </ng-container>
    </ng-container>
  </ng-container>
</main>
<ng-template #overview>
  <irembogov-irembo-loading-circle-spinner
    marginTop="8rem"
    *ngIf="isLoadingDetails"></irembogov-irembo-loading-circle-spinner>

  <irembogov-configuration-metadata-overview-form
    *ngIf="!isLoadingDetails"
    [changeRequest]="changeRequest"
    (metadataFormSubmitted)="
      onStepFormSubmitted($event)
    "></irembogov-configuration-metadata-overview-form>
</ng-template>

<ng-template #formbuilder>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="isLoadingDetails"></irembogov-irembo-loading-circle-spinner>

  <irembogov-configuration-form-builder
    *ngIf="!isLoadingDetails"
    [changeRequest]="changeRequest"
    (jsonFormBuilderFormSubmitted)="onStepFormSubmitted($event)">
  </irembogov-configuration-form-builder>
</ng-template>

<ng-template #pricing>
  <irembogov-configuration-pricing-form
    [changeRequest]="changeRequest"
    [defaultData]="serviceChangeRequestPricingData"
    [formFields]="serviceChangeRequestFormFields"
    (OnPricingFormSubmitted)="onStepFormSubmitted($event)">
  </irembogov-configuration-pricing-form>
</ng-template>

<ng-template #workflow>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="isLoadingDetails"></irembogov-irembo-loading-circle-spinner>

  <irembogov-configuration-workflow-json-editor-form
    *ngIf="!isLoadingDetails"
    [changeRequestJsonWorkflowData]="changeRequestJsonWorkflowData"
    [changeRequest]="changeRequest"
    (jsonWorkflowFormSubmitted)="onWorkflowStepSubmitted($event)">
  </irembogov-configuration-workflow-json-editor-form>
</ng-template>
<ng-template #officerform>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="isLoadingDetails"></irembogov-irembo-loading-circle-spinner>
  <irembogov-configuration-officer-form
    [changeRequest]="changeRequest"
    [changeRequestThirdPartyFormData]="changeRequestThirdPartyFormData"
    (officerFormSubmitted)="
      onStepFormSubmitted($event)
    "></irembogov-configuration-officer-form>
</ng-template>

<ng-template #translation>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="isLoadingDetails"></irembogov-irembo-loading-circle-spinner>
  <irembogov-configuration-translation
    *ngIf="!isLoadingDetails && changeRequestJsonWorkflowData"
    [changeRequest]="changeRequest"
    [defaultData]="changeRequestTranslationsData"
    [formFields]="serviceChangeRequestFormFields"
    [changeRequestJsonWorkflowData]="changeRequestJsonWorkflowData"
    (translationFormSubmitted)="
      onStepFormSubmitted($event)
    "></irembogov-configuration-translation>
</ng-template>
