import { RolesEnum } from '@irembo-andela/irembogov3-common';
import { INavbarAllowedRolesItem } from '../models/navbar-allowed-roles-item.model';

export const MainNavigationBarLinkItems: Record<
  string,
  INavbarAllowedRolesItem
> = {
  dashboard: {
    name: 'Dashboard',
    icon: 'fa-solid fa-grip',
    routerLink: ['/'],
  },
  services: {
    name: 'Services',
    routerLink: ['/services'],
    icon: 'fa-regular fa-rectangle-list',
    allowedRoles: [RolesEnum.ROLE_QA_REVIEWER, RolesEnum.ROLE_SERVICE_MANAGER],
  },
  users: {
    name: 'Users',
    routerLink: ['/users'],
    icon: 'fa-solid fa-users',
    allowedRoles: [RolesEnum.ROLE_QA_REVIEWER, RolesEnum.ROLE_SERVICE_MANAGER],
  },
  reporting: {
    name: 'Reporting',
    routerLink: ['/reporting'],
    icon: 'fa-regular fa-chart-bar',
    allowedRoles: [RolesEnum.ROLE_QA_REVIEWER, RolesEnum.ROLE_SERVICE_MANAGER],
  },
};
