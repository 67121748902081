import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  IBaseConfigurationFormModel,
} from '../../../models/form-builder-types-configurations.model';
import {
  baseFieldParseIntoSettingsFormModel,
  baseFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import {
  getMaxLengthValidatorFormConfig,
  getMinLengthValidatorFormConfig,
  getPatternValidatorFormConfig,
  getRequiredValidatorFormConfig,
  getUniqueFieldValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { IFormFieldsValidationModel } from '../../../models/form-fields-formly-validation.model';
import {
  parseMaxLengthValidatorParseFieldIntoModel,
  parseMinLengthValidatorParseFieldIntoModel,
  parsePatternValidatorParseFieldIntoModel,
  parseRequiredValidatorParseFieldIntoModel,
  parseUniqueFieldValidatorFormDataIntoModel,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import {
  setRequiredValidatorUpdateFieldFromModel,
  setMinLengthValidatorUpdateFieldFromModel,
  setMaxLengthValidatorUpdateFieldFromModel,
  setPatternValidatorUpdateFieldFromModel,
  setUniqueFieldValidatorFormDataIntoField,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { FormBuilderService } from '../../../services/form-builder.service';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { IFormFieldsDisplayModel } from '../../../models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { fieldsBaseConfigurationFormModel } from './shared.configuration';

const defaultInputConfigFormModel: IBaseConfigurationFormModel = {
  ...fieldsBaseConfigurationFormModel(),
};

export const inputFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultInputConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getMinLengthValidatorFormConfig(),
        getMaxLengthValidatorFormConfig(),
        getPatternValidatorFormConfig(),
        getUniqueFieldValidatorFormConfig(formBuilderServiceInstance),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const inputFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IBaseConfigurationFormModel => {
  return baseFieldParseIntoSettingsFormModel(field);
};

export const inputFieldUpdateFromSettingsFormModel = (
  model: IBaseConfigurationFormModel,
  inputField: FormlyFieldConfig
): FormlyFieldConfig => {
  return baseFieldUpdateFromSettingsFormModel(model, inputField);
};

export const inputFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, field);
  model = parseMinLengthValidatorParseFieldIntoModel(model, field);
  model = parseMaxLengthValidatorParseFieldIntoModel(model, field);
  model = parsePatternValidatorParseFieldIntoModel(model, field);
  model = parseUniqueFieldValidatorFormDataIntoModel(model, field);

  return model;
};

export const inputFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  inputField: FormlyFieldConfig
): FormlyFieldConfig => {
  inputField = setRequiredValidatorUpdateFieldFromModel(model, inputField);
  inputField = setMinLengthValidatorUpdateFieldFromModel(model, inputField);
  inputField = setMaxLengthValidatorUpdateFieldFromModel(model, inputField);
  inputField = setPatternValidatorUpdateFieldFromModel(model, inputField);
  inputField = setUniqueFieldValidatorFormDataIntoField(model, inputField);

  return inputField;
};

export const inputFieldParseFieldIntoDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const inputFieldUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
