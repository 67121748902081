import {
  Component,
  ViewEncapsulation,
  Injector,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  EIremboFormlyFieldSubTypes,
  EIremboFormlyFieldTypes,
  EIremboFormlyValidationTypes,
  IIrembogovBasicLabelKeyPair,
  CustomIdInputTypesIdLength,
} from '@irembo-andela/irembogov3-common';
import { FormBuilderManipulatorClass } from '../../../../core/utils/form-builder-manipulator-class.utils';
import { EDragDropItemsIds } from '../../../../core/models/form-builder-drag-item-ids.enum';
import { ValidationTypeDefaultMessage } from '../../../../core/utils/form-fields-formly-configurations/form-builder-validation-type-message.utils';

@Component({
  selector: 'irembogov-form-field-types-list',
  templateUrl: './form-field-types-list.component.html',
  styleUrls: ['./form-field-types-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldTypesListComponent implements OnInit, OnDestroy {
  EDragDropItemsIds = EDragDropItemsIds;

  formFieldTypes: IIrembogovBasicLabelKeyPair<FormlyFieldConfig>[] = [
    {
      label: 'Text Input',
      key: {
        key: 'TextInput',
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Text Input',
          disabled: false,
          required: false,
          placeholder: 'Text Input',
        },
      },
    },
    {
      label: 'Text Area',
      key: {
        key: 'TextArea',
        type: EIremboFormlyFieldTypes.textarea,
        props: {
          label: 'Text Area',
          disabled: false,
          required: false,
          placeholder: 'Text Area',
          rows: 3,
        },
      },
    },
    {
      label: 'Checkbox',
      key: {
        key: 'Checkbox',
        type: EIremboFormlyFieldTypes.multicheckbox,
        props: {
          type: 'array',
          label: 'Checkbox',
          disabled: false,
          required: false,
          placeholder: 'Checkbox',
          options: [
            { value: 'CheckBox1Key', label: 'CheckBox 1' },
            { value: 'CheckBox2Key', label: 'CheckBox 2' },
            { value: 'CheckBox3Key', label: 'CheckBox 3' },
            { value: 'CheckBox4Key', label: 'CheckBox 4' },
          ],
          multiple: false,
        },
      },
    },
    {
      label: 'Radio Input',
      key: {
        key: 'TextInput',
        type: EIremboFormlyFieldTypes.radio,
        props: {
          label: 'Radio Input',
          disabled: false,
          required: false,
          placeholder: 'Radio Input',
          options: [
            { value: 'Radio1Key', label: 'Radio 1' },
            { value: 'Radio2Key', label: 'Radio 2' },
            { value: 'Radio3Key', label: 'Radio 3' },
            { value: 'Radio4Key', label: 'Radio 4' },
          ],
        },
      },
    },
    {
      label: 'Time Picker',
      key: {
        key: 'Timepicker',
        type: EIremboFormlyFieldTypes.customtimepicker,
        props: {
          label: 'Time Picker',
          disabled: false,
          required: false,
          placeholder: 'Time Picker',
          startHour: 8,
          endHour: 16,
          interval: 40,
        },
      },
    },
    {
      label: 'Date Picker',
      key: {
        key: 'datepicker',
        type: EIremboFormlyFieldTypes.customdatepicker,
        props: {
          label: 'Date Picker',
          disabled: false,
          required: false,
          placeholder: 'Date Picker',
        },
      },
    },
    {
      label: 'NID',
      key: {
        key: 'NID',
        type: EIremboFormlyFieldTypes.customidinput,
        props: {
          useBaseUrl: true,
          label: 'National ID',
          disabled: false,
          idLength: CustomIdInputTypesIdLength.NID_ID_LENGTH,
          idType: 'NID',
          required: true,
          placeholder: 'Provide National ID',
          populates: [],
        },
      },
    },
    {
      label: 'Child NID',
      key: {
        key: 'Child NID',
        type: EIremboFormlyFieldTypes.customidinput,
        props: {
          idType: 'CHILD_ID',
          useBaseUrl: true,
          label: 'Child National ID',
          disabled: false,
          idLength: CustomIdInputTypesIdLength.CHILD_ID_LENGTH,
          required: true,
          placeholder: 'Provide Child National ID',
          populates: [],
        },
      },
    },
    {
      label: 'NIN',
      key: {
        key: 'NIN',
        type: EIremboFormlyFieldTypes.customidinput,
        props: {
          url: 'NIN',
          useBaseUrl: true,
          label: 'NIN Number',
          disabled: false,
          idLength: CustomIdInputTypesIdLength.NIN_ID_LENGTH,
          required: true,
          placeholder: 'Provide NIN Number',
          populates: [],
        },
      },
    },
    {
      label: 'Dropdown Selector',
      key: {
        key: 'DropdownSelector',
        type: EIremboFormlyFieldTypes.customdropdown,
        props: {
          label: 'Dropdown Selector',
          disabled: false,
          required: false,
          placeholder: 'Dropdown Selector',
          options: [
            { value: 'option1Key', label: 'Option 1' },
            { value: 'option2Key', label: 'Option 2' },
            { value: 'option3Key', label: 'Option 3' },
            { value: 'option4Key', label: 'Option 4' },
          ],
          multiple: false,
          bindValue: 'value',
          bindLabel: 'label',
        },
      },
    },
    {
      label: 'Dropdown Selector With Data Fetch',
      key: {
        key: 'DropdownSelectorWithDataFetch',
        type: EIremboFormlyFieldTypes.customdropdown,
        name: 'DropdownSelectorWithDataFetch',
        props: {
          label: 'Dropdown Selector With Data Fetch',
          disabled: false,
          required: false,
          placeholder: 'Dropdown Selector With Data Fetch',
          options: [],
          multiple: false,
          virtualScroll: true,
          useBaseUrl: true,
          dataset: {
            url: '/integration/v1/fetch/sync',
          },
          subType: EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATAFETCH,
        },
      },
    },
    {
      label: 'Dropdown Selector With Dataset Paginated',
      key: {
        key: 'DropdownSelectorWithDataset',
        type: EIremboFormlyFieldTypes.customdropdownpaginated,
        name: 'DropdownSelectorWithDataset',
        props: {
          label: 'Dataset',
          disabled: false,
          required: true,
          placeholder: 'Select dataset',
          options: [],
          multiple: false,
          useBaseUrl: true,
          dataset: {
            url: '/admin/v1/dataset-items',
            dataField: 'data.content',
          },
          subType: EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET_PAGINATED,
        },
      },
    },
    {
      label: 'Dropdown Selector With Dataset Non Paginated',
      key: {
        key: 'DropdownSelectorWithDatasetNonPaginated',
        type: EIremboFormlyFieldTypes.customdropdown,
        name: 'DropdownSelectorWithDatasetNonPaginated',
        props: {
          label: 'Dataset Non Paginated',
          disabled: false,
          required: true,
          placeholder: 'Select dataset',
          options: [],
          multiple: false,
          useBaseUrl: true,
          dataset: {
            url: '/admin/v1/datasets/all',
            dataField: 'data',
          },
          subType: EIremboFormlyFieldSubTypes.CUSTOM_DROPDOWN_DATASET,
        },
      },
    },
    {
      label: 'Attachment',
      key: {
        key: 'Attachment',
        type: EIremboFormlyFieldTypes.customfileupload,
        props: {
          required: false,
          label: 'File Attachment',
          minimumUploadSize: 100,
          maximumUploadSize: 400,
          placeholder: 'Select file to upload',
          allowedFormats: [],
        },
        validation: {
          messages: {
            minimumUploadSize: ValidationTypeDefaultMessage(
              EIremboFormlyValidationTypes.minimumUploadSize
            ),
            maximumUploadSize: ValidationTypeDefaultMessage(
              EIremboFormlyValidationTypes.maximumUploadSize
            ),
          },
        },
      },
    },
    {
      label: 'International Phone No.',
      key: {
        key: 'InternationalPhoneNumber',
        type: EIremboFormlyFieldTypes.customphonenumber,
        props: {
          label: 'Phone Number International',
          required: true,
          id: 'telephone',
          countryNoValidation: true,
          preferredCountries: [],
        },
        validation: {
          messages: {
            required: 'Field is required',
            invalidNumber: 'Provided number is invalid',
            invalidNumberFormat: 'Number format is invalid',
          },
        },
        validators: {
          validation: ['invalidNumberFormat'],
        },
      },
    },
  ];

  subscriptionControl$: Subject<void> = new Subject();

  connectedBlockId: string[] = [];

  formBuilderManipulatorClass!: FormBuilderManipulatorClass;

  EIremboFormlyFieldTypes = EIremboFormlyFieldTypes;

  constructor(
    private formBuilderService: FormBuilderService,
    private changeDetectorRef: ChangeDetectorRef,
    private injector: Injector
  ) {
    this.formBuilderManipulatorClass = new FormBuilderManipulatorClass(
      this.injector
    );
  }

  ngOnInit(): void {
    this.formBuilderService.formFieldBlockIdsSet$
      .pipe(takeUntil(this.subscriptionControl$))
      .subscribe((blockIdSet: Set<string>) => {
        this.connectedBlockId = Array.from(blockIdSet);
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.subscriptionControl$.next();
    this.subscriptionControl$.complete();
  }
}
