<div class="mb-3">
  <legend *ngIf="props.label">{{ props.label }}</legend>
  <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
    <formly-field class="col" [field]="field"></formly-field>
  </div>
  <div style="margin: 30px 0">
    <button type="button" class="btn btn-xs btn-alert" (click)="add()">
      <span class="tt_norms500">{{ props['addText'] }}</span>
    </button>
  </div>
</div>
