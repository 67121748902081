<span
  [ngClass]="
    'irembogov-service-change-request-type-badge badge ' +
    getServiceChangeRequestTypeBadgeClass(requestType).bgClass
  ">
  <i
    [ngClass]="
      'fa-regular ' + getServiceChangeRequestTypeBadgeClass(requestType).icon
    "></i>
  {{ getServiceChangeRequestTypeBadgeClass(requestType).label }}
</span>
