<div class="d-flex">
  <div class="ms-5 me-5 {{ changeRequestStatus ? 'w-70' : 'w-100' }}">
    <div
      class="configuration-metadata-overview-form {{
        changeRequestStatus ? 'mt-5' : ''
      }} {{
        changeRequestStatus === EServiceChangeRequestStatus.IN_REDEVELOPMENT ||
        inReviewMode
          ? 'col-lg-6 mx-auto '
          : 'col-lg-4 mx-auto mt-5'
      }} ">
      <div #errorMessageElement>
        <irembogov-irembo-section-message
          *ngIf="showConfigFormErrorMessage"
          [type]="formAlertContent.type"
          [title]="formAlertContent.title"
          [message]="
            formAlertContent.message
          "></irembogov-irembo-section-message>
      </div>

      <form
        [formGroup]="requestOverviewForm"
        (submit)="onFormSubmit()"
        *ngIf="changeRequest">
        <fieldset [disabled]="inReviewMode">
          <legend class="d-none">Change Request Metadata</legend>
          <div class="mb-3">
            <h5 class="form-title mb-3">Service Details</h5>
            <div class="form-group">
              <label for="serviceName" class="form-label">
                Service Name
                <i
                  class="fa-solid fa-circle-info text-muted"
                  placement="top"
                  ngbTooltip="Name of service"></i>
              </label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="serviceName"
                  placeholder="Service Name"
                  formControlName="serviceName"
                  #serviceName />
              </div>
              <div
                class="form-error w-100 small text-danger"
                *ngIf="
                  requestOverviewForm.controls['serviceName'].touched &&
                  !requestOverviewForm.controls['serviceName'].valid &&
                  !inReviewMode
                ">
                Service name is required.
              </div>
            </div>

            <div class="form-group">
              <label for="serviceSummary" class="form-label">
                Enter service summary
                <i
                  class="fa-solid fa-circle-info text-muted"
                  placement="top"
                  ngbTooltip="Summary of service"></i>
              </label>
              <div class="input-group mb-3">
                <textarea
                  class="form-control"
                  id="serviceSummary"
                  rows="3"
                  name="serviceSummary"
                  formControlName="serviceSummary"
                  placeholder="Enter service summary"
                  #serviceSummary></textarea>
                <small class="w-100 text-muted pt-2" *ngIf="!inReviewMode">
                  {{
                    requestOverviewForm.controls['serviceSummary'].value &&
                    requestOverviewForm.controls['serviceSummary'].value
                      .length > 0
                      ? requestOverviewForm.controls[
                          'serviceSummary'
                        ].value.split(' ').length
                      : 0
                  }}
                  words
                </small>
              </div>
            </div>

            <div class="form-group">
              <label for="categoryId" class="form-label">
                Service Category
                <i
                  class="fa-solid fa-circle-info text-muted"
                  placement="top"
                  ngbTooltip="Category of service"></i>
              </label>
              <div class="input-group mb-3">
                <ng-select
                  [readonly]="inReviewMode"
                  formControlName="categoryId"
                  [items]="serviceCategoriesBuffer"
                  [virtualScroll]="true"
                  [loading]="isLoadingServiceCategories"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="Choose the service category"
                  class="form-control"
                  (search)="onSearchServiceCategory($event)"
                  #select>
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm">
                    <strong>{{ item.name }}</strong>
                    <span class="fst-italic">
                      (
                      <span class="text-muted">Keywords:</span>
                      {{
                        item.keywords && item.keywords.length > 15
                          ? (item.keywords | slice : 0 : 15) + '...'
                          : item?.keywords
                      }}
                      )
                    </span>
                  </ng-template>
                </ng-select>
              </div>
              <div
                class="form-error w-100 small text-danger"
                *ngIf="
                  requestOverviewForm.controls['categoryId'].touched &&
                  !requestOverviewForm.controls['categoryId'].valid &&
                  !inReviewMode
                ">
                Please select a service category.
              </div>
            </div>
            <div
              class="form-group application-type d-flex flex-column align-items-start">
              <label for="applicationType" class="form-label">
                Service Availability
              </label>
              <small class="text-muted mb-3">
                Select who can apply for this service</small
              >
              <div
                class="input-group mb-3 d-flex flex-column align-items-start">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="citizenApplication"
                    value="INDIVIDUAL"
                    (change)="onCheckboxChange($event)"
                    [checked]="
                      requestOverviewForm
                        .get('applicantType')
                        ?.value?.includes('INDIVIDUAL')
                    " />
                  <label class="form-check-label" for="citizen">
                    Citizen
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="businessApplication"
                    value="BUSINESS"
                    (change)="onCheckboxChange($event)"
                    [checked]="
                      requestOverviewForm
                        .get('applicantType')
                        ?.value?.includes('BUSINESS')
                    " />
                  <label class="form-check-label" for="business">
                    Business
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="text-end" *ngIf="!inReviewMode">
            <button
              class="btn btn-primary mt-1 mx-auto px-5"
              type="submit"
              [disabled]="
                isLoading ||
                isLoadingServiceCategories ||
                requestOverviewForm.invalid
              ">
              <div
                *ngIf="isLoading"
                class="spinner-border spinner-border-sm mr-2"
                role="status"></div>
              Continue
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
  <div
    class="pt-5 ms-3 me-3 w-30"
    *ngIf="
      changeRequest?.status === EServiceChangeRequestStatus.IN_REDEVELOPMENT
    ">
    <irembogov-service-change-request-feedback-handler-widget
      *ngIf="changeRequest"
      [changeRequestId]="changeRequest.id ?? ''"
      [serviceChangeRequestSection]="
        EServiceChangeRequestSection.OVERVIEW
      "></irembogov-service-change-request-feedback-handler-widget>
  </div>
</div>
