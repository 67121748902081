import { FormlyFieldConfig } from '@ngx-formly/core';
import { EFieldValidationPatternConfigOptions } from '../../../models/form-fields-formly-validation.model';
import { FormBuilderService } from '../../../services/form-builder.service';
import {
  EIremboFormlyFieldTypes,
  EIremboFormlyValidationTypes,
} from '@irembo-andela/irembogov3-common';
import { AbstractControl } from '@angular/forms';
import { filter, tap } from 'rxjs';
import { AttachmentFieldFileFormat } from '../../../constants/attachment-field-file-format-emum-to-types.enum';

export const getRequiredValidatorFormConfig = (): FormlyFieldConfig => {
  return {
    key: 'required',
    type: EIremboFormlyFieldTypes.checkbox,
    defaultValue: false,
    props: {
      formCheck: 'switch',
      label: 'Required',
      placeholder: 'Required Field',
      required: false,
    },
  };
};

export const getMinLengthValidatorFormConfig = (): FormlyFieldConfig => {
  return {
    key: 'minLength',
    type: EIremboFormlyFieldTypes.input,
    props: {
      type: 'number',
      label: 'Min Character Length:',
      placeholder: 'Enter Min Character Length',
      required: false,
      min: 0,
    },
    parsers: [Number],
    validators: {
      validation: [
        {
          name: EIremboFormlyValidationTypes.isLessThanField,
          options: {
            fieldKey: 'maxLength',
          },
        },
      ],
    },
    validation: {
      messages: {
        [EIremboFormlyValidationTypes.isLessThanField]:
          'Should be less than Max Character Length',
      },
    },
  };
};

export const getMaxLengthValidatorFormConfig = (): FormlyFieldConfig => {
  return {
    key: 'maxLength',
    type: EIremboFormlyFieldTypes.input,
    props: {
      type: 'number',
      label: 'Max Character Length:',
      placeholder: 'Enter Max Character Length',
      required: false,
      min: 0,
    },
    parsers: [Number],

    validators: {
      validation: [
        {
          name: EIremboFormlyValidationTypes.isGreaterThanField,
          options: {
            fieldKey: 'minLength',
          },
        },
      ],
    },
    validation: {
      messages: {
        [EIremboFormlyValidationTypes.isGreaterThanField]:
          'Should be Greater than Min Character Length',
      },
    },
  };
};

export const getPatternValidatorFormConfig = (): FormlyFieldConfig => {
  return {
    key: 'patternValidation',
    type: EIremboFormlyFieldTypes['formly-group'],
    fieldGroup: [
      {
        key: 'patternType',
        type: EIremboFormlyFieldTypes.customdropdown,
        props: {
          placeholder: 'Pattern',
          label: 'Pattern',
          options: [
            { label: 'None', value: null },
            {
              label: 'Letters a-z',
              value: EFieldValidationPatternConfigOptions.LETTERS_A_TO_Z,
            },
            {
              label: 'Digit 0-9',
              value: EFieldValidationPatternConfigOptions.NUMBERS_0_TO_9,
            },
            {
              label: 'Letters & numbers',
              value: EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS,
            },
            {
              label: 'Symbols',
              value: EFieldValidationPatternConfigOptions.SYMBOLS,
            },
            {
              label: 'Letters & numbers & symbols',
              value:
                EFieldValidationPatternConfigOptions.LETTERS_AND_NUMBERS_AND_SYMBOLS,
            },
            {
              label: 'Email',
              value: EFieldValidationPatternConfigOptions.EMAIL,
            },
            {
              label: 'Custom',
              value: EFieldValidationPatternConfigOptions.CUSTOM,
            },
          ],
          bindLabel: 'label',
          bindValue: 'value',
          required: false,
        },
      },
      {
        key: 'customPattern',
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Custom Pattern',
          placeholder: 'Enter custom Regex pattern',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return (
              field.model?.patternType !==
              EFieldValidationPatternConfigOptions.CUSTOM
            );
          },
          'props.required':
            "model?.patternType === 'Custom' && !model?.customPattern",
        },
      },
    ],
  };
};

export const getUniqueFieldValidatorFormConfig = (
  formBuilderService: FormBuilderService
): FormlyFieldConfig => {
  return {
    key: 'uniqueField',
    type: EIremboFormlyFieldTypes.customdropdown,
    hide: true,
    props: {
      disabled: true,
      placeholder: 'Unique To Fields',
      label: 'Unique To Fields',
      options: formBuilderService.getAllFormFieldLabelAndDetails(true, true),
      multiple: true,
      bindLabel: 'label',
      bindValue: 'value',
      required: false,
    },
  };
};

export const getValidationFieldsFormConfig = (
  formBuilderService: FormBuilderService
): FormlyFieldConfig => {
  return {
    key: 'validationMessages',
    type: EIremboFormlyFieldTypes.block,
    className: 'validation-messages-list-block',
    props: {
      label: 'Error Messages',
      dragDisabled: true,
    },
    fieldGroup: [
      {
        key: EIremboFormlyValidationTypes.required,
        type: EIremboFormlyFieldTypes.input,
        props: {
          placeholder: 'Enter required validation error message',
          label: 'Required Message:',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return !field.parent?.parent?.model['required'];
          },
          'props.required': (field: FormlyFieldConfig) => {
            return field.parent?.parent?.model?.['required'];
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.minLength,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Min Length Message:',
          required: false,
          placeholder: 'Enter Min Length Error Message',
        },
        expressions: {
          'props.required': (field: FormlyFieldConfig) => {
            return field.parent?.parent?.model?.['minLength'];
          },
          'props.hideField': (field: FormlyFieldConfig) => {
            return !field.parent?.parent?.model['minLength'];
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.maxLength,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Max Length Message:',
          placeholder: 'Enter Max Length Error Message',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return !field.parent?.parent?.model['maxLength'];
          },
          'props.required': (field: FormlyFieldConfig) => {
            return field.parent?.parent?.model?.['maxLength'];
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.pattern,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Pattern:',
          placeholder: 'Enter pattern validation error message',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return !field.parent?.parent?.model?.['patternValidation']?.[
              'patternType'
            ];
          },
          'props.required': (field: FormlyFieldConfig) => {
            return field.parent?.parent?.model?.['patternType'];
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.uniqueField,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Unique Field:',
          placeholder: 'Enter unique validation error message',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return (
              !field.parent?.parent?.model['uniqueField'] ||
              field.parent?.parent?.model['uniqueField'].length < 1
            );
          },
          'props.required': (field: FormlyFieldConfig) => {
            return (
              field.parent?.parent?.model['uniqueField'] &&
              field.parent?.parent?.model['uniqueField'].length > 0
            );
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.invalidInput,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Invalid Input:',
          placeholder: 'Enter invalid Input message',
          required: false,
        },
        expressions: {
          'props.hideField': () => {
            return (
              formBuilderService.configureFieldAction$.getValue()?.type !==
              EIremboFormlyFieldTypes.customidinput
            );
          },
          'props.required': () => {
            return (
              formBuilderService.configureFieldAction$.getValue()?.type ===
              EIremboFormlyFieldTypes.customidinput
            );
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.invalidId,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Invalid Id:',
          placeholder: 'Enter invalid Id message',
          required: false,
        },
        expressions: {
          'props.hideField': () => {
            return (
              formBuilderService.configureFieldAction$.getValue()?.type !==
              EIremboFormlyFieldTypes.customidinput
            );
          },
          'props.required': () => {
            return (
              formBuilderService.configureFieldAction$.getValue()?.type ===
              EIremboFormlyFieldTypes.customidinput
            );
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.minimumUploadSize,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Minimum file size:',
          placeholder: 'Minimum File Size error message',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return !field.parent?.parent?.model?.['fileSize']?.[
              'minimumUploadSize'
            ];
          },
          'props.required': (field: FormlyFieldConfig) => {
            return field.parent?.parent?.model?.['fileSize']?.[
              'minimumUploadSize'
            ];
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.maximumUploadSize,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Maximum file size:',
          placeholder: 'Maximum File Size error message',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return !field.parent?.parent?.model?.['fileSize']?.[
              'maximumUploadSize'
            ];
          },
          'props.required': (field: FormlyFieldConfig) => {
            return field.parent?.parent?.model?.['fileSize']?.[
              'maximumUploadSize'
            ];
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.invalidfileformat,
        type: EIremboFormlyFieldTypes.input,
        props: {
          label: 'Allowed file formats:',
          placeholder: 'Allowed file formats error message',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return !(
              field.parent?.parent?.model?.['fileFormats'] &&
              Object.keys(field.parent?.parent?.model?.['fileFormats']).some(
                x => field.parent?.parent?.model?.['fileFormats'][x]
              )
            );
          },
          'props.required': (field: FormlyFieldConfig) => {
            return (
              field.parent?.parent?.model?.['fileFormats'] &&
              Object.keys(field.parent?.parent?.model?.['fileFormats']).some(
                x => field.parent?.parent?.model?.['fileFormats'][x]
              )
            );
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.invalidNumberFormat,
        type: EIremboFormlyFieldTypes.input,
        props: {
          placeholder: 'Number format is invalid',
          label: 'Invalid Phone Number Format Message:',
          required: false,
        },
        expressions: {
          'props.hideField': () => {
            const configuredField: FormlyFieldConfig | null =
              formBuilderService.configureFieldAction$.getValue();
            return (
              configuredField?.type !==
              EIremboFormlyFieldTypes.customphonenumber
            );
          },
          'props.required': () => {
            const configuredField: FormlyFieldConfig | null =
              formBuilderService.configureFieldAction$.getValue();
            return (
              configuredField?.type ===
              EIremboFormlyFieldTypes.customphonenumber
            );
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.invalidNumber,
        type: EIremboFormlyFieldTypes.input,
        props: {
          placeholder: 'Provided number is invalid',
          label: 'Invalid Phone Number Message:',
          required: false,
        },
        expressions: {
          'props.hideField': () => {
            const configuredField: FormlyFieldConfig | null =
              formBuilderService.configureFieldAction$.getValue();
            return (
              configuredField?.type !==
              EIremboFormlyFieldTypes.customphonenumber
            );
          },
          'props.required': () => {
            const configuredField: FormlyFieldConfig | null =
              formBuilderService.configureFieldAction$.getValue();
            return (
              configuredField?.type ===
              EIremboFormlyFieldTypes.customphonenumber
            );
          },
        },
      },
      {
        key: EIremboFormlyValidationTypes.uniqueField,
        type: EIremboFormlyFieldTypes.input,
        props: {
          placeholder: 'Enter unique validation error message',
          label: 'Unique Message:',
          required: false,
        },
        expressions: {
          'props.hideField': (field: FormlyFieldConfig) => {
            return !field.parent?.parent?.model['unique'];
          },
          'props.uniqueField': (field: FormlyFieldConfig) => {
            return field.parent?.parent?.model?.['unique'];
          },
        },
      },
    ],
  };
};

export const getFileSizeValidatorFormConfig = (): FormlyFieldConfig => {
  return {
    key: 'fileSize',
    type: EIremboFormlyFieldTypes.block,
    props: {
      label: 'File Size',
      dragDisabled: true,
      required: true,
    },
    fieldGroup: [
      {
        key: 'minimumUploadSize',
        type: EIremboFormlyFieldTypes.input,
        props: {
          type: 'number',
          label: 'Minimum size (in kbs)',
          placeholder: 'Input Number',
          required: true,
          dragDisabled: true,
        },
        parsers: [Number],
        validators: {
          minSizeLessThanMaxSize: {
            expression: (c: AbstractControl) =>
              c.parent?.get('maximumUploadSize')?.value > c.value,
            message: (error: any, field: FormlyFieldConfig) =>
              `Minimum Size (${
                field?.formControl?.value
              }) should be less than Maximum Size(${
                field?.parent?.formControl?.get('maximumUploadSize')?.value
              })`,
          },
        },
      },
      {
        key: 'maximumUploadSize',
        type: EIremboFormlyFieldTypes.input,
        props: {
          type: 'number',
          label: 'Maximum size (in kbs)',
          placeholder: 'Input Number',
          required: true,
          dragDisabled: true,
        },
        parsers: [Number],
        validators: {
          maxSizeMoreThanMinSize: {
            expression: (c: AbstractControl) =>
              c.value > c.parent?.get('minimumUploadSize')?.value,
            message: (error: any, field: FormlyFieldConfig) =>
              `Maximum Size (${
                field?.formControl?.value
              }) should be more than Minimum Size(${
                field?.parent?.formControl?.get('minimumUploadSize')?.value
              })`,
          },
        },
      },
    ],
    hooks: {
      onInit: field => {
        return field?.options?.fieldChanges?.pipe(
          filter(e => {
            return (
              e.type === 'expressionChanges' &&
              (e.field.key === 'minimumUploadSize' ||
                e.field.key === 'maximumUploadSize')
            );
          }),
          tap(() => {
            field.formControl
              ?.get('minimumUploadSize')
              ?.updateValueAndValidity();
            field.formControl
              ?.get('maximumUploadSize')
              ?.updateValueAndValidity();
          })
        );
      },
    },
  };
};

export const getAllowedFileFormatsValidatorFormConfig =
  (): FormlyFieldConfig => {
    return {
      key: 'fileFormats',
      type: EIremboFormlyFieldTypes.multicheckbox,
      props: {
        label: 'File Formats',
        disabled: false,
        required: false,
        placeholder: 'Checkbox',
        options: Object.keys(AttachmentFieldFileFormat).map(key => {
          return {
            label: AttachmentFieldFileFormat[key].label,
            value: key,
          };
        }),
        multiple: false,
      },
    };
  };
