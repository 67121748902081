import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RolesEnum } from '@irembo-andela/irembogov3-common';
import { MainNavigationBarLinkItems } from '../../core/config/nav-bar-link-items.config';
import { RolesGuard } from '../../core/guards/roles.guard';
import { ConfigureServiceChangeRequestComponent } from './configure-service-change-request/configure-service-change-request.component';
import { ManageServiceChangeRequestsComponent } from './manage-service-change-requests/manage-service-change-requests.component';
import { ReviewServiceChangeRequestComponent } from './review-service-change-request/review-service-change-request.component';

const routes: Routes = [
  {
    path: '',
    component: ManageServiceChangeRequestsComponent,
    data: {
      roles: MainNavigationBarLinkItems['services'].allowedRoles,
    },
    canActivate: [AuthGuard, RolesGuard],
  },
  {
    path: 'configure/:requestId',
    component: ConfigureServiceChangeRequestComponent,
    data: {
      roles: [RolesEnum.ROLE_SERVICE_MANAGER],
    },
    canActivate: [AuthGuard, RolesGuard],
  },
  {
    path: 'review/:requestId',
    component: ReviewServiceChangeRequestComponent,
    data: {
      roles: [RolesEnum.ROLE_QA_REVIEWER],
    },
    canActivate: [AuthGuard, RolesGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ServicesRoutingModule {}
