import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'irembogov-basic-toggle-switch',
  templateUrl: './irembogov-basic-toggle-switch.component.html',
  styleUrls: ['./irembogov-basic-toggle-switch.component.scss'],
})
export class IrembogovBasicToggleSwitchComponent implements OnChanges {
  @Input() text?: string;
  @Input() disabled = false;
  @Input() uniqueId?: string;
  @Input() startMargin = 'ms-2';
  @Input() labelTextClass = 'my-4 ';
  @Output() switchChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']?.currentValue) {
      this.disabled = changes['disabled'].currentValue;
    }
  }

  onChange($event?: Event | null): void {
    if ($event) {
      const isChecked = ($event.target as HTMLInputElement).checked;
      this.switchChanged.emit(isChecked);
    }
  }
}
