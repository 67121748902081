<div style="position: fixed; width: 100%; z-index: 999">
  <irembogov-navbar>
    <irembogov-navbar-profile-section
      profile
      [username]="
        keycloackProfile && keycloackProfile.firstName
          ? keycloackProfile.firstName
          : ''
      "
      [updatedProfile]="userProfileImageUrl"
      [activeRole]="RoleEnumToLabelUtil(userActiveRole)"
      [roles]="getOtherRoles()"
      (roleChange)="onRoleChange($event)"
      (logout)="logoutUser()"></irembogov-navbar-profile-section>
  </irembogov-navbar>
</div>
<div class="wrapper" style="background-color: #f3f6fb; min-height: 80vh">
  <div class="container-fluid px-0 pt-5 d-flex">
    <main class="w-100 py-3 mt-5">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<irembogov-toast aria-live="polite" aria-atomic="true"></irembogov-toast>
