import { EServicePriceType } from '@irembo-andela/irembogov3-common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface Condition {
  attribute: string;
  comparator: string;
  value: string;
  attributeType: string;
}

export interface PricingScenario {
  key: string;
  value: any;
  valueAttribute: any;
  currency: string;
  conditions: Condition[];
  valueFormula: any;
}

export interface IPricingTypesOption {
  label: string;
  value: EServicePriceType;
}

@Component({
  selector: 'irembogov-configure-service-change-request-price-handler',
  templateUrl:
    './configure-service-change-request-price-handler.component.html',
})
export class ConfigureServiceChangeRequestPriceHandlerComponent
  implements OnInit, OnDestroy
{
  EPricingType = EServicePriceType;
  @Input() label = '';
  @Input() withCurrency = false;
  @Input() isPricingNumberType = false;
  @Input() formFields!: any;
  @Input() defaultData?: any;
  @Input() buttonName!: string;
  @Input() showToggleButton!: boolean;
  @Output() valueChange: EventEmitter<Record<string, any>> = new EventEmitter<
    Record<string, any>
  >();
  selectedPricingType!: EServicePriceType;
  pricingTypes: IPricingTypesOption[] = [];
  scenarioList$ = new BehaviorSubject<PricingScenario[]>([]);
  fixedScenario$ = new BehaviorSubject<any>(null);

  ngOnInit() {
    this.initSelectedPricingType();
    this.subscribeToForms();
    this.initializeDefaultData();
  }

  ngOnDestroy() {
    this.scenarioList$.unsubscribe();
    this.fixedScenario$.unsubscribe();
  }

  initSelectedPricingType() {
    this.selectedPricingType = this.EPricingType.FIXED;
    this.pricingTypes = [
      { label: 'Fixed', value: this.EPricingType.FIXED },
      { label: 'Dynamic', value: this.EPricingType.DYNAMIC },
    ];
  }

  subscribeToForms() {
    this.scenarioList$.subscribe(scenarioList => {
      this.valueChange.emit({
        ...{
          priceType: this.selectedPricingType,
          baseValue: this.defaultData?.baseValue,
          baseCurrency: this.defaultData?.baseCurrency,
        },
        dynamicConditions: scenarioList,
      });
    });

    this.fixedScenario$.subscribe(fixedScenario => {
      this.valueChange.emit({
        ...fixedScenario,
        dynamicConditions: this.scenarioList$.value,
      });
    });
  }

  initializeDefaultData() {
    if (this.defaultData) {
      const selectedPricingType =
        this.defaultData?.dynamicConditions.length > 0 && this.showToggleButton
          ? this.EPricingType.DYNAMIC
          : this.EPricingType.FIXED;
      this.selectedPricingType = selectedPricingType;
      if (selectedPricingType === this.EPricingType.DYNAMIC) {
        this.scenarioList$.next(this.defaultData?.dynamicConditions);
      } else {
        this.fixedScenario$.next({
          priceType: this.selectedPricingType,
          baseValue: this.defaultData?.baseValue,
          baseCurrency: this.defaultData?.baseCurrency,
        });
      }
    }
  }

  onAddScenarios(scenario: any) {
    this.scenarioList$.next(scenario.scenarios);
  }

  onSetScenario(scenario: any) {
    if (this.selectedPricingType === this.EPricingType.FIXED) {
      scenario['priceType'] = this.selectedPricingType;
      this.fixedScenario$.next(scenario);
    }
  }

  onResetScenarios() {
    this.scenarioList$.next([]);
    this.fixedScenario$.next({});
  }

  onSelectPricingType(pricingType: EServicePriceType) {
    this.selectedPricingType = pricingType;
    this.onResetScenarios();
  }
}
