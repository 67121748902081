<div
  [ngClass]="stepperUniqueId"
  cdkDropList
  [cdkDropListData]="field.fieldGroup ?? []"
  [cdkDropListEnterPredicate]="dropStepPredicate"
  (cdkDropListDropped)="dropStep($event)"
  [cdkDropListDisabled]="reviewModeEnabled">
  <div
    class="irembogov-formly-field-group card mb-4"
    [id]="step.key"
    *ngFor="
      let step of field.fieldGroup;
      let stepIndex = index;
      let last = last
    "
    cdkDrag
    cdkDragBoundary=".{{ stepperUniqueId }}"
    [cdkDragData]="step"
    [cdkDragDisabled]="reviewModeEnabled"
    [ngClass]="{ 'item-selected-highlight': step.key === selectedFieldStep }">
    <div class="card-header bg-transparent border-bottom h5 my-3 d-flex">
      <i
        class="{{
          step.props && step.props['icon']
            ? step.props['icon']
            : 'fa-solid fa-rectangle-list'
        }} me-1 text-primary"></i>
      <span>{{ step.props?.label }}</span>
      <span class="ms-auto d-flex">
        {{ step?.props?.type }}
        <irembogov-settings-toolbar
          *ngIf="!reviewModeEnabled"
          [label]="step.props?.label"
          [type]="step | fieldTypeAsString"
          [field]="step"></irembogov-settings-toolbar>
        <i
          class="fa fa-solid ms-3 text-primary {{
            collapsedSteps.has(step.key) ? 'fa-plus' : 'fa-minus'
          }} pointer-cursor"
          (click)="toggleStepContent(step.key)"></i>
      </span>
    </div>
    <div
      class="card-body border-0"
      [hidden]="collapsedSteps.has(step.key)"
      [id]="EDragDropItemsIds.DefaultBlockCdkDragListItems + stepIndex"
      cdkDropList
      [cdkDropListConnectedTo]="
        getBlockListIds(field.fieldGroup?.length, stepIndex)
      "
      [cdkDropListData]="step.fieldGroup ?? []"
      (cdkDropListDropped)="dropBlock($event)"
      [cdkDropListEnterPredicate]="dropBlockPredicate"
      [cdkDropListDisabled]="reviewModeEnabled">
      <formly-field [field]="step"></formly-field>

      <div
        *ngIf="!reviewModeEnabled"
        class="border-dashed border-dashed-primary p-5 text-center pointer-cursor"
        (click)="addNewBlockToSectionFields(stepIndex)">
        <h6><i class="fa-solid fa-plus me-1"></i>New Block</h6>
      </div>
    </div>
  </div>
</div>
