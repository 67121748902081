<div
  class="irembogov-configure-service-change-request-stepper d-flex  {{
    orientation && orientation === 'vertical' ? 'flex-row' : 'flex-column'
  }}">
  <div
    class="configure-stepper-nav-items {{
      orientation && orientation === 'vertical' ? 'w-15' : ''
    }}">
    <div
      class="config-navs d-flex {{
        orientation && orientation === 'vertical'
          ? 'flex-column align-items-start bg-white'
          : 'justify-content-center align-items-center border-bottom'
      }} pb-0 border-2"
      style="height: 100%">
      <button
        *ngFor="let step of steps; let index = index"
        [class]="{
          selected: index === selectedIndex,
          linkitem: true,
          completed: step.completed
        }"
        (click)="selectStepByIndex(index)">
        <i
          class="fa-solid fa-check text-success me-2"
          *ngIf="step.completed"></i>
        <i
          aria-hidden="true"
          [class]="
            configSteps[index].icon ? configSteps[index].icon + ' me-2' : 'me-2'
          "
          *ngIf="!step.completed"></i>
        <span
          ><span *ngIf="showIndexInLabel">{{ index + 1 }}.&nbsp;</span
          >{{ step.label }}</span
        >
      </button>
    </div>
  </div>

  <div
    class="configure-stepper-content  {{
      orientation && orientation === 'vertical' ? 'w-85' : ''
    }}">
    <ng-container [ngTemplateOutlet]="selected ? selected.content : null">
    </ng-container>
  </div>
</div>
