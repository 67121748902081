import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FormlyFieldConfig,
  FormlyForm,
  FormlyFormOptions,
} from '@ngx-formly/core';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { IFormBuilderFieldsData } from '../../../../core/models/form-builder-fields-data.model';
import { Subject, takeUntil } from 'rxjs';
import { FormBuilderManipulatorClass } from '../../../../core/utils/form-builder-manipulator-class.utils';
import {
  alphaNanoIdUtil,
  EIremboFormlyFieldTypes,
} from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-preview-service-change-request-json-formdata-widget',
  templateUrl:
    './preview-service-change-request-json-formdata-widget.component.html',
  styleUrls: [
    './preview-service-change-request-json-formdata-widget.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PreviewServiceChangeRequestJsonFormdataWidgetComponent
  implements OnInit, OnDestroy
{
  previewDataFields!: FormlyFieldConfig[];

  subjectControl$: Subject<void> = new Subject<void>();

  reviewModeEnabled = false;

  form = new FormGroup({});
  model: unknown = {};
  options: FormlyFormOptions = {};
  formBuilderManipulator!: FormBuilderManipulatorClass;

  @ViewChild('formly') formly!: FormlyForm;

  constructor(
    private formBuilderService: FormBuilderService,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.formBuilderManipulator = new FormBuilderManipulatorClass(
      this.injector
    );
  }

  ngOnInit(): void {
    this.previewDataFields =
      this.formBuilderService.formlyFieldsSubject$.getValue().fields;
    this.reviewModeEnabled =
      this.formBuilderService.reviewModeEnabled$.getValue();

    this.formBuilderService.formlyFieldsSubject$
      .pipe(takeUntil(this.subjectControl$))
      .subscribe((formBuilderFieldsData: IFormBuilderFieldsData) => {
        this.previewDataFields = [...formBuilderFieldsData.fields];
        this.form.reset();
        this.changeDetectorRef.detectChanges();
      });
    this.formBuilderService.reviewModeEnabled$
      .pipe(takeUntil(this.subjectControl$))
      .subscribe((isEnabled: boolean) => {
        this.reviewModeEnabled = isEnabled;
        this.changeDetectorRef.markForCheck();
      });
  }

  addNewSectionToFields() {
    const key = `section${alphaNanoIdUtil(5)}`;
    const label = `New Section`;

    const newSection: FormlyFieldConfig = {
      key,
      props: { label },
      fieldGroup: [],
      type: EIremboFormlyFieldTypes['formly-group'],
    };

    this.formBuilderManipulator.addNewSectionToFields(newSection);
  }

  ngOnDestroy(): void {
    this.subjectControl$.next();
    this.subjectControl$.complete();
  }
}
