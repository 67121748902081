<div class="officer-form mt-5 w-100">
  <div class="row m-3 rounded">
    <div class="bg-white rounded-1 p-3 col-12" *ngIf="formExistsForm">
      <form [formGroup]="formExistsForm" class="row mb-3">
        <h5 class="mb-4 font-weight-bold border-bottom-secondary">
          Does the service have an officer form?
        </h5>
        <div class="form-group row">
          <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-xs-3">
            <div
              class="card mb-2"
              [ngClass]="
                formExistsForm.controls['formExists'].value === 'YES'
                  ? 'card-selected'
                  : ''
              ">
              <div
                class="card-body"
                role="button"
                (click)="onFormExistenceChange('YES')">
                <input
                  name="formExists"
                  [id]="'YES'"
                  [value]="'YES'"
                  formControlName="formExists"
                  class="form-check-input"
                  type="radio" />
                <label [for]="'Yes'" style="padding-left: 12px"> Yes </label>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-2 col-sm-2 col-xs-3">
            <div
              class="card mb-2"
              [ngClass]="
                formExistsForm.controls['formExists'].value === 'NO'
                  ? 'card-selected'
                  : ''
              ">
              <div
                class="card-body"
                role="button"
                (click)="onFormExistenceChange('NO')">
                <input
                  name="formExists"
                  [id]="'NO'"
                  [value]="'NO'"
                  formControlName="formExists"
                  class="form-check-input"
                  type="radio" />
                <label [for]="'NO'" style="padding-left: 12px"> No </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    class="row bg-white m-3 rounded {{
      formExistsForm.get('formExists')?.value === 'YES' ? 'd-block' : 'd-none'
    }} ">
    <div class="w-100">
      <div #errorMessageElement>
        <irembogov-irembo-section-message
          *ngIf="showConfigFormErrorMessage"
          [type]="formAlertContent.type"
          [title]="formAlertContent.title"
          [message]="formAlertContent.message"
          [extraMessage]="formAlertContent.extraMessage"
          [extraMessageAsHtml]="true"></irembogov-irembo-section-message>
      </div>
    </div>
    <div class="w-100 d-flex p-3" *ngIf="changeRequestThirdPartyFormData">
      <div class="flex-grow-1 me-3 preview-section-wrapper">
        <div class="preview-section">
          <div class="mb-3">
            <div class="preview-box">
              <div
                class="preview-box-invalid"
                *ngIf="this.jsonEditorValidationErrors.length > 0">
                Invalid Json Object in Code Editor
              </div>
              <div class="preview-box-view p-3 rounded">
                <irembogov-officer-form-preview-json-formdata-widget></irembogov-officer-form-preview-json-formdata-widget>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="justify-self w-40 side-pane-wrapper">
        <div #editor>
          <div class="code-editor-section">
            <div class="mb-3">
              <div class="form-group">
                <div class="input-group">
                  <json-editor
                    [options]="editorOptions"
                    id="jsonFormData"
                    class="form-control p-0 json-form-data-field"
                    (change)="validateJsonEditor()"
                    inputMode="code"
                    [data]="
                      jsonEditorFormData
                        ? jsonEditorFormData
                        : DefaultEmptyFormData
                    "
                    #jsonFormData>
                  </json-editor>
                </div>
                <div
                  class="form-error w-100 small text-danger"
                  *ngIf="jsonEditorValidationErrors.length > 0">
                  JSON input is invalid
                  <ol>
                    <li
                      class="w-100 small"
                      *ngFor="
                        let error of jsonEditorValidationErrors;
                        let index = index
                      ">
                      <em class="text-muted"> Line {{ error['line'] }}</em>
                      <br />
                      <small [innerHTML]="error['message']"></small>
                    </li>
                  </ol>
                </div>
                <div
                  class="form-error w-100 small text-danger"
                  *ngIf="jsonEditorFormData.length === 0">
                  Please enter a JSON Form entry
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="m-3">
    <div class="text-end">
      <button
        (click)="onThirdPartyFormSubmit()"
        class="btn btn-primary mt-1 mx-auto px-5"
        type="button"
        [disabled]="
          isLoading ||
          jsonEditorValidationErrors.length > 0 ||
          (jsonEditorFormData.length === 0 &&
            formExistsForm.get('formExists')?.value === 'YES') ||
          formExistsForm.invalid
        ">
        <div
          *ngIf="isLoading"
          class="spinner-border spinner-border-sm mr-2"
          role="status"></div>
        Continue
      </button>
    </div>
  </div>
</div>
