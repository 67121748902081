import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  IGenericDataFetchFieldsConfigFormModel,
} from '../../../models/form-builder-types-configurations.model';

import { FormBuilderService } from '../../../services/form-builder.service';
import {
  generateDataFetchDataSettingsConfig,
  genericDataFieldFieldParseIntoSettingsFormModel,
  genericDataFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { EIremboFormlyFieldTypes } from '@irembo-andela/irembogov3-common';
import {
  getRequiredValidatorFormConfig,
  getValidationFieldsFormConfig,
} from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';

const defaultCustomDropDownWithDataFetchConfigFormModel: IGenericDataFetchFieldsConfigFormModel =
  {
    fieldLabel: null,
    placeholder: null,
    hint: null,
    tooltip: null,
    internalDescription: null,
    disabled: false,
    options: [],
    datasetSection: {
      url: null,
      dataField: null,
      bindValue: null,
      bindLabel: null,
      customUrl: null,
      params: {
        size: 10,
        page: 0,
      },
    },
  };

export const customDropDownWithDataFetchFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultCustomDropDownWithDataFetchConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
        {
          key: 'disabled',
          type: EIremboFormlyFieldTypes.checkbox,
          props: {
            label: 'Disabled',
            hideHeader: true,
            placeholder: 'Disabled',
            required: false,
            formCheck: 'switch',
          },
          id: 'disabled',
        },
        generateDataFetchDataSettingsConfig(),
      ],
    },
    validations: {
      formModel: {},
      formlyForm: [
        getRequiredValidatorFormConfig(),
        getValidationFieldsFormConfig(formBuilderServiceInstance),
      ],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const customDropDownWithDataFetchFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IGenericDataFetchFieldsConfigFormModel => {
  return genericDataFieldFieldParseIntoSettingsFormModel(field);
};

export const customDropDownWithDataFetchFieldUpdateFromSettingsFormModel = (
  model: IGenericDataFetchFieldsConfigFormModel,
  dropdownField: FormlyFieldConfig
): FormlyFieldConfig => {
  return genericDataFieldUpdateFromSettingsFormModel(model, dropdownField);
};
