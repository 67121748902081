import { Injectable } from '@angular/core';
import { RolesEnum } from '@irembo-andela/irembogov3-common';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { BehaviorSubject, from, Observable, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userActiveRole: BehaviorSubject<RolesEnum> = new BehaviorSubject<RolesEnum>(
    RolesEnum._UNKNOWN_ROLE_
  );
  private cancelSubscription$ = new Subject<void>();

  constructor(private keyCloakService: KeycloakService) {
    this.redirectUserOnTokenExpired();
  }

  async isLoggedIn(): Promise<boolean> {
    return this.keyCloakService.isLoggedIn();
  }

  loadUserProfile(): Observable<KeycloakProfile> {
    return from(this.keyCloakService.loadUserProfile());
  }

  async loadUserIremboRoles(): Promise<RolesEnum[]> {
    const userIremboRoles: RolesEnum[] = [];

    if (!(await this.keyCloakService.isLoggedIn())) return userIremboRoles;

    const keycloakRolesList: string[] = this.keyCloakService.getUserRoles();

    Object.keys(RolesEnum).forEach((role: string) => {
      if (keycloakRolesList.includes(role)) {
        userIremboRoles.push(role as RolesEnum);
      }
    });

    if (
      userIremboRoles.length > 0 &&
      this.userActiveRole.getValue() === RolesEnum._UNKNOWN_ROLE_
    ) {
      this.userActiveRole.next(userIremboRoles[0]);
    }

    return userIremboRoles;
  }

  /**
   * Observers when the token is expired or token refresh process fails
   * and redirects the user to login page
   */
  redirectUserOnTokenExpired() {
    this.keyCloakService.keycloakEvents$
      .pipe(takeUntil(this.cancelSubscription$))
      .subscribe(event => {
        if (event.type === KeycloakEventType.OnAuthRefreshError) {
          this.keyCloakService.login({
            prompt: 'login',
          });
        }
      });
  }

  /**
   * Ends the session of the user
   * and redirects the user to the login page
   */
  async logoutUser(): Promise<void> {
    await this.keyCloakService.logout();
    this.keyCloakService.clearToken();
  }
}
