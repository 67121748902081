import { Pipe, PipeTransform } from '@angular/core';
import { EServicePriceType } from '../enums';

@Pipe({ name: 'serviceTypeEnumToLabel' })
export class ServiceTypeEnumToLabel implements PipeTransform {
  transform(serviceType: EServicePriceType | undefined): string {
    switch (serviceType) {
      case 'DYNAMIC':
        return 'Dynamic';
      case 'FIXED':
        return 'Fixed';
      case 'FREE':
        return 'Free';
      default:
        return '';
    }
  }
}
