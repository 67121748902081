<ng-container>
  <div
    class="irembogov-form-field-types-list my-3"
    cdkDropList
    [id]="EDragDropItemsIds.MainBuilderFieldTypesListItems"
    [cdkDropListConnectedTo]="connectedBlockId">
    <div
      cdkDrag
      class="form-field-types rounded shadow px-3 py-2 mb-2 border border-light-gray text-muted d-flex align-items-center"
      *ngFor="let item of formFieldTypes"
      [cdkDragData]="item.key">
      <i class="fa-solid fa-mattress-pillow me-2"></i>
      <span>
        {{ item.label }}
      </span>
      <i class="fa-solid fa-grip-vertical ms-auto"></i>
    </div>
  </div>
</ng-container>
