import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';
import { EServiceChangeRequestStatus } from '../models/service-change-request-status.enum';

export const GetServiceChangeRequestStatusEnumLabelKeyPair = (
  status: EServiceChangeRequestStatus | null
): IIrembogovBasicLabelKeyPair<EServiceChangeRequestStatus> => {
  switch (status) {
    case EServiceChangeRequestStatus.IN_DEVELOPMENT:
    case EServiceChangeRequestStatus.IN_REDEVELOPMENT:
      return {
        label: 'In Development',
        key: status,
        bgClass: 'bg-development',
      };
    case EServiceChangeRequestStatus.IN_QA:
      return {
        label: 'In QA',
        key: status,
        bgClass: 'bg-qa',
      };
    case EServiceChangeRequestStatus.LIVE:
      return {
        label: 'Live',
        key: status,
        bgClass: 'bg-live',
      };
    case EServiceChangeRequestStatus.LIVE_READY:
      return {
        label: 'Ready To Go Live',
        key: status,
        bgClass: 'bg-live-ready',
      };
    case EServiceChangeRequestStatus.REJECTED_REQUEST:
      return {
        label: 'Request Rejected',
        key: status,
        bgClass: 'bg-rejected-request',
      };
    case EServiceChangeRequestStatus.REJECTED_REVIEW:
      return {
        label: 'Review Rejected',
        key: status,
        bgClass: 'bg-rejected-review',
      };
    case EServiceChangeRequestStatus.WAITING_APPROVER:
      return {
        label: 'Waiting Approval',
        key: status,
        bgClass: 'bg-waiting-approver',
      };
    default:
      return {
        label: 'Unknown',
        key: EServiceChangeRequestStatus._UNKNOWN_,
        bgClass: 'bg-info',
      };
  }
};
