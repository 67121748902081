import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { EServiceChangeRequestStatus } from '../../../../core/models/service-change-request-status.enum';
import { GetServiceChangeRequestStatusEnumLabelKeyPair } from '../../../../core/utils/change-request-status.utils';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormBuilderService } from '../../../../core/services/form-builder.service';
import { Subject, takeUntil } from 'rxjs';
import { IFormBuilderFieldsData } from '../../../../core/models/form-builder-fields-data.model';

@Component({
  selector: 'irembogov-change-request-configuration-header-widget',
  templateUrl: './change-request-configuration-header-widget.component.html',
  styleUrls: ['./change-request-configuration-header-widget.component.scss'],
})
export class ChangeRequestConfigurationHeaderWidgetComponent
  implements OnInit, OnDestroy
{
  @Input() request!: IServiceChangeRequest;
  @Input() inReviewMode = false;
  @Input() readyForSaveSubmit = false;
  @Input() formPersistenceStatus!: string;
  @Input() isSaveAndCloseLoading = false;
  @Output() OnClickRequirementDoc: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() OnSaveAndClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() OnPreviewClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() OnSaveSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  jsonFormFields!: FormlyFieldConfig[];

  formlyFieldsSubjectSbjectControl$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private formBuilderService: FormBuilderService
  ) {}

  ngOnInit(): void {
    this.formBuilderService.formlyFieldsSubject$
      .pipe(takeUntil(this.formlyFieldsSubjectSbjectControl$))
      .subscribe(
        (data: IFormBuilderFieldsData) => (this.jsonFormFields = data.fields)
      );
  }

  getServiceChangeRequestStatusBadgeClass(
    requestStatus: EServiceChangeRequestStatus
  ): IIrembogovBasicLabelKeyPair<EServiceChangeRequestStatus> {
    return GetServiceChangeRequestStatusEnumLabelKeyPair(requestStatus);
  }

  async goBackToServices() {
    try {
      await this.router.navigate(['/services']);
    } catch (e: unknown) {
      return;
    }
  }

  ngOnDestroy(): void {
    this.formlyFieldsSubjectSbjectControl$.next();
    this.formlyFieldsSubjectSbjectControl$.complete();
  }
}
