import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { RouterLinkWithHref } from '@angular/router';

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomLoader(http);
}
export class CustomLoader implements TranslateLoader {
  constructor(private httpClient: HttpClient) {}
  getTranslation(lang: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const apiAddress = `${environment.translationSourceUrl}/admin/v1/translations/static-text/by-locale/${lang}`;
    return this.httpClient.get(apiAddress, { headers: header });
  }
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forChild({
      defaultLanguage:
        localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterLinkWithHref,
  ],
  exports: [TranslateModule],
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
    };
  }
}
