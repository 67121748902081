import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EServiceChangeRequestToggleHeaders } from './../../../../core/models/service-change-request-toggle-header-section.enum';
import { IIrembogovBasicLabelKeyPair } from '@irembo-andela/irembogov3-common';
import { EServiceChangeRequestSection } from './../../../../core/models/service-change-request-sections.enum';

@Component({
  selector: 'irembogov-service-change-request-feedback-handler-widget',
  templateUrl:
    './service-change-request-feedback-handler-widget.component.html',
})
export class ServiceChangeRequestFeedbackHandlerWidgetComponent
  implements OnInit
{
  @Input() serviceChangeRequestSection!: EServiceChangeRequestSection;
  @Input() changeRequestId?: string;
  @Input() selectedOption!: EServiceChangeRequestToggleHeaders;
  @Output()
  selectOptionChanged: EventEmitter<EServiceChangeRequestToggleHeaders> =
    new EventEmitter<EServiceChangeRequestToggleHeaders>();
  EServiceChangeRequestToggleHeaders = EServiceChangeRequestToggleHeaders;
  EServiceChangeRequestSection = EServiceChangeRequestSection;

  headerOptions: IIrembogovBasicLabelKeyPair<EServiceChangeRequestToggleHeaders>[] =
    [
      { label: 'Wizard', key: EServiceChangeRequestToggleHeaders.WIZARD },
      {
        label: 'Code Editor',
        key: EServiceChangeRequestToggleHeaders.CODE_EDITOR,
      },
      { label: 'Reviews', key: EServiceChangeRequestToggleHeaders.REVIEW },
    ];

  ngOnInit(): void {
    if (!this.selectedOption) {
      this.onSelectOptionChange(EServiceChangeRequestToggleHeaders.REVIEW);
    }
  }

  onSelectOptionChange(option: EServiceChangeRequestToggleHeaders) {
    this.selectedOption = option;
    this.selectOptionChanged.emit(option);
  }
}
