<div class="review-submission w-100 d-flex flex-column p-3">
  <div class="loading-issues d-flex justify-content-center" *ngIf="isLoading">
    <div class="spinner-grow text-muted mx-1" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">
    <h5 class="d-flex flex-row align-content-cente">
      <span class="align-content-cente">
        <i class="fa-solid fa-list-check me-2"></i> Preview &amp; Submit
      </span>

      <span class="ms-auto" *ngIf="collectionSize > 0">
        <button class="btn btn-primary" (click)="reviewRejected.emit(true)">
          Submit for changes
        </button>
      </span>
    </h5>

    <div class="w-100">
      <div
        class="card bg-white border-0 rounded mt-3"
        *ngIf="collectionSize < 1">
        <div
          class="text-center justify-contents-center align-items-center flex-column mt-5 px-3 py-5">
          <h5 class="p-3">No issues reported</h5>
          <button
            class="btn btn-primary btn-sm"
            (click)="this.reviewApproved.emit(true)">
            Approve Change Request Configurations
          </button>
        </div>
      </div>

      <div
        class="card bg-white border-0 rounded mt-3 px-3 py-4"
        *ngIf="collectionSize > 0">
        <irembogov-custom-table-wrapper
          wapperTopMarginClass="mt-n5"
          *ngIf="collectionSize > 0"
          [isSortVisible]="false"
          [loading]="isLoading"
          [paginationVisible]="true"
          [totalItems]="collectionSize"
          [itemsPerPage]="pageSize"
          [currentPage]="currentPage"
          [pageSizes]="[10, 20, 50]"
          (paginationChange)="onPaginationChange($event)">
          <table
            body
            aria-label="Service Change Request Issues Table"
            class="table">
            <thead>
              <tr>
                <th scope="col" class="text-nowrap w-20">Title</th>
                <th scope="col" class="text-nowrap w-20">Feature</th>
                <th scope="col" class="text-nowrap">Description</th>
              </tr>
            </thead>
            <tbody class="rounded bg-white" *ngIf="!isLoading">
              <tr *ngFor="let issue of allReviewIssues; let index = index">
                <td class="text-nowrap">
                  <strong>
                    {{ issue.title }}
                  </strong>
                </td>
                <td class="text-nowrap">
                  <strong>
                    {{ issue.section | reviewSectionToLabelPipe }}
                  </strong>
                </td>
                <td class="text-nowrap">
                  <strong>
                    {{ issue.description }}
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </irembogov-custom-table-wrapper>
      </div>
    </div>
  </ng-container>
</div>
