import {
  Component,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IServiceChangeRequest } from '../../../../core/models/service-change-request.model';
import { EServiceChangeRequestReviewSection } from '../../../../core/models/service-change-request-review-issue.model';
import { IServiceRequestPricing } from '../../../../core/models/service-pricing.model';

@Component({
  selector: 'irembogov-review-pricing',
  templateUrl: './review-pricing.component.html',
  styleUrls: ['./review-pricing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewPricingComponent implements OnChanges {
  @Input() changeRequest!: IServiceChangeRequest;
  @Input() pricingData!: Record<string, unknown>;
  @Output() reviewPricingCompleted: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  serviceRequestPricing!: IServiceRequestPricing;

  EServiceChangeRequestReviewSection = EServiceChangeRequestReviewSection;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pricingData']?.currentValue) {
      this.serviceRequestPricing = changes['pricingData'].currentValue;
    }
  }
}
