import { Pipe, PipeTransform } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Pipe({ name: 'fieldTypeAsString' })
export class FieldConfigTypeAsStringPipe implements PipeTransform {
  transform(field: FormlyFieldConfig | null | undefined): string | undefined {
    if (!field) return undefined;
    return field.type ? String(field?.type) : field.props?.type ?? undefined;
  }
}
