import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IFormbuilderFieldTypeConfiguration,
  IGenericDataFetchFieldsConfigFormModel,
} from '../../../models/form-builder-types-configurations.model';

import { FormBuilderService } from '../../../services/form-builder.service';
import {
  generateDatasetSettingsConfig,
  generateNonPaginatedDataSetSettingsConfig,
  genericDataFieldFieldParseIntoSettingsFormModel,
  genericDataFieldUpdateFromSettingsFormModel,
  getDefaultBaseSettingsConfigFormly,
} from '../form-fields-formly-settings/form-builder-default-settings-configuration-formly-fields.utils';
import { EIremboFormlyFieldTypes } from '@irembo-andela/irembogov3-common';
import { IFormFieldsValidationModel } from 'src/app/core/models/form-fields-formly-validation.model';
import { parseRequiredValidatorParseFieldIntoModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-parse-to-model.util';
import { setRequiredValidatorUpdateFieldFromModel } from '../form-fields-formly-validations/form-fields-formly-validation-field-set-from-model.util';
import { IFormFieldsDisplayModel } from 'src/app/core/models/form-fields-formly-display.model';
import { parseFieldIntoDisplayFormModel } from '../form-fields-formly-display/form-fields-formly-display-field-parse-to-model.util';
import { setDisplayHideExpressionFromModel } from '../form-fields-formly-display/form-fields-formly-display-field-set-from-model.util';
import { getFieldDisplayFormConfig } from '../form-fields-formly-display/form-fields-formly-display-field-generator.util';
import { getRequiredValidatorFormConfig } from '../form-fields-formly-validations/form-fields-formly-validation-field-generator.util';

const defaultCustomDropDownWithDataSetConfigFormModel: IGenericDataFetchFieldsConfigFormModel =
  {
    fieldLabel: null,
    placeholder: null,
    hint: null,
    tooltip: null,
    internalDescription: null,
    internal: true,
    readonly: false,
    disabled: false,
    options: [],
    datasetSection: {
      url: null,
      dataField: null,
      bindValue: 'value',
      bindLabel: 'label',
      customUrl: null,
      code: null,
    },
  };

export const customDropDownWithDatasetFieldGetConfigurationConfig = (
  formBuilderServiceInstance: FormBuilderService
): IFormbuilderFieldTypeConfiguration => {
  return {
    settings: {
      formModel: defaultCustomDropDownWithDataSetConfigFormModel,
      formlyForm: [
        ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
        {
          key: 'readonly',
          type: EIremboFormlyFieldTypes.checkbox,
          props: {
            label: 'Read Only',
            hideHeader: true,
            placeholder: 'Read Only',
            required: false,
            formCheck: 'switch',
          },
          id: 'readonly',
        },
        {
          key: 'disabled',
          type: EIremboFormlyFieldTypes.checkbox,
          props: {
            label: 'Disabled',
            hideHeader: true,
            placeholder: 'Disabled',
            required: false,
            formCheck: 'switch',
          },
          id: 'disabled',
        },
        generateDatasetSettingsConfig(),
      ],
    },
    validations: {
      formModel: {
        minLength: '',
        required: '',
        maxLength: '',
      },
      formlyForm: [getRequiredValidatorFormConfig()],
    },
    display: {
      formModel: {},
      formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
    },
  };
};

export const customDropDownWithDataSetFieldNonPaginatedGetConfigurationConfig =
  (
    formBuilderServiceInstance: FormBuilderService
  ): IFormbuilderFieldTypeConfiguration => {
    return {
      settings: {
        formModel: defaultCustomDropDownWithDataSetConfigFormModel,
        formlyForm: [
          ...getDefaultBaseSettingsConfigFormly(formBuilderServiceInstance),
          {
            key: 'readonly',
            type: EIremboFormlyFieldTypes.checkbox,
            props: {
              label: 'Read Only',
              hideHeader: true,
              placeholder: 'Read Only',
              required: false,
              formCheck: 'switch',
            },
            id: 'readonly',
          },
          {
            key: 'disabled',
            type: EIremboFormlyFieldTypes.checkbox,
            props: {
              label: 'Disabled',
              hideHeader: true,
              placeholder: 'Disabled',
              required: false,
              formCheck: 'switch',
            },
            id: 'disabled',
          },
          generateNonPaginatedDataSetSettingsConfig(),
        ],
      },
      validations: {
        formModel: {
          minLength: '',
          required: '',
          maxLength: '',
        },
        formlyForm: [getRequiredValidatorFormConfig()],
      },
      display: {
        formModel: {},
        formlyForm: [...getFieldDisplayFormConfig(formBuilderServiceInstance)],
      },
    };
  };

export const customDropDownWithDatasetFieldParseIntoSettingsFormModel = (
  field: FormlyFieldConfig
): IGenericDataFetchFieldsConfigFormModel => {
  return genericDataFieldFieldParseIntoSettingsFormModel(field);
};

export const customDropDownWithDatasetFieldUpdateFromSettingsFormModel = (
  model: IGenericDataFetchFieldsConfigFormModel,
  dropdownField: FormlyFieldConfig
): FormlyFieldConfig => {
  return genericDataFieldUpdateFromSettingsFormModel(model, dropdownField);
};

export const customDropWithDatasetFieldParseIntoValidationFormModel = (
  field: FormlyFieldConfig
): IFormFieldsValidationModel => {
  let model: IFormFieldsValidationModel = {};

  model = parseRequiredValidatorParseFieldIntoModel(model, field);
  return model;
};

export const customDropWithDatasetFieldUpdateFromValidationFormModel = (
  model: IFormFieldsValidationModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  field = setRequiredValidatorUpdateFieldFromModel(model, field);
  return field;
};

export const customDropDownWithDatasetFieldDisplayFormModel = (
  field: FormlyFieldConfig
): IFormFieldsDisplayModel => {
  return parseFieldIntoDisplayFormModel(field);
};

export const customDropDownWithDatasetUpdateFromDisplayFormModel = (
  model: IFormFieldsDisplayModel,
  field: FormlyFieldConfig
): FormlyFieldConfig => {
  return setDisplayHideExpressionFromModel(model, field);
};
