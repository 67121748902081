<section
  class="irembogov-field-wrapper {{ field.className ?? '' }}"
  [ngClass]="{
    'item-selected-highlight': isSelected,
    'hover-effects': !formState.hideBuilderWrapper
  }">
  <header
    class="wrapper-header d-flex align-items-center pb-3"
    *ngIf="!props['hideHeader']">
    <span class="label tt_norms400 my-1">
      <i
        *ngIf="props['tooltip']"
        aria-hidden="true"
        class="fas fa-circle-info ms-2"
        placement="top"
        [ngbTooltip]="props['tooltip']"></i>
    </span>
    <small *ngIf="!props['hideLabel']">
      {{ props.label }}<sup *ngIf="props.required">*</sup>
    </small>
    <irembogov-settings-toolbar
      *ngIf="
        !(
          reviewModeEnabled ||
          formState.hideBuilderWrapper ||
          field.props?.['nonConfigurable']
        )
      "
      class="ms-auto"
      [label]="props.label"
      [type]="props.type"
      (fieldConfigActionEvent)="isSelected = $event"
      [field]="field"></irembogov-settings-toolbar>
  </header>
  <div class="wrapper-content">
    <ng-container #fieldComponent></ng-container>
    <ng-container *ngIf="props['internalDescription']">
      <em
        *ngIf="!props['internalDescriptionasHtml']"
        class="small d-block w-100 text-muted m-2 internalDescription">
        <i class="fa-solid fa-square-pen me-1"></i>
        {{ props['internalDescription'] }}
      </em>

      <em
        *ngIf="props['internalDescriptionasHtml']"
        class="small d-block w-100 text-muted m-2 internalDescription"
        [innerHTML]="props['internalDescription']">
      </em>
    </ng-container>
  </div>
</section>
