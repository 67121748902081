<main
  class="container-fluid px-0 irembogov-review-service-change-request mt-n5 pt-1">
  <irembogov-irembo-loading-circle-spinner
    *ngIf="isLoadingChangeRequest"></irembogov-irembo-loading-circle-spinner>

  <ng-container *ngIf="!isLoadingChangeRequest">
    <ng-container *ngIf="loadRequestDetailsFailed">
      <div class="col-8 text-center mx-auto mt-5">
        <irembogov-irembo-section-message
          [type]="'danger'"
          [message]="loadFailedErrorCodeMessage.message"
          title="Error Encountered: {{
            loadFailedErrorCodeMessage.code
          }}"></irembogov-irembo-section-message>
        <a [routerLink]="['/services']">
          &lt;&lt; Back to Change Requests List</a
        >
      </div>
    </ng-container>

    <ng-container *ngIf="!loadRequestDetailsFailed">
      <irembogov-change-request-configuration-header-widget
        [readyForSaveSubmit]="readyForFinalReview"
        [request]="changeRequest"
        [inReviewMode]="true"
        (OnPreviewClick)="toggleFormPreview($event)"
        (OnClickRequirementDoc)="
          onViewRequirementDocs()
        "></irembogov-change-request-configuration-header-widget>

      <ng-container *ngIf="changeRequest">
        <div
          class="mt-5 p-5 container rounded bg-white"
          *ngIf="showFormPreview">
          <irembogov-preview-service-change-request-json-formdata-widget
            class="mx-auto"
            *ngIf="
              changeRequestJsonFormData
            "></irembogov-preview-service-change-request-json-formdata-widget>
        </div>

        <irembogov-configure-service-change-request-stepper
          *ngIf="!showFormPreview"
          orientation="vertical"
          [showIndexInLabel]="false"
          [configSteps]="reviewSteps"
          [selectedIndex]="currentConfigStep"
          (selectionChange)="OnStepperChange($event)">
          <cdk-step
            [label]="item.label"
            *ngFor="let item of reviewSteps; let index = index"
            [hasError]="reviewSteps[index].hasError"
            [completed]="reviewSteps[index].isComplete">
            <ng-container
              *ngTemplateOutlet="getCurrentTemplate(index)"></ng-container>
          </cdk-step>
        </irembogov-configure-service-change-request-stepper>
      </ng-container>
    </ng-container>
  </ng-container>
</main>

<ng-template #reviewOverview>
  <irembogov-irembo-loading-circle-spinner
    marginTop="8rem"
    *ngIf="
      isLoadingChangeRequestDetails
    "></irembogov-irembo-loading-circle-spinner>

  <irembogov-review-metadata-overview
    *ngIf="!isLoadingChangeRequestDetails"
    [changeRequest]="changeRequest"
    (reviewMetadataCompleted)="
      onStepFormSubmitted($event)
    "></irembogov-review-metadata-overview>
</ng-template>

<ng-template #reviewForm>
  <irembogov-irembo-loading-circle-spinner
    marginTop="8rem"
    *ngIf="
      isLoadingChangeRequestDetails
    "></irembogov-irembo-loading-circle-spinner>

  <irembogov-review-formbuilder-json-editor
    *ngIf="!isLoadingChangeRequestDetails"
    [changeRequest]="changeRequest"
    (reviewFormBuilderJsonEditorCompleted)="onStepFormSubmitted($event)">
  </irembogov-review-formbuilder-json-editor>
</ng-template>

<ng-template #reviewPricing>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="
      isLoadingChangeRequestDetails
    "></irembogov-irembo-loading-circle-spinner>

  <irembogov-review-pricing
    *ngIf="!isLoadingChangeRequestDetails"
    [pricingData]="serviceChangeRequestPricingData"
    [changeRequest]="changeRequest"
    (reviewPricingCompleted)="onStepFormSubmitted($event)">
  </irembogov-review-pricing>
</ng-template>

<ng-template #reviewWorkflow>
  <irembogov-irembo-loading-circle-spinner
    marginTop="8rem"
    *ngIf="
      isLoadingChangeRequestDetails
    "></irembogov-irembo-loading-circle-spinner>

  <irembogov-review-workflow-json-entry
    *ngIf="!isLoadingChangeRequestDetails"
    [changeRequestJsonWorkflow]="changeRequestJsonWorkflowData"
    [changeRequest]="changeRequest"
    (reviewWorkflowJsonCompleted)="onStepFormSubmitted($event)">
  </irembogov-review-workflow-json-entry>
</ng-template>

<ng-template #reviewSla>
  <div class="text-center">
    SLA:
    <button class="btn btn-sm btn-primary" (click)="onStepFormSubmitted(true)">
      Next
    </button>
  </div>
</ng-template>

<ng-template #reviewNotifications>
  <div class="text-center">
    Notifications:
    <button class="btn btn-sm btn-primary" (click)="onStepFormSubmitted(true)">
      Next
    </button>
  </div>
</ng-template>

<ng-template #reviewOfficeAssignment>
  <div class="text-center">
    Office Assignment:
    <button class="btn btn-sm btn-primary" (click)="onStepFormSubmitted(true)">
      Next
    </button>
  </div>
</ng-template>

<ng-template #reviewIntegrations>
  <div class="text-center">
    Integrations:
    <button class="btn btn-sm btn-primary" (click)="onStepFormSubmitted(true)">
      Next
    </button>
  </div>
</ng-template>

<ng-template #reviewDocuments>
  <div class="text-center">
    Documents:
    <button class="btn btn-sm btn-primary" (click)="onStepFormSubmitted(true)">
      Next
    </button>
  </div>
</ng-template>

<ng-template #reviewTranslation>
  <irembogov-irembo-loading-circle-spinner
    *ngIf="
      isLoadingChangeRequestDetails
    "></irembogov-irembo-loading-circle-spinner>
  <irembogov-review-translations
    *ngIf="!isLoadingChangeRequestDetails"
    [changeRequest]="changeRequest"
    [translationsData]="serviceChangesRequestTranslations"
    (reviewTranslationsCompleted)="onStepFormSubmitted($event)">
  </irembogov-review-translations>
</ng-template>

<ng-template #reviewPreviewSubmit>
  <irembogov-irembo-loading-circle-spinner
    marginTop="8rem"
    *ngIf="
      isLoadingChangeRequestDetails
    "></irembogov-irembo-loading-circle-spinner>

  <irembogov-review-submission
    *ngIf="!isLoadingChangeRequestDetails"
    [changeRequestId]="changeRequest.id"
    (reviewApproved)="OnReviewApproved($event)"
    (reviewRejected)="OnReviewRejected($event)"></irembogov-review-submission>
</ng-template>
