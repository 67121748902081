<div
  class="review-workflow-entry w-100 d-flex p-3"
  *ngIf="changeRequestJsonWorkflow">
  <div class="flex-grow-1 mw-70">
    <div class="w-100 px-3">
      <pre
        class="border bg-white overflow-auto w-100 p-3"
        [innerHTML]="
          changeRequestJsonWorkflow | prettyjson : { escapeHtmlBracket: true }
        "
        *ngIf="changeRequestJsonWorkflow"></pre>
    </div>
  </div>
  <irembogov-reviewer-issues-entry-form-widget
    class="justify-self-end w-30"
    [changeRequestId]="changeRequest.id"
    [reviewSection]="EServiceChangeRequestReviewSection.WORKFLOW"
    (goToNext)="
      reviewWorkflowJsonCompleted.emit($event)
    "></irembogov-reviewer-issues-entry-form-widget>
</div>
