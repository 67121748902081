<div
  class="review-translations-overview w-100 d-flex p-3"
  *ngIf="changeRequest">
  <div class="">
    <div class="languages-tab-buttons mt-5 mx-5" *ngIf="translationsData">
      <button
        type="button"
        *ngFor="let language of serviceLanguages; let index = index"
        class="btn px-2 d-block mb-3 font-weight-700 text-start"
        [ngClass]="{
          'btn-primary': activeLanguage?.locale === language.locale,
          'border border-light bg-white':
            activeLanguage?.locale !== language.locale,
          'border-end': !index
        }"
        (click)="updateLanguageTranslationsByLanguage(language)">
        {{ language.name }}
      </button>
    </div>
    <div
      class="small pointer-cursor text-center text-muted mt-2"
      *ngIf="
        !isLoadingLanguages &&
        serviceLanguages.length < serviceLanguagesCollectionSize
      "
      (click)="loadMoreLanguages()">
      Load more
    </div>
    <!-- Toggle Buttons END-->
  </div>
  <div class="bg-white rounded mt-5 w-70">
    <div class="text-center text-warning mt-5" *ngIf="!selectedTranslation">
      No translation for this language.
    </div>
    <div *ngFor="let keyValue of selectedTranslation | keyvalue">
      <div class="row">
        <form>
          <div class="row p-3">
            <div class="col">
              <textarea
                class="form-control"
                value="{{ keyValue.key }}"
                [attr.disabled]="true"></textarea>
            </div>
            <div class="col">
              <textarea
                class="form-control"
                value="{{ keyValue.value }}"
                [attr.disabled]="true"></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <irembogov-reviewer-issues-entry-form-widget
    class="justify-self-end ms-5 mt-5 w-30"
    [changeRequestId]="changeRequest.id"
    [reviewSection]="EServiceChangeRequestReviewSection.TRANSLATION"
    (goToNext)="
      reviewTranslationsCompleted.emit($event)
    "></irembogov-reviewer-issues-entry-form-widget>
</div>
