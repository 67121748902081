import { Component, Input } from '@angular/core';
import { EServiceChangeRequestReviewStatus } from '../../../../core/models/service-change-request-review-issue.model';

@Component({
  selector: 'irembogov-reviewer-issue-status-badge-widget',
  templateUrl: './reviewer-issue-status-badge-widget.component.html',
  styleUrls: ['./reviewer-issue-status-badge-widget.component.scss'],
})
export class ReviewerIssueStatusBadgeWidgetComponent {
  @Input() requestStatus?: EServiceChangeRequestReviewStatus;
  EServiceChangeRequestReviewStatus = EServiceChangeRequestReviewStatus;
}
